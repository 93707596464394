import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, CircularProgress, styled} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {getUserProfileThunk, ViaUserProfile} from "../../../../features/user/userSlice";
import {RootState} from "../../../../redux/store";
import {BasicModal} from "../../../../shared/Modal";
import {AcceptTermsModalContent} from "./modal/AcceptTermsModalContent";
import {profileServiceApi} from "../../../../services/profile-service";
import {generalMessage} from "../../../../constants/common";
import {ErrorTypography} from "../../../../shared/styles";
import {checkIsAnonymousUser} from "../../../../util/storage";
import {useTheme} from "@mui/material/styles";

const ongoingAcceptedTermsVersion = 3;
const CTAButton = styled(Button)(({theme}) => ({
    borderRadius: "24px",
    height: "48px",
    marginTop: "10px",
    fontSize: "18px",
    fontWeight: "600",
    "&:disabled": {
       backgroundColor: theme.palette.text.primary,
        color: theme.palette.grey[700]
    }
}));
export const AcceptTerms = () => {
    const theme = useTheme()
    const dispatch = useAppDispatch();
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const [ trigger, {isLoading, data, error} ] = profileServiceApi.endpoints.updateProfile.useLazyQuery();
    const [contentRead, setContentRead] = useState(false);


    useEffect(() => {
        if (data) {
            dispatch(getUserProfileThunk());
        }
    }, [data, dispatch]);

    const handleAccept = () => {
        if (contentRead) {
            trigger({user: { accepted_terms_version: ongoingAcceptedTermsVersion }});
        }
    };

    if(theme.custom.isWhiteLabelled){
        return <Outlet/>
    }

    if (((profile?.user.accepted_terms_version || 0) < ongoingAcceptedTermsVersion) && !checkIsAnonymousUser() ) {
        return (
            <BasicModal
                blockClickOutside
                actionContent={
                    <>
                        <CTAButton
                            fullWidth
                            disabled={!contentRead || isLoading}
                            variant="contained"
                            endIcon={isLoading && <CircularProgress size={16} sx={{ml: "5px"}} color="secondary"/>}
                            onClick={handleAccept}
                        >
                            Accept and Continue
                        </CTAButton>
                        {error && <ErrorTypography>{generalMessage}</ErrorTypography>}
                    </>
                }
            >
                <AcceptTermsModalContent setContentRead={setContentRead}/>
            </BasicModal>
        );
    }
    return <Outlet />;
}
