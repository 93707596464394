import React from 'react'
import {Box, Link, styled, Typography} from "@mui/material";
import {routes} from "../../../constants/routes";
import {useNavigate} from "react-router-dom";
import {U13MessageTopic} from "../../gateway/U13Message";

const StyledBox = styled(Box)(({theme})=> ({
        color: theme.custom.darkerPrimaryColor
}))


export interface AskParentProps {
    topic: U13MessageTopic
}

export const AskParent = (props: AskParentProps): React.ReactElement => {
    const navigate = useNavigate()

    const messages: { [key in U13MessageTopic]: string} = {
        feedback: 'Please ask your parent/guardian to purchase your Academy Feedback',
        training: 'Please ask your parent/guardian to purchase your Pro Membership',
    };


    return (<StyledBox>
        <Typography sx={{
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: "24px",
            padding: "8px",
        }}>{messages[props.topic]}. <Link sx={{cursor: "pointer"}} onClick={() => navigate(routes.u13+"?topic=" + props.topic)}>Learn more.</Link></Typography>
        
    </StyledBox>)
}