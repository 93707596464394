import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {IAcademy} from "../../../types/categories";
import {ICompleteUploadStepArgs} from "../upload-video/UploadVideoStep";
import {IPaymentCardsData} from "../payment-cards/PaymentCardsStep";
import {PayReviewStep} from "./PayReviewStep";
import {createMonthlyDrawRequestData} from "../../order/requestData";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {MediaUploadResponse, UploadFilename} from "../../media/mediaSlice";
import {coachFeedbackServiceApi} from "../../../services/coach-feedback-service";
import {getErrorMessage} from "../../../util/error";
import {routes} from "../../../constants/routes";
import {ICommonStepProps, ICommonVideoStepProps} from "../../../types/stepProps";
import {Analytics} from "../../../lib/analytics";
import {IPlayerInformationStepData} from "../../../hooks/usePurchaseStepDataStorage";

interface IReviewStepProps extends ICommonStepProps, ICommonVideoStepProps {
    selectedAcademy?: IAcademy;
    uploadVideoStepData: ICompleteUploadStepArgs | null;
    uploadVideoInBackgroundCB: (droppedFile: File) => void;
    videoPreviewUrl: string;
    setVideoPreviewUrl: (value: string) => void;
    playerInformationStepData: IPlayerInformationStepData | null,
    improvementAreasStepData: string[];
    paymentCardsStepData: IPaymentCardsData | null;
}

export const ReviewStep = ({
   uploadVideoStepData,
   improvementAreasStepData,
   playerInformationStepData,
   videoPreviewUrl,
   ...props
}: IReviewStepProps) => {
    const navigate = useNavigate();
    const [ trigger, {isLoading: orderSubmitting} ] = coachFeedbackServiceApi.endpoints.submitMonthlyDraw.useLazyQuery();
    const fileData = useAppSelector<UploadFilename | undefined>((state: RootState) => state.media.uploadFilename);
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse);
    const [submitError, setSubmitError] = useState<string>("");

    const createOrder = async () => {
        if (!uploadResponse) {
            return;
        }

        setSubmitError("");
        const response = await trigger(createMonthlyDrawRequestData({
            othersInVideo: uploadVideoStepData!.otherPlayersExist,
            othersInVideoComment: uploadVideoStepData!.otherPlayersComment,
            orderName: playerInformationStepData ? playerInformationStepData.firstName || "" : "",
            orderAge: playerInformationStepData ? playerInformationStepData.age : undefined,
            orderPosition: playerInformationStepData? playerInformationStepData.position || "" : "",
            improvementAreas: improvementAreasStepData,
            videoUrlKey: uploadResponse.key,
            videoMimeType: fileData!.fileType
        }));

        if (response.error) {
            setSubmitError(getErrorMessage(response.error));
            return;
        }

        Analytics.competitionOrderSubmitted();
        navigate(routes.monthlyDrawCreated);
    }
    return (
        <PayReviewStep
            {...props}
            uploadVideoStepData={uploadVideoStepData}
            playerInformationStepData={playerInformationStepData}
            improvementAreasStepData={improvementAreasStepData}
            videoPreviewUrl={videoPreviewUrl}
            submitError={submitError}
            setSubmitError={setSubmitError}
            parentOrderSubmitting={orderSubmitting}
            onMonthlyDrawSubmit={createOrder}
        />
    );
}
