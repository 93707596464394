import {useEffect, useState} from "react";

export const useVideoDimensions = (url: string) => {

    const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const video = document.createElement('video');

        const onLoadedMetadata = () => {
            setDimensions({
                width: video.videoWidth,
                height: video.videoHeight,
            });
        };

        const onError = () => {
            setError('Failed to load video metadata');
        };

        video.addEventListener('loadedmetadata', onLoadedMetadata);
        video.addEventListener('error', onError);
        video.src = url;
        video.load();

        // Cleanup event listeners
        return () => {
            video.removeEventListener('loadedmetadata', onLoadedMetadata);
            video.removeEventListener('error', onError);
        };
    }, [url]);

    return { dimensions, error };
};