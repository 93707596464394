import React from "react";
import {Box, styled} from "@mui/material";
import {CenteredItemsBox, VerticallyCenteredItemsBox} from "./styles";

interface IFullScreenContentProps {
    actionButtons: JSX.Element;
    children: JSX.Element;
}
const CenteredBox = styled(VerticallyCenteredItemsBox)(({theme}) => ({
    flexDirection: "column",
    flex: 1,
    margin: "30px 0",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
        margin: "20px 0",
        gap: "20px",
    },
}));
export const CenteredBodyBox = styled(CenteredItemsBox)(() => ({
    flexDirection: "column",
    gap: "20px"
}));
export const FullScreenContent = ({actionButtons, children}: IFullScreenContentProps): JSX.Element => {
    return (
        <CenteredBox>
            {children}
            <Box>
                {actionButtons}
            </Box>
        </CenteredBox>
    );
}
