import React, {useEffect, useRef, useState} from 'react';
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Button,
  Box,
  useTheme
} from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {
    LikertScalePage,
    submitLikertResponse,
    upgradeItemPreviewThunk
} from "../../../features/academyContent/academyContentSlice";
import {useAppDispatch} from "../../../redux/hooks";
import {Loader} from "../../../shared/Loader";
import {StyledUserFeedbackTextField} from "../../../shared/styles";
import {checkIsAnonymousUser} from "../../../util/storage";
import {StyledCardClickOverlay} from "../AcademyUpdates";

enum LikertOptions {
    very_dissatisfied=-2,
    dissatisfied=-1,
    neutral=0,
    satisfied=1,
    very_satisfied=2,
}

export interface LikertScaleProps {
    item: LikertScalePage
    likertResponseSending?: boolean
    addLikertResponseSending?: (newVal: number) => void
    likertDone?: boolean
    addLikertItemDone?: (newVal: number) => void
    activateSubWallFn?: () => void
    showingSubWall?: boolean
}


export const LikertScale = (props: LikertScaleProps) => {
    const dispatch = useAppDispatch()
  const [selectedEmoji, setSelectedEmoji] = useState<number|undefined>(undefined);
    const [charRemaining, setCharRemaining] = useState(144);
  const [comment, setComment] = useState<string>('');
    const submitted = props.item.response_data.user_has_submitted
  const theme = useTheme();
    const containerRef = useRef(null);
    const [overlayStyle, setOverlayStyle] = useState({});

    useEffect(() => {
        if (containerRef.current) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            setOverlayStyle({
                width: `${offsetWidth}px`,
                height: `${offsetHeight}px`
            });
        }
    }, [containerRef]);

    const handleEmojiClick = (emoji: number) => {
      if(!props.likertResponseSending){
          setSelectedEmoji(emoji);
      }
  };

    const handleCommentChange = (event:any) => {
        if(charRemaining > 0 || event.target.value.length < 144){
            setComment(event.target.value);
            setCharRemaining(144 - event.target.value.length)
        }
    };

  const handleSubmit = () => {
      if(selectedEmoji !== undefined){
          props.addLikertResponseSending?.(props.item.id)
          dispatch(submitLikertResponse({id: props.item.id, value: selectedEmoji, comment: comment, destination: props.item.response_data.submission_url}))
          dispatch(upgradeItemPreviewThunk(props.item.id.toString()))
      }
  };

  // if (checkIsAnonymousUser()) {
  //   return null;
  // }
  return (<>
          {
              (submitted && props.likertResponseSending && !props.likertDone)
              || !submitted ?
              <Card raised sx={{mx: 'auto', mb: 2, position: "relative"}}
                    ref={containerRef}
              >
                  { checkIsAnonymousUser() && !props.showingSubWall &&
                      <StyledCardClickOverlay
                          style={overlayStyle}
                          onClick={e=>{
                              e.stopPropagation()
                              if(checkIsAnonymousUser() && props.activateSubWallFn) {
                                  props.activateSubWallFn()
                              }
                          }}
                      ></StyledCardClickOverlay>
                  }
                  <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 1}}>
                      <Typography sx={{
                          color: theme.custom.communityLikertTextColor,
                          fontSize: '24px',
                          fontWeight: '600',
                          flexGrow: 1,
                          lineHeight: "30.24px",
                          paddingTop: theme.spacing(3),
                          paddingBottom: theme.spacing(1),
                          paddingX: theme.spacing(2)
                      }}>
                          {/*{submitted?"submitted":"not_submitted"}<br/>*/}
                          {/*{props.likertResponseSending?"sending":"not_sending"}<br/>*/}
                          {/*{props.likertDone?"done":"not_done"}<br/>*/}
                          { !submitted ? props.item.question : "Thank you for your feedback!" }
                      </Typography>
                  </Box>
                  <CardContent sx={{paddingX: theme.spacing(1)}}>
                      {!submitted ? (
                          <>
                              <Box display="flex" justifyContent="space-between" mb={2} sx={{paddingTop: "0"}}>
                                  <IconButton sx={{padding: theme.spacing(1)}} onClick={() => handleEmojiClick(LikertOptions.very_dissatisfied)}
                                              color={selectedEmoji === LikertOptions.very_dissatisfied ? 'primary' : 'default'}>
                                      <SentimentVeryDissatisfiedIcon fontSize="large"/>
                                  </IconButton>
                                  <IconButton sx={{padding: theme.spacing(1)}} onClick={() => handleEmojiClick(LikertOptions.dissatisfied)}
                                              color={selectedEmoji === LikertOptions.dissatisfied ? 'primary' : 'default'}>
                                      <SentimentDissatisfiedIcon fontSize="large"/>
                                  </IconButton>
                                  <IconButton sx={{padding: theme.spacing(1)}} onClick={() => handleEmojiClick(LikertOptions.neutral)}
                                              color={selectedEmoji === LikertOptions.neutral ? 'primary' : 'default'}>
                                      <SentimentNeutralIcon fontSize="large"/>
                                  </IconButton>
                                  <IconButton sx={{padding: theme.spacing(1)}} onClick={() => handleEmojiClick(LikertOptions.satisfied)}
                                              color={selectedEmoji === LikertOptions.satisfied ? 'primary' : 'default'}>
                                      <SentimentSatisfiedIcon fontSize="large"/>
                                  </IconButton>
                                  <IconButton sx={{padding: theme.spacing(1)}} onClick={() => handleEmojiClick(LikertOptions.very_satisfied)}
                                              color={selectedEmoji === LikertOptions.very_satisfied ? 'primary' : 'default'}>
                                      <SentimentVerySatisfiedIcon fontSize="large"/>
                                  </IconButton>
                              </Box>
                              {(selectedEmoji !== undefined) && (
                                  <Box>
                                      <StyledUserFeedbackTextField
                                          disabled={props.likertResponseSending}
                                          label={charRemaining + ""}
                                          multiline
                                          minRows={3}
                                          fullWidth
                                          value={comment}
                                          onChange={handleCommentChange}
                                          margin="normal"
                                          variant="outlined"
                                          inputProps={{
                                              style: {color: theme.custom.communityLikertTextColor},
                                          }}
                                      />
                                      <Box display="flex" justifyContent="flex-end" mt={2}>
                                          {props.likertResponseSending ?
                                              <Loader/>
                                              :
                                              <Button fullWidth={true}
                                                      disableElevation={true}
                                                      variant="contained"
                                                      onClick={handleSubmit}
                                                      sx={{padding: theme.spacing(2), color: "#FFFFFF"}}
                                              >
                                                  SUBMIT
                                              </Button>
                                          }
                                      </Box>
                                  </Box>
                              )}
                          </>
                      ) : (
                          <Box textAlign="center">
                              <Button variant="outlined" sx={{mt: 2}}
                                      onClick={() => props.addLikertItemDone?.(props.item.id) }>
                                  Done
                              </Button>
                          </Box>
                      )}
                  </CardContent>
              </Card>
              : null
          }
      </>
  );
};
