export enum DeviceType {
    iPhone,
    Android,
    Desktop
}

export const getDeviceType = (): DeviceType => {
    const deviceAgent = navigator.userAgent;

    if (deviceAgent.includes("iPhone")) {
        return DeviceType.iPhone;
    }

    if (deviceAgent.includes("Android")) {
        return DeviceType.Android;
    }

    return DeviceType.Desktop;
}
