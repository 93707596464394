import React from "react"
import {useParams} from "react-router-dom";
import {PurchasedOrderCreated} from "./PurchasedOrderCreated";
import {MonthlyDrawOrderCreated} from "./MonthlyDrawOrderCreated";
import {Box, styled} from "@mui/material";
import { SpacedMain } from "../../../shared/styles";

const StatusBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
}));
export const OrderCreated = (): JSX.Element => {
    const { order } = useParams();
    return (
        <SpacedMain>
            <StatusBox>
                {order === "purchase-order" ? (
                    <PurchasedOrderCreated />
                ) : <MonthlyDrawOrderCreated/>}
            </StatusBox>
        </SpacedMain>
    );
}
