import React, {useEffect} from 'react'
import {LoaderWithWatchdog} from "../../shared/LoaderWithWatchdog";
import {useNavigate, useParams} from "react-router-dom";
import {
    checkIsAnonymousUser, getAttemptedPage, getGotoUrl,
    setAttemptedPage,
    setCurrentSiteSection,
    setRedirectAfterLogin,
    setSignupMode,
    SiteSections
} from "../../util/storage";
import {
    checkForHydrationTokens,
    clearPreventEmailSignInTimeOut,
} from "../../services/cognito";
import {createAnonAccountThunk} from "../../features/user/userSlice";
import {useAppDispatch} from "../../redux/hooks";
import {routes} from "../../constants/routes";
import {useTheme} from "@mui/material";

export const DirectPageAccess = (): React.ReactElement => {
    const params = useParams();
    const pagePath = params.pagePath ? params.pagePath : "/"
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()


    setAttemptedPage('/' + pagePath)


    useEffect(() => {
        const AnonLoginClickHandler = (goto: SiteSections) => {
            setCurrentSiteSection(goto)
            setSignupMode(0, false, true)
            clearPreventEmailSignInTimeOut()
            dispatch(createAnonAccountThunk())
        }

        const hasRehydrationTokens = checkForHydrationTokens()
        console.log("checkHT: ", hasRehydrationTokens)
        if(!theme.custom.isWhiteLabelled || hasRehydrationTokens){
            navigate(getAttemptedPage())
        }
        if(checkIsAnonymousUser()){
            console.log("isAnon on Directr")
            navigate(
                getAttemptedPage() === "/" ? routes.home
                    : getGotoUrl()
            )
        }else{
            if(pagePath.toUpperCase() === 'FEEDBACK'){
                setRedirectAfterLogin()
                AnonLoginClickHandler("Feedback")
            }else{
                navigate(
                    getAttemptedPage() === "/" ? routes.home
                        : getGotoUrl()
                )
            }
        }
    }, [navigate,pagePath, dispatch, theme.custom.isWhiteLabelled]);



    return (<LoaderWithWatchdog/>)
}