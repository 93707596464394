import React, {useEffect, useRef, useState} from 'react'
import {Box, Button, Chip, styled, Typography, useTheme} from "@mui/material";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
    deleteSubscriptionThunk,
    getUserSubscriptionsThunk,
    UserSubscription
} from "../../features/subscriptions/subscriptionsSlice";
import {RootState} from "../../redux/store";
import {formatDateStringFromDB} from "../../lib/utils";
import {Loader} from "../../shared/Loader";
import {Analytics} from "../../lib/analytics";

const OutlineChip = styled(Chip)(({ theme }) => ({
    border: `1px solid ${theme.custom.darkerPrimaryColor}`,
    backgroundColor: 'transparent',
    borderRadius: "4px",
    color: theme.custom.darkerPrimaryColor,
    cursor: 'default',
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const StyledHr = styled('hr')(()=>({
    borderTop: "0.5px solid #B3BDC8",
    width: "100%",
    margin: "0",
}))

const StyledHeading = styled(Typography)(()=>({
    fontSize: "22px",
    fontWeight: 500,
    lineHeight: "16px",
    margin: "0 16px 16px",
}))

const StyledPlanItem = styled(Typography)(()=>({
    alignItems: "center",
    padding: "8px 0",
    display: "flex",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    gap: "8px"
}))

const StyledDoneIcon = styled(DoneOutlinedIcon)(()=>({
    fontSize: "14px",
}))

export type ActiveSubscriptionState = "UNRECOGNISED" | "ACTIVE_RENEWABLE" | "ACTIVE_CANCELLED" | "TRIAL_PROMISING" | "TRIAL_CANCELLED"

export interface AccountSubscriptionPageContent {
    paymentTypography: React.ReactElement
    cancelWarningText?: string
    confirmCancelButtonText: string
    cancelButton: React.ReactElement
}


export const AccountSubscriptions = (): React.ReactElement => {
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const activeProSubscription = useAppSelector<UserSubscription|undefined>((state: RootState) => state.subscriptions.activatedSubscription);
    const [confirmSubCancel, setConfirmSubCancel] = useState<boolean>(false)
    const [pollForCancelled, setPollForCancelled] = useState<string|undefined>(undefined)

    const timeoutId = useRef<NodeJS.Timeout | null>(null); // Using useRef to store the timeout ID
    useEffect(() => {
        const checkSub = () => {
            dispatch(getUserSubscriptionsThunk())
            timeoutId.current = setTimeout(() => {
                if(pollForCancelled && activeProSubscription && !(activeProSubscription.cancelled || activeProSubscription.status === "PAST_DUE")){
                    checkSub()
                }else{
                    setPollForCancelled(undefined)
                }
            }, 2500)
        }
        if(pollForCancelled && activeProSubscription && !(activeProSubscription.cancelled || activeProSubscription.status === "PAST_DUE")){
            checkSub()
        }
        return () => {
            if(timeoutId.current){
                clearTimeout(timeoutId.current)
            }
        }
    },[dispatch, activeProSubscription, pollForCancelled])


    let subscriptionState: ActiveSubscriptionState = "UNRECOGNISED";

    if(activeProSubscription?.status === "ACTIVE"){
        subscriptionState = "ACTIVE_RENEWABLE"
    }else if(activeProSubscription?.status === "PAST_DUE"){
        subscriptionState = "ACTIVE_CANCELLED"
    }else if(activeProSubscription?.status === "TRIALING" && !activeProSubscription.cancelled){
        subscriptionState = "TRIAL_PROMISING"
    }else if(activeProSubscription?.status === "TRIALING" && activeProSubscription.cancelled){
        subscriptionState = "TRIAL_CANCELLED"
    }


    useEffect(()=>{
        dispatch(getUserSubscriptionsThunk())
    },[dispatch])

    useEffect(() => {
        Analytics.myAccountSubscriptionView();
    }, [])

    const pageContent: { [key in ActiveSubscriptionState]: AccountSubscriptionPageContent } = {
        ACTIVE_RENEWABLE: {
            paymentTypography:  <Typography sx={{
                padding: "0 16px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
            }}>Your next bill is for £{activeProSubscription?.product.price} and is due on {formatDateStringFromDB(activeProSubscription?.period_end)}
            </Typography>,
            cancelWarningText: `Cancelling your subscription will limit your access to Academy Training after ${formatDateStringFromDB(activeProSubscription?.period_end)}, are you sure?`,
            confirmCancelButtonText: "CANCEL SUBSCRIPTION",
            cancelButton: <>
                {confirmSubCancel ?
                    <Button sx={{ marginTop: "8px"}} variant="text" color="primary" onClick={()=>{setConfirmSubCancel(false)}}>Keep Subscription</Button>
                    :
                    <Button sx={{color: "#D2232A", marginTop: "8px"}} variant="text" color="primary" onClick={() => {
                        setConfirmSubCancel(true)
                        setTimeout(() => window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'}), 20)
                    }}>Cancel Subscription</Button>
                }
            </>
        },
        ACTIVE_CANCELLED: {
            paymentTypography: <Typography sx={{
                padding: "0 16px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#D2232A",
            }}>Your subscription will end on {formatDateStringFromDB(activeProSubscription?.period_end)}</Typography>,
            cancelWarningText: undefined,
            confirmCancelButtonText: "",
            cancelButton: <Button disabled={true} sx={{marginTop: "8px"}} variant="text" color="primary">Subscription Cancelled</Button>,
        },
        TRIAL_PROMISING: {
            paymentTypography: <Typography sx={{
                padding: "0 16px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
            }}>Your free trial ends on {formatDateStringFromDB(activeProSubscription?.period_end)} and you will be
                charged £{activeProSubscription?.product.price} unless you cancel</Typography>,
            cancelWarningText: `Cancelling your free trial will limit your access to Academy Training after ${formatDateStringFromDB(activeProSubscription?.period_end)}, are you sure?`,
            confirmCancelButtonText: "CANCEL FREE TRIAL",
            cancelButton: <>
                {confirmSubCancel ?
                    <Button sx={{ marginTop: "8px"}} variant="text" color="primary" onClick={()=>{setConfirmSubCancel(false)}}>Keep Free Trial</Button>
                    :
                    <Button sx={{ color: "#D2232A", marginTop: "8px"}} variant="text" color="primary" onClick={()=>{
                        setConfirmSubCancel(true)
                        setTimeout(() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }), 20)
                    }}>Cancel Free Trial</Button>
                }
            </>

        },
        TRIAL_CANCELLED: {
            paymentTypography: <Typography sx={{
                padding: "0 16px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#D2232A",
            }}>Your free trial will end on {formatDateStringFromDB(activeProSubscription?.period_end)}</Typography>,
            cancelWarningText: undefined,
            confirmCancelButtonText: "",
            cancelButton: <Button disabled={true} sx={{marginTop: "8px"}} variant="text" color="primary">Free trial cancelled</Button>
        },
        UNRECOGNISED: {
            paymentTypography: <Typography sx={{
                padding: "0 16px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                // color: "#D2232A",
            }}>
                {activeProSubscription ?
                activeProSubscription.cancelled ?
                    `This subscription/trial has been cancelled. You will have access until ${formatDateStringFromDB(activeProSubscription?.period_end)} and no further payments will be made.`
                    :
                    `This subscription/trial has not been cancelled. You will be charged £${activeProSubscription.product.price} after ${formatDateStringFromDB(activeProSubscription?.period_end)}.`
                :
                "Error: Subscription not found."
                }<br/><br/>
                SKU: {activeProSubscription?.product.sku} ({activeProSubscription?.product.name})<br/>
                Subscription/Trial Start Date: {formatDateStringFromDB(activeProSubscription?.period_start)}<br/>
                Subscription/Trial End Date: {formatDateStringFromDB(activeProSubscription?.period_end)}<br/>
                Subscription Price:  £{activeProSubscription?.product.price} per period<br/>
            </Typography>,
            cancelWarningText: `Cancelling your subscription/trial will limit your access to Academy Training after ${formatDateStringFromDB(activeProSubscription?.period_end)}, are you sure?`,
            confirmCancelButtonText: "CANCEL SUBSCRIPTION/TRIAL",
            cancelButton: (activeProSubscription?.cancelled ?
                <Button disabled={true} sx={{marginTop: "8px"}} variant="text" color="primary">Subscription/Trial is cancelled</Button>
                :
                confirmSubCancel ?
                    <Button sx={{marginTop: "8px"}} variant="text" color="primary" onClick={() => {
                        setConfirmSubCancel(false)
                    }}>Keep Subscription/Trial</Button>
                    :
                    <Button sx={{color: "#D2232A", marginTop: "8px"}} variant="text" color="primary" onClick={() => {
                        setConfirmSubCancel(true)
                        setTimeout(() => window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'}), 20)
                    }}>Cancel Subscription/Trial</Button>
            ),
        }
    }


    return (
        <Box sx={{
            // padding: "16px",
            color: theme.custom.darkerPrimaryColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "space-between",
            minHeight: "92vh"
        }}>
            <Box sx={{padding: "16px 16px 0 16px" }}><img style={{maxHeight: "48px"}} src={!!theme.custom.brandAcademyLogoHorizontal ? theme.custom.brandAcademyLogoHorizontal : theme.custom.brandTitleLogo}
                      alt={theme.custom.academyCommonName + " Logo"}/></Box>
            { !!activeProSubscription ?
                <>
                    <Typography
                        sx={{
                            padding: "0 16px",
                            fontSize: "22px",
                            fontWeight: 500,
                        }}
                    >Pro Membership</Typography>
                    <Box sx={{
                        padding: "0 16px",
                    }}><OutlineChip label={
                        activeProSubscription.product.sku === theme.custom.monthlyProSubscriptionSKU ?
                            "Monthly"
                            :
                            activeProSubscription.product.sku === theme.custom.yearlyProSubscriptionSKU ?
                                "Yearly"
                                :
                                "No Subscription Found"
                    }/></Box>
                    <StyledHr sx={{margin: "16px 0"}}/>
                    <StyledHeading>Payment</StyledHeading>
                    {pageContent[subscriptionState].paymentTypography}
                    <StyledHr sx={{margin: "16px 0"}}/>
                    <StyledHeading>Plan Includes</StyledHeading>
                    <Box sx={{padding: "0 16px"}}>
                        <StyledPlanItem><StyledDoneIcon/><span>Access to the full {theme.custom.academyCommonName} Training library</span></StyledPlanItem>
                        <StyledPlanItem><StyledDoneIcon/><span>Training for all age groups from U9-U18</span></StyledPlanItem>
                        <StyledPlanItem><StyledDoneIcon/><span>Skills & holistic training</span></StyledPlanItem>
                        <StyledPlanItem><StyledDoneIcon/><span>New training content added each week</span></StyledPlanItem>
                    </Box>
                    <StyledHr sx={{margin: "16px 0 0"}}/>
                    <Box sx={{textAlign: "center"}}>
                        {confirmSubCancel &&
                            <Box sx={{
                                // marginTop: "16px",
                                backgroundColor: "#F4F4F4",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "16px",
                            }}>
                                <Typography sx={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    marginBottom: "24px"
                                }}>{pageContent[subscriptionState].cancelWarningText}</Typography>

                                <Button variant="outlined" color="error" onClick={() => {
                                    dispatch(deleteSubscriptionThunk(activeProSubscription.uuid))
                                    setPollForCancelled(activeProSubscription.uuid)
                                    setConfirmSubCancel(false)
                                    // navigate(routes.accountManagement)
                                }}>{pageContent[subscriptionState].confirmCancelButtonText}</Button>
                                {pageContent[subscriptionState].cancelButton}
                            </Box>
                        }
                    </Box>
                    { (pollForCancelled && !activeProSubscription.cancelled) ?
                        <Loader/>
                        :
                        !confirmSubCancel && pageContent[subscriptionState].cancelButton
                    }
                    <Box sx={{flexGrow: 1}}></Box>
                </>
                :
                <StyledHeading>Subscription Not Found.</StyledHeading>
            }
        </Box>
    )
}