import React, {useEffect, useState} from "react";
import {CircularProgress, styled, Typography} from "@mui/material";
import {CheckCircle, SyncProblem} from "@mui/icons-material";
import {MediaUploadStatus} from "../../../media/mediaSlice";
import {useAppSelector} from "../../../../redux/hooks";
import {RootState} from "../../../../redux/store";
import useQueryEditReasons from "../../../../hooks/useQueryEditReasons";

type StyledProgressProcessProps = {
    smoothhide?: string;
};
const StyledProgressProcess = styled(Typography)<StyledProgressProcessProps>(({theme, smoothhide}) => ({
    color: theme.custom.isWhiteLabelled ? theme.custom.wl.purchaseStepHeader.liveProgressTextColor : theme.palette.text.primary,
    position: "absolute",
    right: "0",
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    margin: "0 5px",
    fontWeight: "500",
    opacity: smoothhide ? 0 : 1,
    transition: smoothhide ? "opacity 0.2s ease-out" : "",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "10px",
        background: theme.custom.isWhiteLabelled ? theme.custom.wl.purchaseStepHeader.backgroundColor : "rgba(18,18,18, 0.7)",
    },
}));
export const UploadStatus = (): JSX.Element => {
    const uploadStatus = useAppSelector<MediaUploadStatus>((state: RootState) => state.media.uploadStatus);
    const [has] = useQueryEditReasons();
    const [successVisible, setSuccessVisible] = useState<boolean>(true);
    const showSuccessValue = uploadStatus === MediaUploadStatus.Success && (has.noIssues || has.videoReason);

    useEffect(() => {
        if (showSuccessValue) {
            const timeout = setTimeout(() => {
                setSuccessVisible(false);
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [uploadStatus, showSuccessValue, has.noIssues, has.videoReason]);

    return (
        <>
            {uploadStatus === MediaUploadStatus.Posting && <StyledProgressProcess variant="caption">
                Uploading...
                <CircularProgress size={16}/>
            </StyledProgressProcess>}
            {uploadStatus === MediaUploadStatus.Fail && <StyledProgressProcess variant="caption">
               <SyncProblem color="warning"/>
            </StyledProgressProcess>}
            {showSuccessValue && (
                <StyledProgressProcess variant="caption" smoothhide={successVisible ? "" : successVisible.toString()}>
                    Uploaded
                    <CheckCircle color="primary"/>
                </StyledProgressProcess>
            )}
        </>
    );
}
