import React from "react";
import {Typography, styled, Box, Divider, useTheme} from "@mui/material";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {useQuery} from "../hooks/useQuery";
import {editReason} from "../constants/urlParameters";
import {ColumnBox} from "./styles";
import DynamicTopBarColor from "../DynamicTopBarColor";

interface IProgressComponentProps {
    currentStep: number;
    totalSteps: number;
    backgroundProgressComponent: JSX.Element;
    stickyBox: JSX.Element | null;
}

const ProgressContainer = styled("div")(({theme}) => ({
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.wl.purchaseStepHeader.backgroundColor : theme.palette.grey[900],
    position: "sticky",
    top: "0",
    zIndex: 1,
}));
const StyledBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
}));
const ProgressText = styled(Typography)(({theme}) => ({
    textAlign: "center",
    padding: "10px 0",
    color: theme.custom.isWhiteLabelled ? theme.custom.wl.purchaseStepHeader.color : theme.palette.grey[500],
    fontWeight: "600",
    textTransform: "uppercase",
}));
const ProgressBar = styled(LinearProgress)(({theme}) => ({
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.wl.purchaseStepHeader.progressBackgroundColor  : "#414141",
    },
}));
const Logo = styled("img")({
    height: "24px",
});

export const ProgressComponent = ({currentStep, totalSteps, backgroundProgressComponent, stickyBox}: IProgressComponentProps): JSX.Element => {
    const theme = useTheme();
    const queryEditReason = useQuery().get(editReason);
    const progressPercentage = (currentStep / totalSteps) * 100;
    return (
        <>
            <DynamicTopBarColor color={theme.custom.mainBackgroundColor}/>
            <ProgressContainer>
                {queryEditReason && (
                    <>
                        <StyledBox>
                            <ColumnBox>
                                <ProgressText>Please help us resolve some issues</ProgressText>
                            </ColumnBox>
                            {backgroundProgressComponent}
                        </StyledBox>
                        <Divider sx={{borderWidth: "initial"}}/>
                    </>
                )}
                {!queryEditReason && <>
                    <StyledBox>
                        {theme.custom.name && (
                            <Logo src={!!theme.custom.brandAcademyLogoHorizontalDarkMode ? theme.custom.brandAcademyLogoHorizontalDarkMode : theme.custom.brandTitleLogo} alt="Logo" style={{ position: 'absolute', left: 16 }} />
                        )}
                        <ProgressText variant="subtitle2" align="center">Step {currentStep} of {totalSteps}</ProgressText>
                        {backgroundProgressComponent}
                    </StyledBox>
                    <ProgressBar variant="determinate" value={progressPercentage}/>
                </>}
                {stickyBox}
            </ProgressContainer>
        </>
    );
};
