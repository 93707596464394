import React, {useEffect, useState} from "react";
import {Sync} from "@mui/icons-material";
import {IAcademy} from "../../../types/categories";
import {ICompleteUploadStepArgs} from "../upload-video/UploadVideoStep";
import {resubmitOrderRequestData} from "../../order/requestData";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {MediaUploadResponse, MediaUploadStatus, UploadFilename} from "../../media/mediaSlice";
import {ReviewScreenSection} from "../../../shared/sections/ReviewScreenSection";
import {Box, styled, Typography} from "@mui/material";
import {defaultCurrency} from "../../../constants/currency";
import {FeedbackUploadZone} from "../../media/FeedbackUploadZone";
import {ScreenLayout} from "../../../shared/ScreenLayout";
import {categoriesServiceApi} from "../../../services/categories-service";
import {generalMessage} from "../../../constants/common";
import {ICoachFeedbackResult} from "../../../types/coachFeedback";
import {VerticallyCenteredItemsBox} from "../../../shared/styles";
import { PurchaseCtaBox, PurchaseStyledCTA, PurchaseListTypography } from "../../../shared/styles";
import {orderServiceApi} from "../../../services/order-service";
import {getErrorMessage} from "../../../util/error";
import {Stage} from "../../../constants/purchase";
import {useNavigate} from "react-router-dom";
import useQueryEditReasons from "../../../hooks/useQueryEditReasons";
import {useCustomNavigate} from "../../../hooks/useCustomNavigate";
import {update} from "../../../constants/urlParameters";
import {useQuery} from "../../../hooks/useQuery";
import {ICommonStepProps, ICommonVideoStepProps} from "../../../types/stepProps";
import {IPlayerInformationStepData} from "../../../hooks/usePurchaseStepDataStorage";
import {getCurrencySymbolWithCode} from "../../../lib/utils";

interface IReSubmitStepProps extends ICommonStepProps, ICommonVideoStepProps {
    videoPreviewUrl: string;
    setVideoPreviewUrl: (value: string) => void;
    uploadVideoStepData: ICompleteUploadStepArgs | null;
    uploadVideoInBackgroundCB: (droppedFile: File) => void;
    playerInformationStepData: IPlayerInformationStepData| null;
    improvementAreasStepData: string[];
}

const StyledAcademyTypography = styled(Typography)(({theme}) => ({
    fontWeight: "500",
    color: theme.palette.secondary.main
}));
export const ReSubmitStep = ({
     videoPreviewUrl,
     setVideoPreviewUrl,
     uploadVideoStepData,
     uploadVideoPercentage,
     uploadVideoInBackgroundCB,
     improvementAreasStepData,
     onCancelUpdateStep
}: IReSubmitStepProps) => {
    const navigate = useNavigate();
    const queryUpdate = useQuery().get(update);
    const modifyingOrder = useAppSelector<ICoachFeedbackResult | null>((state: RootState) => state.orderSlice.modifyingOrder);
    const {isFetching: academiesFetching, data: academies, error: fetchAcademiesError} = categoriesServiceApi.useGetCategoriesQuery("", {refetchOnMountOrArgChange: true, skip: !modifyingOrder?.organisationId});
    const [ trigger, {isLoading: orderSubmitting, error: orderError} ] = orderServiceApi.endpoints.resubmitOrder.useLazyQuery();
    const uploadStatus = useAppSelector<MediaUploadStatus>((state: RootState) => state.media.uploadStatus);
    const [selectedAcademy, setSelectedAcademy] = useState<IAcademy | null>(null);
    const [ctaTitle, setCtaTitle] = useState<string | JSX.Element>("");
    const [submitError, setSubmitError] = useState<string>("");
    const fileData = useAppSelector<UploadFilename | undefined>((state: RootState) => state.media.uploadFilename);
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse);
    const [has, , , allow] = useQueryEditReasons();
    const [navigateWithQueryParam] = useCustomNavigate();
    const onBackToEdit = (stage: Stage) => navigateWithQueryParam(update, stage);

    useEffect(() => {
        if (academiesFetching) {
            return;
        }
        if (!academies?.length || fetchAcademiesError) {
            setSubmitError(generalMessage);
            return;
        }

        const orderedAcademy = academies?.find(academy => academy.id === modifyingOrder?.organisationId);
        if (orderedAcademy) {
            setSelectedAcademy(orderedAcademy);
        } else {
            setSubmitError(generalMessage);
        }
    }, [academiesFetching, academies, fetchAcademiesError, modifyingOrder, setSelectedAcademy, setSubmitError]);
    useEffect(() => {
        setSubmitError("");
        if (uploadStatus === MediaUploadStatus.Posting) {
            setCtaTitle(<PurchaseCtaBox><PurchaseStyledCTA>Uploading your video... </PurchaseStyledCTA><Sync/></PurchaseCtaBox>);
            return;
        }
        if (uploadStatus === MediaUploadStatus.Fail) {
            setSubmitError("Sorry, your video upload failed. Please try again.");
        }
        if (orderError) {
            setSubmitError(getErrorMessage(orderError));
        }
        setCtaTitle("Submit");
    }, [uploadStatus, selectedAcademy, orderError, setSubmitError]);


    const reSubmitOrder = async () => {
        if (!modifyingOrder) {
            return;
        }
        if (has.videoReason && !uploadResponse) {
            return;
        }
        setSubmitError("");

        const response = await trigger({
            id: modifyingOrder.id,
            requestBody: resubmitOrderRequestData({
                othersInVideo: has.failedUploadVideoStepReason ? uploadVideoStepData!.otherPlayersExist : undefined,
                othersInVideoComment: has.failedUploadVideoStepReason ? uploadVideoStepData!.otherPlayersComment : undefined,
                improvementAreas: allow.modifyImprovementAreas ? improvementAreasStepData : undefined,
                videoUrlKey: has.videoReason ? uploadResponse!.key : undefined,
                videoMimeType: has.videoReason ? fileData!.fileType : undefined,
            })
        });
        if (!response.data) {
            return;
        }

        navigate("/order-submitted");
    }

    return (
        <ScreenLayout
            title={"Review & Submit"}
            ctaTitle={ctaTitle}
            ctaDisabled={(has.videoReason && uploadStatus !== MediaUploadStatus.Success) || orderSubmitting}
            errorMessage={submitError}
            onAction={reSubmitOrder}
            onCancel={queryUpdate ? onCancelUpdateStep : undefined}
        >
            <>
                {selectedAcademy && (
                    <ReviewScreenSection title="Choose academy" disabledSection>
                        <>
                            <VerticallyCenteredItemsBox sx={{margin: "10px 0"}}>
                                <img src={selectedAcademy.avatar} alt="team logo" width="32px"/>
                                <StyledAcademyTypography variant="h5">
                                    {getCurrencySymbolWithCode(selectedAcademy.currency_symbol ?? defaultCurrency.symbol)}{selectedAcademy.price}
                                </StyledAcademyTypography>
                            </VerticallyCenteredItemsBox>
                            <Typography variant="caption">Feedback ready within 10 days</Typography>
                        </>
                    </ReviewScreenSection>
                )}
                <ReviewScreenSection
                    title="Video"
                    disabledSection={!has.videoReason}
                    onEdit={() => onBackToEdit(Stage.uploadVideo)}
                >
                    <FeedbackUploadZone
                        showControls
                        showReplaceVideo={false}
                        uploadVideoPercentage={uploadVideoPercentage}
                        uploadVideoInBackgroundCB={uploadVideoInBackgroundCB}
                        previewUrl={videoPreviewUrl}
                        updatePreviewUrl={setVideoPreviewUrl}
                        isMonthlyDrawSelected={false}
                    />
                </ReviewScreenSection>
                <ReviewScreenSection
                    title="Are there others in your video?"
                    disabledSection={!has.failedUploadVideoStepReason}
                    onEdit={() => onBackToEdit(Stage.uploadVideo)}
                >
                  <Typography variant="body2">
                      {uploadVideoStepData ? uploadVideoStepData.otherPlayersExist : (modifyingOrder?.othersInVideo ? "Yes" : "No")}
                      {(uploadVideoStepData?.otherPlayersComment || modifyingOrder?.identificationNotes) && ', '}
                      {uploadVideoStepData ? uploadVideoStepData.otherPlayersComment : modifyingOrder?.identificationNotes}
                  </Typography>
                </ReviewScreenSection>
                {improvementAreasStepData.length && (
                    <ReviewScreenSection title="What do you want to improve on?" disabledSection={!allow.modifyImprovementAreas} onEdit={() => onBackToEdit(Stage.improvementAreas)}>
                        <PurchaseListTypography variant="caption">
                            <ol>
                                {improvementAreasStepData.map((value: string, index: number) => <li key={index + 1}>{value}</li>)}
                            </ol>
                        </PurchaseListTypography>
                    </ReviewScreenSection>
                )}
                <ReviewScreenSection title="Payment" showDivider={false} disabledSection>
                    <Box sx={{lineHeight: "22px", m: "20px 0"}}>
                        <Typography variant="body2">Payment not required</Typography>
                    </Box>
                </ReviewScreenSection>
            </>
        </ScreenLayout>
    );
}
