import React, {useCallback} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppBar, Divider, IconButton, Menu, MenuItem, Toolbar, Typography, styled, useTheme} from "@mui/material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {IUserMenu} from "../types/userMenu";
import CloseIcon from '@mui/icons-material/Close';
import {routes} from "../constants/routes";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DynamicTopBarColor from "../DynamicTopBarColor";
import {getGotoUrl} from "../util/storage";

export type HeaderMode = "Default" | "SubscriptionPurchase" | "AccountPage" | "AccountSubscriptions"

interface HeaderProps {
    title: string;
    userMenu: IUserMenu[];
    plan?: string;
    headerMode?: HeaderMode;
}

const Overlay = styled("div")(({theme}) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.black,
    opacity: 0.8,
    zIndex: 1,
}));
const StyledLogoLink = styled(Link)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))
const BrandTitle = styled(Typography)(({theme}) => ({
    fontSize: theme.custom.isWhiteLabelled ? "22px": "18px",
    flexGrow: 1,
    fontWeight: 600,
    textAlign: "left",
    color: theme.custom.headerBrandTitleColor,
    paddingLeft: "0.25em",
}));
const StyledToolbar = styled(Toolbar)(({theme}) => ({
    minHeight: "54px",
    backgroundColor: theme.custom.headerBrandTitleBackgroundColor,
}));
const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    padding: "16px",
    color: theme.palette.grey[500],
    fontSize: "14px",
    fontWeight: "500",
    "&:hover": {
        color: theme.palette.primary.main,
    },
}));

export const isAccountsSection = (mode: HeaderMode | undefined): boolean => {
    const s: string = mode + ""
    return (s.indexOf('Account') === 0)
}

export const Header = (props: HeaderProps): React.ReactElement => {
    console.log("plan", props.plan)
    const navigate = useNavigate()
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const toggleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [anchorEl]);
    const menuItemClick = useCallback((event: React.MouseEvent<HTMLElement>, handler: Function) => {
        handler();
        toggleMenu(event);
    }, [toggleMenu]);


    return (
        <>
            <DynamicTopBarColor color={theme.custom.headerBrandTitleBackgroundColor}/>
            <AppBar elevation={0} position="static" color="secondary" sx={{zIndex: 2}}>
                <StyledToolbar
                    sx={isAccountsSection(props.headerMode) ? {
                        paddingTop: 0,
                        paddingLeft: "8px",
                        minHeight: "8vh",
                    } : {}}
                >
                    {props.headerMode === "AccountPage" &&
                        <IconButton onClick={() => navigate(getGotoUrl(true))}>
                            <CloseIcon sx={{paddingLeft: 0, color: theme.custom.headerBrandTitleColor}}/>
                        </IconButton>
                    }
                    {props.headerMode === "AccountSubscriptions" &&
                        <IconButton onClick={() => navigate(routes.accountManagement)}>
                            <ArrowBackOutlinedIcon sx={{color: theme.custom.headerBrandTitleColor}}/>
                        </IconButton>
                    }
                    {!isAccountsSection(props.headerMode) &&
                        <StyledLogoLink to={"/"}>
                            <img style={{maxHeight: "32px"}} src={!!theme.custom.brandAcademyLogoHorizontalDarkMode ? theme.custom.brandAcademyLogoHorizontalDarkMode : theme.custom.brandTitleLogo}
                                 alt={(theme.custom.isWhiteLabelled ? theme.custom.academyCommonName || "Via Academy" : "Via Academy") + " Logo"}/>
                        </StyledLogoLink>
                    }
                    {props.headerMode === "AccountSubscriptions" &&
                        <BrandTitle className="use-header-font" sx={{fontSize: "18px", textAlign: "center", marginRight: "48px"}} color={theme.custom.headerBrandTitleColor}>Current Subscription</BrandTitle>
                    }
                    {!isAccountsSection(props.headerMode) &&
                        <BrandTitle className="use-header-font" color={theme.custom.headerBrandTitleColor}>{props.title}</BrandTitle>
                    }
                    {!isAccountsSection(props.headerMode) &&
                        <div>
                            {props.plan !== undefined ?
                                <IconButton onClick={() => navigate(routes.train + "?cancelPurchase=true")}>
                                    <CloseIcon sx={{color: theme.custom.headerBrandTitleColor}}/>
                                </IconButton>
                                :
                                <IconButton onClick={() => theme.custom.isWhiteLabelled ? navigate(routes.accountManagement) : toggleMenu}>
                                    {theme.custom.isWhiteLabelled ?
                                        <AccountCircleOutlinedIcon sx={{color: theme.custom.headerBrandTitleColor}}/>
                                        :
                                        <AccountCircleOutlinedIcon color={Boolean(anchorEl) ? "primary" : "secondary"}/>
                                    }
                                </IconButton>
                            }
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            anchorOrigin={{vertical: "bottom", horizontal: "right",}}
                            transformOrigin={{vertical: "top", horizontal: "right"}}
                            style={{marginTop: "12px"}}
                            slotProps={{
                                paper: {
                                    style: {
                                        padding: "0",
                                        backgroundColor: theme.palette.grey[900],
                                        backgroundImage: "none",
                                        border: "1px solid #414141",
                                        borderRadius: "16px",
                                    },
                                }
                            }}
                            open={Boolean(anchorEl)}
                            onClose={toggleMenu}
                        >
                            {
                                props.userMenu.filter(item=> theme.custom.isWhiteLabelled && item.title !== "My Account")
                                    .map((menuItem, index): React.ReactElement =>
                                        <div key={index}>
                                            <StyledMenuItem
                                                onClick={(event) => menuItemClick(event, menuItem.handler)}>
                                                {menuItem.title}
                                            </StyledMenuItem>
                                            {index !== props.userMenu.length - 1 &&
                                                <Divider style={{margin: "0 15px"}}/>}
                                        </div>
                                )
                            }
                        </Menu>
                        </div>
                    }
                </StyledToolbar>
            </AppBar>
            {Boolean(anchorEl) && <Overlay/>}
        </>
    )
}
