import React from "react";
import {CircularProgress} from "@mui/material";
import {CenteredItemsBox} from "./styles";

export const Loader = (props?: {size?: number|string}) => (
    <CenteredItemsBox sx={{flex: 1}}>
        { props ?
            <CircularProgress size={props.size}/>
            :
            <CircularProgress/>
        }
    </CenteredItemsBox>
)
