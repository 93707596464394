// navigationService.ts
import { NavigateFunction } from 'react-router-dom';

let navigate: NavigateFunction | null = null;

export const setNavigate = (navFn: NavigateFunction) => {
    navigate = navFn;
};

export const getNavigate = (): NavigateFunction => {
    if (!navigate) {
        throw new Error('Navigate function not set');
    }
    return navigate;
};