import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
    accessTokenKey,
    appErrorKey,
    appAccountErrorValue,
    idTokenKey,
    refreshTokenKey,
    appAccessErrorValue
} from "../../constants/urlParameters";
import {authorizeByToken, roleHasAccess, getIdToken, setIdToken, signOut} from "../../services/cognito";
import {getNavigationPath} from "../../util/url";
import {routes} from "../../constants/routes";
import {getUserProfileThunk, setAllowUserToRedirect, userLogout} from "../../features/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IExtendedErrorData} from "../../types/state";
import {RootState} from "../../redux/store";
import {Loader} from "../../shared/Loader";
import {useHash} from "../../hooks/useHash";
import {Analytics, SignInMethod} from "../../lib/analytics";


// login using tokens from the application
export const AppLogin = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const hashIdToken = useHash().get(idTokenKey);
    const hashAccessToken = useHash().get(accessTokenKey);
    const hashRefreshToken = useHash().get(refreshTokenKey);
    const errorData = useAppSelector<IExtendedErrorData | null>((state: RootState) => state.user.error);

    useEffect(() => {
        Analytics.appAutoLoginView();
    })

    useEffect(() => {
        if (errorData) {
            console.log("errorData exists", errorData)
            navigate(getNavigationPath(routes.signIn, {[appErrorKey]: appAccountErrorValue}));
        }
    }, [errorData, navigate]);

    useEffect(() => {
        const handleSignInFromApp = async () => {
            if (!hashIdToken || !hashAccessToken || !hashRefreshToken) {
                console.log()
                navigate(routes.signIn);
                return;
            }

            Analytics.signInAttempt(SignInMethod.Token);

            if (await getIdToken()) {
                console.log("falsey idToken, now signout & dispatch(userLogout)")
                await signOut();
                dispatch(userLogout());
            }

            try {
                console.log("hashIdToken", hashIdToken)
                console.log("hashAccessToken", hashIdToken)
                console.log("hashRefreshToken", hashIdToken)
                await authorizeByToken({
                    idToken: hashIdToken,
                    accessToken: hashAccessToken,
                    refreshToken: hashRefreshToken,
                });
                const hasAccess = await roleHasAccess();
                console.log("hasAccess", hasAccess)
                window.history.replaceState({}, document.title, window.location.pathname);

                if (hasAccess) {
                    await setIdToken();
                    dispatch(getUserProfileThunk());
                    dispatch(setAllowUserToRedirect(true));
                    Analytics.signInSuccess();
                    navigate(routes.home);
                    return;
                }

                console.log("got to end of try")
                navigate(getNavigationPath(routes.signIn, {[appErrorKey]: appAccessErrorValue}));
            } catch (e) {
                console.log("try got caught.")
                navigate(getNavigationPath(routes.signIn, {[appErrorKey]: appAccountErrorValue}));
            }

        }

        handleSignInFromApp();
    }, [hashIdToken, hashAccessToken, hashRefreshToken, dispatch, navigate]);

    return <Loader/>;
}
