import {Theme} from "@mui/material";
import {getOrganisationsList} from "../services/api";
import {UserProfileOrganisation} from "../features/user/userSlice";
import {setNewsFeedParentId, setTrainingFeedParentId} from "../services/content-api";


export const getOrgIdFromTheme = async (theme: Theme): Promise<string|undefined> => {
    const gotten = await getOrganisationsList()
    const orgs = gotten.results as UserProfileOrganisation[]
    const themeOrgs = orgs.filter(o => o.name === theme.custom.academyName)
    if(themeOrgs.length){
        return themeOrgs[0].uuid
    }else
        return undefined
}

export const setFeedParentPages = async (theme: Theme) => {
    await setNewsFeedParentId(theme.custom.newsFeedIndexSlug)
    await setTrainingFeedParentId(theme.custom.trainingFeedIndexSlug)
}
