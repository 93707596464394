import {useAppDispatch} from "../redux/hooks";
import {consentServiceApi} from "../services/consent-service";
import {coachFeedbackServiceApi} from "../services/coach-feedback-service";
import {categoriesServiceApi, couponCategoriesServiceApi} from "../services/categories-service";
import {orderServiceApi} from "../services/order-service";

export const useResetApiState = () => {
    const dispatch = useAppDispatch();
    const resetApiState = () => {
        dispatch(consentServiceApi.util.resetApiState());
        dispatch(coachFeedbackServiceApi.util.resetApiState());
        dispatch(categoriesServiceApi.util.resetApiState());
        dispatch(couponCategoriesServiceApi.util.resetApiState());
        dispatch(orderServiceApi.util.resetApiState());
    }

    return [resetApiState] as const;
}
