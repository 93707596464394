import {AppConfig} from "../config";
import ReactGA from "react-ga4";
import {ViaUserProfile} from "../features/user/userSlice";
import {Stage} from "../constants/purchase";
import {IAcademy} from "../types/categories";


enum SignInMethod {
    Apple = 'Apple',
    Facebook = 'Facebook',
    Google = 'Google',
    Token = 'Token',
    UsernamePassword = 'UsernamePassword'
}

interface UserInfo {
    id: string;
    email: string;
    acceptedTermsVersion: number;
    isMinor: boolean;
    dateOfBirth: number;
    signInMethod: SignInMethod | null;
}


class AnalyticsManager {

    private userInfo: UserInfo | null = null; // Possible switch to ViaUserProfile

    public setUserData(data: ViaUserProfile): void {
        this.userInfo = {
            id: data.uuid,
            email: data.user.email,
            acceptedTermsVersion: data.user.accepted_terms_version,
            isMinor: data.user.is_minor,
            dateOfBirth: data.date_of_birth,
            signInMethod: null
        };
        ReactGA.set({ userId: this.userInfo.id });
    }

    constructor() {
      ReactGA.initialize(AppConfig.ANALYTICS.GA_MEASUREMENT_ID, {
        testMode: AppConfig.ANALYTICS.TEST_MODE,
        gtagOptions: {
            send_page_view: false
        }});
    }

    public signInView() {
        ReactGA.event('page_view', {
            page_title: 'Sign In',
            page_location: '/sign-in'
        });
    }

    public appAutoLoginView() {
        ReactGA.event('page_view', {
            page_title: 'App Auto Login',
            page_location: '/app-sign-in'
        });
    }

    public signInAttempt(method: SignInMethod):void {
        if (this.userInfo) {
            this.userInfo.signInMethod = method;
        }
        ReactGA.event('button_click', {
            button_text: 'Sign In',
            method: method,
            page_title: 'Sign In',
            page_location: '/sign-in'
        });
    }

    public signInSuccess():void {
        let method = this.userInfo?.signInMethod || 'Unknown';
        ReactGA.event('login', {
            method : method,
            page_title: 'Sign In',
            page_location: '/sign-in'
        });
    }

    public signUp():void {
        let method = this.userInfo?.signInMethod || 'Unknown';
        ReactGA.event('sign_up', {
            method : method,
            page_title: 'Sign Up',
            page_location: '/sign-up'
        });
    }

    public landingView() {
        ReactGA.event('page_view', {
            page_title: 'Landing',
            page_location: '/landing'
        });
    }

    public getStartedButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'Get Started',
            page_title: 'Landing',
            page_location: '/landing'
        });
    }

    public createAccountAgeView() {
        ReactGA.event('page_view', {
            page_title: 'Create Account - Age',
            page_location: '/enterage'
        });
    }

    public createAccountAgeContinueButtonClick(age:number) {
        ReactGA.event('button_click', {
            button_text: 'Continue',
            page_title: 'Create Account - Age',
            page_location: '/enterage',
            age: age
        });
        if (age < 13) {
            this.createAccountUnderAgeDownloadView();
        } 
    }

    public createAccountSetAge(age:number) {
        let eventName = age < 13 ? 'underage' : 'overage';
        ReactGA.event(eventName, {
            page_title: 'Create Account - Age',
            page_location: '/enterage'
        });
    }

    public createAccountAgeClubLoginButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'Club Login',
            page_title: 'Create Account - Age',
            page_location: '/enterage',
        });
    }

    public createAccountAgeCreateAccountButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'Create account for free',
            page_title: 'Create Account - Age',
            page_location: '/enterage',
        });
    }

    public createAccountSocialSignUpView() {
        ReactGA.event('page_view', {
            page_title: 'Create Account - Social Sign Up',
            page_location: '/socialsignup'
        });
    }

    public selectSocialLogin(platform:string):void {
        ReactGA.event('select_item', {
            item_list_id: platform,
            item_list_name: platform,
            page_title: 'Create Account - Social Sign Up',
            page_location: '/createaccount',
        });
    }

    public createAccountSocialSignUpButtonClick(method: string) {
        ReactGA.event('button_click', {
            button_text: 'Agree and Continue',
            page_title: 'Create Account - Social Sign Up',
            page_location: '/socialsignup',
            method: method
        });
    }

    public createAccountSocialSignUpSuccess(method: string) {
        ReactGA.event('sign_up', {
            page_title: 'Create Account - Social Sign Up',
            page_location: '/socialsignup',
            method: method
        });
    }

    public createAccountUnderAgeDownloadView() {
        ReactGA.event('page_view', {
            page_title: 'Create Account - Underage Download',
            page_location: '/underage'
        });
    }

    public createAccountUnderAgeDownloadButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'Download VIA Academy',
            page_title: 'Create Account - Underage Download',
            page_location: '/underage'
        });
    }

    public insightsView() {
        ReactGA.event('page_view', {
            page_title: 'Insights',
            page_location: '/insights'
        });
    }

    public insightsPostView(postId:string,postType:string|undefined) {
        ReactGA.event('page_view', {
            page_title: 'Insight Post',
            page_location: '/posts/' + postId,
            postId,
            postType
        });
    }

    public feedbackView() {
        ReactGA.event('page_view', {
            page_title: 'Feedback',
            page_location: '/feedback'
        });
    }

    public trainingView() {
        ReactGA.event('page_view', {
            page_title: 'Training',
            page_location: '/training'
        });
    }

    public selectTrainingCategory(category:string) {
        ReactGA.event('select_content', {
            content_type: category,
            page_title: 'Training',
            page_location: '/training'
        });
    }

    public trainingSubscribeButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'Subscribe',
            page_title: 'Training',
            page_location: '/training'
        });
    }

    public trainingVideoView(videoId:string) {
        ReactGA.event('page_view', {
            page_title: 'Training Video',
            page_location: '/train',
            video_id: videoId
        });
    }

    public subscriptionWallView() {
        ReactGA.event('page_view', {
            page_title: 'Subscription Wall',
            page_location: '/subscriptionwall'
        });
    }

    public subscriptionWallStartFreeTrialButtonClick(duration:string) {
        ReactGA.event('button_click', {
            button_text: 'Start Free Trial',
            duration: duration,
            page_title: 'Subscription Wall',
            page_location: '/subscriptionwall'
        });
    }

    public subscriptionWallNotNow(duration:string) {
        ReactGA.event('button_click', {
            button_text: 'Not now',
            duration: duration,
            page_title: 'Subscription Wall',
            page_location: '/subscriptionwall'
        });
    }

    public subscriptionPaymentView(plan:string) {
        ReactGA.event('page_view', {
            page_title: 'Subscription Payment',
            page_location: '/subscriptionpayment',
            plan: plan
        });
    }

    public overEighteenButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'I am over 18 years old',
            page_title: 'Start Trial',
            page_location: '/start-trial'
        });
    }

    public subscriptionPurchased(name: string|undefined, sku:string|undefined, price:string|undefined, transaction_id:string|undefined) {
        ReactGA.event('purchase', {
            currency: 'GBP',
            value: price,
            transaction_id,
            items: [
                {
                  item_id: sku,
                  item_name: name,
                  price: price,
                  quantity:1
                }
            ],
            page_title: 'Subscription Payment',
            page_location: '/subscriptionpayment'
        });
    }

    public myAccountHomeView() {
        ReactGA.event('page_view', {
            page_title: 'My Account',
            page_location: '/myaccount/home'
        });
    } 

    public myAccountSubscriptionView() {
        ReactGA.event('page_view', {
            page_title: 'My Subscription',
            page_location: '/myaccount/subscriptions'
        });
    } 

    public feedbackUnderAgeView() {
        ReactGA.event('page_view', {
            page_title: 'Feedback for Under 13s',
            page_location: '/u13-info'
        });
    } 

    public feedbackUnderAgeParentButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Parent/Guardian Sign-In',
            page_title: 'Feedback for Under 13s',
            page_location: '/homeu13-info'
        });
    }

    public homeView() {
        ReactGA.event('page_view', {
            page_title: 'Home',
            page_location: '/home'
        });
    }

    public homeInsightsButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Insights',
            page_title: 'Home',
            page_location: '/home'
        });
    }

    public homeFeedbackButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Feedback',
            page_title: 'Home',
            page_location: '/home'
        });
    }

    public homeTrainingButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Training',
            page_title: 'Home',
            page_location: '/home'
        });
    }

    public signInOrSignUpView():void {
        ReactGA.event('page_view', {
            page_title: 'Sign in or Sign up',
            page_location: '/signinorsignup'
        });
    }

    public signInOrSignUpSetAge(age:number) {
        let eventName = age < 13 ? 'underage' : 'overage';
        ReactGA.event(eventName, {
            page_title: 'Sign in or Sign up',
            page_location: '/signinorsignup'
        });
    }

    public signInOrSignUpClubLoginButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'Club Login',
            page_title: 'Sign in or Sign up',
            page_location: '/signinorsignup',
        });
    }

    public signInOrSignUpCreateAccountButtonClick() {
        ReactGA.event('button_click', {
            button_text: 'Create account for free',
            page_title: 'Sign in or Sign up',
            page_location: '/signinorsignup',
        });
    }

    public getCoachFeedbackButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Get Coach Feedback',
            page_title: 'Home',
            page_location: '/home'
        });
    }

    public enterDrawButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Enter Draw',
            page_title: 'Home',
            page_location: '/home'
        });
    }
    
    public purchaseView() {
        ReactGA.event('page_view', {
            page_title: 'Get Started',
            page_location: '/getstarted',
            step: Stage.selectAcademies
        });
    }

    public academySelectionButtonClick(sku:string,name:string):void {
        ReactGA.event('select_item', {
            item_list_id:sku,
            item_list_name:name,
            page_title: 'Get Started',
            page_location: '/getstarted',
            step: Stage.selectAcademies
        });
    }

    public monthlyDrawButtonClick():void {
        ReactGA.event('select_item', {
            item_list_id:'CF-DRAW',
            item_list_name:'Monthly Draw',
            page_title: 'Get Started',
            page_location: '/getstarted',
            step: Stage.selectAcademies
        });
    }

    public gettingStartedCompleted():void {
        ReactGA.event('button_click', {
            button_text: 'Upload Video',
            page_title: 'Get Started',
            page_location: '/getstarted',
            step: Stage.selectAcademies
        });
    }

    public uploadVideoView() {
        ReactGA.event('page_view', {
            page_title: 'Upload Video',
            page_location: '/uploadvideo',
            step: Stage.uploadVideo
        });
    }

    public videoGuidelinesView() {
        ReactGA.event('page_view', {
            page_title: 'Video Guidelines',
            page_location: '/video-guidelines',
            step: Stage.uploadVideo
        });
    } 

    public selectVideo():void {
        ReactGA.event('button_click', {
            button_text: 'Select Video',
            page_title: 'Upload Video',
            page_location: '/uploadvideo',
            step: Stage.uploadVideo
        });
    }

    public videoUploadCompleted():void {
        ReactGA.event('button_click', {
            button_text: 'Player Information',
            page_title: 'Upload Video',
            page_location: '/uploadvideo',
            step: Stage.uploadVideo
        });
    }

    public playerInformationView():void {
        ReactGA.event('page_view', {
            page_title: 'Player Information',
            page_location: '/playerinformation',
            step: Stage.playerInformation
        });
    }

    public playerInformationCompleted():void {
        ReactGA.event('button_click', {
            button_text: 'Add Improvement Areas',
            page_title: 'Player Information',
            page_location: '/playerinformation',
            step: Stage.playerInformation
        });
    }

    public addImprovementAreasView() {
        ReactGA.event('page_view', {
            page_title: 'Add Improvement Areas',
            page_location: '/addimprovement',
            step: Stage.improvementAreas
        });
    }

    /**
     * @param video_size the video file size in bytes
     * @param video_format the video file extension, e.g 'mp4', 'mov'
     */
    public videoUploadFailure(video_size: number, video_format: string):void {
        ReactGA.event('failed_video_upload', {
            video_size,
            video_format,
            page_title: 'Upload Video',
            page_location: '/uploadvideo'
        });
    }

    /**
     * @param video_duration the video duration in seconds
     */
    public videoUploadSuccess(video_duration: number):void {
        ReactGA.event('success_video_upload', {
            video_duration,
            page_title: 'Upload Video',
            page_location: '/uploadvideo'
        });
    }

    /**
     * @param video_size the video file size in bytes
     */
    public videoUploadUserCancel(video_size: number):void {
        ReactGA.event('cancel_video_upload', {
            video_size,
            page_title: 'Upload Video',
            page_location: '/uploadvideo'
        });
    }

    public addImprovementAreaDone():void {
        ReactGA.event('button_click', {
            button_text: 'Done',
            page_title: 'Add Improvement Areas',
            page_location: '/addimprovement',
            step: Stage.improvementAreas
        });
    }

    public addImprovementAreasButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Add something else',
            page_title: 'Add Improvement Areas',
            page_location: '/addimprovement',
            step: Stage.improvementAreas
        });
    }

    public paymentButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Payment',
            page_title: 'Add Improvement Areas',
            page_location: '/addimprovement',
            step: Stage.improvementAreas
        });
    }

    public paymentView() {
        ReactGA.event('page_view', {
            page_title: 'Payment',
            page_location: '/payment',
            step: Stage.paymentCard
        });
    }

    public reviewButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Review',
            page_title: 'Payment',
            page_location: '/payment',
            step: Stage.paymentCard
        });
    }

    public reviewView() {
        ReactGA.event('page_view', {
            page_title: 'Review',
            page_location: '/review',
            step: Stage.sendOrder
        });
    }

    public purchaseOrderSubmitted(transaction_id:string, selectedAcademy?: IAcademy) {
        ReactGA.event('button_click', {
            button_text: 'Purchase Feedback',
            page_title: 'Review',
            page_location: '/review',
            step: Stage.sendOrder
        });

        if (selectedAcademy) {
            ReactGA.event('purchase', {
                currency: 'GBP', //TODO: selectedAcademy.currency,
                value: selectedAcademy.price,
                transaction_id,
                page_title: 'Review',
                page_location: '/review',
                step: Stage.sendOrder,
                items: [
                    {
                    item_id: selectedAcademy.sku,
                    item_name: selectedAcademy.name,
                    price: selectedAcademy.price
                    }
                ]
            });
        }
    } 

    public competitionOrderSubmitted():void {
        ReactGA.event('button_click', {
            button_text: 'Submit to Monthly Draw',
            page_title: 'Review',
            page_location: '/review',
            step: Stage.sendOrder
        });
    } 

    public purchaseConfirmationView() {
        ReactGA.event('page_view', {
            page_title: 'Purchase Confirmation',
            page_location: '/purchaseconfirmation',
            step: Stage.sendOrder
        });
    }

    public purchaseConfirmationMyVideosButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Go to My Videos',
            page_title: 'Purchase Confirmation',
            page_location: '/purchaseconfirmation',
            step: Stage.purchaseConfirmation
        });
    }

    public purchaseConfirmationAnotherVideoButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Go to My Videos',
            page_title: 'Purchase Confirmation',
            page_location: '/purchaseconfirmation',
            step: Stage.purchaseConfirmation
        });
    }

    public competitionConfirmationView() {
        ReactGA.event('page_view', {
            page_title: 'Competition Confirmation',
            page_location: '/competitionconfirmation',
            step: Stage.purchaseConfirmation
        });
    }

    public competitionConfirmationMyVideosButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Go to My Videos',
            page_title: 'Competition Confirmation',
            page_location: '/competitionconfirmation',
            step: Stage.purchaseConfirmation
        });
    }
 

    public submissionReviewComplete():void {
        ReactGA.event('button_click', {
            button_text: 'Review Complete',
            page_title: 'Payment',
            page_location: '/payment',
            step: Stage.paymentCard
        });
    }

    public goToMyVideosButtonClick():void {
        ReactGA.event('button_click', {
            button_text: 'Go to My Videos',
            page_title: 'Order Submitted',
            page_location: '/ordersubmitted',
        });
    }

}

const Analytics = new AnalyticsManager();
export {
    Analytics,
    SignInMethod
}
