import React, {useEffect, useRef} from 'react'
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
    getIntendedSubscriptionThunk,
    getUserSubscriptionsThunk,
    UserSubscription
} from "../../features/subscriptions/subscriptionsSlice";
import {RootState} from "../../redux/store";
import {Button, styled, Typography } from "@mui/material";
import {CheckCircleOutlineOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../constants/routes";
import {CenteredBodyBox, FullScreenContent} from "../../shared/FullScreenContent";
import {ColumnBox} from "../../shared/styles";
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import {ViaUserProfile} from "../../features/user/userSlice";
import {Loader} from "../../shared/Loader";
import {Analytics} from "../../lib/analytics";

export const SubscriptionConfirmation = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const subId = params.subId ? params.subId : ""

    const subReceipt = useAppSelector<UserSubscription|undefined>((state: RootState)=> state.subscriptions.subReceipt)
    // const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const activatedSubscription = useAppSelector<UserSubscription|undefined>((state: RootState)=> state.subscriptions.activatedSubscription)


    useEffect(() => {
        if(subId)
            dispatch(getIntendedSubscriptionThunk(subId))
    },[subId, dispatch])

    const timeoutId = useRef<NodeJS.Timeout | null>(null); // Using useRef to store the timeout ID

    useEffect(() => {
        setTimeout(() => window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'}), 20)
    }, []);

    useEffect(() => {
        const checkSub = () => {
            dispatch(getUserSubscriptionsThunk())
            timeoutId.current = setTimeout(() => {
                if (activatedSubscription === undefined) {
                    checkSub()
                }
            }, 2500)
        }
        if (activatedSubscription === undefined) {
            checkSub()
        }
        return () => {
            if(timeoutId.current){
                clearTimeout(timeoutId.current)
            }
        }
    },[dispatch, activatedSubscription])

    const StyledButton = styled(Button)(({theme}) => ({
        marginBottom: "10px",
        minWidth: "200px",
        borderRadius: "24px",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "600",
        backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.wl.bottomCTA.buttonBackgroundColor : undefined,
        color: theme.custom.isWhiteLabelled ? theme.custom.wl.bottomCTA.bgColor : undefined,
    }));
    const SpacedTypography = styled(Typography)(({theme}) => ({
        margin: "20px 0",
        [theme.breakpoints.down("sm")]: {
            margin: 0
        },
    }));

    useEffect(() => {
        console.log();
        Analytics.subscriptionPurchased(activatedSubscription?.product.name, activatedSubscription?.product.sku, 
            activatedSubscription?.product.price, activatedSubscription?.subscription_id);
        },[activatedSubscription])
;    

    return(
        <>
            <FullScreenContent
                actionButtons={<ColumnBox>
                    { !activatedSubscription ?
                        <StyledButton disabled={true} variant="contained">Activating Subscription... <Loader/></StyledButton>
                        :
                        <StyledButton variant="contained" onClick={() => navigate(routes.train)}>Access Academy Training</StyledButton>
                    }
                </ColumnBox>}
            >
                <CenteredBodyBox sx={{justifyContent: "flex-start"}}>
                    <CheckCircleOutlineOutlined sx={{width: "80px", height: "80px"}} />
                    {subReceipt?.trial_activated ? <SpacedTypography color="secondary">You’ve started your 1 week free trial to</SpacedTypography> : <SpacedTypography color="secondary">You're now subscribed to</SpacedTypography>}
                    <SpacedTypography sx={{ fontSize:"16px", fontWeight: 700, marginTop: -4}} color="secondary">{`Pro Membership: ${subReceipt?.product.name.includes("Yearly") ? "Yearly" : subReceipt?.product.name.includes("Monthly") ? "Monthly" : ""}`}</SpacedTypography>
                    <Box sx={{
                        border: `1px solid ${theme.custom.isWhiteLabelled ? theme.palette.secondary.main : 'white'}`,
                        padding: '20px',
                        margin: '0px 0',
                        backgroundColor: theme.custom.mainBackgroundColor,
                        textAlign: 'center',
                        width: '60%',
                    }}>
                        <Typography variant="body1" sx={{fontWeight: "750", textTransform: "uppercase", paddingBottom: '25px'}} color="secondary">Order Details</Typography>
                        <Typography variant="body1" sx={{fontWeight: "500", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500], paddingBottom: '15px'}}>Order Number: {subReceipt?.uuid.substring(0, 8)}</Typography>
                        {/* <Typography variant="body2" align="center" sx={{fontWeight: "500", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500], paddingBottom: '8px'}}>
                        Your subscription confirmation has been sent to <br /> {profile?.user.email}
                        </Typography> */}
                    </Box>
                    {subReceipt?.trial_activated && <Typography variant="body1" sx={{fontWeight: "400", textAlign: "center", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500]}}>You will be charged £{subReceipt?.product.price} on {new Date(subReceipt?.period_end).toLocaleDateString('en-GB', {day: '2-digit', month: 'long', year: 'numeric',})} unless you cancel your subscription.</Typography>}
                    <Typography variant="body1" sx={{fontWeight: "400", textAlign: "center", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500]}}>Please note that it could take up to 5 minutes for your subscription to be activated.</Typography>
                    {/* <Typography variant="body1" sx={{fontWeight: "400", textAlign: "center", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500]}}>Any future communications about your subscription will be sent to the above email address.</Typography> */}
                </CenteredBodyBox>
            </FullScreenContent>
            
        </>
    )


}