import React, {useState, useEffect} from "react";
import {PayReviewStep} from "./PayReviewStep";
import {purchaseOrderThunk, updatePaymentIntentThunk} from "../../order/orderSlice";
import {createOrderRequestData} from "../../order/requestData";
import {useViaStripe} from "../../../hooks/useViaStripe";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {IAcademy} from "../../../types/categories";
import {ICompleteUploadStepArgs} from "../upload-video/UploadVideoStep";
import {IPaymentCardsData} from "../payment-cards/PaymentCardsStep";
import {RootState} from "../../../redux/store";
import {MediaUploadResponse, UploadFilename} from "../../media/mediaSlice";
import {useNavigate} from "react-router-dom";
import {generalMessage} from "../../../constants/common";
import {ICommonStepProps, ICommonVideoStepProps} from "../../../types/stepProps";
import {Analytics} from "../../../lib/analytics";
import {IPlayerInformationStepData} from "../../../hooks/usePurchaseStepDataStorage";
import {useTheme} from "@mui/material";

interface IPaymentStepProps extends ICommonStepProps, ICommonVideoStepProps {
    selectedAcademy?: IAcademy;
    uploadVideoStepData: ICompleteUploadStepArgs | null;
    uploadVideoInBackgroundCB: (droppedFile: File) => void;
    videoPreviewUrl: string;
    setVideoPreviewUrl: (value: string) => void;
    playerInformationStepData: IPlayerInformationStepData | null;
    improvementAreasStepData: string[];
    paymentCardsStepData: IPaymentCardsData | null;
    setPaymentCardStage: () => void;
    handleSetSubmitErrorCopy: (submitErrorMsg:string) => void;
}
export const PaymentStep = ({
    selectedAcademy,
    uploadVideoStepData,
    playerInformationStepData,
    improvementAreasStepData,
    videoPreviewUrl,
    paymentCardsStepData,
    setPaymentCardStage,
    handleSetSubmitErrorCopy,
    ...props
}: IPaymentStepProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [,confirmStripePayment] = useViaStripe();
    const dispatch = useAppDispatch();
    const stripeClientSecretId = useAppSelector<string | null>((state: RootState) => state.orderSlice.clientSecretId);
    const fileData = useAppSelector<UploadFilename | undefined>((state: RootState) => state.media.uploadFilename);
    const organisationId = useAppSelector<string>((state: RootState) => state.user.organisationId);
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse);
    const [orderSubmitting, setOrderSubmitting] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>("");

    const purchaseOrder = async () => {

        if (!paymentCardsStepData || !uploadResponse || !stripeClientSecretId) {
            return;
        }

        setOrderSubmitting(true);

        const { payload: createOrderRequestPayload } = await dispatch(purchaseOrderThunk(
            createOrderRequestData(
                selectedAcademy!.sku,
                theme.custom.isWhiteLabelled ? "CLUB_VFA" : "VIA_ACADEMY",
                {
                    othersInVideo: uploadVideoStepData!.otherPlayersExist,
                    othersInVideoComment: uploadVideoStepData!.otherPlayersComment,
                    orderName: playerInformationStepData?.firstName ? playerInformationStepData.firstName : "",
                    orderAge: playerInformationStepData ? playerInformationStepData.age : undefined ,
                    orderPosition: playerInformationStepData ? playerInformationStepData.position : "",
                    improvementAreas: improvementAreasStepData,
                    videoUrlKey: uploadResponse.key,
                    videoMimeType: fileData!.fileType
                }
            )
        ));

        if (!createOrderRequestPayload.id) {
            setSubmitError(createOrderRequestPayload.full_message ?? generalMessage);
            setOrderSubmitting(false);
            return;
        }

        const { payload: updatePaymentIntentPayload } = await dispatch(updatePaymentIntentThunk({
            orderId: createOrderRequestPayload.id,
            source: (theme.custom.isWhiteLabelled ? "CLUB_VFA" : "VIA_ACADEMY"),
            organisation_id: organisationId,
            receiptRecipient: paymentCardsStepData.email
        }));

        if (!updatePaymentIntentPayload.id) {
            setSubmitError(updatePaymentIntentPayload.full_message ?? generalMessage);
            setOrderSubmitting(false);
            return;
        }

        try {
            await confirmStripePayment(stripeClientSecretId, paymentCardsStepData.paymentMethodId);
            setOrderSubmitting(false);
        } catch (e: any) {
            setSubmitError(e.message ?? generalMessage);
            setOrderSubmitting(false);
            return;
        }
        
        Analytics.purchaseOrderSubmitted(createOrderRequestPayload.id, selectedAcademy);

        navigate(`/order-created/purchase-order?recipientEmail=${paymentCardsStepData.email}`);
    }

    useEffect(() => {
        if (submitError) {
            setPaymentCardStage();
            handleSetSubmitErrorCopy(submitError)
        }
    }, [submitError, setPaymentCardStage, handleSetSubmitErrorCopy]);

    return (
        <PayReviewStep
            {...props}
            selectedAcademy={selectedAcademy}
            uploadVideoStepData={uploadVideoStepData}
            playerInformationStepData={playerInformationStepData}
            improvementAreasStepData={improvementAreasStepData}
            videoPreviewUrl={videoPreviewUrl}
            paymentCardsStepData={paymentCardsStepData}
            submitError={submitError}
            parentOrderSubmitting={orderSubmitting}
            setSubmitError={setSubmitError}
            onPaymentSubmit={purchaseOrder}
        />
    );
}
