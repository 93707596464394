import React, { useEffect } from 'react';

type DynamicTopBarColorProps = {
    color: string;
  };

const DynamicTopBarColor = ({ color }: DynamicTopBarColorProps) => {
  
    useEffect(() => {
      const metaThemeColor = document.querySelector('meta[name=theme-color]');
      if (metaThemeColor) {
        metaThemeColor.setAttribute('content', color);
      } else {
        const head = document.getElementsByTagName('head')[0];
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', 'theme-color');
        newMetaTag.setAttribute('content', color);
        head.appendChild(newMetaTag);
      }
    }, [color]);
  
    return (<></>)
  };

export default DynamicTopBarColor;