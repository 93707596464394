import React, {useState, useEffect} from "react";
import {ScreenLayout} from "../../../shared/ScreenLayout";
// import { ErrorTypography } from "../../../shared/styles";
import useQueryEditReasons from "../../../hooks/useQueryEditReasons";
import {Box, styled, TextField, useTheme} from "@mui/material";
import {useQuery} from "../../../hooks/useQuery";
import {update} from "../../../constants/urlParameters";
import {ICommonStepProps} from "../../../types/stepProps";
// import {Analytics} from "../../../lib/analytics";
import {IPlayerInformationStepData} from "../../../hooks/usePurchaseStepDataStorage";
import {useAppSelector} from "../../../redux/hooks";
import {ViaUserProfile} from "../../user/userSlice";
import {RootState} from "../../../redux/store";
import {calculateAge} from "../../../lib/utils";
import {Analytics} from "../../../lib/analytics";
import {ErrorTypography} from "../../../shared/styles";

interface IPlayerInformationStepProps extends ICommonStepProps {
    playerInformationStepData: IPlayerInformationStepData|null;
    onBack: () => void;
    onCompletePlayerInformationStep: (data: IPlayerInformationStepData|null) => void;
}


const StyledTextField = styled(TextField)(() => ({
    margin: "10px 0"
}));

export const PlayerInformationStep = ({playerInformationStepData, onBack, onCompletePlayerInformationStep, onCancelUpdateStep}: IPlayerInformationStepProps) => {
    const theme = useTheme();
    const queryUpdate = useQuery().get(update);
    const [has, , , ] = useQueryEditReasons();
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const [nameError, setNameError] = useState<string>("");
    const [ageError, setAgeError] = useState<string>("");
    const [positionError, setPositionError] = useState<string>("");

    const defaultName = (playerInformationStepData ? playerInformationStepData.firstName : "") || (profile ? profile.user.first_name : "")
    const defaultAge = (playerInformationStepData ? playerInformationStepData.age : undefined) || (profile ? calculateAge(new Date(profile.date_of_birth * 1000).toString()) : undefined)
    const defaultPosition = (playerInformationStepData ? playerInformationStepData.position : "") || (profile ? profile.playing_position : "")
    const [firstName, setFirstName] = useState<string>(defaultName)
    const [age, setAge] = useState<number|undefined>(defaultAge)
    const [position, setPosition] = useState<string>(defaultPosition)

    const onComplete = () => {
        if(firstName.length){
            onCompletePlayerInformationStep({firstName: firstName, age: age, position: position});
        }else{
            onCompletePlayerInformationStep(null);
        }
    }

    useEffect(() => {
        Analytics.playerInformationView();
    }, []);

    return (
        <ScreenLayout
            title="Player Information"
            subtitle="Tell the coach about the player so that they can provide more personalised feedback"
            ctaTitle={!queryUpdate ? "Add Improvement Areas" : "Save"}
            ctaDisabled={!!nameError || !!ageError || !playerInformationStepData || firstName === "" || (theme.custom.isWhiteLabelled && !!ageError) }
            onBack={has.noIssues ? onBack : undefined}
            onCancel={queryUpdate ? onCancelUpdateStep : undefined}
            onAction={onComplete}
            contentFeedBackgroundColor={theme.custom.isWhiteLabelled ? theme.custom.mainBackgroundColor : undefined}
        >
            <Box sx={{mt: "-20px"}}>
                <br/>
                <StyledTextField fullWidth label="Player First Name (Required)" variant="outlined"
                    defaultValue={defaultName}
                    error={nameError !== ""}
                    onChange={event => {
                        setFirstName(event.currentTarget.value)
                        if(event.currentTarget.value.length < 2){
                            setNameError("Please enter the player's first name")
                        }else{ setNameError("")}
                    }} />
                { theme.custom.isWhiteLabelled &&
                    <>
                        <StyledTextField fullWidth label="Player Position (Required)" variant="outlined"
                                         defaultValue={defaultPosition}
                                         error={positionError !== ""}
                                         inputProps={{autoCapitalize: "characters"}}
                                         onChange={event => {
                                             const val = event.currentTarget.value
                                             if(val === "" || val.length < 2) {
                                                 setPositionError("Please enter the players position")
                                             }else {
                                                 setPositionError("")
                                             }
                                             setPosition(val)
                                         }}
                        />
                        {!!positionError &&
                            <>
                                <ErrorTypography>You need to enter Player position</ErrorTypography>
                                <br/>
                            </>
                        }
                    </>
                }
                <StyledTextField fullWidth label="Player Age" type="number" variant="outlined"
                           defaultValue={defaultAge}
                           inputProps={{ inputMode: 'numeric' }}
                           error={ageError !== ""}
                           onChange={event => {
                               const a = Number(event.currentTarget.value)
                               if(!a){
                                   setAgeError("")
                               }
                               else if(Math.round(Number(event.currentTarget.value)) < 1 || Number.isNaN(a)){
                                  setAgeError("Invalid age")
                               }else{
                                  setAgeError("")
                               }
                               setAge(Math.round(Number(event.currentTarget.value)))
                           }}/>
                {/*{ageError}*/}
                { !theme.custom.isWhiteLabelled &&
                    <StyledTextField fullWidth label="Player Position" variant="outlined"
                                     defaultValue={defaultPosition}
                                     inputProps={{autoCapitalize: "characters"}}
                                     onChange={event => setPosition(event.currentTarget.value) }/>
                }
            </Box>
        </ScreenLayout>
    );
}
