export const purchaseStepDataStorageKey: string = "purchaseStepData";
export enum Stage {
    selectAcademies = 1,
    uploadVideo = 2,
    playerInformation = 3,
    improvementAreas = 4,
    paymentCard = 5,
    sendOrder = 6,
    purchaseConfirmation = 7,
}
