import React from "react";
import {Box, Button, styled, Typography} from "@mui/material";
import {ArrowBack, ArrowForward} from "@mui/icons-material";

interface IStickyUploadFailedProps {
    shouldGoBack: boolean;
    onActionClick: () => void;
}
const StickyStatus = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    padding: "19px 0",
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.mainBackgroundColor : theme.palette.divider,
    [theme.breakpoints.down("sm")]: {
        margin: "0 -16px",
    },
}));
const ErrorTypography = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "500"
}));
const StyledRetryButton = styled(Button)(() => ({
    borderRadius: "25px"
}));
export const StickyUploadFailed = ({shouldGoBack, onActionClick}: IStickyUploadFailedProps): JSX.Element => {
    return (
        <StickyStatus>
            <ErrorTypography color="warning.main">Video upload failed</ErrorTypography>
            <StyledRetryButton
                size="small"
                variant="outlined"
                color="secondary"
                startIcon={shouldGoBack ? <ArrowBack /> : <ArrowForward/>}
                onClick={() => onActionClick()}>
                {shouldGoBack ? "Go back" : "Resolve"}
            </StyledRetryButton>
        </StickyStatus>
    );
}
