import React, {useEffect, useRef, useState} from 'react'
import {Box, Button, Card, Typography, CardContent, useTheme} from "@mui/material";

import {
    setSendingRollResponseTo,
    submitUserPollResponse, upgradeItemPreviewThunk,
    UserFeedbackOptionSubmissionArgs,
    UserPollPage
} from "../../../features/academyContent/academyContentSlice";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {Loader} from "../../../shared/Loader";
import {timeLeftString} from "../../../lib/utils";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {checkIsAnonymousUser} from "../../../util/storage";
import {StyledCardClickOverlay} from "../AcademyUpdates";


export interface PollProps {
    item: UserPollPage
    activateSubWallFn?: () => void
    showingSubWall?: boolean
}


export const Poll = (props: PollProps): React.ReactElement => {
    const theme = useTheme();
    const dispatch = useAppDispatch()
    const sendingPollResponseTo = useAppSelector<number>((state: RootState) => state.academyContent.sendingPollResponseTo);
    const daysLeft = props.item.response_data.time_remaining.days
    const hoursLeft = props.item.response_data.time_remaining.hours
    const timesUp = (daysLeft < 0) || (daysLeft === 0 && hoursLeft < 1)
    const deactivatePoll = props.item.response_data.user_has_submitted || timesUp
    const totalVotes = props.item.options.reduce((acc, option) => option.votes + acc, 0)
    const containerRef = useRef(null);
    const [overlayStyle, setOverlayStyle] = useState({});

    useEffect(() => {
        if (containerRef.current) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            setOverlayStyle({
                width: `${offsetWidth}px`,
                height: `${offsetHeight}px`
            });
        }
    }, [containerRef]);

    return(
        <Card raised sx={{ mx: 'auto', mb: 2, mt:1.1, position: "relative"}}
              ref={containerRef}
        >
            { checkIsAnonymousUser() && !props.showingSubWall &&
                <StyledCardClickOverlay
                    style={overlayStyle}
                    onClick={e=>{
                        e.stopPropagation()
                        if(checkIsAnonymousUser() && props.activateSubWallFn) {
                            props.activateSubWallFn()
                        }
                    }}
                ></StyledCardClickOverlay>
            }
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr:1}}>
                <Typography sx={{
                    color: theme.custom.communityLikertTextColor,
                    fontSize: '24px',
                    fontWeight: '600',
                    flexGrow: 1,
                    lineHeight: "30.24px",
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(1),
                    paddingX: theme.spacing(2)
                }}>
                    {props.item.title}
                </Typography>
            </Box>
            <CardContent>
                { deactivatePoll ? <Box sx={{display: "flex", flexDirection: "column"}}>
                    {props.item.options.map(option => (
                        <Box sx={{display: "flex",flexDirection: "row", justifyContent: "space-between", mb: "5px", alignItems: "stretch"}}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    position: "relative",
                                    overflow: "visible",
                                }}
                            >
                                { !props.showingSubWall ?
                                    <>
                                    <Box
                                        sx={{
                                            zIndex: "10",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            backgroundColor: option.selected ? theme.custom.wl.surveyPoll.chosenColor : "#E4E8F2",
                                            width: option.percentage + "%",
                                            borderRadius: "4px",
                                            marginRight: "10px",

                                        }}
                                    ></Box>
                                <div style={{zIndex: "15", display: "inline-flex", position: "relative", fontWeight: "500", padding: "5px 16px", color: theme.custom.communityLikertTextColor }}>
                                    {option.option} {option.selected ? <CheckCircleOutlineIcon style={{padding: "1px", marginLeft: "4px"}}/> : null}

                                </div>
                                    </>
                                    : null}
                            </Box>
                            <Box sx={{padding: "5px 16px 5px 16px", fontWeight: "500", color: theme.custom.communityLikertTextColor}}>{option.percentage}%</Box>
                        </Box>
                    ))}
                        <Typography
                            sx={{color: "#666666", fontSize: "12px", marginTop: "16px"}}
                        >
                            {totalVotes} votes &#8226; {timesUp ? "Final results" : timeLeftString(daysLeft, hoursLeft) + " left"}
                        </Typography>
                </Box>
                : <Box sx={{display: "flex", flexDirection: "column", flexStart: "left"}}>
                    {props.item.options.map(option => (
                        <Button
                            variant="outlined"
                            sx={{
                                justifyContent: "",
                                mb: "10px",
                                fontWeight: "500",
                                color: theme.custom.wl.surveyPoll.optionColor,
                                borderColor: theme.custom.wl.surveyPoll.optionColor,
                            }}
                            onClick={() => {
                                if (!deactivatePoll) {
                                    dispatch(setSendingRollResponseTo(props.item.id))
                                    dispatch(submitUserPollResponse({
                                        id: props.item.id,
                                        value: option.id,
                                        destination: props.item.response_data.submission_url,
                                    } as UserFeedbackOptionSubmissionArgs))
                                    setTimeout(() => {
                                        dispatch(upgradeItemPreviewThunk(props.item.id.toString()))
                                    }, 1000)
                                }
                            }}
                        >{option.option}</Button>
                    ))}
                    {sendingPollResponseTo === props.item.id ? <Loader/> : null}
                        <Typography
                            fontSize="12px"
                            color={theme.custom.wl.surveyPoll.voteStatusColor}
                        >
                            {totalVotes} votes &#8226; {timesUp ? "Final results" : timeLeftString(daysLeft, hoursLeft) + " left"}
                        </Typography>
                </Box>
                }
            </CardContent>
        </Card>
            )
}