import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Divider, Grid, styled, Typography, useTheme} from "@mui/material";
import {Sync} from "@mui/icons-material";
import {ScreenLayout} from "../../../shared/ScreenLayout";
import {ReviewScreenSection} from "../../../shared/sections/ReviewScreenSection";
import {IAcademy} from "../../../types/categories";
import {ICompleteUploadStepArgs} from "../upload-video/UploadVideoStep";
import {IPaymentCardsData} from "../payment-cards/PaymentCardsStep";
import {luckyDraw} from "../getting-started/GettingStartedStep";
import {defaultCurrency} from "../../../constants/currency";
import {FeedbackUploadZone} from "../../media/FeedbackUploadZone";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {MediaUploadResponse, MediaUploadStatus, UploadFilename} from "../../media/mediaSlice";
import {RootState} from "../../../redux/store";
import {categoriesServiceApi} from "../../../services/categories-service";
import {ButtonVariant} from "../../../constants/styles";
import {Stage} from "../../../constants/purchase";
import {
    PurchaseCtaBox,
    PurchaseStyledCTA,
    PurchaseListTypography,
    VerticallyCenteredItemsBox, ColumnBox
} from "../../../shared/styles";
import {resetError} from "../../order/orderSlice";
import {update} from "../../../constants/urlParameters";
import {useCustomNavigate} from "../../../hooks/useCustomNavigate";
import {useQuery} from "../../../hooks/useQuery";
import {ICommonStepProps, ICommonVideoStepProps} from "../../../types/stepProps";
import {Analytics} from "../../../lib/analytics";
import {IPlayerInformationStepData} from "../../../hooks/usePurchaseStepDataStorage";
import {getCouponCode, getCouponPrice, getLuckyDrawClubName} from "../../../util/storage";
import {getCurrencySymbolWithCode} from "../../../lib/utils";

interface IPayReviewStepProps extends ICommonStepProps, ICommonVideoStepProps {
    selectedAcademy?: IAcademy;
    uploadVideoStepData: ICompleteUploadStepArgs | null;
    uploadVideoInBackgroundCB: (droppedFile: File) => void;
    videoPreviewUrl: string;
    setVideoPreviewUrl: (value: string) => void;
    playerInformationStepData: IPlayerInformationStepData|null;
    improvementAreasStepData: string[];
    submitError: string;
    setSubmitError: (error: string) => void;
    paymentCardsStepData: IPaymentCardsData | null;
    parentOrderSubmitting: boolean;
    onMonthlyDrawSubmit?: () => void;
    onPaymentSubmit?: () => void;
}

const RoundedButton = styled(Button)(() => ({
    borderRadius: "20px",
    fontSize: "16px",
    marginTop: "10px",
    cursor: "pointer"
}));

const StyledAppliedButton = styled(Button)(({theme}) => ({
    color: theme.palette.grey[500],
}));


const ctaTitleValues = {
    paymentProcessing: "Payment processing...",
}
export const PayReviewStep = ({
  selectedAcademy,
  uploadVideoStepData,
  uploadVideoPercentage,
  uploadVideoInBackgroundCB,
  videoPreviewUrl,
  setVideoPreviewUrl,
  playerInformationStepData,
  improvementAreasStepData,
  paymentCardsStepData,
  submitError,
  setSubmitError,
  onMonthlyDrawSubmit,
  onPaymentSubmit,
  parentOrderSubmitting,
  onCancelUpdateStep,
}: IPayReviewStepProps): JSX.Element => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const queryUpdate = useQuery().get(update);
    const [navigateWithQueryParam] = useCustomNavigate();
    const {isLoading: academiesLoading, data: academies, error: fetchAcademiesError} = categoriesServiceApi.useGetCategoriesQuery("", {refetchOnMountOrArgChange: true});
    const uploadStatus = useAppSelector<MediaUploadStatus>((state: RootState) => state.media.uploadStatus);
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse);
    const fileData = useAppSelector<UploadFilename | undefined>((state: RootState) => state.media.uploadFilename);
    const orderError = useAppSelector<string>((state: RootState) => state.orderSlice.error);
    const [selectedAcademyError, setSelectedAcademyError] = useState<boolean>(false);
    const [ctaTitle, setCtaTitle] = useState<string | JSX.Element>("");
    const [ctaVariant, setCtaVariant] = useState<ButtonVariant>(ButtonVariant.contained);
    const isMonthlyDraw = selectedAcademy?.sku === luckyDraw.sku ?? false;
    const chooseAcademyError = !isMonthlyDraw && !academiesLoading && (!academies?.find(academy => academy.sku === selectedAcademy?.sku)?.ticketsLeft || fetchAcademiesError);
    const getPurchasedOrderCtaTitle = useCallback(() => {
        const cp = getCouponPrice()
        return `Purchase Feedback for ${getCurrencySymbolWithCode(selectedAcademy?.price_currency || "GBP")}${ !!cp ? cp : (selectedAcademy?.price ?? 0)}`
    }, [selectedAcademy?.price_currency, selectedAcademy?.price]);
    const setChooseAcademyError = useCallback(() => {
        setSelectedAcademyError(true);
        setSubmitError(theme.custom.isWhiteLabelled ? "We’re sorry, Academy Feedback has just sold out! Please try again tomorrow." : "Sorry, your selected club is no longer available.");
        setCtaTitle("Choose another Academy");
        setCtaVariant(ButtonVariant.outlined);
    }, [setSubmitError, theme]);
    const onBackToEdit = (stage: Stage) => navigateWithQueryParam(update, stage);
    const unmountHandler = useCallback(() => {
        dispatch(resetError());
    }, [dispatch])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isMonthlyDraw) return;

        if (parentOrderSubmitting) {
            setCtaTitle(ctaTitleValues.paymentProcessing);
        } else {
            setCtaTitle(getPurchasedOrderCtaTitle());
        }
    }, [parentOrderSubmitting, isMonthlyDraw, getPurchasedOrderCtaTitle]);
    useEffect(() => () => unmountHandler(), [unmountHandler]);
    useEffect(() => {
        Analytics.reviewView();
        setSubmitError("");
        if (uploadStatus === MediaUploadStatus.Posting) {
            setCtaTitle(<PurchaseCtaBox><PurchaseStyledCTA>Uploading your video... </PurchaseStyledCTA><Sync/></PurchaseCtaBox>);
            return;
        }
        if (uploadStatus === MediaUploadStatus.Fail) {
            setSubmitError("Sorry, your video upload failed. Please try again.");
        }
        if (chooseAcademyError) {
            setChooseAcademyError();
            return;
        }
        if (orderError) {
            setSubmitError(orderError);
            if (orderError.includes("selected club") || orderError.includes("available")) {
                setChooseAcademyError();
                return;
            }
        }
        setCtaTitle(isMonthlyDraw ? "Submit to Monthly Draw" : getPurchasedOrderCtaTitle());
    }, [uploadStatus, isMonthlyDraw, selectedAcademy, orderError, chooseAcademyError, setSubmitError, setChooseAcademyError, getPurchasedOrderCtaTitle]);

    const submitAction = () => {
        if (chooseAcademyError) {
            onBackToEdit(Stage.selectAcademies);
            return;
        }
        if (isMonthlyDraw && uploadVideoStepData && uploadResponse && fileData && onMonthlyDrawSubmit) {
            onMonthlyDrawSubmit();
            return;
        }

        if (selectedAcademy && uploadVideoStepData && uploadResponse && fileData && onPaymentSubmit) {
            onPaymentSubmit();
        }
    }

    const StyledPlayerInfoLabel = styled(Typography)(() => ({
        margin: "4px 0",
    }));
    const StyledPlayerInfoItem = styled(Typography)(({theme}) => ({
        margin: "4px 0",
        fontWeight: 600,
        color: theme.palette.secondary.main,
    }));

    return (
        <>
            {academiesLoading && <CircularProgress sx={{m: "20px auto"}} />}
            {!academiesLoading && <>
              <ScreenLayout
                title={isMonthlyDraw ? "Review & Submit" : "Review & Pay"}
                subtitle={isMonthlyDraw ? "If you are drawn as a winner, your video will be reviewed by " + (!!getLuckyDrawClubName() ? "a " + getLuckyDrawClubName() : "an academy") + " coach." : ""}
                ctaTitle={ctaTitle}
                ctaVariant={ctaVariant}
                ctaDisabled={uploadStatus !== MediaUploadStatus.Success || parentOrderSubmitting}
                errorMessage={submitError}
                onAction={submitAction}
                onCancel={queryUpdate ? onCancelUpdateStep : undefined}
              >
                <>
                    {selectedAcademy && !isMonthlyDraw ? (
                        // <ReviewScreenSection
                        // title={!theme.custom.isWhiteLabelled ? "Choose academy" : ""}
                        // onEdit={!theme.custom.isWhiteLabelled ? () => onBackToEdit(Stage.selectAcademies) : undefined}
                        // >
                            <>
                                <VerticallyCenteredItemsBox sx={{margin: "10px 0"}}>
                                    <img src={selectedAcademy.avatar} alt="team logo" width="32px" style={{ opacity: selectedAcademyError ? 0.5 : 1 }}/>
                                    <Typography variant="h5" sx={{fontWeight: "500", color: selectedAcademyError ? theme.palette.warning.main : theme.palette.secondary.main}}>
                                        <>
                                            {selectedAcademy.sku !== luckyDraw.sku && (getCurrencySymbolWithCode(selectedAcademy.price_currency || "GBP"))}
                                            {!!getCouponPrice() ? getCouponPrice() : selectedAcademy.price}
                                            {!!getCouponPrice() ? <span className="strike-price">{defaultCurrency.symbol}{selectedAcademy.price}</span> : null}
                                        </>
                                    </Typography>
                                    {selectedAcademyError && <Typography sx={{color: theme.palette.warning.main, fontWeight: "500"}} variant="caption">
                                      We're very sorry, {selectedAcademy.name} is no longer available.
                                    </Typography>}
                                </VerticallyCenteredItemsBox>
                                {selectedAcademyError && (
                                    <ColumnBox>
                                        <Typography variant="caption" sx={{lineHeight: "24px"}}>Please choose another Academy instead. We’ll keep your progress so far.</Typography>
                                        <Box>
                                            <RoundedButton variant="outlined" fullWidth={false} color="secondary" onClick={() => onBackToEdit(Stage.selectAcademies)}>Select Academy</RoundedButton>
                                        </Box>
                                    </ColumnBox>
                                )}
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    {!selectedAcademyError && <Typography variant="caption">Your feedback video will be ready within 10 days of purchasing.</Typography>}
                                    {!theme.custom.isWhiteLabelled && (!!getCouponCode() ?
                                        <StyledAppliedButton color="secondary" variant="text" size="small" disabled={true} onClick={() => onBackToEdit(Stage.selectAcademies)}>Promo code applied</StyledAppliedButton>
                                        :
                                            null
                                        // <Button color="primary" variant="text" size="small" disabled={false} onClick={() => onBackToEdit(Stage.selectAcademies)}>Have a promo code?</Button>
                                    )}
                                </div>
                            </>
                        // </ReviewScreenSection>
                    ) : (
                        <Divider sx={{margin: "15px 0"}}/>
                    )}
                  <ReviewScreenSection title="Video" onEdit={() => onBackToEdit(Stage.uploadVideo)}>
                    <FeedbackUploadZone
                      showControls
                      showReplaceVideo={false}
                      uploadVideoPercentage={uploadVideoPercentage}
                      uploadVideoInBackgroundCB={uploadVideoInBackgroundCB}
                      previewUrl={videoPreviewUrl}
                      updatePreviewUrl={setVideoPreviewUrl}
                      selectAnotherVideoProp={() => onBackToEdit(Stage.uploadVideo)}
                      isMonthlyDrawSelected={isMonthlyDraw}
                    />
                  </ReviewScreenSection>
                    {uploadVideoStepData && <ReviewScreenSection title="Are there other players in the video?" onEdit={() => onBackToEdit(Stage.uploadVideo)}>
                      <Typography variant="body2">
                          {uploadVideoStepData.otherPlayersExist}
                          {uploadVideoStepData.otherPlayersComment && ', ' + uploadVideoStepData.otherPlayersComment}
                      </Typography>
                    </ReviewScreenSection>}
                    {!!playerInformationStepData &&
                        <ReviewScreenSection
                            title="Player Information"
                            onEdit={() => onBackToEdit(Stage.playerInformation)}
                        >
                            <>
                                <Grid container spacing={1}>
                                    <Grid container item xs={5} direction="column">
                                        <StyledPlayerInfoLabel variant="body2">Player First
                                            Name: </StyledPlayerInfoLabel>
                                    </Grid>
                                    <Grid container item xs={7} direction="column">
                                        <StyledPlayerInfoItem
                                            variant="body2">{playerInformationStepData.firstName}</StyledPlayerInfoItem>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid container item xs={5} direction="column">
                                        <StyledPlayerInfoLabel variant="body2">Player Age:</StyledPlayerInfoLabel>
                                    </Grid>
                                    <Grid container item xs={7} direction="column">
                                        <StyledPlayerInfoItem
                                            variant="body2">{playerInformationStepData.age}</StyledPlayerInfoItem>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid container item xs={5} direction="column">
                                        <StyledPlayerInfoLabel variant="body2">Player Position:</StyledPlayerInfoLabel>
                                    </Grid>
                                    <Grid container item xs={7} direction="column">
                                        <StyledPlayerInfoItem
                                            variant="body2">{playerInformationStepData.position}</StyledPlayerInfoItem>
                                    </Grid>
                                </Grid>
                            </>
                        </ReviewScreenSection>
                    }
                    {improvementAreasStepData.length && (
                        <ReviewScreenSection
                            title="Improvement Areas"
                            showDivider={!!paymentCardsStepData && selectedAcademy?.sku !== luckyDraw.sku}
                            onEdit={() => onBackToEdit(Stage.improvementAreas)}
                        >
                            <PurchaseListTypography variant="caption">
                                <ol>
                                    {improvementAreasStepData.map((value: string, index: number) => <li key={index + 1}>{value}</li>)}
                                </ol>
                            </PurchaseListTypography>
                        </ReviewScreenSection>
                    )}
                    {paymentCardsStepData && selectedAcademy?.sku !== luckyDraw.sku && (
                        <>
                            <ReviewScreenSection title="Payment" showDivider={false} onEdit={() => onBackToEdit(Stage.paymentCard)}>
                                <Box sx={{lineHeight: "22px", mt: "20px"}}>
                                    <Typography variant="body2">Credit/Debit Card</Typography>
                                    {paymentCardsStepData.name && <Typography variant="body2">
                                        {paymentCardsStepData.name}
                                    </Typography>}
                                    {paymentCardsStepData.cardDetails.last4 && <Typography variant="body2">
                                      **** **** **** {paymentCardsStepData.cardDetails.last4}
                                    </Typography>}
                                    {paymentCardsStepData.cardDetails.expiration && <Typography variant="body2">
                                        {paymentCardsStepData.cardDetails.expiration}
                                    </Typography>}
                                </Box>
                            </ReviewScreenSection>
                            {paymentCardsStepData.billingAddress && (
                                <Box sx={{m: "20px 0"}}>
                                    <ReviewScreenSection title="Billing Address" showDivider={false} onEdit={() => onBackToEdit(Stage.paymentCard)}>
                                        <>
                                            <Box sx={{lineHeight: "22px", mt: "20px"}}>
                                                <Typography variant="body2">{paymentCardsStepData.name}</Typography>
                                                <Typography variant="body2">{paymentCardsStepData.billingAddress.country}</Typography>
                                                <Typography variant="body2">
                                                    {paymentCardsStepData.billingAddress.postalCode + ' ' + paymentCardsStepData.billingAddress.city}
                                                </Typography>
                                                <Typography variant="body2">{paymentCardsStepData.billingAddress.state}</Typography>
                                                <Typography variant="body2">{paymentCardsStepData.billingAddress.line1}</Typography>
                                                <Typography variant="body2">{paymentCardsStepData.billingAddress.line2}</Typography>
                                                <Typography variant="body2" sx={{mt: "10px"}}>{paymentCardsStepData.email}</Typography>
                                            </Box>
                                        </>
                                    </ReviewScreenSection>
                                </Box>
                            )}
                        </>

                    )}
                </>
              </ScreenLayout>
            </>}
        </>
    );
};
