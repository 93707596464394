import {IAcademy} from "../types/categories";

export const formatSecondsToISO = (s: number): string => {
    return new Date(s * 1000).toISOString().slice(14, 19);
}

export const calculateAge = (birthday: string): number => { // birthday is a date
    const ageDifMs = new Date().getTime() - new Date(birthday).getTime()
    const ageDate = new Date(ageDifMs) // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const findCoupon = (academySku: string, coupons: IAcademy[]|undefined): IAcademy|null => {
    if(coupons !== undefined){
        const found = coupons.filter(c => c.sku === academySku)
        if(found.length > 0){
            return  found[0]
        }
    }
    return null
}

export const timeSince = (timestamp: string): string => {
    // Parse the given timestamp
    const pastDate = new Date(timestamp);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const diffInMilliseconds = currentDate.getTime() - pastDate.getTime();

    // Convert milliseconds to hours
    const hours = diffInMilliseconds / (1000 * 60 * 60);

    // Check if the difference is greater than or equal to 24 hours
    if (hours >= 24) {
        const days = Math.floor(hours / 24);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else {
        const roundedHours = Math.floor(hours);
        return `${roundedHours} hour${roundedHours !== 1 ? 's' : ''} ago`;
    }
}

export const timeLeftString = (days: number, hours: number): string => {

    const daySpace = days > 0 ? " " : ""
    const dayString = days === 1 ? "1 day" :
        days > 1 ? days + " days" : ""
    const hourString = hours === 1 ? "1 hour" :
        hours > 1 ? hours + " hours" : ""

    return dayString + daySpace + hourString

}

export const formatDateStringFromDB = (dateString: string): string => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getUTCFullYear();

    const daySuffix = getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
}

function getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

export const generateRandom16 = (): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const timestamp = Date.now().toString(36); // Base-36 timestamp
    const remainingLength = 16 - timestamp.length;
    let result = timestamp;

    for (let i = 0; i < remainingLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};

export const decryptAES = async (cipherText: string, encryptionKey: string, ivBase64: string) => {
    // Convert base64 strings to ArrayBuffers
    const keyBuffer = Uint8Array.from(encryptionKey, c => c.charCodeAt(0));
    const ivBuffer = Uint8Array.from(atob(ivBase64), c => c.charCodeAt(0));
    const cipherBuffer = Uint8Array.from(atob(cipherText), c => c.charCodeAt(0));

    // Import the AES key
    const key = await crypto.subtle.importKey(
        'raw',
        keyBuffer,
        'AES-CBC',
        false,
        ['decrypt']
    );

    // Decrypt the cipher text
    const decryptedBuffer = await crypto.subtle.decrypt(
        {
            name: 'AES-CBC',
            iv: ivBuffer
        },
        key,
        cipherBuffer
    );

    // Convert decrypted ArrayBuffer to string
    const decoder = new TextDecoder();
    return decoder.decode(decryptedBuffer);
}

export const hasTrialEnded = (period_start: string): boolean => {
    const today = new Date()
    const start_date = new Date(period_start)
    return today < start_date
}

export function viaDelay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export function prefaceWithArticle(input: string): string {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    const nextWord = input.trim().split(' ')[0];
    const firstLetter = nextWord[0].toLowerCase();

    // Function to check if the word should be preceded by 'an'
    const startsWithVowelSound = (word: string) => {
        if (vowels.includes(firstLetter)) {
            return true;
        }
        if (word.length > 1 && firstLetter === 'h') {
            // Check for silent 'h'
            const secondLetter = word[1].toLowerCase();
            return vowels.includes(secondLetter);
        }
        if (word.toUpperCase() === word) {
            // Acronym case: use 'an' for vowels and some special consonants that sound like vowels
            return ['a', 'e', 'f', 'h', 'i', 'l', 'm', 'n', 'o', 'r', 's', 'x'].includes(firstLetter);
        }
        return false;
    };

    const article = startsWithVowelSound(nextWord) ? 'an' : 'a';
    return `${article} ${input}`;
}

const currencySymbols: { [key: string]: string } = {
    AED: 'د.إ',
    AUD: '$',
    CAD: '$',
    CHF: 'CHF',
    CNY: '¥',
    DKK: 'kr',
    EUR: '€',
    GBP: '£',
    HKD: '$',
    INR: '₹',
    JPY: '¥',
    NOK: 'kr',
    NZD: '$',
    SEK: 'kr',
    SGD: '$',
    USD: '$',
    ZAR: 'R',
    // Add more currency codes and symbols as needed
};

export function getCurrencySymbolWithCode(currencyCode: string): string {
    const upperCurrencyCode = currencyCode.toUpperCase();
    const symbol = currencySymbols[upperCurrencyCode];
    if (symbol) {
        if (symbol === '$') {
            return `${upperCurrencyCode} ${symbol}`;
        } else {
            return symbol;
        }
    } else {
        return `${currencyCode} `;
    }
}