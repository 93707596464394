import { useState } from "react";
import {passwordRules} from "../constants/rules";

export const useValidatePassword = (passwordArg: string) => {
  const [password, setPassword] = useState<string>(passwordArg);
  const isPasswordEmpty = password.length === 0;
  const isPasswordValid = password.length >= passwordRules.minLength.value;

  return [password, setPassword, isPasswordEmpty, isPasswordValid] as const;
}
