import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Divider, styled, Typography, CircularProgress, IconButton} from "@mui/material";
import {Close, InfoOutlined} from '@mui/icons-material';
import {ScreenLayout} from "../../../shared/ScreenLayout";
import {LuckyDrawRules} from "./LuckyDrawRules";
import {defaultCurrency} from "../../../constants/currency";
import {useQuery} from "../../../hooks/useQuery";
import {coachFeedbackServiceApi} from "../../../services/coach-feedback-service";
import {categoriesServiceApi, couponCategoriesServiceApi} from "../../../services/categories-service";
import {IAcademy} from "../../../types/categories";
import {editResultId, update, selected} from "../../../constants/urlParameters";
import {ICommonStepProps} from "../../../types/stepProps";
import {Analytics} from "../../../lib/analytics";
import {CouponCodeTextBox} from "./CouponCodeTextBox";
import {findCoupon, getCurrencySymbolWithCode} from "../../../lib/utils";
import {
    clearCoupon,
    getCouponCode,
    getDefaultClubName, setCFLengthAndGb,
    setCoupon,
    setLuckyDrawClubName
} from "../../../util/storage";
import {LuckyDrawTeamSelect} from "./LuckyDrawTeamSelect";

interface IGettingStartedStepProps extends ICommonStepProps {
    gettingStartedStepData: string;
    onCompleteGettingStartedStep: (selectedItem: string) => void;
}
const StyledTeam = styled(Button)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${theme.palette.text.primary}`,
    padding: "10px",
    // minHeight: "80px",
    height: "56px",
    marginBottom: "10px",
    borderRadius: "8px",
    "&:disabled": {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        backgroundColor: "#414141",
    },
    "&.selected-team:hover": {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
    },
    "&.greyed-team:not(:disabled)": {
        borderColor: theme.palette.grey[700],
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            'p': {
                color: theme.palette.secondary.main,
            },
            'img': {
                opacity: 1
            },
        },
        'p': {
            color: theme.palette.grey[700],
        },
        'img': {
            opacity: 0.5
        },
    },
}));
const StyledTeamNotAvailable = styled(StyledTeam)(() => ({
    backgroundColor: "#414141",
    border: "none"
}));
const StyledTeamBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
}));
const StyledTeamNameBox = styled(Box)(() => ({
    margin: "0 20px",
    flexGrow: 1,
}));
const StyledNotAvailableBox = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    flexBasis: "140px",
    backgroundColor: theme.palette.grey[500],
    borderRadius: "4px",
}));
const StyledNotAvailableTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.contrastText,
    fontSize: "15px",
    fontWeight: "500",
    textTransform: "uppercase"
}));
const StyledNotAvailableInfoOutlined = styled(InfoOutlined)(({theme}) => ({
    color: theme.palette.primary.contrastText,
    width: "16px",
    height: "16px",
}));
const StyledTeamName = styled(Typography)(() => ({
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "600",
}));
const StyledTickets = styled(Typography)(({theme}) => ({
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "600",
    color: theme.palette.warning.main,
    textTransform: "uppercase"
}));
const StyledTeamPrice = styled(Typography)(() => ({
    flexGrow: 1,
    fontSize: "18px",
    fontWeight: "700",
    display: "flex",
    alignItems: "baseline",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
}));
const StyledOrderTypeText = styled(StyledTeamName)(()=>({
    fontSize: "22px",
    fontWeight: "600",
}))
const StyledOrderTypeSubText = styled(StyledTeamName)(()=>({
    color: "#D2D2D2",
    fontSize: "12px",
    fontWeight: "500",
}))
const StyledPriceWord = styled(Typography)(()=>({
    fontSize: "12px",
    fontWeight: "500",

}))
const StyledRegularPrice = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "700",
}));
const StyledSlashPrice = styled(Typography)(() => ({
    fontSize: "14px",
    color: "#FF6E6E",
    textDecoration: "line-through",
    fontWeight: "700",
}));
const StyledLuckyDrawTeamSelectText = styled(Typography)(() => ({
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "16px",
    marginBottom: 0,
}));
const StyledFeedbackTeamSelectText = styled(StyledLuckyDrawTeamSelectText)(() => ({
    marginTop: "6px",
}));
const GreyBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[500],
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px"
}));
const GreBoxTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.secondary.contrastText,
    fontSize: "14px",
    fontWeight: "400"
}));
const StyledCloseIcon = styled(Close)(({theme}) => ({
    color: theme.palette.secondary.contrastText,
    fontSize: "20px"
}));

const StyledAcademyTypeHeader = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '24px 0 16px',
}));

const StyledAcademyTypeHeading = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#D2D2D2"
}));
const StyledAcademyReqSubHeading = styled(Typography)(() => ({
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#D2D2D2"
}));


export const luckyDraw: IAcademy = { id: "none", sku: "SKU_LUCKY", avatar: "/misc/coupon.svg", name: "Enter Monthly Draw", category: 99, price: "Free", price_currency: "GBP", currency_symbol: getCurrencySymbolWithCode("GBP"), ticketsLeft: 1 };
const maxAmountTicketsLeftError = 10;
export const GettingStartedStep = ({gettingStartedStepData, onCompleteGettingStartedStep, onCancelUpdateStep}: IGettingStartedStepProps) => {
    const minCouponLength = 6
    const querySelected = useQuery().get(selected);
    const queryEditResultId = useQuery().get(editResultId);
    const queryUpdate = useQuery().get(update);
    const couponCode = getCouponCode()
    const [couponEntered, setCouponEntered] = useState<string>(couponCode)
    const {isLoading: isMonthlyDrawDataLoading, data: monthlyDrawData} = coachFeedbackServiceApi.useGetOpenBallotsQuery("", {refetchOnMountOrArgChange: true, skip: !!queryEditResultId});
    const {isLoading: isSubmittedMonthlyDrawLoading, data: submittedMonthlyDraw} = coachFeedbackServiceApi.useGetSubmittedBallotQuery("", {refetchOnMountOrArgChange: true, skip: !!queryEditResultId});
    const {isLoading: academiesLoading, data: academies, isFetching: academiesFetching} = categoriesServiceApi.useGetCategoriesQuery("", {refetchOnMountOrArgChange: true, skip: !!queryEditResultId});
    const {currentData: coupons, isFetching: couponsFetching} = couponCategoriesServiceApi.useGetCategoriesQuery(couponEntered, {refetchOnMountOrArgChange: couponEntered.length >= minCouponLength || couponEntered === "" , skip: couponEntered.length < minCouponLength });
    const [showLuckyDrawRules, setShowLuckyDrawRules] = useState<boolean>(false);
    const [monthlyDrawOnly, setMonthlyDrawOnly] = useState<boolean>(false);
    const [refreshProNoticeShown, setRefreshProNoticeShown] = useState<boolean>(false);
    const [refreshPrivateNoticeShown, setRefreshPrivateNoticeShown] = useState<boolean>(false);
    const getAvailableAcademies = useCallback(() => academies?.filter((item:IAcademy) => item.ticketsLeft > 0) ?? [], [academies]);
    const getAvailableProAcademies = useCallback(() => academies?.filter((item:IAcademy) => item.ticketsLeft > 0 && item.category !== 4) ?? [], [academies]);
    const getAvailablePrivateAcademies = useCallback(() => academies?.filter((item:IAcademy) => item.ticketsLeft > 0 && item.category === 4) ?? [], [academies]);
    const getNotAvailableProAcademies = useCallback(() => academies?.filter(item => item.ticketsLeft === 0 && item.category !== 4) ?? [], [academies]);
    const getNotAvailablePrivateAcademies = useCallback(() => academies?.filter(item => item.ticketsLeft === 0 && item.category === 4) ?? [], [academies]);
    const [buyButtonSelected, setBuyButtonSelected] = useState<boolean>(false);
    const [luckyButtonSelected, setLuckyButtonSelected] = useState<boolean>(false);
    const [delayingCta, setDelayingCta] = useState<boolean>(false);
    const couponSuccess = couponEntered !== "" && (coupons ? coupons.length > 0 : false)
    let defaultClubName = getDefaultClubName()
    let defaultClubSku = ""
    getAvailableAcademies().forEach(academy => {
        if(academy.name === defaultClubName){
            defaultClubSku = academy.sku
        }
    })
    const [selectedItem, setSelectedItem] = useState<string>("");
    const [luckyDrawTeam, setLuckyDrawTeam] = useState<string>("");

    useEffect(() => {
        Analytics.purchaseView();
    }, []);

    useEffect(() => {

        if (gettingStartedStepData) {
            setSelectedItem(gettingStartedStepData);
            return;
        }
        if (querySelected && querySelected === luckyDraw.sku) {
            if (monthlyDrawData?.current?.open) {
                setSelectedItem(querySelected);
                setMonthlyDrawOnly(true)
                // setShowLuckyDrawRules(true);
            } else {
                window.history.replaceState({}, document.title, window.location.pathname);
            }
        }
    }, [gettingStartedStepData, querySelected, monthlyDrawData]);
    useEffect(() => {
        if (academiesFetching || !selectedItem || selectedItem === luckyDraw.sku) {
            return;
        }
        if (!getAvailableAcademies().find(academy => academy.sku === selectedItem)) {
            setSelectedItem("");
        }
    }, [selectedItem, academiesFetching, getAvailableAcademies])

    const onComplete = (): void => {
        setLuckyDrawClubName(luckyDrawTeam)
        if(couponSuccess){
            const c = findCoupon(selectedItem, coupons)
            if(c){
                setCoupon(couponEntered, Number(c.price))
            }
        }else{
            clearCoupon()
        }
        if (!showLuckyDrawRules && selectedItem === luckyDraw.sku) {
            setShowLuckyDrawRules(true);
            return;
        }
        onCompleteGettingStartedStep(selectedItem);
    }
    const onSelectAcademy = useCallback((sku: string,name: string, category: number) => {
        Analytics.academySelectionButtonClick(sku,name);
        console.log("category", category)
        if(category === 4){
            setCFLengthAndGb("100", "5")
        }else{
            setCFLengthAndGb("3", "2")
        }
        setSelectedItem(sku);
    }, []);
    const selectedMonthlyDrawAlready = () => selectedItem === luckyDraw.sku && submittedMonthlyDraw?.current;
    const displayTicketsAmount = (ticketsLeft: number) => ticketsLeft <= maxAmountTicketsLeftError;
    const getCtaTitle = useCallback(() => {
        if (queryUpdate) {
            return "Save";
        }
        if (selectedItem === luckyDraw.sku) {
            return "Enter Monthly Draw";
        }

        return "Upload Video";
    }, [queryUpdate, selectedItem]);
    const getMonthlyDrawSubtitle = () => {
        if (!monthlyDrawData?.current?.open) {
            return "No active Monthly draw";
        }

        if (submittedMonthlyDraw?.current) {
            return "You have already entered this month's draw";
        }

        return "Winners drawn on the 1st day of every month";
    }
    const loading = isMonthlyDrawDataLoading || isSubmittedMonthlyDrawLoading || academiesLoading;

    useEffect(()=>{
        if(!!defaultClubSku){
            if(buyButtonSelected){
                setSelectedItem("")
                setDelayingCta(true)
                setTimeout(()=>{
                    setSelectedItem(defaultClubSku)
                    setTimeout(()=>{
                        setDelayingCta(false)
                    },300)
                },500)
            }
        }
    },[buyButtonSelected, setSelectedItem, setDelayingCta, defaultClubSku])

    useEffect(()=>{
        if(!!defaultClubName){
            if(selectedItem === luckyDraw.sku && !( monthlyDrawData?.current?.name === "March" && defaultClubName === "QPR Academy")){
                setDelayingCta(true)
                setTimeout(()=>{
                    setLuckyDrawTeam(defaultClubName)
                    setTimeout(()=>{
                        setDelayingCta(false)
                    },300)
                },500)
            }else{
                setLuckyDrawTeam("")
            }
        }
    },[selectedItem, setLuckyDrawTeam, setDelayingCta, defaultClubName, monthlyDrawData])

    return (
        <>
            {loading && <CircularProgress sx={{m: "0 auto"}} />}
            {!loading && (
                showLuckyDrawRules ?
                    <LuckyDrawRules onComplete={onComplete}/>
                    : (
                        <ScreenLayout
                            title={monthlyDrawOnly || buyButtonSelected || luckyButtonSelected ? "" : "Get Started"}
                            subtitle={monthlyDrawOnly || buyButtonSelected || luckyButtonSelected ? "" : "Buy Coach Feedback now, or try your luck at winning the Monthly Draw"}
                            ctaDisabled={(buyButtonSelected && !selectedItem) || (luckyButtonSelected && !luckyDrawTeam)}
                            displayCta={
                                !delayingCta && ((
                                    ((buyButtonSelected && !!selectedItem && selectedItem !== luckyDraw.sku) || (selectedItem === luckyDraw.sku && !!luckyDrawTeam))
                                    && !selectedMonthlyDrawAlready()
                                ) || !!queryUpdate || buyButtonSelected || luckyButtonSelected )
                            }
                            ctaTitle={getCtaTitle()}
                            onBack={
                                luckyButtonSelected || buyButtonSelected ?
                                    ()=>{
                                        setBuyButtonSelected(false); setSelectedItem("");
                                        setLuckyButtonSelected(false); setLuckyDrawTeam("");
                                    }
                                    : (queryUpdate ? onCancelUpdateStep : undefined)
                            }
                            onAction={onComplete}
                        >
                            <>
                                {monthlyDrawOnly || luckyButtonSelected ? null :
                                    <>
                                        {buyButtonSelected ?
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: "20px",
                                                    lineheight: "32px",
                                                }}
                                            >Buy Feedback</Typography>
                                            :
                                            <StyledTeam
                                                sx={{height: "80px"}}
                                                fullWidth
                                                variant={buyButtonSelected ? "contained" : "outlined"}
                                                color="secondary"
                                                className={buyButtonSelected ? "selected-team" : ""}
                                                onClick={() => {
                                                    setBuyButtonSelected(!buyButtonSelected)
                                                    setLuckyButtonSelected(false)
                                                    if(selectedItem === luckyDraw.sku){
                                                        setSelectedItem(defaultClubName)
                                                    }
                                                    if(buyButtonSelected){
                                                    }
                                                }}
                                            >
                                                <StyledTeamBox>
                                                    <StyledTeamNameBox>
                                                        <StyledOrderTypeText>{'Buy Feedback'}</StyledOrderTypeText>
                                                        <StyledOrderTypeSubText className={buyButtonSelected ? "selected-order-type" : ""}>Guaranteed feedback within 10 days</StyledOrderTypeSubText>
                                                    </StyledTeamNameBox>
                                                    <Box className="feedback-item-right">
                                                        <Divider orientation="vertical" flexItem/>
                                                        <StyledTeamPrice>
                                                            <StyledPriceWord>from</StyledPriceWord>
                                                            <span>&#163; 25</span>
                                                        </StyledTeamPrice>
                                                    </Box>
                                                </StyledTeamBox>
                                            </StyledTeam>
                                        }
                                        {buyButtonSelected && <StyledFeedbackTeamSelectText>Choose the Academy you want to receive feedback from. </StyledFeedbackTeamSelectText>}
                                        { buyButtonSelected && getAvailableAcademies() &&
                                            <>
                                                <CouponCodeTextBox
                                                    setCouponCode={(newCode: string) => {
                                                        clearCoupon()
                                                        setCouponEntered(newCode)
                                                    }}
                                                    resetCoupon={() => {
                                                        setCouponEntered("")
                                                        clearCoupon()
                                                    }}
                                                    couponsFetching={couponsFetching && couponEntered.length >= minCouponLength}
                                                    couponsSuccess={couponSuccess}
                                                    couponsFailure={!(couponEntered.length < minCouponLength || couponSuccess)}
                                                    couponCode={couponCode}
                                                />
                                            </>
                                        }
                                        {buyButtonSelected &&
                                            <StyledAcademyTypeHeader>
                                                <StyledAcademyTypeHeading>PRO ACADEMIES</StyledAcademyTypeHeading>
                                                <StyledAcademyReqSubHeading>Max 3 mins footage</StyledAcademyReqSubHeading>
                                            </StyledAcademyTypeHeader>
                                        }
                                        {buyButtonSelected && getAvailableProAcademies().map(({sku, avatar, name, price, price_currency, ticketsLeft, category}) => (
                                            <StyledTeam
                                                key={sku}
                                                fullWidth
                                                variant={sku === selectedItem ? "contained" : "outlined"}
                                                color="secondary"
                                                className={sku === selectedItem ? "selected-team" : (selectedItem ? "greyed-team" : "")}
                                                onClick={() => onSelectAcademy(sku,name,category)}
                                            >
                                                <StyledTeamBox>
                                                    <img className="feedback-item-club-logo" src={avatar} alt="team logo"/>
                                                    <StyledTeamNameBox>
                                                        <StyledTeamName>{name}</StyledTeamName>
                                                        {displayTicketsAmount(ticketsLeft) && <StyledTickets>Only {ticketsLeft} left</StyledTickets>}
                                                    </StyledTeamNameBox>
                                                    <Box className="feedback-item-right">
                                                        <Divider orientation="vertical" flexItem/>
                                                        <StyledTeamPrice>
                                                            { findCoupon(sku, coupons) !== null && findCoupon(sku, coupons)?.price !== price ?
                                                                <>
                                                                    <StyledRegularPrice>{findCoupon(sku, coupons)?.price_currency ?? defaultCurrency.symbol}{findCoupon(sku, coupons)?.price}</StyledRegularPrice>
                                                                    <StyledSlashPrice>{getCurrencySymbolWithCode(price_currency || "GBP")}{price}</StyledSlashPrice>
                                                                </>
                                                                :
                                                                <StyledRegularPrice>{getCurrencySymbolWithCode(price_currency || "GBP")}{price}</StyledRegularPrice>

                                                            }
                                                        </StyledTeamPrice>
                                                    </Box>
                                                </StyledTeamBox>
                                            </StyledTeam>
                                        ))}
                                        {buyButtonSelected && getNotAvailableProAcademies() && <Box>
                                            {getNotAvailableProAcademies().map(({sku, avatar, name}) => (
                                                <StyledTeamNotAvailable key={sku} fullWidth color="secondary">
                                                    <StyledTeamBox>
                                                        <img className="feedback-item-club-logo" src={avatar} alt="team logo"/>
                                                        <StyledTeamNameBox>
                                                            <StyledTeamName>{name}</StyledTeamName>
                                                        </StyledTeamNameBox>
                                                        <StyledNotAvailableBox>
                                                            <StyledNotAvailableTypography>BACK SOON</StyledNotAvailableTypography>
                                                            <StyledNotAvailableInfoOutlined  onClick={() => setRefreshProNoticeShown(true)}/>
                                                        </StyledNotAvailableBox>
                                                    </StyledTeamBox>
                                                </StyledTeamNotAvailable>
                                            ))}
                                        </Box>}
                                        {refreshProNoticeShown && (
                                            <GreyBox>
                                                <GreBoxTypography>Coach availability is continually refreshed. Please come back later.</GreBoxTypography>
                                                <IconButton size="small" onClick={() => setRefreshProNoticeShown(false)}><StyledCloseIcon/></IconButton>
                                            </GreyBox>
                                        )}
                                        {buyButtonSelected && (!!getAvailablePrivateAcademies().concat(getNotAvailablePrivateAcademies()).length) &&
                                            <StyledAcademyTypeHeader>
                                                <StyledAcademyTypeHeading>PRIVATE ACADEMIES</StyledAcademyTypeHeading>
                                                <StyledAcademyReqSubHeading>Max 100 mins footage</StyledAcademyReqSubHeading>
                                            </StyledAcademyTypeHeader>
                                        }
                                        {buyButtonSelected && getAvailablePrivateAcademies().map(({sku, avatar, name, price, price_currency, ticketsLeft, category}) => (
                                            <StyledTeam
                                                key={sku}
                                                fullWidth
                                                variant={sku === selectedItem ? "contained" : "outlined"}
                                                color="secondary"
                                                className={sku === selectedItem ? "selected-team" : (selectedItem ? "greyed-team" : "")}
                                                onClick={() => onSelectAcademy(sku,name, category)}
                                            >
                                                <StyledTeamBox>
                                                    <img className="feedback-item-club-logo" src={avatar} alt="team logo"/>
                                                    <StyledTeamNameBox>
                                                        <StyledTeamName>{name}</StyledTeamName>
                                                        {displayTicketsAmount(ticketsLeft) && <StyledTickets>Only {ticketsLeft} left</StyledTickets>}
                                                    </StyledTeamNameBox>
                                                    <Box className="feedback-item-right">
                                                        <Divider orientation="vertical" flexItem/>
                                                        <StyledTeamPrice>
                                                            { findCoupon(sku, coupons) !== null && findCoupon(sku, coupons)?.price !== price ?
                                                                <>
                                                                    <StyledRegularPrice>{findCoupon(sku, coupons)?.price_currency ?? defaultCurrency.symbol}{findCoupon(sku, coupons)?.price}</StyledRegularPrice>
                                                                    <StyledSlashPrice>{getCurrencySymbolWithCode(price_currency || "GBP" )}{price}</StyledSlashPrice>
                                                                </>
                                                                :
                                                                <StyledRegularPrice>{getCurrencySymbolWithCode(price_currency || "GBP")}{price}</StyledRegularPrice>

                                                            }
                                                        </StyledTeamPrice>
                                                    </Box>
                                                </StyledTeamBox>
                                            </StyledTeam>
                                        ))}
                                        {buyButtonSelected && getNotAvailablePrivateAcademies() && <Box>
                                            {getNotAvailablePrivateAcademies().map(({sku, avatar, name}) => (
                                                <StyledTeamNotAvailable key={sku} fullWidth color="secondary">
                                                    <StyledTeamBox>
                                                        <img className="feedback-item-club-logo" src={avatar} alt="team logo"/>
                                                        <StyledTeamNameBox>
                                                            <StyledTeamName>{name}</StyledTeamName>
                                                        </StyledTeamNameBox>
                                                        <StyledNotAvailableBox>
                                                            <StyledNotAvailableTypography>BACK SOON</StyledNotAvailableTypography>
                                                            <StyledNotAvailableInfoOutlined  onClick={() => setRefreshPrivateNoticeShown(true)}/>
                                                        </StyledNotAvailableBox>
                                                    </StyledTeamBox>
                                                </StyledTeamNotAvailable>
                                            ))}
                                        </Box>}
                                        {refreshPrivateNoticeShown && (
                                            <GreyBox>
                                                <GreBoxTypography>Coach availability is continually refreshed. Please come back later.</GreBoxTypography>
                                                <IconButton size="small" onClick={() => setRefreshPrivateNoticeShown(false)}><StyledCloseIcon/></IconButton>
                                            </GreyBox>
                                        )}
                                        {!buyButtonSelected &&
                                            <div className="feedback-or">
                                                <hr className="feedback-or-divider"/>
                                                <span className="feedback-or-word">OR</span>
                                                <hr className="feedback-or-divider"/>
                                            </div>
                                        }
                                    </>
                                }

                                {!buyButtonSelected &&
                                    <>
                                        {luckyButtonSelected ?
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: "20px",
                                                    lineheight: "32px",
                                                }}
                                            >Enter the Monthly Draw for FREE</Typography>
                                            :
                                            <StyledTeam
                                                sx={{height: "80px"}}
                                                fullWidth
                                                variant={luckyDraw.sku === selectedItem ? "contained" : "outlined"}
                                                color="secondary"
                                                className={luckyDraw.sku === selectedItem ? "selected-team" : (selectedItem ? "greyed-team" : "")}
                                                disabled={!!submittedMonthlyDraw?.current || !monthlyDrawData?.current?.open}
                                                onClick={() => {
                                                    setBuyButtonSelected(false)
                                                    setLuckyButtonSelected(true)
                                                    if(!!defaultClubName){
                                                        if (selectedItem !== luckyDraw.sku) {
                                                            setLuckyDrawTeam("")
                                                            setSelectedItem(luckyDraw.sku)
                                                            Analytics.monthlyDrawButtonClick();
                                                        } else {
                                                            setLuckyDrawTeam("")
                                                            setSelectedItem("")
                                                        }
                                                    }else{
                                                        setSelectedItem(selectedItem !== luckyDraw.sku ? luckyDraw.sku : "")
                                                    }
                                                }}
                                            >
                                                <StyledTeamBox>
                                                    {/*<img*/}
                                                    {/*    className="feedback-item-club-logo"*/}
                                                    {/*    src={luckyDraw.sku === selectedItem ? "/misc/coupon_dark.svg" : luckyDraw.avatar}*/}
                                                    {/*    alt="team logo"*/}
                                                    {/*/>*/}
                                                    <StyledTeamNameBox>
                                                        <StyledOrderTypeText>{luckyDraw.name}</StyledOrderTypeText>
                                                        <StyledOrderTypeSubText className={selectedItem === luckyDraw.sku ? "selected-order-type" : ""}>{getMonthlyDrawSubtitle()}</StyledOrderTypeSubText>
                                                    </StyledTeamNameBox>
                                                    <Box className="feedback-item-right">
                                                        <Divider orientation="vertical" sx={{borderColor: "#414141"}} flexItem={true}/>
                                                        <StyledTeamPrice>{luckyDraw.price}</StyledTeamPrice>
                                                    </Box>
                                                </StyledTeamBox>
                                            </StyledTeam>
                                        }
                                    </>
                                }
                                {selectedItem === luckyDraw.sku ?
                                    <>
                                        <StyledFeedbackTeamSelectText>Choose the Academy you want to receive feedback from if you win the Monthly Draw</StyledFeedbackTeamSelectText>
                                        <LuckyDrawTeamSelect teamSelected={luckyDrawTeam} teams={getAvailableProAcademies().filter(a=> !(a.name === "QPR Academy" && monthlyDrawData?.current?.name === "March")) || []} setLuckyDrawTeam={(team: string) => setLuckyDrawTeam(team)}/>
                                        <StyledOrderTypeSubText className={selectedItem === luckyDraw.sku ? "selected-order-type" : ""}>{getMonthlyDrawSubtitle()}</StyledOrderTypeSubText>
                                    </>
                                    : null
                                }
                            </>
                        </ScreenLayout>
                    )
            )}
        </>
    );
}
