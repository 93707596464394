import React, {useCallback, useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ProgressComponent} from "../../shared/ProgressBar";
import {RootState} from "../../redux/store";
import {MediaUploadResponse, MediaUploadStatus, updateUploadStatus} from "../media/mediaSlice";
import {GettingStartedStep, luckyDraw} from "./getting-started/GettingStartedStep";
import {ICompleteUploadStepArgs, UploadVideoStep} from "./upload-video/UploadVideoStep";
import {abortUploadVideo, putVideo} from "../../lib/dataUploads";
import {ImprovementAreaStep} from "./improvement-area/ImprovementAreaStep";
import {IPaymentCardsData, PaymentCardsStep} from "./payment-cards/PaymentCardsStep";
import {defaultCurrency} from "../../constants/currency";
import {stripeOptions} from "../../constants/stripe";
import {createPaymentIntentThunk, setOrderWithIssues} from "../order/orderSlice";
import {normalizePrice} from "../../util/stripe";
import {PaymentStep} from "./pay-review/PaymentStep";
import {ReviewStep} from "./pay-review/ReviewStep";
import usePurchaseStepDataStorage, {IPlayerInformationStepData} from "../../hooks/usePurchaseStepDataStorage";
import {UploadStatus} from "./upload-video/components/UploadStatus";
import {StickyUploadFailed} from "./upload-video/components/StickyUploadFailed";
import {categoriesServiceApi} from "../../services/categories-service";
import {Stage} from "../../constants/purchase";
import {editReason, editResultId, update} from "../../constants/urlParameters";
import {ICoachFeedbackResult} from "../../types/coachFeedback";
import {useSearchParams} from "react-router-dom";
import {ReSubmitStep} from "./pay-review/ReSubmitStep";
import useEditOrderMountStage from "../../hooks/useEditOrderMountStage";
import useQueryEditReasons from "../../hooks/useQueryEditReasons";
import {Analytics} from "../../lib/analytics";
import {PlayerInformationStep} from "./player-information/PlayerInformationStep";
import {getCouponPrice} from "../../util/storage";
import {useTheme} from "@mui/material";
import {AutoFillGettingStartedStep} from "./getting-started/AutoFillGettingStartedStep";
import {AppConfig} from "../../config";

export const PurchaseFlow = (): JSX.Element => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [whiteLabelStepOffset, setWhiteLabelStepOffset] = useState<number>(0)
    const {isFetching: academiesFetching, data: academies} = categoriesServiceApi.useGetCategoriesQuery("", {skip: !!searchParams.get(editResultId)});
    const uploadStatus = useAppSelector<MediaUploadStatus>((state: RootState) => state.media.uploadStatus);
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse)
    const stripeClientSecretId = useAppSelector<string | null>((state: RootState) => state.orderSlice.clientSecretId);
    const organisationId = useAppSelector<string>((state: RootState) => state.user.organisationId);
    const paymentIntentAmount = useAppSelector<number | null>((state: RootState) => state.orderSlice.paymentIntentAmount);
    const modifyingOrder = useAppSelector<ICoachFeedbackResult | null>((state: RootState) => state.orderSlice.modifyingOrder);
    const [
        resetValues,
        purchaseStage,
        setPurchaseStage,
        gettingStartedStepData,
        setGettingStartedStepData,
        uploadVideoStepData,
        setUploadVideoStepData,
        videoPreviewUrl,
        setVideoPreviewUrl,
        playerInformationStepData,
        setPlayerInformationStepData,
        improvementAreasStepData,
        setImprovementAreasStepData,
        paymentCardsStepData,
        setPaymentCardsStepData,
        ,
        setId,
    ] = usePurchaseStepDataStorage();
    const [has, only, first, allow] = useQueryEditReasons();
    const [mountEditReasonStage] = useEditOrderMountStage({setPurchaseStage, setVideoPreviewUrl, setUploadVideoStepData, setImprovementAreasStepData});
    const [uploadVideoPercentage, setUploadVideoPercentage] = useState<number | null>(null);
    const [initModifyingOrderData, setInitModifyingOrderData] = useState<boolean>(false);
    const stickyStatusShown = purchaseStage !== Stage.uploadVideo && purchaseStage !== Stage.sendOrder && uploadStatus === MediaUploadStatus.Fail;
    const onCancelUpdateStep = useCallback((): void => {
        searchParams.delete(update);
        setSearchParams(searchParams);
        setPurchaseStage(Stage.sendOrder);
    }, [searchParams, setSearchParams, setPurchaseStage]);
    const handleModifyOrder = useCallback(() => {
        if (!searchParams.get(editResultId) && !searchParams.get(editReason) && !modifyingOrder) {
            setId(undefined);
        }
        if ((!searchParams.get(editResultId) && !searchParams.get(editReason) && !modifyingOrder) || academiesFetching || initModifyingOrderData) {
            return;
        }

        resetValues();
        setInitModifyingOrderData(true);

        if (searchParams.get(editResultId) === modifyingOrder?.id) {
            setId(searchParams.get(editResultId) || undefined);
            mountEditReasonStage();
        } else {
            searchParams.delete(editResultId);
            searchParams.delete(editReason);
            setSearchParams(searchParams);
            dispatch(setOrderWithIssues(null));
            setId(undefined);
            setPurchaseStage(Stage.selectAcademies);
        }
    }, [
        searchParams, modifyingOrder, academiesFetching, initModifyingOrderData, resetValues,
        setInitModifyingOrderData, setSearchParams, setPurchaseStage, setId, mountEditReasonStage, dispatch
    ]);
    const handleUpdateOrder = useCallback(() => {
        const updateStage: string | null = searchParams.get(update);
        if (!updateStage || Number.isNaN(Number(updateStage))) {
            return;
        }
        setPurchaseStage(Number(updateStage));
    }, [searchParams, setPurchaseStage]);
    const confirmExit = useCallback((e: BeforeUnloadEvent) => e.returnValue = 'Are you sure you want to leave this page?', []);
    const mountHandler = () => {
        window.addEventListener('beforeunload', confirmExit);

        return () => {
            resetValues();
            dispatch(setOrderWithIssues(null));
            window.removeEventListener('beforeunload', confirmExit);
        };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(mountHandler, []);
    useEffect(() => window.scrollTo({top: 0}), [purchaseStage]);
    useEffect(() => handleModifyOrder(), [handleModifyOrder]);
    useEffect(() => handleUpdateOrder(), [handleUpdateOrder]);

    const getSelectedAcademy = useCallback(() => {
        if (gettingStartedStepData === luckyDraw.sku) {
            return luckyDraw;
        }

        return academies?.find(item => item.sku === gettingStartedStepData);
    }, [academies, gettingStartedStepData]);
    useEffect(() => {
        if (searchParams.get(editResultId)) {
            return;
        }
        const price = getCouponPrice() || getSelectedAcademy()?.price;
        if (!price || price === luckyDraw.price || normalizePrice(Number(price)) === paymentIntentAmount) {
            return;
        }
        const paymentIntentArgs = {
            amount: normalizePrice(Number(price)),
            currency: getSelectedAcademy()?.price_currency ?? defaultCurrency.code,
            organisation_id: !!organisationId ? organisationId : undefined,
            source: theme.custom.isWhiteLabelled ? "CLUB_VFA" : "VIA_ACADEMY"
        }
        console.log("createPaymentIntentThunk with:")
        console.log(paymentIntentArgs)
        dispatch(createPaymentIntentThunk(paymentIntentArgs));
    }, [getSelectedAcademy, stripeClientSecretId, paymentIntentAmount, searchParams, dispatch, theme, organisationId]);

    useEffect(() => {
        setUploadVideoPercentage(null);
        if (modifyingOrder && !modifyingOrder.video?.url && !uploadResponse) {
            return;
        }
        if (videoPreviewUrl && uploadResponse) {
            dispatch(updateUploadStatus(MediaUploadStatus.Success));
        }
    }, [dispatch, videoPreviewUrl, modifyingOrder, uploadResponse]);

    const onBack = () => {
        // console.log("toPurchaseStage",toPurchaseStage as number)
        console.log("purchaseStage",purchaseStage)
        // if (toPurchaseStage !== undefined) {
        //     setPurchaseStage(toPurchaseStage);
        // } else {
            setPurchaseStage(purchaseStage - 1)
        // }
    }

    const onCompleteGettingStartedStep = (selectedItem: string): void => {
        //Analytics.gettingStartedCompleted();
        setGettingStartedStepData(selectedItem);
        if (!searchParams.get(update)) {
            setPurchaseStage(purchaseStage + 1);
            return;
        }
        onCancelUpdateStep();
    }

    const onCompleteUploadVideoStep = (data: ICompleteUploadStepArgs): void => {
        Analytics.videoUploadCompleted();
        setUploadVideoStepData(data);
        if (searchParams.get(update)) {
            onCancelUpdateStep();
            return;
        }
        if (only.identifyingComment || (first.improvementAreas && has.failedUploadVideoStepReason)) {
            setPurchaseStage(Stage.sendOrder);
            return;
        }
        if (allow.modifyImprovementAreas) {
            setPurchaseStage(Stage.improvementAreas);
            return;
        }
        setPurchaseStage(purchaseStage + 1);
    }
    const onCompletePlayerInformationStep = (data: IPlayerInformationStepData|null): void => {
        Analytics.playerInformationCompleted();
        setPlayerInformationStepData(data);
        if (searchParams.get(update)) {
            onCancelUpdateStep();
            return;
        }
        if (only.playerInformation) {
            setPurchaseStage(Stage.improvementAreas);
            return;
        }
        if ((has.videoReason && !uploadResponse) || (has.identifyingCommentReason && !uploadVideoStepData?.otherPlayersComment)) {
            setPurchaseStage(Stage.uploadVideo);
            return;
        }
        if (has.noIssues) {
            setPurchaseStage(purchaseStage + 1);
            return;
        }
        setPurchaseStage(Stage.improvementAreas);
    }
    const onCompleteImprovementAreaStep = (data: string[]): void => {
        setImprovementAreasStepData(data);
        if (searchParams.get(update)) {
            onCancelUpdateStep();
            return;
        }
        if (only.improvementAreas) {
            setPurchaseStage(Stage.sendOrder);
            return;
        }
        if ((has.videoReason && !uploadResponse) || (has.identifyingCommentReason && !uploadVideoStepData?.otherPlayersComment)) {
            setPurchaseStage(Stage.uploadVideo);
            return;
        }
        if (has.noIssues) {
            if (gettingStartedStepData === luckyDraw.sku) {
                setPurchaseStage(purchaseStage + 2); // omit payment cards screen
                return;
            }
            setPurchaseStage(purchaseStage + 1);
            return;
        }

        setPurchaseStage(Stage.sendOrder);
    }
    const onCompletePaymentCardsStep = (data: IPaymentCardsData) => {
        setPaymentCardsStepData(data);
        if (!searchParams.get(update)) {
            setPurchaseStage(purchaseStage + 1)
            return;
        }
        onCancelUpdateStep();
    }
    const uploadVideoInBackground = (droppedFile: File) => {
        if (uploadResponse && droppedFile) {
            dispatch(updateUploadStatus(MediaUploadStatus.Posting));
            putVideo(
                uploadResponse,
                droppedFile,
                (percentageProgress: number) => setUploadVideoPercentage(percentageProgress),
                () => {
                    setVideoPreviewUrl(uploadResponse.cdn_base_url + "/" + uploadResponse.key);
                    const video = document.createElement("video");
                    video.onloadedmetadata = () => {
                        const videoDurationInSeconds = video.duration;
                        Analytics.videoUploadSuccess(videoDurationInSeconds);
                    }
                    video.src = URL.createObjectURL(droppedFile);
                },
                () => {
                    if (uploadStatus !== MediaUploadStatus.Aborted) {
                        dispatch(updateUploadStatus(MediaUploadStatus.Fail));
                    }
                },
            );
        }
    }

    const stripePublicKey = useAppSelector<string>((state: RootState) => state.user.stripePublicKey)

    let stripePromise: Promise<Stripe|null> = loadStripe(!!stripePublicKey ? stripePublicKey : AppConfig.STRIPE_PUBLIC_KEY)
    useEffect(()=>{
        // eslint-disable-next-line
        stripePromise = loadStripe(!!stripePublicKey ? stripePublicKey : AppConfig.STRIPE_PUBLIC_KEY);
    },[stripePublicKey])


    const abortBackgroundUploading = () => {
        abortUploadVideo();
        setVideoPreviewUrl("");
        dispatch(updateUploadStatus(MediaUploadStatus.Aborted));
    };

    const setPaymentCardStage = () => {
        setPurchaseStage(Stage.paymentCard);
    };

    const [submitErrorCopy, setSubmitErrorCopy] = useState("");
    const handleSetSubmitErrorCopy = (submitErrorMsg:string) => {
        setSubmitErrorCopy(submitErrorMsg);
    }

    return (
        <>
            <ProgressComponent
                currentStep={purchaseStage - whiteLabelStepOffset}
                totalSteps={Stage.sendOrder - whiteLabelStepOffset}
                backgroundProgressComponent={<UploadStatus/>}
                stickyBox={stickyStatusShown ? (
                    <StickyUploadFailed
                        shouldGoBack={purchaseStage > Stage.uploadVideo}
                        onActionClick={() => setPurchaseStage(Stage.uploadVideo)}
                    />) : null
                }
            />
            {purchaseStage === (Stage.selectAcademies as number) && (
                <>
                    {theme.custom.isWhiteLabelled ?
                        <AutoFillGettingStartedStep
                            onCompleteGettingStartedStep={onCompleteGettingStartedStep}
                            whiteLabelledBy={theme.custom.academyName}
                            setWhiteLabelStepOffset={setWhiteLabelStepOffset}
                            whiteLabelStepOffset={whiteLabelStepOffset as number}
                        />
                        :
                        <GettingStartedStep
                            gettingStartedStepData={gettingStartedStepData}
                            onCompleteGettingStartedStep={onCompleteGettingStartedStep}
                            onCancelUpdateStep={onCancelUpdateStep}
                        />
                    }
                </>

            )}
            {purchaseStage === (Stage.uploadVideo as number)  && (
                <UploadVideoStep
                    uploadVideoStepData={uploadVideoStepData}
                    uploadVideoPercentage={uploadVideoPercentage}
                    uploadVideoInBackgroundCB={uploadVideoInBackground}
                    abortBackgroundUploadingCB={abortBackgroundUploading}
                    videoPreviewUrl={videoPreviewUrl}
                    setVideoPreviewUrl={setVideoPreviewUrl}
                    isMonthlyDrawSelected={gettingStartedStepData === luckyDraw.sku}
                    onBack={onBack}
                    onCompleteUploadVideoStep={onCompleteUploadVideoStep}
                    onCancelUpdateStep={onCancelUpdateStep}
                />
            )}
            {purchaseStage === (Stage.playerInformation as number)  && (
                <PlayerInformationStep
                    playerInformationStepData={playerInformationStepData}
                    onBack={onBack}
                    onCompletePlayerInformationStep={onCompletePlayerInformationStep}
                    onCancelUpdateStep={onCancelUpdateStep}
                />
            )}
            {purchaseStage === (Stage.improvementAreas as number)  && (
                <ImprovementAreaStep
                    improvementAreasStepData={improvementAreasStepData}
                    monthlyDrawFlow={gettingStartedStepData === luckyDraw.sku}
                    onBack={onBack}
                    onCompleteImprovementAreaStep={onCompleteImprovementAreaStep}
                    onCancelUpdateStep={onCancelUpdateStep}
                />
            )}
            {stripeClientSecretId && <Elements key={stripeClientSecretId} stripe={stripePromise} options={{...stripeOptions, clientSecret: stripeClientSecretId }}>
                <>
                    {purchaseStage === (Stage.paymentCard as number)  && (
                        <PaymentCardsStep
                            paymentCardsStepData={paymentCardsStepData}
                            onBack={onBack}
                            onCompletePaymentCardsStep={onCompletePaymentCardsStep}
                            onCancelUpdateStep={onCancelUpdateStep}
                            submitErrorCopy={submitErrorCopy}
                            handleSetSubmitErrorCopy={handleSetSubmitErrorCopy}
                        />
                    )}
                    {purchaseStage === (Stage.sendOrder as number) && (
                        <PaymentStep
                            selectedAcademy={getSelectedAcademy()}
                            uploadVideoStepData={uploadVideoStepData}
                            uploadVideoPercentage={uploadVideoPercentage}
                            uploadVideoInBackgroundCB={uploadVideoInBackground}
                            videoPreviewUrl={videoPreviewUrl}
                            setVideoPreviewUrl={setVideoPreviewUrl}
                            playerInformationStepData={playerInformationStepData}
                            improvementAreasStepData={improvementAreasStepData}
                            paymentCardsStepData={paymentCardsStepData}
                            onCancelUpdateStep={onCancelUpdateStep}
                            setPaymentCardStage={setPaymentCardStage}
                            handleSetSubmitErrorCopy={handleSetSubmitErrorCopy}
                        />
                    )}
                </>
            </Elements>}
            {purchaseStage === Stage.sendOrder && !stripeClientSecretId && (
                <>
                    {searchParams.get(editResultId) && (
                        <ReSubmitStep
                            uploadVideoStepData={uploadVideoStepData}
                            uploadVideoPercentage={uploadVideoPercentage}
                            uploadVideoInBackgroundCB={uploadVideoInBackground}
                            videoPreviewUrl={videoPreviewUrl}
                            setVideoPreviewUrl={setVideoPreviewUrl}
                            playerInformationStepData={playerInformationStepData}
                            improvementAreasStepData={improvementAreasStepData}
                            onCancelUpdateStep={onCancelUpdateStep}
                        />
                    )}
                    {!searchParams.get(editResultId) && (
                        <ReviewStep
                            selectedAcademy={getSelectedAcademy()}
                            uploadVideoStepData={uploadVideoStepData}
                            uploadVideoPercentage={uploadVideoPercentage}
                            uploadVideoInBackgroundCB={uploadVideoInBackground}
                            videoPreviewUrl={videoPreviewUrl}
                            setVideoPreviewUrl={setVideoPreviewUrl}
                            playerInformationStepData={playerInformationStepData}
                            improvementAreasStepData={improvementAreasStepData}
                            paymentCardsStepData={paymentCardsStepData}
                            onCancelUpdateStep={onCancelUpdateStep}
                        />
                    )}
                </>

            )}
        </>
    )


}
