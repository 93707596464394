import React, {useEffect} from 'react'
import {CircularProgress} from "@mui/material";
import {useAppDispatch} from "../../redux/hooks";
import {userInitiatedSignOut} from "../../services/cognito";
import {userLogout} from "../../features/user/userSlice";
import {useTheme} from "@mui/material/styles";
import {getItem, setItem} from "../../util/storage";
import {useQuery} from "../../hooks/useQuery";


export function LogoutFromApp() {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const queryParams = useQuery()

    const ssoError = queryParams.get('ssoError') || undefined


    useEffect(() => {

        console.log("cookies before: ", document.cookie)
        console.log("ssoError", ssoError)

        console.log(getItem('sendingToSSO'))
        if(theme.custom.name === "not_qpr_to_disable_loop" && getItem('sendingToSSO') !== "qprKore"){
            setItem("sendingToSSO", "qprKore")
            console.log("qprKore")
            const ssoHostname =  process.env.REACT_APP_QPR_SSO_HOSTNAME as string
            const retunvisitortourl = window.location.href
            const successredirecturl = window.location.href
            const tenantid :string = process.env.REACT_APP_QPR_SSO_TENANT_ID as string
            const qprSignOutUrl =
                `${ssoHostname}/dashboard/logout?tenantid=${tenantid}&forcelogout=true&returnvisitorurl=${retunvisitortourl}&successredirecturl=${successredirecturl}&logoutSuccess=true`
            console.log(qprSignOutUrl)
            window.location.assign(
                qprSignOutUrl
            )
        }else{
            if(theme.custom.name === "qpr" && getItem('sendingToSSO') === "qprKore") {
                const cookiesToDelete = ['_sa_sso_upid']
                cookiesToDelete.forEach(cookieName => {
                    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                });
            }

            const signOutOfApp = async () => {
                await userInitiatedSignOut()
            }

            signOutOfApp().then(
                ()=>{
                    dispatch(userLogout());
                    sessionStorage.clear()
                    localStorage.clear()
                    window.location.assign("/")
                }
            )

        }

        console.log("cookies after: ", document.cookie)

        return () => {
            // this now gets called when the component unmounts
        };

    },[dispatch, theme, ssoError])

    return(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
            <CircularProgress/>
        </div>
    )

}