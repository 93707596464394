import React from "react";
import {ITextBoxItemItem, TextBoxItem} from "../../../../shared/text-field";

interface IImprovementAreaListProps {
    list: ITextBoxItemItem[];
    errorText: string;
    onEdit: (id: number, value?: string) => void;
    onChange: () => void;
}

export const ImprovementAreaList = ({list, errorText, onEdit, onChange}: IImprovementAreaListProps): JSX.Element => {
    return (
        <>
            {
                list.map(
                    (item: ITextBoxItemItem) =>
                        <TextBoxItem key={item.id} item={item} errorText={errorText} onEdit={onEdit} onChange={onChange}/>
                )
            }
        </>
    );
}
