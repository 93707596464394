import React, {useCallback} from "react"
import {CheckCircleOutline} from "@mui/icons-material";
import {Button, styled, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../constants/routes";
import {CenteredBodyBox, FullScreenContent} from "../../../shared/FullScreenContent";
import {Analytics} from "../../../lib/analytics";

const StyledCheckCircleOutline = styled(CheckCircleOutline)(() => ({
    width: "40px",
    height: "40px",
    textAlign: "center"
}));
const StatusTypography = styled(Typography)(() => ({
    textAlign: "center",
}));
const StyledButton = styled(Button)(() => ({
    marginBottom: "10px",
    borderRadius: "24px",
    fontSize: "16px",
    fontWeight: "600",
    color: "#FFFFFF"
}));
export const OrderSubmitted = (): JSX.Element => {
    const navigate = useNavigate();
    const goToVideos = useCallback(() => {
        Analytics.goToMyVideosButtonClick();
        navigate(routes.coachFeedback);
    }, [navigate]);
    return (
        <FullScreenContent
            actionButtons={<StyledButton variant="contained" onClick={goToVideos}>Go to My Videos</StyledButton>}
        >
            <CenteredBodyBox>
                <StyledCheckCircleOutline />
                <StatusTypography variant="body2">Your updates have been submitted.</StatusTypography>
            </CenteredBodyBox>
        </FullScreenContent>
    );
}
