import React from "react";
import {Box, Button, Divider, styled, Typography} from "@mui/material";

interface IReviewScreenSectionProps {
    title: string;
    onEdit?: () => void;
    showDivider?: boolean;
    disabledSection?: boolean;
    children: JSX.Element;
}

const StyledBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
}));
const StyledTitle = styled(Typography)(() => ({
    flexGrow: "1",
    fontSize: "18px",
    fontWeight: "500",
}));
const StyledButton = styled(Button)(({theme}) => ({
    flexBasis: "30px",
    color: theme.palette.grey[500],
    minWidth: "unset",
}));
export const ReviewScreenSection = ({title, onEdit, showDivider = true, disabledSection = false, children}: IReviewScreenSectionProps): JSX.Element => {
    return (
        <>
            <StyledBox color="secondary">
                <StyledTitle color="secondary" style={{ opacity: disabledSection ? 0.5 : 1 }}>{title}</StyledTitle>
                {onEdit && <StyledButton color="secondary" variant="text" size="small" disabled={!onEdit || disabledSection} onClick={onEdit}>Edit</StyledButton>}
            </StyledBox>
            <div style={{ opacity: disabledSection ? 0.5 : 1 }}>{children}</div>
            {showDivider && <Divider sx={{m: "15px 0"}} />}
        </>
    );
};
