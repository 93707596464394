import React, {useEffect, useState} from "react";
import {AddressElement, CardElement, useElements} from "@stripe/react-stripe-js";
import {Box, styled, TextField, Typography, useTheme} from "@mui/material";
import {IPaymentCardsData, StripeElementsConfirmation} from "./PaymentCardsStep";
import {useViaStripe} from "../../../hooks/useViaStripe";
import {PaymentMethod} from "@stripe/stripe-js";
import {useValidateEmail} from "../../../hooks/useValidateEmail";
import {emailRules} from "../../../constants/rules";
import {StripeElementChangeEvent} from "@stripe/stripe-js/types/stripe-js/elements/base";
import {StripeAddressElementChangeEvent} from "@stripe/stripe-js/types/stripe-js/elements/address";
import {generalMessage} from "../../../constants/common";
import {stripeAddressErrorCode} from "../../../constants/stripe/error";
import {requiredFieldsMissing} from "../../../constants/cardNumber";

interface IAddDebitCardProps {
    paymentCardsStepData: IPaymentCardsData | null;
    showFormErrors: boolean;
    setShowFormErrors: (value: boolean) => void;
    confirmStripeElementsStatus: StripeElementsConfirmation;
    setConfirmStripeElementsStatus: (value: StripeElementsConfirmation) => void;
    submitError: string;
    setSubmitError: (value: string) => void;
    onSubmit: (data: IPaymentCardsData) => void;
}
interface IElementState {
    complete: boolean;
    error?: string;
}
// const StyledFormControlLabel = styled(FormControlLabel)(() => ({
//     fontSize: "16px",
//     fontWeight: "500",
// }));
const LabelTypography = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "10px"
}));
const SpacedBox = styled(Box)(() => ({
    margin: "30px 2px",
    "> div": {
        marginBottom: "10px",
    },
    "div": {
        borderRadius: "16px",
    },
}));
const NoticeTypography = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    color: theme.palette.grey[900]
}));
const StyledTextField = styled(TextField)(({theme}) => ({
    "& .MuiInputBase-input:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.default} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
    },
}));
export const AddDebitCard = ({
     paymentCardsStepData,
     showFormErrors,
     setShowFormErrors,
     confirmStripeElementsStatus,
     setConfirmStripeElementsStatus,
     setSubmitError,
     onSubmit
}: IAddDebitCardProps): JSX.Element => {
    const theme = useTheme()
    const [createStripeCard] = useViaStripe();
    const elements = useElements();
    const [cardElementState, setCardElementState] = useState<IElementState>({complete: false});
    const [addressElementState, setAddressElementState] = useState<IElementState>({complete: false});
    const [email, setEmail, isEmailEmpty, isEmailValid] = useValidateEmail(paymentCardsStepData?.email ?? "");
    const [showEmailError, setShowEmailError] = useState<boolean>(false);

    useEffect(() => {
        if (confirmStripeElementsStatus === StripeElementsConfirmation.ShouldConfirm) {
            confirmStripeElements();
            setConfirmStripeElementsStatus(StripeElementsConfirmation.Confirming);
        }
    });

    useEffect(() => {
        const validateAddress = async () => {
            if (!elements) {
                return;
            }
            const { error } = await elements?.submit();
            if (error) {
                setAddressElementState({...addressElementState, error: error.message});
            }
        }
        if (addressElementState.complete && elements) {
            validateAddress();
        }
    }, [elements, addressElementState]);

    const confirmStripeElements = async () => {
        if (!await validateEntries()) {
            setConfirmStripeElementsStatus(StripeElementsConfirmation.NotConfirmed);
            setShowFormErrors(true);
            return;
        }

        try {
            // @ts-ignore
            const paymentCard: PaymentMethod = await createStripeCard() as PaymentMethod;

            setConfirmStripeElementsStatus(StripeElementsConfirmation.Confirmed);
            onSubmit({
                paymentMethodId: paymentCard.id,
                name: paymentCard.billing_details?.name ?? "",
                billingAddress: {
                    city: paymentCard.billing_details.address?.city ?? "",
                    country: paymentCard.billing_details.address?.country ?? "",
                    line1: paymentCard.billing_details.address?.line1 ?? "",
                    line2: paymentCard.billing_details.address?.line2 ?? "",
                    postalCode: paymentCard?.billing_details.address?.postal_code ?? "",
                    state: paymentCard.billing_details.address?.state ?? "",
                },
                cardDetails: {
                    last4: paymentCard.card?.last4 ?? "",
                    expiration: `${paymentCard.card?.exp_month}/${paymentCard.card?.exp_year}`
                },
                email,
            });
        } catch (e: any) {
            setConfirmStripeElementsStatus(StripeElementsConfirmation.NotConfirmed);
            setSubmitError(e.message);
            setShowFormErrors(true);
        }
    }

    const validateEntries = async () => {
        if (!elements) {
            return false;
        }

        if (!cardElementState.complete || cardElementState.error) {
            setSubmitError(cardElementState.error ?? "Card is incomplete");
            return false;
        }

        const { error } = await elements.submit();

        if (error) {
            setSubmitError(error.code === stripeAddressErrorCode ? requiredFieldsMissing : (error.message || generalMessage));
            return false;
        }

        if (isEmailEmpty || !isEmailValid) {
            setSubmitError(emailRules.notValid.message);
            setShowEmailError(true);
            return false;
        }

        return true;
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setShowEmailError(false);
        setSubmitError("");
        setShowFormErrors(false);
    };

    const resetErrors = () => {
        setSubmitError("");
        setShowFormErrors(false);
    }

    const getDefaultValues = () => {
        if (!paymentCardsStepData) {
            return {};
        }

        return {
            address: {
                line1: paymentCardsStepData?.billingAddress.line1,
                line2: paymentCardsStepData?.billingAddress.line2,
                city: paymentCardsStepData?.billingAddress.city,
                state: paymentCardsStepData?.billingAddress.state,
                postal_code: paymentCardsStepData?.billingAddress.postalCode,
                country: paymentCardsStepData?.billingAddress.country,
            }
        }
    }

    return (
        <>
            {/*<RadioGroup defaultValue="card" sx={{mb: "20px"}}>*/}
            {/*    <StyledFormControlLabel value="card" control={<Radio color="secondary"/>} label="Credit/Debit Card" />*/}
            {/*</RadioGroup>*/}
            <SpacedBox className="card-info-box">
                <LabelTypography>Card Information</LabelTypography>
                <CardElement
                    onChange={({complete, error}: StripeElementChangeEvent) => setCardElementState({complete, error: error?.message})}
                    onFocus={resetErrors}
                    options={{
                        hidePostalCode: true,
                        classes: {
                            base: showFormErrors && (!cardElementState.complete || cardElementState.error)
                                ? "base-card-element error"
                                : "base-card-element",
                        },
                        style: {
                            base: {
                                color: theme.custom.stripe.colorText,
                                fontSize: theme.custom.stripe.fontSizeBase
                            },
                            invalid: {
                                color: theme.custom.stripe.colorWarning
                            },
                        }
                    }}
                />
            </SpacedBox>
            <SpacedBox>
                <LabelTypography>Billing Address</LabelTypography>
                <AddressElement
                    options={{mode: "billing", defaultValues: getDefaultValues(), fields: {phone: "never"}, display: {name: "split"}}}
                    onFocus={resetErrors}
                    onChange={({complete}: StripeAddressElementChangeEvent) => setAddressElementState({complete})}
                />
            </SpacedBox>
            <SpacedBox>
                <LabelTypography>Purchaser's email address</LabelTypography>
                <StyledTextField
                    label="Email address"
                    variant="outlined"
                    fullWidth
                    color="secondary"
                    value={email}
                    error={showFormErrors && showEmailError && (isEmailEmpty || !isEmailValid)}
                    helperText={showFormErrors && showEmailError && (isEmailEmpty || !isEmailValid) && emailRules.notValid.message}
                    onChange={handleEmailChange}
                />
                <NoticeTypography>Your purchase receipt will be sent to the above email address</NoticeTypography>
            </SpacedBox>
        </>
    );
}
