import React, {ReactNode, useEffect, useState} from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useAppSelector} from "../redux/hooks";
import {RootState} from "../redux/store";
import {Loader} from "./Loader";


interface StripeProviderProps {
    children: ReactNode
    options: object
}


export const StripeProvider = (props: StripeProviderProps): React.ReactElement => {
    const stripePublicKey = useAppSelector<string>((state: RootState) => state.user.stripePublicKey)
    console.log("loadStripe: ", stripePublicKey)
    // let stripePromise: Promise<Stripe|null> = loadStripe(stripePublicKey)
    const [stripePromise, setStripePromise] = useState(!!stripePublicKey ? loadStripe(stripePublicKey) : null)
    useEffect(()=>{
        // eslint-disable-next-line
        if(stripePublicKey){
            setStripePromise(loadStripe(stripePublicKey));
        }
        console.log("loadStripe: ", stripePublicKey)
    },[setStripePromise, stripePublicKey])

    return (
        <>
            {stripePromise ?
                <Elements stripe={stripePromise} options={{...props.options}}>
                    {props.children}
                </Elements>
                :
                <Loader/>
            }
        </>
    )
}
