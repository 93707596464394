import React, {useEffect, useRef, useState} from 'react'
import {Box, Typography, useTheme} from "@mui/material";
import {Loader} from "../../shared/Loader";
import {ScreenLayout} from "../../shared/ScreenLayout";
import {
    ContentTags,
    FeedItem,
    FeedItemPreview, getInitialTrainingPageLimit, getTagsThunk,
    getTrainingContentThunk
} from "../../features/academyContent/academyContentSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {CenteredItemsBox} from "../../shared/styles";
import {useLocation} from "react-router-dom";
import {FeedItemSifter} from "../academyUpdates/FeedItemSifter";
import CancelIcon from '@mui/icons-material/Cancel';
import {UserSubscription} from "../../features/subscriptions/subscriptionsSlice";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import {Analytics} from "../../lib/analytics";
import {setCurrentSiteSection} from "../../util/storage";

// const StyledTagButton = styled(Button)(({theme, variant}) => ({
//     minWidth: "45px",
//     "& .MuiButton-root": {
//         borderRadius: "8px important"
//     },
//     color: variant === 'contained' ? theme.palette.common.white : theme.custom.darkerPrimaryColor,
//     backgroundColor: variant === 'contained' ? theme.custom.darkerPrimaryColor : "#E4E8F2",
//     borderStyle: "none",
//     boxShadow: 'none',
//     '&:hover': {
//         boxShadow: 'none',  // Ensures no shadow appears on hover
//         borderStyle: "none",
//     }
// }))


export const Train = (): React.ReactElement => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Extract parameters
    const cancelPurchase = queryParams.get('cancelPurchase') === "true";

    const dispatch = useAppDispatch()
    const loading = false
    const theme = useTheme()

    const contentItems = useAppSelector<FeedItem[]>((state: RootState) => state.academyContent.trainingFeedItems);
    const fetchingTrainingItems = useAppSelector<boolean>((state: RootState) => state.academyContent.fetchingTrainingItems);
    const fetchingTrainingPage = useAppSelector<number>((state: RootState) => state.academyContent.fetchingTrainingPage);
    const totalTrainingFeedItems = useAppSelector<number>((state: RootState) => state.academyContent.totalTrainingFeedItems);
    const trainingItemLimit = useAppSelector<number>((state: RootState) => state.academyContent.trainingItemLimit);
    const containerRef = useRef<HTMLDivElement>(null);
    const [boxWidth, setBoxWidth] = useState(0);
    const tags = useAppSelector<ContentTags>((state: RootState) => state.academyContent.tags);
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [selectedGeneralTag, setSelectedGeneralTag] = useState<boolean>(false)
    // const [_selectedAgeTag, setSelectedAgeTag] = useState<boolean>(false)
    const [showSubWall, setShowSubWall] = useState<boolean>(cancelPurchase)
    const [nextGetMoreTime, setNextGetMoreTime] = useState<number>(1000)

    const activeProSubscription = useAppSelector<UserSubscription|undefined>((state: RootState) => state.subscriptions.activatedSubscription);
    const hasActiveProSubscription = !!activeProSubscription

    // const toggleTag = (t: string): void => {
    //     let newSelectedTags: string[];
    //     if(selectedTags.includes(t)){
    //         newSelectedTags = selectedTags.filter((tt: string) => (tt !== t) && !tags.age_related.includes(tt) )
    //     }else{
    //         newSelectedTags = selectedTags.concat([t])
    //     }
    //     setSelectedTags(newSelectedTags)
    //     setSelectedGeneralTag(!!newSelectedTags.filter((t)=> tags.general.includes(t)).length)
    //     setSelectedAgeTag(!!newSelectedTags.filter((t)=> tags.age_related.includes(t)).length)
    //     dispatch(getTrainingContentThunk({offset:0, limit: getInitialTrainingPageLimit(), tags: newSelectedTags, resetItems: true }))
    // }

    const toggleTag = (t: string): void => {
        let newSelectedTags: string[];
        if(selectedTags.includes(t)){
            newSelectedTags = selectedTags.filter((tt: string) => (tt !== t) && !tags.age_related.includes(tt) )
        }else{
            if (tags.general.includes(t)) {
                newSelectedTags = [];
            } else if (tags.age_related.includes(t)) {
                newSelectedTags = selectedTags.filter((tt: string) => !tags.age_related.includes(tt));
            } else {
                newSelectedTags = [...selectedTags];
            }
            newSelectedTags = newSelectedTags.concat([t]);
        }
        setSelectedTags(newSelectedTags);
        Analytics.selectTrainingCategory(newSelectedTags.toString());
        setSelectedGeneralTag(!!newSelectedTags.filter((t)=> tags.general.includes(t)).length)
        // setSelectedAgeTag(!!newSelectedTags.filter((t)=> tags.age_related.includes(t)).length)
        dispatch(getTrainingContentThunk({
            params: {
                offset: 0,
                limit: getInitialTrainingPageLimit(),
                tags: newSelectedTags,
                resetItems: true
            },
            hasActiveProSubscription: hasActiveProSubscription
        }));
    }

    const cancelTags = (): void => {
        setSelectedTags([])
        setSelectedGeneralTag(false)
        dispatch(getTrainingContentThunk({
            params: {
                offset: 0,
                limit: getInitialTrainingPageLimit(),
                resetItems: true
            },
            hasActiveProSubscription: hasActiveProSubscription
        }));
    }


    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setBoxWidth(containerRef.current.offsetWidth);
            }
        };

        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [setBoxWidth]);


    useEffect(() => {
        dispatch(getTrainingContentThunk({
            params: {
                offset: 0,
                limit: trainingItemLimit
            },
            hasActiveProSubscription: hasActiveProSubscription
        }));
    },[dispatch, trainingItemLimit, hasActiveProSubscription])

    useEffect(() => {
        const handleScroll  = () => {
            const now = new Date().getTime();
            if( ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 120)  && now > nextGetMoreTime && !fetchingTrainingItems && contentItems.length < totalTrainingFeedItems ){
                console.log(contentItems)
                setNextGetMoreTime(now + 2000)
                console.log(fetchingTrainingPage, trainingItemLimit)
                dispatch(getTrainingContentThunk({
                    params: {
                        offset: contentItems.length,
                        limit: trainingItemLimit,
                        tags: selectedTags,
                    },
                    hasActiveProSubscription: hasActiveProSubscription
                }));
            }
        };

        const intervalId = setInterval(() => {
            handleScroll()
        }, 250);

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(intervalId);
        };
    }, [dispatch, nextGetMoreTime, fetchingTrainingItems, fetchingTrainingPage, trainingItemLimit, totalTrainingFeedItems, contentItems, selectedTags, hasActiveProSubscription]);

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        Analytics.trainingView();
        setCurrentSiteSection("Training")

        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change

    useEffect(() => {
        if (hasActiveProSubscription) {
            dispatch(getTrainingContentThunk({
                params: {
                    offset: 0,
                    limit: trainingItemLimit,
                    tags: selectedTags,
                    resetItems: true
                },
                hasActiveProSubscription: hasActiveProSubscription
            }));
        } else {
            dispatch(getTrainingContentThunk({
                params: {
                    offset: 0,
                    limit: trainingItemLimit,
                    tags: selectedTags,
                    resetItems: true
                },
                hasActiveProSubscription: hasActiveProSubscription
            }));
        }
    }, [hasActiveProSubscription, dispatch, trainingItemLimit, selectedTags]);

    useEffect(()=> {dispatch(getTagsThunk())},[dispatch])




    return (
        <>
            <Box sx={{display: loading ? "none" : "flex", flex: 1}}>
                <ScreenLayout
                    title={"Training"}
                    ctaTitle={"SUBSCRIBE TO PRO MEMBERSHIP"}
                    onAction={()=>{setShowSubWall(!showSubWall);Analytics.trainingSubscribeButtonClick()}}
                    hideSubWall={()=>setShowSubWall(false)}
                    reloadSignIn={ async ()=> {
                        setShowSubWall(false)
                        setTimeout(()=> setShowSubWall(true), 0.001 )
                    }}
                    displayCta={!hasActiveProSubscription && !showSubWall}
                    displaySubGateway={showSubWall}
                    contentPadding={"0"}
                >
                    <>
                    <Typography style={{fontWeight: 400, backgroundColor: "#FFFFFF", fontSize: "16px", color: "#666666", paddingLeft: "18px", paddingBottom: "8px", paddingRight: "18px"}}>
                        Train like an academy player with exclusive training videos direct from the {theme.custom.academyCommonName}
                    </Typography>
                        <div ref={containerRef}>
                            <Box
                                sx={{
                                    backgroundColor: "#FFFFFF",
                                    padding: "0 8px 8px 8px",
                                    display: "flex",
                                    gap: "8px",
                                    overflowX: "auto",
                                    whiteSpace: "nowrap",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: `${boxWidth}px`,
                                    boxSizing: 'border-box',
                                }}
                            >
                                {!!selectedTags.length &&
                                    <CancelIcon
                                        sx={{
                                            color: theme.custom.darkerPrimaryColor,
                                            fontSize: "42px",
                                            "&:hover": {
                                                cursor: "pointer"
                                            },
                                            marginTop: "8px",
                                        }}
                                        onClick={()=> cancelTags()}/>
                                }
                                {/* {!!tags ? tags.general.map((t) => {
                                        if (selectedGeneralTag && selectedTags.includes(t)){
                                            return (<StyledTagButton key={t} variant="contained"
                                                                     onClick={() => toggleTag(t)}>{t}</StyledTagButton>)
                                        } else {
                                            if(selectedGeneralTag){
                                                return(<></>)
                                            }else{
                                                return (<StyledTagButton key={t} variant="outlined"
                                                                         onClick={() => toggleTag(t)}>{t}</StyledTagButton>)
                                            }
                                        }
                                    }
                                ) : null} */}
                                {!!tags && (
                                    <Select
                                        value={selectedTags[0] || ""}
                                        onChange={(e: SelectChangeEvent<string>) => toggleTag(e.target.value)}
                                        displayEmpty
                                        style={{ height: '40px', marginTop: '8px', marginLeft: '10px',}}
                                    >
                                        <MenuItem value="" disabled>
                                            Category
                                        </MenuItem>
                                        {!!tags ? tags.general.map((t) => (
                                        <MenuItem key={t} value={t}>{t}</MenuItem>
                                        )) : null}
                                    </Select>
                                )}
                                {/* {(selectedGeneralTag && !!tags) ? tags.age_related.map((t) => {
                                        if (selectedAgeTag && selectedTags.includes(t)) {
                                            return (<StyledTagButton key={t} variant="contained"
                                                                     onClick={() => toggleTag(t)}>{t}</StyledTagButton>)
                                        } else {
                                            if (selectedAgeTag) {
                                                return (<></>
                                                )
                                            } else {
                                                return (<StyledTagButton key={t} variant="outlined"
                                                                         onClick={() => toggleTag(t)}>{t}</StyledTagButton>)
                                            }
                                        }
                                    }
                                ): null} */}
                                {(selectedGeneralTag && !!tags) ? (
                                    <Select
                                    value={selectedTags[1] || ""}
                                    onChange={(e: SelectChangeEvent<string>) => toggleTag(e.target.value)}
                                    displayEmpty
                                    style={{ height: '40px', marginTop: '8px'}}
                                >
                                    <MenuItem value="" disabled>
                                        Age Group
                                    </MenuItem>
                                    {!!tags ? tags.age_related.map((t) => (
                                    <MenuItem key={t} value={t}>{t}</MenuItem>
                                    )) : null}
                                </Select>
                                )
                                : null}
                            </Box>
                        </div>



                        {contentItems.map(((item: FeedItemPreview) =>
                                <section key={item.id} id={"item-" + item.id} style={{padding: "0 8px"}}>
                                    <FeedItemSifter
                                        hasProSubscription={hasActiveProSubscription}
                                        activateSubWallFn={()=>setShowSubWall(true)}
                                        item={item}/>
                                </section>
                        ))}

                        <div style={{paddingTop: "8px"}}>
                            {  !fetchingTrainingItems && contentItems.length < totalTrainingFeedItems
                                ?
                                <CenteredItemsBox>
                                    <MoreVertIcon
                                        sx={{
                                            "&:hover": { cursor: "pointer"}
                                        }}
                                        onClick={() => {
                                            console.log("fetchingTrainingPage: ", fetchingTrainingPage)
                                            dispatch(getTrainingContentThunk({
                                                params: {
                                                    offset: contentItems.length,
                                                    limit: trainingItemLimit,
                                                },
                                                hasActiveProSubscription: hasActiveProSubscription
                                            }));
                                        }}
                                        fontSize="large"/>
                                </CenteredItemsBox>
                                : null
                            }
                            { fetchingTrainingItems ? <Loader/> : null }
                        </div>

                    </>
                </ScreenLayout>
            </Box>
        </>
    )

}