import React, {useEffect} from 'react'
import {Loader} from "../../shared/Loader";
import {useNavigate, useParams} from "react-router-dom";
import {processClubCode} from "../../lib/skinningUtils";


export const WhiteLabelled = (): React.ReactElement => {
    const { orgName } = useParams<string>();
    const navigate = useNavigate()

    useEffect(()=>{
        console.log(orgName)
        console.log(orgName?.trim().toUpperCase())
        if(!!orgName){
            processClubCode(orgName)
            window.location.replace('/')
        }
    },[orgName, navigate])

    return <Loader/>
}