import React, {useEffect} from 'react'
import {Box, Button, styled, Typography} from "@mui/material";
import {Loader} from "../../shared/Loader";
import {ScreenLayout} from "../../shared/ScreenLayout";

import {
    getAcademyItemThunk, FeedItem, TrainingVideoPage, clearPosts
} from "../../features/academyContent/academyContentSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {useNavigate, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {routes} from "../../constants/routes";
import {HLSVideoPlayer} from "../../shared/HLSVideoPlayer";
import {Analytics} from "../../lib/analytics";
import {useVideoDimensions} from "../../hooks/useVideoDimensions";
import DynamicTopBarColor from '../../DynamicTopBarColor';
import {useTheme} from "@mui/material/styles";
import Divider from '@mui/material/Divider';
import {setCurrentPostPath} from "../../util/storage";


const StyledBackButton = styled(Button)(() => ({
    fontSize: "32px",
    fontWeight: "700",
    margin: "0",
    padding: "10px",
    minWidth: "5px"
}));

export const TrainingPost = (): React.ReactElement => {
    const navigate = useNavigate()
    const theme = useTheme();
    const params = useParams();
    const postId = params.pageId ? params.pageId : ""

    const dispatch = useAppDispatch()

    const postContent = useAppSelector<FeedItem|undefined>((state: RootState) => state.academyContent.trainingItem);
    const fetchingItem = useAppSelector<boolean>((state: RootState) => state.academyContent.fetchingTrainingItem);

    const post = postContent as TrainingVideoPage

    const {dimensions, error } = useVideoDimensions(post?.media?.video?.url || "")

    useEffect(() => {
        dispatch(clearPosts())
    }, [dispatch]);

    useEffect(()=>{
        console.log(dimensions)
        console.log("width:", dimensions?.width, "height:", dimensions?.height)
        console.log(error)
    },[dimensions, error])

    useEffect(() => {
        if(postId)
            dispatch(getAcademyItemThunk(postId))
    },[postId, dispatch])

    useEffect(() => {
        Analytics.trainingVideoView((postId));
        setCurrentPostPath(routes.trainingVideo.replace(":pageId",postId))
    },[postId])

    return (
        <>
            <DynamicTopBarColor color={theme.custom.mainBackgroundColor}/>
            <Box sx={{display: "flex", flex: 1}}>
                <ScreenLayout
                    title={<StyledBackButton onClick={()=> navigate(postContent ? routes.train + "#item-" + postContent.id : routes.train)}><KeyboardBackspaceIcon/></StyledBackButton>}
                    ctaTitle={""}
                    onAction={()=>{}}
                    displayCta={false}
                    contentMarginTop={"0"}
                    contentPadding={"0"}
                    headerMarginTop={"0"}
                    headerPadding={"0"}
                    contentFeedBackgroundColor="#FFFFFF"
                ><>
                    {
                        (!(postContent === undefined) || fetchingItem)
                            && post
                            && post.media
                            && post.media.video
                            // && dimensions
                        ?
                        <>
                                <HLSVideoPlayer
                                    hlsSrc={!!post.media ? post.media.video.stream.url : ""}
                                    mp4Src={!!post.media ? post.media.video.url : ""}
                                    surl={!!post.media ? post.media.surl : undefined}
                                    videoPageId={post.id}
                                    controls
                                    width="100%"
                                    height={dimensions && dimensions.height >= dimensions.width ? "250px" : undefined}
                                    // src={!!post.media ? post.media.video.url : ""}
                                    autoPlay
                                    playsInline
                                />
                            <Box sx={{padding: "16px 16px 0 16px"}}>
                                <Typography sx={{fontSize: "18px", fontWeight: "600"}}>{post.title}</Typography>
                                <Typography
                                    sx={{
                                        marginTop: "8px",
                                        color: "#666666",
                                        fontWeight: 400,
                                        fontSize: "12px"
                                    }}
                                    dangerouslySetInnerHTML={{ __html: post.tags.join('&nbsp;&nbsp;&#8226;&nbsp;&nbsp;') }}
                                />
                                {/*<Typography variant="caption" color="secondary">*/}
                                {/*    {timeSince(post.meta.first_published_at)}*/}
                                {/*</Typography>*/}
                            </Box>
                            {/*<Box sx={{padding: "8px 5px"}}>*/}
                            {/*    <IconButton aria-label="like" sx={{flexShrink: 0, borderRadius: "16px"}} onClick={() => {*/}
                            {/*        dispatch(setSendingLikeTo(post.id))*/}
                            {/*        dispatch(likeItemThunk(post.id))*/}
                            {/*        setLikeSent(!likeSent)*/}
                            {/*    }}  >*/}
                            {/*        { liked ?*/}
                            {/*            <FavoriteIcon/>*/}
                            {/*            :*/}
                            {/*            <FavoriteBorderIcon/>*/}
                            {/*        }*/}
                            {/*        {(post.reactions.count + likeCounted) > 0 && (post.reactions.count + likeCounted) }*/}
                            {/*    </IconButton>*/}
                            {/*</Box>*/}
                            <Box sx={{ paddingTop: '8px', paddingBottom: '0px', paddingLeft: '12px', paddingRight: '12px' }}>
                                <Divider sx={{ borderBottomWidth: '0.1px', borderColor: theme.custom.wl.bottomAppTabs.borderTop }}/>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: "-8px",
                                    paddingBottom: "0px",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    color: theme.custom.darkerPrimaryColor
                                }}
                                dangerouslySetInnerHTML={{ __html: post.body }}
                            />

                        </>
                        :
                        <Loader/>
                    }
                </>
                </ScreenLayout>
            </Box>
        </>
    )

}