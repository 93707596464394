import React, {useState} from "react";
import {Box, Button, CircularProgress, Link, styled, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {FeedbackItem, Status} from "./sections/item/FeedbackItem";
import {luckyDraw} from "../../../features/purchase/getting-started/GettingStartedStep";
import {createMonthlyDrawRequestData} from "../../../features/order/requestData";
import {routes} from "../../../constants/routes";
import {coachFeedbackServiceApi} from "../../../services/coach-feedback-service";
import {generalMessage} from "../../../constants/common";
import {getErrorMessage} from "../../../util/error";
import {usePurchaseNavigate} from "../../../hooks/usePurchaseNavigate";
import {selected} from "../../../constants/urlParameters";

const Title = styled(Typography)(({theme}) => ({
    fontSize: "18px",
    fontWeight: "700",
    background: `linear-gradient(${theme.palette.primary.main}, #BF841A);`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
}));
const Content = styled(Typography)(() => ({
    margin: "10px 0",
    fontSize: "12px",
    fontWeight: "500",
}));
const ErrorTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.warning.main,
    fontSize: "12px",
    fontWeight: "500"
}));
export const MonthlyDrawContent = () => {
    const navigate = useNavigate();
    const [startPurchase, deletePurchaseFlowData] = usePurchaseNavigate();
    const {data: monthlyDraw, error: submittedMonthlyDrawError} = coachFeedbackServiceApi.useGetSubmittedBallotQuery("");
    const {data: ballots, error: ballotsError} = coachFeedbackServiceApi.useGetOpenBallotsQuery("");
    const [ trigger, {isLoading: orderSubmitting} ] = coachFeedbackServiceApi.endpoints.submitMonthlyDraw.useLazyQuery();
    const [submitError, setSubmitError] = useState<string | null>(null);

    if (!monthlyDraw?.current && !monthlyDraw?.last) {
        return <></>;
    }

    const createMonthlyDrawOrder = async () => {
        if (!monthlyDraw.last) {
            return;
        }

        deletePurchaseFlowData();
        setSubmitError(null);
        const response = await trigger(createMonthlyDrawRequestData({
            othersInVideo: monthlyDraw.last.othersInVideo ? "Yes" : "No",
            othersInVideoComment: monthlyDraw.last.identificationNotes,
            orderName: monthlyDraw.last.orderName,
            orderAge: monthlyDraw.last.orderAge,
            orderPosition: monthlyDraw.last.orderPosition,
            improvementAreas: monthlyDraw.last.improvementAreas,
            videoUrlKey: monthlyDraw.last.media.video.key,
            videoMimeType: monthlyDraw.last.media.video.mimeType,
            organisation: monthlyDraw.last.organisation
        }));

        if (response.error) {
            setSubmitError(getErrorMessage(response.error));
            return;
        }

        navigate(routes.monthlyDrawCreated);
    }

    return (
        <>
            {monthlyDraw.last && monthlyDraw.last.isWinner && !monthlyDraw.current && (
                <>
                    <Title>Congratulations!</Title>
                    <Title>You’ve won the {ballots?.previous?.name} Monthly Draw!</Title>
                    <Content color="secondary">
                        Your entry has been moved to Submitted for Feedback. It will be reviewed by an Academy coach within 14 days.
                    </Content>
                    <Content color="secondary" sx={{margin: "20px 0"}}>You haven’t entered the {ballots?.current?.name} Monthly Draw.&nbsp;
                        <Link sx={{cursor: "pointer"}} onClick={() => startPurchase({[selected]: luckyDraw.sku})}>Enter the {ballots?.current?.name} Draw</Link>
                    </Content>
                </>
            )}
            {monthlyDraw.last && !monthlyDraw.last.isWinner && !monthlyDraw.current && (
                <>
                    <Content color="secondary">Sorry, you weren’t drawn as a winner in the {ballots?.previous?.name} Monthly Draw.</Content>
                    <Content color="secondary" sx={{margin: "20px 0"}}>Want to enter the {ballots?.current?.name} Monthly Draw?</Content>
                    <Box>
                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            disabled={orderSubmitting}
                            endIcon={orderSubmitting && <CircularProgress size={12} color="secondary" />}
                            onClick={createMonthlyDrawOrder}
                        >
                            Re-enter with Same Video
                        </Button>
                        {submitError && <ErrorTypography align="center" sx={{mt: "10px"}}>{submitError}</ErrorTypography>}
                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            sx={{marginTop: "20px"}}
                            onClick={() => startPurchase({[selected]: luckyDraw.sku})}
                        >
                            Enter with New Video
                        </Button>
                    </Box>
                </>
            )}
            {monthlyDraw.current && (
                <FeedbackItem
                    key={"Entered monthly draw"}
                    videoItem={{
                        src: monthlyDraw.current.media.video.url,
                        title: monthlyDraw.current.displayName,
                        createdDate: monthlyDraw.current.createdAt,
                        status: Status.Entered
                    }}
                />
            )}
            {(submittedMonthlyDrawError || ballotsError) && <ErrorTypography>{generalMessage}</ErrorTypography>}
        </>
    );
};
