import {createTheme} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        olive: Palette['primary'];
    }

    interface PaletteOptions {
        olive?: PaletteOptions['primary'];
    }
}

export const pufctheme = createTheme({
    custom: {
        name: 'pufc',
        academyName: 'PUFC Academy',
        academyCommonName: 'Posh Academy',
        academyCommonShortName: 'PUFC',
        isWhiteLabelled: true,
        stripe: {
            colorPrimary: "#0050B4", //this.palette.secondary.main,
            colorBackground: "#FFFFFF", //this.custom.isWhiteLabelled ? this.custom.mainBackgroundColor : this.custom.mainBackgroundColor,
            colorText: "#0050B4", //this.custom.isWhiteLabelled ? this.custom.stripePaymentText : this.palette.text.primary,
            colorDanger: "#FF6E6E", //this.palette.warning.main,
            colorWarning: "#FF6E6E", //this.palette.warning.main,
            fontFamily: [
                'Outfit',
                'sans-serif',
            ].join(','), //this.typography.fontFamily,
            fontSizeBase: "16px",
            fontWeightNormal: "400",
            spacingUnit: "5px",
            borderRadius: "4px",
            borderColor: "#0050B4",
        },
        wl: {
            supportEmail: "support@pufc.viaacademy.com",
            termsURI: "https://www.viaacademy.com/posh/terms",
            privacyURI: "https://www.viaacademy.com/posh/privacy",
            faqURI: "https://www.viaacademy.com/posh/faq",
            globalBorderRadius: "4px",
            splashPageButtonBgColor: "#0050B4",
            signIn: {
                buttonBackgroundColor: "#0050B4",
                buttonBorderColor: "#0050B4",
            },
            accountPages: {
                accountManagementURI: "https://www.theposh.com/user/login"
            },
            bottomCTA: {
                bgColor: "#FFFFFF",
                buttonBackgroundColor: "#0050B4",
                buttonColor: "#FFFFFF",
            },
            purchaseStepHeader: {
                backgroundColor: '#FFFFFF',
                color: "#0050B4",
                liveProgressTextColor: "#000000",
                progressBackgroundColor: "#999999"
            },
            improvementStep: {
                backgroundColor: '#F1F1F1',
                color: '#0050B4'
            },
            bottomAppTabs: {
                backgroundColor: "#0050B4",
                color: "#012448",
                selectedColor: "#F0C509",
                borderTop: "#B3BDC8"
            },
            surveyPoll: {
                optionColor: "#0054A4",
                voteStatusColor: "#0962D2",
                chosenColor: "#7FA7D9",
            }
        },
        headerBrandTitleColor: '#F1F1F1',
        headerBrandTitleText: 'Virtual Academy',
        headerBrandTitleBackgroundColor: '#0050B4',
        brandTitleLogo: "/club_logos/pb_logo.png",
        brandCoverPhoto: "/wl/pufc_gateway_cover.jpeg",
        mainBackgroundColor: "#FFFFFF",
        contentFeedBackgroundColor: "#F1F1F1",
        stripePaymentText: '#0050B4',
        uploadBoxColor: "#F1F1F1",
        communityPostTextColor: "",
        communityLikertTextColor: "#0050B4",
        communityPollButtonColor: "#0050B4",
        darkerPrimaryColor: "#012448",
        subscriptionChoiceBorderColor: "#0F4C8B",
        monthlyProSubscriptionSKU: "PS-PUFC-M",
        yearlyProSubscriptionSKU: "PS-PUFC-Y",
        coachFeedbackVideoSampleLink: "https://via.kz-cdn.net/static/samples/pufc_sample.mp4",
        coachFeedbackVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/pufc_sample_thumbnail.jpg",
        submissionVideoSampleLink: "https://via.kz-cdn.net/static/samples/video_guidelines.mp4",
        submissionVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/video_guidelines_thumbnail.jpg",
        newsFeedIndexSlug: "pufc-news-feed-index",
        trainingFeedIndexSlug: "pufc-training-videos-index",
        proVideoLabelColor: "#C98B07",
        freeVideoLabelColor: "#012448",
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0050B4',
            contrastText: '#081111',
        },
        secondary: {
            main: '#012448',
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        error: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        info: {
            main: '#2196f3',
            contrastText: '#DAB870',
        },
        text: {
            primary: '#0050B4',
            secondary: '#999999',
        },
        grey: {
            100: '#F1F1F1',
            200: '#E8E8E8',
            300: '#D2D2D2',
            500: '#AAAAAA',
            600: '#A9A9A9',
            700: '#616161',
            800: '#414141',
            900: '#121212'
        },
        common: {
            black: '#081111'
        },
        background: {
            paper: '#F1F1F1'
        },
        divider: "#222222",
        olive: {
            main: '#162121',
        }
    },
    typography: {
        fontFamily: 'Barlow, Arial, sans-serif', // Default font family for body text
        h1: {
            fontFamily: 'Enamela, Arial, sans-serif', // Font family for h1 headers
        },
        h2: {
            fontFamily: 'Enamela, Arial, sans-serif', // Font family for h2 headers
        },
        h3: {
            fontFamily: 'Enamela, Arial, sans-serif', // Font family for h3 headers
        },
        h4: {
            fontFamily: 'Enamela, Arial, sans-serif', // Font family for h4 headers
        },
        h5: {
            fontFamily: 'Enamela, Arial, sans-serif', // Font family for h5 headers
        },
        h6: {
            fontFamily: 'Enamela, Arial, sans-serif', // Font family for h6 headers
        },
        button: {
            textTransform: "none"
        }
    },
    // components: {
    //     MuiTypography: {
    //         defaultProps: {
    //             variantMapping: {
    //                 h1: 'h2',
    //                 h2: 'h2',
    //                 h3: 'h2',
    //                 h4: 'h2',
    //                 h5: 'h2',
    //                 h6: 'h2',
    //                 subtitle1: 'h2',
    //                 subtitle2: 'h2',
    //                 body1: 'span',
    //                 body2: 'span',
    //             },
    //         },
    //     },
    // },
})
