import React, {useEffect, useRef, useState} from 'react'
import {Box, Button, Link, styled, Typography, useTheme} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Analytics} from "../../lib/analytics";
import {calculateAge} from "../../lib/utils";
import {isInViewport} from "../../util/isInViewport";
import {checkIsAnonymousUser} from "../../util/storage";
import CloseIcon from "@mui/icons-material/Close";
import {SocialSignInGroup} from "./components/SocialSignInGroup";
import {Links} from "../../constants/links";

const StyledInsideBoxHeader = styled(Typography)(()=>({
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "center",

}))

const StyledCloseIcon = styled('span')(()=>({
    borderRadius: "50%",
    height: "32px",
    width: "32px",
    backgroundColor: "#E4E8F2",
    "&:hover": {cursor: "pointer"},
}))


export interface GuestUpgradeGatewayProps {
    closeFn: () => void
    reloadSignIn?: () => void
}

export const GuestUpgradeGateway = (props: GuestUpgradeGatewayProps): React.ReactElement => {
    // const navigate = useNavigate()
    const theme = useTheme()
    const coverHeight: string = "48px"
    const buttonRef = useRef<HTMLButtonElement>(null);

    const birthdayBtnRef = useRef<HTMLButtonElement>(null);
    const [age, setAge] = useState<number|undefined>(undefined)
    const [dateReasonable, setDateReasonable] = useState<boolean>(false)
    // const [dateAccepted, setDateAccepted] = useState<boolean>(false)
    // const [socialChoice, setSocialChoice] = useState<string>("")
    const [dateOfBirth, setDateOfBirth] = useState<number>(0)
    const formRef = useRef<HTMLDivElement>(null);
    const socialButtonsRef = useRef<HTMLInputElement>(null);
    const [dateSelected, setDateSelected] = useState<string|null>(null)
    const [showSocialOptions, setShowSocialOptions] = useState<boolean>(false)



    useEffect(() => {
        if (dateReasonable && birthdayBtnRef.current) {
            birthdayBtnRef.current.focus();
        }
    }, [dateReasonable]);

    useEffect(() => {
        const handleScrollForKeyboard = () => {
            const form = formRef.current;
            if (!form || !socialButtonsRef.current) return;

            const socialButtonsInViewport = isInViewport(socialButtonsRef.current)

            if (!socialButtonsInViewport) {
                form.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        };

        window.addEventListener('resize', handleScrollForKeyboard);

        return () => {
            window.removeEventListener('resize', handleScrollForKeyboard);
        };
    }, []);

    useEffect(() => {
        if (dateReasonable) {
            buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [dateReasonable]);

    useEffect(() => {
        Analytics.signInOrSignUpView();
    },[])

    const dateUpdate = ( d: string|null) => {
        console.log(d)
        if(d){
            const age = calculateAge(d)
            setDateOfBirth(Math.round((new Date(d).getTime())/1000)) // divide by 1000 to get epoch time in seconds for python backend
            if(!isNaN(age) && age > 2 && age < 105){
                setAge(age)
                Analytics.signInOrSignUpSetAge(age);
                setDateReasonable(true)
            }
            else{
                setDateReasonable(false)
            }
        }
        else
            setAge(undefined)
    }

    return(
        <>
            <Box
                sx={{
                    width: '100%',
                    height: coverHeight, // Set the desired height for the Box
                    // backgroundImage: 'url(' + window.location.origin + theme.custom.brandCoverPhoto + ')', // Replace with your image URL
                    // backgroundSize: 'cover',
                    // backgroundPosition: 'center',
                    // backgroundRepeat: 'no-repeat',
                    backgroundColor: theme.custom.mainBackgroundColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white', // Text color for visibility
                }}
                // onClick={()=>navigate(routes.signIn)}
            >
                <img src={theme.custom.brandAcademyLogoVertical ? theme.custom.brandAcademyLogoVertical : theme.custom.brandTitleLogo}  style={{zIndex:100, marginTop: coverHeight, height: (!!theme.custom.brandAcademyLogoVertical ? "80px" : "64px")}} alt="Academy Cover"/>

            </Box>
            <Box sx={{
                padding: "64px 32px 0 32px",
                color: theme.custom.darkerPrimaryColor,
                backgroundColor: theme.custom.mainBackgroundColor,
            }}>
                <Typography sx={{
                    fontSize: "22px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    textAlign: "center",
                }}>Sign in or Sign up</Typography>
                <Typography sx={{
                    marginTop: "8px",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    textAlign: "center",
                    color: "#666666",
                }}>
                    { theme.custom.name === "ffc" ?
                        "to purchase Academy Feedback and enjoy all the features of the Virtual Academy"
                        : "to become an official member, get your membership number and enjoy all the features of the Virtual Academy"
                    }
                </Typography>

                <Box>
                    { showSocialOptions ?
                        <Box><SocialSignInGroup dateOfBirth={dateOfBirth}/></Box>
                        :
                    <Box sx={{
                        margin: "32px 0 " + ( theme.custom.name === "ffc" ? "8px" : (checkIsAnonymousUser() ? "40px" : "64px")),
                        padding: "24px 16px",
                        border: "1px solid " + theme.custom.wl.splashPageButtonBgColor,
                        borderRadius: theme.custom.wl.globalBorderRadius,
                    }}>
                        {/*{(age === undefined || !dateAccepted) && !(age && age >= 13) &&*/}
                            <>
                                <StyledInsideBoxHeader>What’s your date of birth?</StyledInsideBoxHeader>
                                {/*<Typography sx={{*/}
                                {/*    fontSize: "13px",*/}
                                {/*    fontWeight: 400,*/}
                                {/*    lineHeight: "16px",*/}
                                {/*    textAlign: "center",*/}
                                {/*    color: "#666",*/}
                                {/*}}>We need your date of birth to confirm your age</Typography>*/}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={dateSelected}
                                        sx={{
                                            mt: "24px", 
                                            mb: "16px", 
                                            width: "100%",
                                            '& .MuiDateCalendar-root': {
                                                backgroundColor: theme.custom.isWhiteLabelled ? '#FFFFFF' : "#3E3E3E"
                                            },
                                            "& .MuiPickersToolBar-root": {
                                                backgroundColor: theme.custom.isWhiteLabelled ? '#FFFFFF' : "#3E3E3E"
                                            }
                                        }}
                                        format="DD/MM/YYYY"
                                        label="Enter your date of birth"
                                        onChange={dateUpdate}
                                        // onAccept={() => setDateAccepted(true) }
                                        disableFuture
                                    />
                                </LocalizationProvider>
                                {dateReasonable && (age !== undefined && age < 13) &&
                                    <>

                                    <Button
                                        disableElevation
                                        ref={birthdayBtnRef}
                                        onClick={() => {
                                            // Analytics.createAccountAgeContinueButtonClick(1)
                                            // deletePurchaseFlowData()
                                            // setSignupMode(0, false, true)
                                            // dispatch(createAnonAccountThunk())
                                            setDateSelected(null)
                                            if(props.reloadSignIn){
                                                props.reloadSignIn()
                                            }
                                        }}
                                        {...{
                                            fullWidth: true,
                                            color: "primary",
                                            variant: "contained",
                                            sx: {
                                                borderRadius: "31px",
                                                minHeight: "60px",
                                                textAlign: "center",
                                                fontSize: "20px",
                                                fontWeight: "600",
                                                margin: "30px 0 0",
                                                color: "#FFFFFF"
                                            }
                                        }}
                                    >PARENT/GUARDIAN SIGN-IN</Button>
                                    <Typography sx={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    textAlign: "center",
                                    marginTop: "16px"
                                }}>For now, players aged under 13 must access the Virtual Academy from a parent/guardian account.</Typography>
                                        </>
                                }
                            </>
                        {/*}*/}
                        { age !== undefined && age >= 13 &&
                            <>
                                <Button
                                    ref={buttonRef}
                                    disableElevation
                                    onClick={async () => {
                                        setShowSocialOptions(true)
                                        // Analytics.signInOrSignUpClubLoginButtonClick()
                                        // deletePurchaseFlowData()
                                        // await signOut()
                                        // setRedirectAfterLogin()
                                        // setSignupMode(dateOfBirth, false)
                                        // wlSocialSignIn(theme.custom.name)
                                    }}
                                    {...{
                                        fullWidth: true,
                                        color: "primary",
                                        variant: "contained",
                                        sx: {
                                            borderRadius: "31px",
                                            minHeight: "60px",
                                            textAlign: "center",
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            margin: "16px 0 0",
                                            textTransform: "uppercase",
                                            color: "#FFFFFF"
                                        }
                                    }}
                                >CONTINUE</Button>
                            </>
                        }
                    </Box>
                    }
                    { theme.custom.name === "ffc" ?
                        <>
                            <Typography sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                textAlign: "center",
                                marginBottom: "8px",
                            }}>By continuing you agree to our <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms}>Terms</Link> and <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy}>Privacy Policy</Link>
                            </Typography>
                            <Typography sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "16px",
                                textAlign: "center",
                                marginBottom: "12px",
                            }}>VIA Academy is Fulham FC’s Official Virtual Football Academy Partner and the operator of the Fulham FC Virtual Academy website
                            </Typography>
                        </>
                        : null
                    }
                    <Box
                        sx={{
                            justifyContent: "center",
                            display: 'flex',
                            alignItems: 'center',
                            pb: "24px"
                        }}
                    ><StyledCloseIcon
                        onClick={props.closeFn}
                    ><CloseIcon sx={{ml: "4px", mt: "4px"}}/></StyledCloseIcon></Box>
                </Box>
            </Box>
        </>
    )
}