import React, {useEffect, useState} from "react";
import {TextField, Button, Typography, Box, styled, Stack, useTheme} from "@mui/material";

export interface IEditTextAreaValidationProps {
    maxLength: number;
}

interface IEditTextAreaProps {
    validation?: IEditTextAreaValidationProps;
    defaultValue?: string;
    placeholderValue?: string;
    errorText?: string;
    onChange?: () => void;
    onSave: (value: string) => void;
    onDiscard?: () => void;
}

const StyledBox = styled(Box)(({theme}) => ({
    flexDirection: "column",
    border: `1px solid #0054A4`,
    borderRadius: "8px",
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.wl.improvementStep.backgroundColor : "#162121",
    padding: "14px",
}));
const ActionsContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
}));
const StyledButton = styled(Button)(({theme}) => ({
    textTransform: "none",
    borderRadius: "12px",
    maxWidth: "57px",
    maxHeight: "25px",
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.wl.improvementStep.backgroundColor : undefined,
    color: theme.custom.isWhiteLabelled ? theme.custom.wl.improvementStep.buttonTextColor || theme.custom.wl.improvementStep.color : undefined,
    "&:hover": {
        backgroundColor: theme.custom.wl.improvementStep.buttonHoverColor
    },
    "&:disabled": {
        backgroundColor: theme.palette.grey[400],
    },
}));
const LengthIndicator = styled(Typography)(({theme}) => ({
    color: theme.palette.grey[700]
}));
const ErrorTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.warning.main,
    fontSize: "12px",
    fontWeight: "500"
}));
export const EditTextArea = ({
   validation = { maxLength: 280 },
   defaultValue = "",
   placeholderValue = "",
   errorText,
   onChange,
   onSave,
   onDiscard
}: IEditTextAreaProps): JSX.Element => {
    const theme = useTheme();
    const [value, setValue] = useState(defaultValue);
    const [errorExist, setErrorExist] = useState<boolean>(false);

    useEffect(() => {
        setErrorExist(!!errorText);
    }, [errorText]);
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (errorExist) {
            setErrorExist(false);
        }
        if (onChange) {
            onChange();
        }
        setValue(event.target.value.slice(0, validation.maxLength));
    };
    return (
        <Box sx={{m: "20px 0"}}>
            <StyledBox sx={{
                borderColor: errorExist && errorText ? theme.palette.warning.main : "#414141" ,
                backgroundColor: theme.custom.mainBackgroundColor
            }}>
                <TextField
                    multiline
                    color="secondary"
                    variant="standard"
                    placeholder={placeholderValue}
                    value={value}
                    onChange={handleTextChange}
                    fullWidth
                    sx={
                        {"& .MuiInputBase-multiline": {
                            color: theme.custom.isWhiteLabelled ? theme.custom.wl.improvementStep.color : "#EEEEEE",
                            paddingBottom: "20px"
                        }}
                    }
                />
                <ActionsContainer>
                    <Stack spacing={1} direction="row">
                        <StyledButton size="small" variant="contained" color="inherit" onClick={() => onSave(value)}
                                      disabled={!value.length}>
                            {defaultValue ? "Save" : "Done"}
                        </StyledButton>
                        {defaultValue && onDiscard && <StyledButton size="small" variant="outlined" color="secondary" onClick={onDiscard}>
                            Discard
                        </StyledButton>}
                    </Stack>
                    <LengthIndicator>{value.length}/{validation.maxLength}</LengthIndicator>
                </ActionsContainer>
            </StyledBox>
            {errorExist && errorText && <ErrorTypography sx={{mt: "10px"}}>{errorText}</ErrorTypography>}
        </Box>
    );
}
