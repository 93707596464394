import {BaseStripeElementsOptions} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {getClubTheme} from "../../lib/skinningUtils";

export const stripePriceDelimiter = 100;
const theme = getClubTheme()

export const stripeOptions: BaseStripeElementsOptions = {
    appearance: {
        theme: "stripe",
        variables: {
            colorPrimary: theme.custom.stripe.colorPrimary,
            colorBackground: theme.custom.stripe.colorBackground,
            colorText: theme.custom.stripe.colorText,
            colorDanger: theme.custom.stripe.colorDanger,
            colorWarning: theme.custom.stripe.colorWarning,
            fontFamily: theme.custom.stripe.fontFamily,
            fontSizeBase: theme.custom.stripe.fontSizeBase,
            fontWeightNormal: theme.custom.stripe.fontWeightNormal,
            spacingUnit: theme.custom.stripe.spacingUnit,
            borderRadius: theme.custom.stripe.borderRadius,
        },
        rules: {
            '.Input': {
                borderColor: theme.custom.stripe.borderColor, // Text color
                // '::placeholder': {
                //     color: theme.custom.stripe.placeholderColor, // Placeholder color
                // },
                // '.Input:focus': {
                //     borderColor: theme.custom.stripe.colorPrimary, // Border color on focus
                // },
                // '.Input--invalid': {
                //     color: theme.custom.stripe.colorDanger, // Color for invalid input
                //     iconColor: theme.custom.stripe.colorDanger,
                // },
            },
            '.Label': {
                fontFamily: theme.custom.stripe.labelFontFamily || theme.custom.stripe.fontFamily,
            }
        },
    }
};
