import {Box, Link, styled} from "@mui/material";
import {Links} from "../constants/links";
import React from "react";

const MobileDownloadButtonsBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "20px",
    margin: "30px 0",
}));
export const MobileDownloadButtons = (): JSX.Element => {
    return (
        <MobileDownloadButtonsBox>
            <Link href={Links.downloadAppStore} target="_blank" rel="noreferrer">
                <img src={process.env.PUBLIC_URL + "/appstore_buttons/app_store.svg"} alt="App store" width="144px"/>
            </Link>
            <Link href={Links.downloadGooglePlay} target="_blank" rel="noreferrer">
                <img src={process.env.PUBLIC_URL + "/appstore_buttons/google_play.svg"} alt="Google play" width="144px"/>
            </Link>
        </MobileDownloadButtonsBox>
    );
}
