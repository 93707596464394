import React, {useEffect} from 'react';
import {Box, Button, styled, Typography} from "@mui/material";
import {EmailOutlined} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ViaUserProfile} from "../user/userSlice";
import {RootState} from "../../redux/store";
import {getConsentThunk, IConsentData, requestConsentThunk} from "../consent/consentSlice";
import { SpacedMain } from '../../shared/styles';

const CenteredBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "100px",
}));
const TitleTypography = styled(Typography)(({theme}) => ({
    fontSize: "22px",
    fontWeight: "700",
    marginBottom: "40px",
    color: theme.palette.text.primary,
}));
const SubtitleTypography = styled(Typography)(({theme}) => ({
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "30px",
    color: theme.palette.grey[500],
}));
const StyledEmailOutlined = styled(EmailOutlined)(({theme}) => ({
    width: "32px",
    height: "32px",
    color: theme.palette.grey[500],
    marginBottom: "10px",
}));
const StyledTypography = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    fontWeight: "500",
    color: theme.palette.grey[500],
    marginBottom: "20px",
}));
const StyledButton = styled(Button)(({theme}) => ({
    marginBottom: "10px",
    width: "200px",
    height: "48px",
    borderRadius: "24px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.grey[500],
}));
export const PermissionRequired = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const pollConsentDataTimer = 30 * 1000; // Every 30 seconds
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const consent = useAppSelector<IConsentData>((state: RootState) => state.consentSlice.consent);

    useEffect(() => {dispatch(requestConsentThunk())}, [dispatch]);
    useEffect(() => {
        const timer = setInterval(() => {
            if (consent.data?.consented) {
                clearTimeout(timer);
                return;
            }

            dispatch(getConsentThunk());
        }, pollConsentDataTimer);

        return () => clearTimeout(timer);
    }, [pollConsentDataTimer, consent, dispatch]);

    const resendEmail = () => dispatch(requestConsentThunk());

    return (
        <SpacedMain>
            <CenteredBox>
                <TitleTypography>Hi, {profile?.user.first_name}!</TitleTypography>
                <SubtitleTypography>You need permission to use Coach Feedback</SubtitleTypography>
                <StyledEmailOutlined/>
                <StyledTypography>We've sent an email to your parent/guardian to ask them for permission</StyledTypography>
                <StyledTypography sx={{color: "#777777"}}>If your parent/guardian can’t find the email, please ask them to check their Spam folder</StyledTypography>
                <StyledButton variant="outlined" color="secondary" sx={{mt: "50px"}} onClick={resendEmail}>Resend Email</StyledButton>
                {consent.error && <Typography color="warning.main">{consent.error}</Typography>}
            </CenteredBox>
        </SpacedMain>
    );
}
