import React, {useEffect, useRef, VideoHTMLAttributes} from 'react';
import Hls from 'hls.js';
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getProVideoSecureUrlThunk, ProVideoSecureMedia} from "../features/academyContent/academyContentSlice";
import {RootState} from "../redux/store";
import {Loader} from "./Loader";

export interface HLSVideoPlayerProps extends VideoHTMLAttributes<HTMLVideoElement> {
    hlsSrc: string; // URL of the HLS stream
    mp4Src: string; // Fallback URL of the MP4 video
    surl?: string;
    videoPageId: number
}

const hlsConfig = {
    // Buffer settings
    maxBufferLength: 60,             // Maximum buffer length in seconds
    // maxMaxBufferLength: 60,          // Maximum buffer length in seconds
    // maxBufferSize: 60 * 1000 * 1000, // Maximum buffer size in bytes
    maxBufferHole: 1.0,              // Maximum buffer hole in seconds

    // Fragment load settings
    maxFragLookUpTolerance: 0.4,     // Tolerance when seeking for a fragment, in seconds

    // Live streaming settings
    // liveSyncDurationCount: 3,        // Number of segments to keep in buffer for live streams
    // liveMaxLatencyDurationCount: Infinity, // Maximum latency duration for live streams
    // liveSyncDuration: undefined,     // Live sync duration in seconds (alternative to liveSyncDurationCount)
    // liveMaxLatencyDuration: undefined, // Maximum latency duration in seconds (alternative to liveMaxLatencyDurationCount)

    // ABR settings
    enableWorker: true,              // Use web workers for ABR (Adaptive Bitrate) management
    // enableSoftwareAES: true,         // Enable software AES decryption for DRM
    // manifestLoadingTimeOut: 20000,   // Manifest loading timeout in milliseconds
    // manifestLoadingMaxRetry: 4,      // Maximum number of retries for manifest loading
    // manifestLoadingRetryDelay: 500,  // Delay between retries for manifest loading
    // manifestLoadingMaxRetryTimeout: 64000, // Maximum retry timeout for manifest loading
    // levelLoadingTimeOut: 20000,      // Level loading timeout in milliseconds
    // levelLoadingMaxRetry: 4,         // Maximum number of retries for level loading
    // levelLoadingRetryDelay: 500,     // Delay between retries for level loading
    // levelLoadingMaxRetryTimeout: 64000, // Maximum retry timeout for level loading
    // fragLoadingTimeOut: 20000,       // Fragment loading timeout in milliseconds
    // fragLoadingMaxRetry: 6,          // Maximum number of retries for fragment loading
    // fragLoadingRetryDelay: 500,      // Delay between retries for fragment loading
    // fragLoadingMaxRetryTimeout: 64000, // Maximum retry timeout for fragment loading

    // Low latency settings
    lowLatencyMode: true,            // Enable low latency mode if your stream supports it
    // startLevel: undefined,           // Level to start with (undefined means auto)

    // Cap level on FPS drop
    capLevelToPlayerSize: true,      // Cap the quality level to player size

    // Logging
    // debug: false,                    // Set to true to enable debug logs

    // Additional settings
    // initialLiveManifestSize: 1,      // Number of segments in the initial live manifest

    // cause "Failed to set the 'duration' property on 'MediaSource': The 'updating' attribute
    // is true on one or more of this MediaSource's SourceBuffers." error
    // startFragPrefetch: true,         // Prefetch fragments ahead of time
    // nudgeMaxRetry: 3,                // Maximum number of nudge retries
};


export const HLSVideoPlayer = (props: HLSVideoPlayerProps): React.ReactElement => {
    const { hlsSrc, mp4Src, videoPageId, ...rest } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const dispatch = useAppDispatch()

    // const [gotSecureMedia, setGotSecureMedia] = useState<boolean>(props.surl === undefined)

    const secureMedia = useAppSelector<ProVideoSecureMedia|undefined>((state: RootState) => state.academyContent.trainingVideoSecureMedia);
    const fetchingSecureMedia = useAppSelector<boolean>((state: RootState) => state.academyContent.trainingVideoFetchingSecureMedia);

    const secureVideoReady = secureMedia && !!props.surl && secureMedia.videoPageId === videoPageId
    const secureVideoRequired = !!props.surl
    const hlsSrcResult = secureVideoRequired && secureVideoReady ? secureMedia.media.video.stream.url : hlsSrc

    useEffect(() => {
        const getSecureMedia = async () => {
            if(secureVideoRequired && !secureVideoReady) {
                await dispatch(getProVideoSecureUrlThunk({
                    videoPageId: videoPageId,
                    surl: props.surl || ""
                }))
                // setGotSecureMedia(true)
            }
        }
        getSecureMedia()
    }, [props.surl, dispatch, secureVideoRequired, secureVideoReady, videoPageId ]);


    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        let hls: Hls;

        if (Hls.isSupported()) {
            hls = new Hls(hlsConfig);
            // hls = new Hls();
            hls.loadSource(hlsSrcResult);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                console.log("playing with: hls (supported)")
                video.play().catch(error => console.error('Error playing the video:', error));
            });
        } else {
            video.src = video.canPlayType('application/vnd.apple.mpegurl') ? hlsSrcResult : mp4Src;
           console.log("playing with: ",video.canPlayType('application/vnd.apple.mpegurl') ? "hlsSrcResult (application/vnd.apple.mpegurl)" : "mp4Src" )
            video.addEventListener('loadedmetadata', () => {
                video.play().catch(error => console.error('Error playing the video:', error));
            });
        }

        return () => {
            if (hls) {
                hls.destroy();
            }else{
                video.pause();
                video.removeAttribute('src'); // empty source
                video.load()
            }
        };
    }, [hlsSrcResult, mp4Src, secureVideoReady, secureVideoRequired, videoRef]);

    return (<>
        { secureVideoRequired && !secureVideoReady ?
            <>{ !fetchingSecureMedia && secureMedia === undefined ?
                <h2>Media Not Available</h2>
                :
                <Loader/>
            }</>
            :
            <video ref={videoRef} {...rest} />
        }
        </>)
};
