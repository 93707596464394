import {Box, Button} from "@mui/material";
import {checkIsAnonymousUser} from "../../util/storage";
import {GuestUpgradeGateway} from "../../views/gateway/GuestUpgradeGateway";
import {SubscriptionWall} from "../SubscriptionWall";
import React from "react";


export interface SignInFooterComponentProps {
    hideSubWall?: () => void
    reloadSignIn?: () => void
    onAction?: () => void
}

export const SignInFooterComponent = (props:SignInFooterComponentProps):React.ReactElement => {
    return (
        <Box sx={{display: "flex", flexDirection: "column", zIndex: 1}} className="sticky-footer">
            { checkIsAnonymousUser() ?
                <GuestUpgradeGateway
                    closeFn={
                        props.hideSubWall ?
                            props.hideSubWall
                            : ()=>{}
                    }
                    reloadSignIn={props.reloadSignIn}/>
                : <><SubscriptionWall/><Button sx={{padding: "10px 0"}} variant="text"
                                               onClick={props.onAction ?
                                                   props.onAction
                                                   :
                                                   () => {}
                }>Not now</Button></>
            }
        </Box>
    )
}
