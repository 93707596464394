import React from "react"
import {Box, styled, Typography} from "@mui/material";
import {Links} from "../constants/links";
import {maxFileSize, maxVideoLengthInMinutes, minVideoLengthInMinutes} from "../features/media/FeedbackUploadZone";
import {formatBytes} from "../util/file";
import {getCFMaxFileSize, getCFMaxLength} from "../util/storage";

const HeaderTypography = styled(Typography)(() => ({
    fontSize: "30px",
    fontWeight: "800",
    textAlign: "center",
    textTransform: "uppercase",
}));
const ContentBox = styled(Box)(({theme}) => ({
    flexDirection: "column",
    "p, div, li": {
        color: theme.palette.grey[700]
    }
}));
const SectionBox = styled(Box)(() => ({
    paddingTop: "20px",
}));
const TitleTypography = styled(Typography)(() => ({
    textTransform: "uppercase",
    fontSize: "14px",
}));
const VideoWrapper = styled("div")(() => ({
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    paddingBottom: "56.25%", // 16:9 aspect ratio
    borderRadius: "16px",
    overflow: "hidden",
    margin: "5px 0",
}));
const ListTypography = styled(Typography)(() => ({
    fontSize: "12px",
    lineHeight: "22px",
    "ul": {
        paddingLeft: "16px",
        "li": {
            marginBottom: "5px",
        },
    },
}));
export const VideoInstructions = () => {
    return (
        <Box>
            <Box>
                <HeaderTypography color="common.black">Video guidelines</HeaderTypography>
            </Box>
            <ContentBox>
                <SectionBox>
                    <TitleTypography>Video type</TitleTypography>
                    <Typography variant="caption">Match footage, skills footage, or a combination of both</Typography>
                </SectionBox>
                <SectionBox>
                    <TitleTypography>Length</TitleTypography>
                    <Typography variant="caption">{minVideoLengthInMinutes} to {getCFMaxLength() || maxVideoLengthInMinutes} minutes</Typography>
                </SectionBox>
                <SectionBox>
                    <TitleTypography>Size</TitleTypography>
                    <Typography variant="caption">Maximum {formatBytes(getCFMaxFileSize() || maxFileSize)}</Typography>
                </SectionBox>
                <SectionBox>
                    <TitleTypography>Supported VIDEO FormatS</TitleTypography>
                    <Typography variant="caption">MP4, MOV, HEVC</Typography>
                </SectionBox>
                <SectionBox>
                    <TitleTypography>Match example</TitleTypography>
                    <VideoWrapper>
                        <video src={Links.matchVideoExample} className="guideline-sample" controls muted playsInline controlsList="noplaybackrate nodownload" disablePictureInPicture></video>
                    </VideoWrapper>
                </SectionBox>
                <SectionBox>
                    <TitleTypography>Skills example</TitleTypography>
                    <VideoWrapper>
                        <video src={Links.skillsVideoExample} className="guideline-sample" controls muted playsInline controlsList="noplaybackrate nodownload" disablePictureInPicture></video>
                    </VideoWrapper>
                </SectionBox>
                <SectionBox>
                    <TitleTypography>Do’s & don’ts</TitleTypography>
                    <ListTypography variant="caption">
                        <ul>
                            <li>Do show examples of the things you"d like to improve on</li>
                            <li>Do make sure you’re clearly visible and that the video focuses on you</li>
                            <li>Don’t upload low quality videos that are dark, blurry, or filmed from far away</li>
                        </ul>
                    </ListTypography>
                </SectionBox>
                <SectionBox>
                    <TitleTypography>Permission</TitleTypography>
                    <ListTypography variant="caption">
                        <ul>
                            <li>Make sure you own or have permission to use any video you send to us</li>
                            <li>Make sure you have permission from any other people who feature in your video</li>
                        </ul>
                    </ListTypography>
                </SectionBox>
            </ContentBox>
        </Box>
    );
}
