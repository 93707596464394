import React, {useEffect} from 'react'
import {
    FeedItem, ImageCarouselFeedEntryPage, LikertScalePage, TrainingVideoPage,
    upgradeItemPreviewThunk, UserPollPage, UserQuestionPage, VideoFeedEntryPage
} from "../../features/academyContent/academyContentSlice";
import {useAppDispatch} from "../../redux/hooks";
import {Post} from "./components/Post";
import {cmsPageType} from "../../constants/cms";
import {LikertScale} from "./components/LikertScale";
import {QnA} from "./components/QnA";
import {Poll} from "./components/Poll";
import {TrainingVideo} from "../train/components/TrainingVideo";
import { Box } from '@mui/material';

export interface FeedItemSifterProps {
    item: FeedItem
    activateSubWallFn?: () => void
    hasProSubscription?: boolean
    likertResponseSending?: boolean
    addLikertResponseSending?: (newVal: number) => void
    likertDone?: boolean
    addLikertItemDone?: (newVal: number) => void
    showingSubWall?: boolean
}

export const FeedItemSifter = (props: FeedItemSifterProps): React.ReactElement => {
    const hasProSubscription = !!props.hasProSubscription

    const dispatch = useAppDispatch()

    useEffect(() => {
        if(props.item.isPreview){
            dispatch(upgradeItemPreviewThunk(props.item.id.toString()))
        }
    }, [dispatch, props.item.id, props.item.isPreview]);

    switch(props.item.meta.type){
        case cmsPageType.ImageCarouselFeedEntryPage:
            return (<>
                { props.item.isPreview
                    ?
                    <Box sx={{height: "600px"}}></Box>
                    // <PostPreview
                    //     key={props.item.id}
                    //     timestamp={props.item.meta.first_published_at}
                    //     postId={props.item.id}
                    //     description={props.item.title}
                    // />
                    :
                    <Post
                        item={(props.item as ImageCarouselFeedEntryPage)}
                    />
                }
            </>)
        case cmsPageType.VideoFeedEntryPage:
            return (<>
                { props.item.isPreview
                    ?
                    <Box sx={{height: "600px"}}></Box>
                    // <PostPreview
                    //     key={props.item.id}
                    //     timestamp={props.item.meta.first_published_at}
                    //     postId={props.item.id}
                    //     description={props.item.title}
                    // />
                    :
                    <Post
                        item={(props.item as VideoFeedEntryPage )}
                    />
                }
            </>)
        case cmsPageType.LikertScalePage:
            if(props.item.isPreview){
                // return (<></>)
                return (<Box sx={{height: "600px"}}></Box>)
            }else{
                const likertPage = props.item as LikertScalePage
                return(<LikertScale
                    item={likertPage}
                    likertResponseSending={props.likertResponseSending}
                    addLikertResponseSending={props.addLikertResponseSending}
                    likertDone={props.likertDone}
                    addLikertItemDone={props.addLikertItemDone}
                    activateSubWallFn={props.activateSubWallFn}
                    showingSubWall={props.showingSubWall}
                />)
            }
        case cmsPageType.UserPollPage:
            if(props.item.isPreview){
                // return (<></>)
                return (<Box sx={{height: "600px"}}></Box>)
            }else {
                const pollPage = props.item as UserPollPage
                return (<Poll
                    item={pollPage}
                    activateSubWallFn={props.activateSubWallFn}
                    showingSubWall={props.showingSubWall}
                />)
            }
        case cmsPageType.UserQuestionPage:
            if(props.item.isPreview){
                // return (<></>)
                return (<Box sx={{height: "600px"}}></Box>)
            }else {
                const questionsPage = props.item as UserQuestionPage
                return (<QnA
                    item={questionsPage}
                    activateSubWallFn={props.activateSubWallFn}
                    showingSubWall={props.showingSubWall}
                />)
            }
        case cmsPageType.TrainingVideoPage:
            if(props.item.isPreview){
                return (<Box sx={{height: "600px"}}></Box>)
            }else {
                const trainingVideoPage = props.item as TrainingVideoPage
                return (<TrainingVideo hasProSubscription={hasProSubscription} activateSubWallFn={props.activateSubWallFn || (()=>{})} item={trainingVideoPage}/>)
            }
        default:
            return (<></>)
    }
}
