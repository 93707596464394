import React from "react";
import {ScreenLayout} from "../../../shared/ScreenLayout";
import {Box, Link, List, ListItem, styled, Typography} from "@mui/material";
import {Links} from "../../../constants/links";

interface ILuckyDrawRulesProps {
    onComplete: () => void;
}
interface IInstructionsData {
    title: string;
    subtitle: string;
}
const StyledBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}));
const StyledNumber = styled(Typography)(({theme}) => ({
    fontSize: "16px",
    width: "32px",
    height: "32px",
    backgroundColor: theme.palette.grey[700],
    padding: "10px",
    borderRadius: "16px",
    marginRight: "30px",
    lineHeight: 0.8,
    textAlign: "center",
    fontWeight: "500",
}));
const StyledTitle = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "600",
}));
const StyledSubtitle = styled(Typography)(() => ({
    fontSize: "14px",
    fontWeight: "400",
}));
export const LuckyDrawRules = ({onComplete}: ILuckyDrawRulesProps): JSX.Element => {
    const instructionsData: IInstructionsData[] = [
        {
            title: "Upload a Video",
            subtitle: "to enter the draw",
        },
        {
            title: "Winners Announced",
            subtitle: "on the 1st of each month at 9am GMT",
        },
        {
            title: "Receive Feedback",
            subtitle: "If you win, your submitted video will be reviewed by the first available coach",
        },
    ]
    return (
        <ScreenLayout
            title="Monthly Lucky Draw"
            subtitle="Your chance to WIN Coach Feedback for free"
            ctaTitle={"Upload Video"}
            onAction={onComplete}
        >
            <>
                <List>
                    {instructionsData.map(({title, subtitle}: IInstructionsData, index: number) => (
                        <ListItem key={index} sx={{ pl: 0, pr: 0 }}>
                            <StyledBox>
                                <StyledNumber>{index + 1}</StyledNumber>
                                <Box>
                                    <StyledTitle>{title}</StyledTitle>
                                    <StyledSubtitle>{subtitle}</StyledSubtitle>
                                </Box>
                            </StyledBox>
                        </ListItem>
                    ))}
                </List>
                <StyledSubtitle sx={{ mt: "10px" }}>
                    You can enter the draw every month for a new chance to win. See our&nbsp;
                    <Link color="secondary" underline="always" target="_blank" href={Links.terms}>Terms</Link>&nbsp;
                    for details
                </StyledSubtitle>
            </>
        </ScreenLayout>
    );
}
