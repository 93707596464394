import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography, Card, CardContent, useTheme, styled, Button} from "@mui/material";
import {
    setSendingUserQuestionTo,
    submitUserQuestionResponse,
    UserQuestionPage
} from "../../../features/academyContent/academyContentSlice";
import AddIcon from '@mui/icons-material/Add';
import {Loader} from "../../../shared/Loader";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {StyledUserFeedbackTextField} from "../../../shared/styles";
import { checkIsAnonymousUser } from '../../../util/storage';
import {StyledCardClickOverlay} from "../AcademyUpdates";


const StyledAddQuestionButton = styled(Button)(() => ({
    marginTop: "16px",
    padding: "16px",
    color: "#AAAAAA",
    borderColor: "#AAAAAA",
    borderStyle: "dashed",
    textTransform: "uppercase",
    '&:hover': {
        borderStyle: "dashed",
        borderColor: "#AAAAAA",
    },
}))

export interface QnAProps{
    item: UserQuestionPage
    activateSubWallFn?: () => void
    showingSubWall?: boolean
}

export const QnA = (props: QnAProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [question, setQuestion] = useState('');
    const [charRemaining, setCharRemaining] = useState(144);
    // const [submitted, setSubmitted] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [done, setDone] = useState<boolean>(false);
    const sendingUserQuestionTo = useAppSelector<number>((state: RootState) => state.academyContent.sendingUserQuestionTo);
    const containerRef = useRef(null);
    const [overlayStyle, setOverlayStyle] = useState({});

    useEffect(() => {
        if (containerRef.current) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            setOverlayStyle({
                width: `${offsetWidth}px`,
                height: `${offsetHeight}px`
            });
        }
    }, [containerRef]);

    const handleQuestionChange = (event:any) => {
        if(charRemaining > 0 || event.target.value.length < 144){
            setQuestion(event.target.value);
            setCharRemaining(144 - event.target.value.length)
        }
    };

    const handleSubmit = () => {
        // setSubmitted(true);
        dispatch(setSendingUserQuestionTo(props.item.id))
        dispatch(submitUserQuestionResponse({id: props.item.id, question: question, destination: props.item.response_data.submission_url}))
        // dispatch(upgradeItemPreviewThunk(props.item.id.toString()))
    };

    const handleAddQuestionClick = () => {
        setShowInput(true);
    };

    const handleDoneClick = () => {
        setDone(true)
        setQuestion("");
        setCharRemaining(144);
        setShowInput(false);
        // dispatch(setUserQuestionDone(props.item.id))
    };

    if (done) {
        return null;
    }
    return (
        <>
            { !props.item.response_data.user_has_submitted ?
        <Card raised sx={{ mx: 'auto', mb: 2, position: "relative"}}
              ref={containerRef}
        >
            { checkIsAnonymousUser() && !props.showingSubWall &&
                <StyledCardClickOverlay
                    style={overlayStyle}
                    onClick={e=>{
                        e.stopPropagation()
                        if(checkIsAnonymousUser() && props.activateSubWallFn) {
                            props.activateSubWallFn()
                        }
                    }}
                ></StyledCardClickOverlay>
            }
            <Typography sx={{
                color: theme.custom.communityLikertTextColor,
                fontSize: '24px',
                fontWeight: '600',
                flexGrow: 1,
                lineHeight: "30.24px",
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(1),
                paddingX: theme.spacing(2)
            }}>
                {!props.item.response_data.user_just_submitted ? props.item.headline_text : props.item.post_submission_heading}
            </Typography>
            {!props.item.response_data.user_just_submitted ?
                <div style={{
                    padding: "0 16px 16px 16px"
                }}>
                    {!showInput ?
                        <StyledAddQuestionButton
                            fullWidth={true}
                            variant="outlined" onClick={handleAddQuestionClick}
                        >
                            <AddIcon/> TYPE HERE
                        </StyledAddQuestionButton>
                        :
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "stretch",
                                flexDirection: "column",
                            }}
                        >
                            <StyledUserFeedbackTextField
                                label={charRemaining + ""}
                                multiline
                                minRows={3}
                                fullWidth
                                value={question}
                                onChange={handleQuestionChange}
                                margin="normal"
                                variant="outlined"
                                inputProps={{
                                    style: {color: theme.custom.communityLikertTextColor},
                                }}
                            />
                            {sendingUserQuestionTo === props.item.id ?
                                <Loader/>
                                :
                                <Button fullWidth={true}
                                        disableElevation={true}
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{padding: theme.spacing(2), color: "#FFFFFF"}}
                                        disabled={charRemaining > 134}
                                >
                                    SUBMIT
                                </Button>
                            }
                        </Box>
                    }
                </div>
            :
            <>
                {props.item.response_data.user_just_submitted && !done ?
                    <CardContent>
                        <Typography sx={{
                            color: theme.custom.communityPostTextColor,
                            textAlign: 'center',
                            fontSize: '16px',
                            flexGrow: 1,
                            padding: theme.spacing(2)
                        }}>
                            {props.item.post_submission_text}
                        </Typography>
                        <Box display="flex" justifyContent="center">
                            <Button variant="outlined" onClick={handleDoneClick}>
                                Done
                            </Button>
                        </Box>
                    </CardContent>
                    : null
                }
            </>
            }
        </Card>
            : null }
        </>
    );
};
