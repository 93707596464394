import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    FormControlLabel,
    Link,
    Radio,
    styled,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {
    clearCurrentOrder,
    createSubscriptionThunk, getIntendedSubscriptionThunk,
    getSubscriptionProductsThunk, getUserSubscriptionsThunk,
    SubscriptionProduct,
    UserSubscription
} from "../../features/subscriptions/subscriptionsSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {AddressElement, CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {StripeCardElement, StripeError} from "@stripe/stripe-js";
import {CheckBoxOutlineBlank, CheckBoxOutlined} from "@mui/icons-material";
import {Links} from "../../constants/links";
import {StripeElementChangeEvent} from "@stripe/stripe-js/types/stripe-js/elements/base";
import {StripeAddressElementChangeEvent} from "@stripe/stripe-js/types/stripe-js/elements/address";
import {emailRules} from "../../constants/rules";
import {useValidateEmail} from "../../hooks/useValidateEmail";
import {Loader} from "../../shared/Loader";
import {performTrialSubscriptionPurchase} from "../../lib/stripeUtils";
import {useNavigate} from "react-router-dom";
import {routes} from "../../constants/routes";
import {viaDelay} from "../../lib/utils";
import {Analytics} from "../../lib/analytics";

const StickyFormControlLabel = styled(FormControlLabel)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "16px",
    margin: 0
}));

const LabelTypography = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "10px"
}));

const SpacedBox = styled(Box)(() => ({
    margin: "30px 2px",
    "> div": {
        marginBottom: "10px",
    },
    "div": {
        borderRadius: "16px",
    },
}));
const NoticeTypography = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    color: theme.palette.grey[900]
}));
const StyledTextField = styled(TextField)(({theme}) => ({
    "& .MuiInputBase-input:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.default} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
    },
    // borderColor: theme.custom.stripe.borderColor || theme.custom.stripe.colorText,
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.custom.stripe.borderColor, // Default border color
            borderRadius: theme.custom.stripe.borderRadius,
        },
    }
}));

// const StyledSubtitle = styled(Typography)(({theme}) => ({
//     fontSize: "14px",
//     color: theme.palette.text.primary,
//     padding: "0 16px",
// }));

interface StripeElementState {
    complete: boolean;
    error?: string;
}

export interface StripeAddress {
    city?: string
    country?: string
    line1?: string
    line2?: string
    postal_code?: string
    state?: string
}

export interface SubscribeProps{
    step?: string
    plan: string
}


export const Subscribe = (props: SubscribeProps): React.ReactElement => {
    const stripe = useStripe();
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const step: number = props.step === 'start-trial' ? 0 :
        !!props.step ? +props.step || 1 : 1
    const plan: string = props.plan || "nothing"

    const elements = useElements();
    const [submitError, setSubmitError] = useState<string>("");
    const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
    const [cardElementState, setCardElementState] = useState<StripeElementState>({complete: false});
    const [addressElementState, setAddressElementState] = useState<StripeElementState>({complete: false});
    const [yearlyIntended, setYearlyIntended] = useState<UserSubscription[]>()
    const [monthlyIntended, setMonthlyIntended] = useState<UserSubscription[]>()
    const [over18Years, setOver18Years] = useState<boolean>(false)
    const subscriptionProducts = useAppSelector<SubscriptionProduct[]>((state: RootState) => state.subscriptions.subscriptionProducts);
    const mySubscriptions = useAppSelector<UserSubscription[]>((state: RootState) => state.subscriptions.mySubscriptions);
    const organisationId = useAppSelector<string>((state: RootState) => state.user.organisationId);
    const [email, setEmail, isEmailEmpty, isEmailValid] = useValidateEmail("");
    const [showEmailError, setShowEmailError] = useState<boolean>(false);
    const [showCardError, setShowCardError] = useState<boolean>(false);
    const [showAddressError, setShowAddressError] = useState<boolean>(false);
    const [payButtonPressed, setPayButtonPressed] = useState(false)
    const currentOrder = useAppSelector<UserSubscription|undefined>((state: RootState) => state.subscriptions.currentOrder);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>("")
    const [address, setAddress] = useState<StripeAddress>({
        line1: "",
        line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: ""
    })
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [showFirstNameError, setShowFirstNameError] = useState<boolean>(false);
    const [showLastNameError, setShowLastNameError] = useState<boolean>(false);
    const [showAgeDisclaimerError, setShowAgeDisclaimerError] = useState<boolean>(false);


    useEffect(() => {
        dispatch(getSubscriptionProductsThunk())
        dispatch(getUserSubscriptionsThunk())
    },[dispatch])

    useEffect(() => {
        if(mySubscriptions.length){
            setMonthlyIntended(mySubscriptions.filter(ms =>
                ms.product.sku === theme.custom.monthlyProSubscriptionSKU
                && ms.status === "INCOMPLETE"
            ))
            setYearlyIntended(mySubscriptions.filter(ms =>
                ms.product.sku === theme.custom.yearlyProSubscriptionSKU
                && ms.status === "INCOMPLETE"
            ))
        }
    }, [mySubscriptions, setYearlyIntended, setMonthlyIntended, theme]);

    useEffect(() => {
        if(
            payButtonPressed
            && step === 0
            && !!stripe
            && !!elements
        ){
            const createTrialSub = async () => {

                const cardElement = elements.getElement(CardElement) as StripeCardElement

                const performTrialResponse = await performTrialSubscriptionPurchase(
                    stripe,
                    cardElement,
                    address,
                    organisationId,
                    (plan === 'monthly' ? theme.custom.monthlyProSubscriptionSKU : theme.custom.yearlyProSubscriptionSKU),
                    email
                )

                if(performTrialResponse.error){
                    const paymentError: StripeError = performTrialResponse.error as StripeError
                    setPaymentErrorMessage(`${paymentError.message} [${paymentError.type}:${paymentError.code}]`);
                    setPayButtonPressed(false)
                }else if(performTrialResponse.subscriptionResponse){
                    dispatch(getUserSubscriptionsThunk)
                    viaDelay(1000)
                    navigate(routes.subscriptionPurchaseConfirmation.replace(":subId", performTrialResponse.subscriptionResponse.uuid))
                }
            }

            createTrialSub().then()

        }

        // non-trial
        if(
            payButtonPressed
            && step === 1
            && !!stripe
            && !!elements
        ){
            if(plan === "monthly") {
                if(monthlyIntended !== undefined && monthlyIntended.length > 0) {
                        dispatch(getIntendedSubscriptionThunk(monthlyIntended[0].uuid))
                }else {
                        dispatch(createSubscriptionThunk({
                            sku: theme.custom.monthlyProSubscriptionSKU,
                            receipt_recipient: email,
                            organisation_id: organisationId,
                            source: "CLUB_VFA",
                            activate_trial: false
                        }))
                }
            }
            if(plan === "yearly") {
                if(yearlyIntended !== undefined && yearlyIntended.length > 0) {
                    dispatch(getIntendedSubscriptionThunk(yearlyIntended[0].uuid))
                }else {
                    dispatch(createSubscriptionThunk({
                        sku: theme.custom.yearlyProSubscriptionSKU,
                        receipt_recipient: email,
                        organisation_id: organisationId,
                        source: "CLUB_VFA",
                        activate_trial: false
                    }))
                }
            }
        }
    },[stripe, address, elements, navigate,organisationId, dispatch, monthlyIntended, yearlyIntended, payButtonPressed, email, plan, theme, step])


    useEffect(() => {
        console.log("got here")
        if(
            currentOrder
            && stripe
            && elements
            && payButtonPressed
            && step === 1 //non-trial
        ){
            const cardElement = elements.getElement(CardElement) as StripeCardElement
            console.log("defining makePurchase")
            const makePurchase = async () => {
                // await viaDelay(3000)
                console.log("finished delay, stripe.confirmCardPayment about to happen")
                if(currentOrder !== undefined && currentOrder.payment_intent && payButtonPressed && stripe){
                    console.log("stripe.confirmCardPayment happening next")
                    const { error, paymentIntent } = await stripe.confirmCardPayment(currentOrder.payment_intent.client_secret, {
                        payment_method: {
                            card: cardElement,
                            billing_details: {
                                address,
                                email,
                            },
                        },
                    });

                    if(error){
                        const paymentError: StripeError = error as StripeError
                        setPaymentErrorMessage(`${paymentError.message} [${paymentError.type}][${paymentError.code}]`);
                        setPayButtonPressed(false)
                    } else if (paymentIntent) {
                        console.log('PaymentIntent', paymentIntent);
                        // alert('Payment successful!');
                        // dispatch(clearCurrentOrder())
                    }
                    // setPayButtonPressed(false);
                }
            }
            makePurchase().then(async ()=>{
                const routeTo = routes.subscriptionPurchaseConfirmation.replace(":subId", currentOrder.uuid )
                dispatch(clearCurrentOrder())
                dispatch(getUserSubscriptionsThunk())
                await viaDelay(200)
                navigate(routeTo)
            });
        }
    }, [step, navigate, dispatch, currentOrder,payButtonPressed, stripe, elements, address, setPaymentErrorMessage, setPayButtonPressed, email]);


    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setShowEmailError(false);
        setShowFormErrors(false);
    };

    const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
        const {complete, value} = event;
        setAddressElementState({complete});
        setAddress(value.address as StripeAddress);
        setFirstName(value.firstName || "");
        setLastName(value.lastName || "");
    
        if (complete) {
            setShowAddressError(false);
        } else {
            setShowAddressError(true);
        }
    };

    const validateEntries = async () => {
        if (!elements) {
            return false;
        }
    
        if (!cardElementState.complete || cardElementState.error) {
            setSubmitError(cardElementState.error ?? "Card information is incomplete");
            setShowCardError(true);
            return false;
        }
    
        const {error} = await elements.submit();
        if (error) {
            setSubmitError(error.message || "Please enter a valid address");
            setShowAddressError(true);
            return false;
        }
    
        if (isEmailEmpty || !isEmailValid) {
            setSubmitError(emailRules.notValid.message);
            setShowEmailError(true);
            return false;
        }


    
        if (firstName === "" || lastName === "") {
            setSubmitError("Please enter your name");
            setShowFirstNameError(firstName === "");
            setShowLastNameError(lastName === "");
            return false;
        }

        if(!over18Years){
            setShowAgeDisclaimerError(true)
            setSubmitError("Please complete the age declaration")
            return false;
        }

        return true;
    };
    
    const resetErrors = () => {
        setShowFormErrors(false);
        setShowCardError(false);
        setShowAddressError(false);
        setShowEmailError(false);
        setShowFirstNameError(false);
        setShowLastNameError(false);
        setShowAgeDisclaimerError(false);
    };

    useEffect(() => {
      Analytics.subscriptionPaymentView(plan);
    }, [plan])



    return (
        <Box sx={{padding: "16px"}}>
                <>
                    <Box>
                        <Typography sx={{ color:theme.custom.darkerPrimaryColor, fontSize:"24px", fontWeight:500}}>Payment</Typography>
                        <StickyFormControlLabel
                            control={
                                <Radio
                                    checked={over18Years}
                                    sx={{pl: 0}}
                                    icon={<CheckBoxOutlineBlank color={showAgeDisclaimerError ? "error" : "secondary"}/>}
                                    checkedIcon={<CheckBoxOutlined color="secondary"/>}
                                    onClick={() => {
                                        setShowAgeDisclaimerError(false)
                                        setOver18Years(!over18Years);
                                        Analytics.overEighteenButtonClick();
                                      }
                                    }
                                />
                            }
                            label={
                                <Typography>
                                    I am over 18 years old and I agree to the <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms}>Terms and Conditions</Link>. Our&nbsp; <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy}>Privacy Policy</Link>&nbsp;applies.
                                </Typography>
                            }
                        />
                        {showAgeDisclaimerError && <Typography color="error">Please complete the age declaration</Typography>}
                    </Box>
                        <>
                            <SpacedBox className="card-info-box">
                                <LabelTypography>Card Information</LabelTypography>
                                <CardElement
                                    onChange={({
                                                   complete,
                                                   error
                                               }: StripeElementChangeEvent) => setCardElementState({
                                        complete,
                                        error: error?.message
                                    })}
                                    onFocus={resetErrors}
                                    options={{
                                        hidePostalCode: true,
                                        classes: {
                                            base: showFormErrors && (!cardElementState.complete || cardElementState.error)
                                                ? "base-card-element error"
                                                : "base-card-element",
                                        },
                                        style: {
                                            base: {
                                                color: theme.custom.stripe.colorText,
                                                fontSize: theme.custom.stripe.fontSizeBase
                                            },
                                            invalid: {
                                                color: theme.custom.stripe.colorWarning
                                            },
                                        }
                                    }}
                                />
                                {showCardError && <Typography color="error">Please enter valid card information.</Typography>}
                            </SpacedBox>
                            <SpacedBox>
                                <LabelTypography>Billing Address</LabelTypography>
                                <AddressElement
                                    options={{
                                        mode: "billing",
                                        fields: {phone: "never"},
                                        display: {name: "split"}
                                    }}
                                    onFocus={resetErrors}
                                    onChange={handleAddressChange}
                                />
                            </SpacedBox>
                            <SpacedBox>
                                {showFirstNameError && <Typography color="error">Please enter your first name.</Typography>}
                                {showLastNameError && <Typography color="error">Please enter your last name.</Typography>}
                                {showFormErrors && showAddressError && <Typography color="error">Please enter a complete billing address.</Typography>}
                            </SpacedBox>
                            <SpacedBox>
                                <LabelTypography>Purchaser's email address</LabelTypography>
                                <StyledTextField
                                    label="Email address"
                                    variant="outlined"
                                    fullWidth
                                    color="secondary"
                                    style={{borderColor: theme.custom.stripe.borderColor}}
                                    value={email}
                                    error={showFormErrors && showEmailError && (isEmailEmpty || !isEmailValid)}
                                    helperText={showFormErrors && showEmailError && (isEmailEmpty || !isEmailValid) && emailRules.notValid.message}
                                    onChange={handleEmailChange}
                                />
                                <NoticeTypography>Your purchase receipt will be sent to the above email address</NoticeTypography>
                            </SpacedBox>
                            <SpacedBox>
                                {payButtonPressed ? <Loader/> :
                                    <Button
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={async () => {
                                            resetErrors();
                                            setShowFormErrors(true);
                                            setShowCardError(!cardElementState.complete);
                                            setShowAddressError(!addressElementState.complete);
                                            setShowEmailError(!isEmailValid);
                                            setShowFirstNameError(firstName === "");
                                            setShowLastNameError(lastName === "");
                                            setShowAgeDisclaimerError(false);
                                            setPaymentErrorMessage("")
                                            setSubmitError("")

                                            if (await validateEntries()) {
                                                setPayButtonPressed(true);
                                            }
                                        }}
                                        sx={{color: "#FFFFFF", fontSize: "16px"}}
                                    >
                                        {step === 0 ? "START FREE TRIAL" : "SUBSCRIBE TO PRO MEMBERSHIP"}
                                    </Button>
                                }
                                {submitError ?
                                        <NoticeTypography sx={{textAlign: "center", mb: 0, pb: 0, color: "#D2232A"}}>{submitError.replace("This field", "A field")}</NoticeTypography>
                                    : null
                                }
                                {paymentErrorMessage ?
                                    <NoticeTypography sx={{textAlign: "center", mb: 0, pb: 0, color: "#D2232A"}}>{paymentErrorMessage}</NoticeTypography>
                                    : null
                                }
                            </SpacedBox>
                        </>
                
                </>

            {subscriptionProducts.map(sp=>{
                return(<p>{sp.sku} {sp.name} {sp.organisation.name} {sp.price}</p>)
            })}
            {mySubscriptions.map(sp=>{
                return(<p>{sp.uuid} - {sp.status},sku:{sp.product.sku}, id:{sp.subscription_id}, trial_activated:{sp.trial_activated ? "true" : "false"} {sp.period_end}</p>)
            })}
        </Box>
    )

}
