import axios from 'axios';
import {AppConfig} from "../config";
import {getIdToken} from "./cognito";
import {forceLogoutKey} from "../constants/urlParameters";
import {getFragmentParams} from "../util/url";
import {ErrorCode} from "../constants/error";
import {
    AcademyContentResponse,
    ContentTags,
    getInitialPageLimit,
    getInitialTrainingPageLimit,
    queryParams
} from "../features/academyContent/academyContentSlice";
import {getItem, setItem} from "../util/storage";
const api_host = AppConfig.CONTENT_API_HOST;


export const contentApi = axios.create({
    baseURL: api_host,
    headers: {
        Accept: "application/json",
    },
});

contentApi.interceptors.request.use(
    async (config) => {
        const token = await getIdToken();
        if (config.headers) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

contentApi.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if (err.response) {
            if (err.response.status === ErrorCode.unauthorized && !window.location.href.includes(forceLogoutKey)) {
                window.location.href = window.location.href + getFragmentParams(forceLogoutKey, true);
                return Promise.reject(err);
            }

            if (err.response.status === ErrorCode.forbidden && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

export async function setNewsFeedParentId(slug: string): Promise<void> {

    const response = await contentApi.get(
        '/api/v2/pages/?type=vfa.NewsFeedIndexPage',
    );
    const data = response.data as AcademyContentResponse
    const indexPage = data.items.filter(i=> i.meta.slug === slug)
    if(indexPage.length){
        console.log("news index", indexPage[0].id)
        setItem('news_index_id', indexPage[0].id)
    }else{
        console.log("cannot find slug", slug)
        console.log("in", data)
        setTimeout(async () => await setNewsFeedParentId(slug), 2000)
    }
}

export async function setTrainingFeedParentId(slug: string): Promise<void> {

    const response = await contentApi.get(
        '/api/v2/pages/?type=vfa.TrainingEntriesIndexPage',
    );
    const data = response.data as AcademyContentResponse
    const indexPage = data.items.filter(i=> i.meta.slug === slug)
    if(indexPage.length){
        console.log("training index", indexPage[0].id)
        setItem('training_index_id', indexPage[0].id)
    }else{
        console.log("cannot find training slug", slug)
        console.log("in", data)
        setTimeout(async () => await setTrainingFeedParentId(slug), 2000)
    }
}

export async function getTags() {
    console.log("getting Content Tags ")
    const response = await contentApi.get(
        '/api/v2/tags'
    );
    console.log("CONTENT API TAGS RESPONSE")
    console.log(response)
    return response.data.tags as ContentTags;
}

export async function getAcademyItem(itemId: string) {
    console.log("getting academy item " + itemId )
    const response = await contentApi.get(
        '/api/v2/pages/' + itemId + '/',
    );
    console.log("CONTENT API ITEM RESPONSE")
    console.log(response)
    return response;
}
export async function getAcademyContent(params: queryParams | undefined) {
    console.log("getting academy content. params:", params)
    const paramsString = params ? "&offset=" + params.offset.toString() + "&limit=" + params.limit.toString() : "&offset=0&limit=" + getInitialPageLimit().toString()
    if(!getItem('news_index_id')){
        await setNewsFeedParentId(getItem('news_index_slug') + "")
    }
    const response = await contentApi.get(
        `/api/v2/pages/?child_of=${getItem('news_index_id')}&order=-id` + paramsString,
    );
    console.log("CONTENT API RESPONSE")
    console.log(response)
    return {...response.data, items: response.data.items.map((i:any)=> ({...i, isPreview: true }))}
}

let trainingParamStringRepeatedTimes = 0

export async function getTrainingContent(params: queryParams | undefined, hasActiveProSubscription: boolean) {
    console.log("getting training content. params:", params)
    const tags = (params && !!params.tags && !!params.tags.length ) ? "&tags=" + params.tags.join(",") : ""
    let paramsString = params ? "&offset=" + params.offset.toString() + "&limit=" + params.limit.toString() : "&offset=0&limit=" + getInitialTrainingPageLimit().toString()
    const last_paramString = sessionStorage.getItem('lastTrainingParamString')
    if(paramsString === last_paramString && params ){
        trainingParamStringRepeatedTimes += 1
        const maxLimit = 20
        const targetAmount = params.offset + getInitialPageLimit() * trainingParamStringRepeatedTimes
        const limit = targetAmount < maxLimit ? targetAmount : maxLimit
        const offset = targetAmount < maxLimit ? 0 : targetAmount - maxLimit

        paramsString = params ? "&offset=" + offset + "&limit=" + limit : ""
    }else{
        trainingParamStringRepeatedTimes = 0
    }
    sessionStorage.setItem("lastTrainingParamString", paramsString)
    if(!getItem('training_index_id')){
        await setTrainingFeedParentId(getItem('training_index_slug') + "")
    }
    let response;
    if(hasActiveProSubscription){
        response = await contentApi.get(
            `/api/v2/pages/?child_of=${getItem('training_index_id')}&type=vfa.TrainingVideoPage&order=-id` + paramsString + tags,
        );
    } else {
        
        response = await contentApi.get(
            `/api/v2/pages/?child_of=${getItem('training_index_id')}&type=vfa.TrainingVideoPage&order=subscription_level,id` + paramsString + tags,
            // '/api/v2/pages/?order=-id&type=vfa.TrainingVideoPage' + paramsString,
        );
    }
    console.log("Training CONTENT API RESPONSE")
    console.log(response)
    return {...response.data, items: response.data.items.map((i:any)=> ({...i, isPreview: true })), resetItems: params?.resetItems}
}

export interface PutLikeResponse{
    status: number
    id: number
}

export interface LikertSubmitPayload {
    data: {
        response: number
        comment?: string
    }
}
export interface UserQuestionSubmitPayload {
    data: {
        question: string
    }
}


export interface PutUserFeedbackResponse {
    status: number
    id:number
}

export interface UserPollPayload{
    option_id: number
}

export async function putUserPollSubmission(id: number, optionId: number, destination: string) {
    const payload = {option_id: optionId} as UserPollPayload
    const response = await contentApi.put(
        destination,
        payload
    );
    console.log("User Poll API RESPONSE")
    console.log(response)
    return {...response, id: id}  as PutUserFeedbackResponse;
}

export async function putLikertResponse(id: number, value: number, comment: string, destination: string) {
    const payload = comment.length ?
        {data: {response: value, comment: comment}} as LikertSubmitPayload
        :
        {data: {response: value}} as LikertSubmitPayload
    const response = await contentApi.put(
        destination,
        payload
    );
    console.log("LIKERT ITEM API RESPONSE")
    console.log(response)
    return {...response, id: id}  as PutUserFeedbackResponse;
}

export async function putUserQuestionResponse(id: number, question: string, destination: string) {
    const payload = {data: {question: question}} as UserQuestionSubmitPayload
    const response = await contentApi.put(
        destination,
        payload
    );
    console.log("USER QUESTION ITEM API RESPONSE")
    console.log(response)
    return {...response, id: id}  as PutUserFeedbackResponse;
}

export async function putLikeItem(itemId: number) {
    const response = await contentApi.put(
        '/api/v2/pages/' + itemId + '/heart',
    );
    console.log("LIKE ITEM API RESPONSE")
    console.log(response)
    return {status: response.status, id: itemId} as PutLikeResponse;
}
