import React from 'react'

const emojiMap: { [key: string]: string } = {
    ":grinning:": "&#x1F600;",
    ":smiley:": "&#x1F603;",
    ":smile:": "&#x1F604;",
    ":grin:": "&#x1F601;",
    ":laughing:": "&#x1F606;",
    ":sweat_smile:": "&#x1F605;",
    ":joy:": "&#x1F602;",
    ":rofl:": "&#x1F923;",
    ":relaxed:": "&#x263A;",
    ":blush:": "&#x1F60A;",
    ":innocent:": "&#x1F607;",
    ":slightly_smiling_face:": "&#x1F642;",
    ":upside_down_face:": "&#x1F643;",
    ":wink:": "&#x1F609;",
    ":relieved:": "&#x1F60C;",
    ":heart_eyes:": "&#x1F60D;",
    ":smiling_face_with_three_hearts:": "&#x1F970;",
    ":kissing_heart:": "&#x1F618;",
    ":kissing:": "&#x1F617;",
    ":kissing_smiling_eyes:": "&#x1F619;",
    ":kissing_closed_eyes:": "&#x1F61A;",
    ":yum:": "&#x1F60B;",
    ":stuck_out_tongue:": "&#x1F61B;",
    ":stuck_out_tongue_winking_eye:": "&#x1F61C;",
    ":zany_face:": "&#x1F92A;",
    ":stuck_out_tongue_closed_eyes:": "&#x1F61D;",
    ":money_mouth_face:": "&#x1F911;",
    ":hugs:": "&#x1F917;",
    ":nerd_face:": "&#x1F913;",
    ":sunglasses:": "&#x1F60E;",
    ":clown_face:": "&#x1F921;",
    ":cowboy_hat_face:": "&#x1F920;",
    ":partying_face:": "&#x1F973;",
    ":smirk:": "&#x1F60F;",
    ":unamused:": "&#x1F612;",
    ":disappointed:": "&#x1F61E;",
    ":pensive:": "&#x1F614;",
    ":worried:": "&#x1F61F;",
    ":confused:": "&#x1F615;",
    ":slightly_frowning_face:": "&#x1F641;",
    ":frowning_face:": "&#x2639;",
    ":persevere:": "&#x1F623;",
    ":confounded:": "&#x1F616;",
    ":tired_face:": "&#x1F62B;",
    ":weary:": "&#x1F629;",
    ":pleading_face:": "&#x1F97A;",
    ":cry:": "&#x1F622;",
    ":sob:": "&#x1F62D;",
    ":triumph:": "&#x1F624;",
    ":angry:": "&#x1F620;",
    ":rage:": "&#x1F621;",
    ":exploding_head:": "&#x1F92F;",
    ":flushed:": "&#x1F633;",
    ":hot_face:": "&#x1F975;",
    ":cold_face:": "&#x1F976;",
    ":scream:": "&#x1F631;",
    ":fearful:": "&#x1F628;",
    ":cold_sweat:": "&#x1F630;",
    ":hushed:": "&#x1F62F;",
    ":frowning:": "&#x1F626;",
    ":anguished:": "&#x1F627;",
    ":open_mouth:": "&#x1F62E;",
    ":astonished:": "&#x1F632;",
    ":sleeping:": "&#x1F634;",
    ":drooling_face:": "&#x1F924;",
    ":sleepy:": "&#x1F62A;",
    ":dizzy_face:": "&#x1F635;",
    ":zipper_mouth_face:": "&#x1F910;",
    ":nauseated_face:": "&#x1F922;",
    ":sneezing_face:": "&#x1F927;",
    ":mask:": "&#x1F637;",
    ":thermometer_face:": "&#x1F912;",
    ":head_bandage:": "&#x1F915;",
    ":woozy_face:": "&#x1F974;",
    ":face_with_spiral_eyes:": "&#x1F635;&#x200D;&#x1F4AB;",
    ":face_with_head_bandage:": "&#x1F915;",
    ":skull:": "&#x1F480;",
    ":skull_and_crossbones:": "&#x2620;",
    ":poop:": "&#x1F4A9;",
    ":ogre:": "&#x1F479;",
    ":goblin:": "&#x1F47A;",
    ":ghost:": "&#x1F47B;",
    ":alien:": "&#x1F47D;",
    ":space_invader:": "&#x1F47E;",
    ":robot:": "&#x1F916;",
    ":jack_o_lantern:": "&#x1F383;",
    ":smiley_cat:": "&#x1F63A;",
    ":smile_cat:": "&#x1F638;",
    ":joy_cat:": "&#x1F639;",
    ":heart_eyes_cat:": "&#x1F63B;",
    ":smirk_cat:": "&#x1F63C;",
    ":kissing_cat:": "&#x1F63D;",
    ":scream_cat:": "&#x1F640;",
    ":crying_cat_face:": "&#x1F63F;",
    ":pouting_cat:": "&#x1F63E;",
    ":see_no_evil:": "&#x1F648;",
    ":hear_no_evil:": "&#x1F649;",
    ":speak_no_evil:": "&#x1F64A;",
    ":kiss:": "&#x1F48B;",
    ":love_letter:": "&#x1F48C;",
    ":cupid:": "&#x1F498;",
    ":gift_heart:": "&#x1F49D;",
    ":sparkling_heart:": "&#x1F496;",
    ":heartpulse:": "&#x1F497;",
    ":heartbeat:": "&#x1F493;",
    ":revolving_hearts:": "&#x1F49E;",
    ":two_hearts:": "&#x1F495;",
    ":broken_heart:": "&#x1F494;",
    ":heart:": "&#x2764;",
    ":orange_heart:": "&#x1F9E1;",
    ":yellow_heart:": "&#x1F49B;",
    ":green_heart:": "&#x1F49A;",
    ":blue_heart:": "&#x1F499;",
    ":purple_heart:": "&#x1F49C;",
    ":brown_heart:": "&#x1F90E;",
    ":black_heart:": "&#x1F5A4;",
    ":white_heart:": "&#x1F90D;",
    ":100:": "&#x1F4AF;",
    ":anger:": "&#x1F4A2;",
    ":boom:": "&#x1F4A5;",
    ":dizzy:": "&#x1F4AB;",
    ":sweat_drops:": "&#x1F4A6;",
    ":dash:": "&#x1F4A8;",
    ":hole:": "&#x1F573;",
    ":bomb:": "&#x1F4A3;",
    ":speech_balloon:": "&#x1F4AC;",
    ":eye_in_speech_bubble:": "&#x1F441;&#xFE0F;&#x200D;&#x1F5E8;&#xFE0F;",
    ":left_speech_bubble:": "&#x1F5E8;",
    ":right_anger_bubble:": "&#x1F5EF;",
    ":thought_balloon:": "&#x1F4AD;",
    ":zzz:": "&#x1F4A4;",
    ":wave:": "&#x1F44B;",
    ":raised_back_of_hand:": "&#x1F91A;",
    ":raised_hand_with_fingers_splayed:": "&#x1F590;",
    ":hand_with_fingers_splayed:": "&#x1F590;",
    ":raised_hand:": "&#x270B;",
    ":vulcan_salute:": "&#x1F596;",
    ":ok_hand:": "&#x1F44C;",
    ":pinching_hand:": "&#x1F90F;",
    ":v:": "&#x270C;",
    ":crossed_fingers:": "&#x1F91E;",
    ":love_you_gesture:": "&#x1F91F;",
    ":metal:": "&#x1F918;",
    ":call_me_hand:": "&#x1F919;",
    ":point_left:": "&#x1F448;",
    ":point_right:": "&#x1F449;",
    ":point_up:": "&#x1F446;",
    ":point_up_2:": "&#x1F446;",
    ":middle_finger:": "&#x1F595;",
    ":point_down:": "&#x1F447;",
    ":fu:": "&#x1F595;",
    ":writing_hand:": "&#x270D;",
    ":clap:": "&#x1F44F;",
    ":raised_hands:": "&#x1F64C;",
    ":open_hands:": "&#x1F450;",
    ":palms_up_together:": "&#x1F932;",
    ":handshake:": "&#x1F91D;",
    ":pray:": "&#x1F64F;",
    ":hand_with_index_finger_and_thumb_crossed:": "&#x1FAF0;",
    ":oncoming_fist:": "&#x1F44A;",
    ":fist_left:": "&#x1F91B;",
    ":fist_right:": "&#x1F91C;",
    ":fist:": "&#x270A;",
    ":fist_oncoming:": "&#x1F44A;",
    ":raised_fist:": "&#x270A;",
    ":left_facing_fist:": "&#x1F91B;",
    ":right_facing_fist:": "&#x1F91C;",
    ":fist_raised:": "&#x270A;",
    ":fist_up:": "&#x270A;",
    ":fist_bump:": "&#x1F44A;",
    ":fist_handshake:": "&#x1F91D;",
    ":thumbsup:": "&#x1F44D;",
    ":thumbsdown:": "&#x1F44E;",
    ":punch:": "&#x1F44A;",
    ":facepunch:": "&#x1F44A;",
    ":left_fist:": "&#x1F91B;",
    ":right_fist:": "&#x1F91C;",
    ":clapping_hands:": "&#x1F44F;",
    ":raising_hands:": "&#x1F64C;",
    ":folded_hands:": "&#x1F64F;",
    ":nail_care:": "&#x1F485;",
    ":selfie:": "&#x1F933;",
    ":muscle:": "&#x1F4AA;",
    ":mechanical_arm:": "&#x1F9BE;",
    ":mechanical_leg:": "&#x1F9BF;",
    ":leg:": "&#x1F9B5;",
    ":foot:": "&#x1F9B6;",
    ":ear:": "&#x1F442;",
    ":ear_with_hearing_aid:": "&#x1F9BB;",
    ":nose:": "&#x1F443;",
    ":brain:": "&#x1F9E0;",
    ":anatomical_heart:": "&#x1FAC0;",
    ":lungs:": "&#x1FAC1;",
    ":tooth:": "&#x1F9B7;",
    ":bone:": "&#x1F9B4;",
    ":eyes:": "&#x1F440;",
    ":eye:": "&#x1F441;",
    ":tongue:": "&#x1F445;",
    ":lips:": "&#x1F444;",
    ":baby:": "&#x1F476;",
    ":child:": "&#x1F9D2;",
    ":boy:": "&#x1F466;",
    ":girl:": "&#x1F467;",
    ":adult:": "&#x1F9D1;",
    ":man:": "&#x1F468;",
    ":woman:": "&#x1F469;",
    ":blond_haired_person:": "&#x1F471;",
    ":blond_haired_man:": "&#x1F471;&#x200D;&#x2642;&#xFE0F;",
    ":blond_haired_woman:": "&#x1F471;&#x200D;&#x2640;&#xFE0F;",
    ":older_adult:": "&#x1F9D3;",
    ":older_man:": "&#x1F474;",
    ":older_woman:": "&#x1F475;",
    ":person_frowning:": "&#x1F64D;",
    ":frowning_man:": "&#x1F64D;&#x200D;&#x2642;&#xFE0F;",
    ":frowning_woman:": "&#x1F64D;&#x200D;&#x2640;&#xFE0F;",
    ":person_with_pouting_face:": "&#x1F64E;",
    ":pouting_man:": "&#x1F64E;&#x200D;&#x2642;&#xFE0F;",
    ":pouting_woman:": "&#x1F64E;&#x200D;&#x2640;&#xFE0F;",
    ":person_gesturing_no:": "&#x1F645;",
    ":no_good_man:": "&#x1F645;&#x200D;&#x2642;&#xFE0F;",
    ":no_good_woman:": "&#x1F645;&#x200D;&#x2640;&#xFE0F;",
    ":person_gesturing_ok:": "&#x1F646;",
    ":ok_man:": "&#x1F646;&#x200D;&#x2642;&#xFE0F;",
    ":ok_woman:": "&#x1F646;&#x200D;&#x2640;&#xFE0F;",
    ":person_tipping_hand:": "&#x1F481;",
    ":information_desk_person:": "&#x1F481;",
    ":tipping_hand_man:": "&#x1F481;&#x200D;&#x2642;&#xFE0F;",
    ":tipping_hand_woman:": "&#x1F481;&#x200D;&#x2640;&#xFE0F;",
    ":person_raising_hand:": "&#x1F64B;",
    ":raising_hand_man:": "&#x1F64B;&#x200D;&#x2642;&#xFE0F;",
    ":raising_hand_woman:": "&#x1F64B;&#x200D;&#x2640;&#xFE0F;",
    ":deaf_person:": "&#x1F9CF;",
    ":deaf_man:": "&#x1F9CF;&#x200D;&#x2642;&#xFE0F;",
    ":deaf_woman:": "&#x1F9CF;&#x200D;&#x2640;&#xFE0F;",
    ":person_bowing:": "&#x1F647;",
    ":bowing_man:": "&#x1F647;&#x200D;&#x2642;&#xFE0F;",
    ":bowing_woman:": "&#x1F647;&#x200D;&#x2640;&#xFE0F;",
    ":facepalm:": "&#x1F926;",
    ":man_facepalming:": "&#x1F926;&#x200D;&#x2642;&#xFE0F;",
    ":woman_facepalming:": "&#x1F926;&#x200D;&#x2640;&#xFE0F;",
    ":shrug:": "&#x1F937;",
    ":man_shrugging:": "&#x1F937;&#x200D;&#x2642;&#xFE0F;",
    ":woman_shrugging:": "&#x1F937;&#x200D;&#x2640;&#xFE0F;",
    ":health_worker:": "&#x1F9D1;&#x200D;&#x2695;&#xFE0F;",
    ":man_health_worker:": "&#x1F468;&#x200D;&#x2695;&#xFE0F;",
    ":woman_health_worker:": "&#x1F469;&#x200D;&#x2695;&#xFE0F;",
    ":student:": "&#x1F9D1;&#x200D;&#x1F393;",
    ":man_student:": "&#x1F468;&#x200D;&#x1F393;",
    ":woman_student:": "&#x1F469;&#x200D;&#x1F393;",
    ":teacher:": "&#x1F9D1;&#x200D;&#x1F3EB;",
    ":man_teacher:": "&#x1F468;&#x200D;&#x1F3EB;",
    ":woman_teacher:": "&#x1F469;&#x200D;&#x1F3EB;",
    ":judge:": "&#x1F9D1;&#x200D;&#x2696;&#xFE0F;",
    ":man_judge:": "&#x1F468;&#x200D;&#x2696;&#xFE0F;",
    ":woman_judge:": "&#x1F469;&#x200D;&#x2696;&#xFE0F;",
    ":farmer:": "&#x1F9D1;&#x200D;&#x1F33E;",
    ":man_farmer:": "&#x1F468;&#x200D;&#x1F33E;",
    ":woman_farmer:": "&#x1F469;&#x200D;&#x1F33E;",
    ":cook:": "&#x1F9D1;&#x200D;&#x1F373;",
    ":man_cook:": "&#x1F468;&#x200D;&#x1F373;",
    ":woman_cook:": "&#x1F469;&#x200D;&#x1F373;",
    ":mechanic:": "&#x1F9D1;&#x200D;&#x1F527;",
    ":man_mechanic:": "&#x1F468;&#x200D;&#x1F527;",
    ":woman_mechanic:": "&#x1F469;&#x200D;&#x1F527;",
    ":factory_worker:": "&#x1F9D1;&#x200D;&#x1F3ED;",
    ":man_factory_worker:": "&#x1F468;&#x200D;&#x1F3ED;",
    ":woman_factory_worker:": "&#x1F469;&#x200D;&#x1F3ED;",
    ":office_worker:": "&#x1F9D1;&#x200D;&#x1F4BC;",
    ":man_office_worker:": "&#x1F468;&#x200D;&#x1F4BC;",
    ":woman_office_worker:": "&#x1F469;&#x200D;&#x1F4BC;",
    ":scientist:": "&#x1F9D1;&#x200D;&#x1F52C;",
    ":man_scientist:": "&#x1F468;&#x200D;&#x1F52C;",
    ":woman_scientist:": "&#x1F469;&#x200D;&#x1F52C;",
    ":technologist:": "&#x1F9D1;&#x200D;&#x1F4BB;",
    ":man_technologist:": "&#x1F468;&#x200D;&#x1F4BB;",
    ":woman_technologist:": "&#x1F469;&#x200D;&#x1F4BB;",
    ":singer:": "&#x1F9D1;&#x200D;&#x1F3A4;",
    ":man_singer:": "&#x1F468;&#x200D;&#x1F3A4;",
    ":woman_singer:": "&#x1F469;&#x200D;&#x1F3A4;",
    ":artist:": "&#x1F9D1;&#x200D;&#x1F3A8;",
    ":man_artist:": "&#x1F468;&#x200D;&#x1F3A8;",
    ":woman_artist:": "&#x1F469;&#x200D;&#x1F3A8;",
    ":pilot:": "&#x1F9D1;&#x200D;&#x2708;&#xFE0F;",
    ":man_pilot:": "&#x1F468;&#x200D;&#x2708;&#xFE0F;",
    ":woman_pilot:": "&#x1F469;&#x200D;&#x2708;&#xFE0F;",
    ":astronaut:": "&#x1F9D1;&#x200D;&#x1F680;",
    ":man_astronaut:": "&#x1F468;&#x200D;&#x1F680;",
    ":woman_astronaut:": "&#x1F469;&#x200D;&#x1F680;",
    ":firefighter:": "&#x1F9D1;&#x200D;&#x1F692;",
    ":man_firefighter:": "&#x1F468;&#x200D;&#x1F692;",
    ":woman_firefighter:": "&#x1F469;&#x200D;&#x1F692;",
    ":police_officer:": "&#x1F46E;",
    ":policeman:": "&#x1F46E;&#x200D;&#x2642;&#xFE0F;",
    ":policewoman:": "&#x1F46E;&#x200D;&#x2640;&#xFE0F;",
    ":detective:": "&#x1F575;",
    ":male_detective:": "&#x1F575;&#xFE0F;&#x200D;&#x2642;&#xFE0F;",
    ":female_detective:": "&#x1F575;&#xFE0F;&#x200D;&#x2640;&#xFE0F;",
    ":guard:": "&#x1F482;",
    ":guardsman:": "&#x1F482;&#x200D;&#x2642;&#xFE0F;",
    ":guardswoman:": "&#x1F482;&#x200D;&#x2640;&#xFE0F;",
    ":construction_worker:": "&#x1F477;",
    ":construction_worker_man:": "&#x1F477;&#x200D;&#x2642;&#xFE0F;",
    ":construction_worker_woman:": "&#x1F477;&#x200D;&#x2640;&#xFE0F;",
    ":prince:": "&#x1F934;",
    ":princess:": "&#x1F478;",
    ":person_with_turban:": "&#x1F473;",
    ":man_with_turban:": "&#x1F473;&#x200D;&#x2642;&#xFE0F;",
    ":woman_with_turban:": "&#x1F473;&#x200D;&#x2640;&#xFE0F;",
    ":man_with_gua_pi_mao:": "&#x1F472;",
    ":woman_with_headscarf:": "&#x1F9D5;",
    ":person_in_tuxedo:": "&#x1F935;",
    ":man_in_tuxedo:": "&#x1F935;&#x200D;&#x2642;&#xFE0F;",
    ":woman_in_tuxedo:": "&#x1F935;&#x200D;&#x2640;&#xFE0F;",
    ":person_with_veil:": "&#x1F470;",
    ":man_with_veil:": "&#x1F470;&#x200D;&#x2642;&#xFE0F;",
    ":woman_with_veil:": "&#x1F470;&#x200D;&#x2640;&#xFE0F;",
    ":pregnant_woman:": "&#x1F930;",
    ":breast_feeding:": "&#x1F931;",
    ":baby_bottle:": "&#x1F37C;",
    ":woman_feeding_baby:": "&#x1F931;&#x200D;&#x1F469;&#x200D;&#x1F37C;",
    ":man_feeding_baby:": "&#x1F931;&#x200D;&#x1F468;&#x200D;&#x1F37C;",
    ":person_feeding_baby:": "&#x1F931;&#x200D;&#x1F9D1;&#x200D;&#x1F37C;",
    ":angel:": "&#x1F47C;",
    ":santa:": "&#x1F385;",
    ":mrs_claus:": "&#x1F936;",
    ":superhero:": "&#x1F9B8;",
    ":superhero_man:": "&#x1F9B8;&#x200D;&#x2642;&#xFE0F;",
    ":superhero_woman:": "&#x1F9B8;&#x200D;&#x2640;&#xFE0F;",
    ":supervillain:": "&#x1F9B9;",
    ":supervillain_man:": "&#x1F9B9;&#x200D;&#x2642;&#xFE0F;",
    ":supervillain_woman:": "&#x1F9B9;&#x200D;&#x2640;&#xFE0F;",
    ":mage:": "&#x1F9D9;",
    ":man_mage:": "&#x1F9D9;&#x200D;&#x2642;&#xFE0F;",
    ":woman_mage:": "&#x1F9D9;&#x200D;&#x2640;&#xFE0F;",
    ":fairy:": "&#x1F9DA;",
    ":man_fairy:": "&#x1F9DA;&#x200D;&#x2642;&#xFE0F;",
    ":woman_fairy:": "&#x1F9DA;&#x200D;&#x2640;&#xFE0F;",
    ":vampire:": "&#x1F9DB;",
    ":man_vampire:": "&#x1F9DB;&#x200D;&#x2642;&#xFE0F;",
    ":woman_vampire:": "&#x1F9DB;&#x200D;&#x2640;&#xFE0F;",
    ":merperson:": "&#x1F9DC;",
    ":merman:": "&#x1F9DC;&#x200D;&#x2642;&#xFE0F;",
    ":mermaid:": "&#x1F9DC;&#x200D;&#x2640;&#xFE0F;",
    ":elf:": "&#x1F9DD;",
    ":man_elf:": "&#x1F9DD;&#x200D;&#x2642;&#xFE0F;",
    ":woman_elf:": "&#x1F9DD;&#x200D;&#x2640;&#xFE0F;",
    ":genie:": "&#x1F9DE;",
    ":man_genie:": "&#x1F9DE;&#x200D;&#x2642;&#xFE0F;",
    ":woman_genie:": "&#x1F9DE;&#x200D;&#x2640;&#xFE0F;",
    ":zombie:": "&#x1F9DF;",
    ":man_zombie:": "&#x1F9DF;&#x200D;&#x2642;&#xFE0F;",
    ":woman_zombie:": "&#x1F9DF;&#x200D;&#x2640;&#xFE0F;",
    ":person_getting_massage:": "&#x1F486;",
    ":man_getting_face_massage:": "&#x1F486;&#x200D;&#x2642;&#xFE0F;",
    ":woman_getting_face_massage:": "&#x1F486;&#x200D;&#x2640;&#xFE0F;",
    ":person_getting_haircut:": "&#x1F487;",
    ":man_getting_haircut:": "&#x1F487;&#x200D;&#x2642;&#xFE0F;",
    ":woman_getting_haircut:": "&#x1F487;&#x200D;&#x2640;&#xFE0F;",
    ":person_walking:": "&#x1F6B6;",
    ":man_walking:": "&#x1F6B6;&#x200D;&#x2642;&#xFE0F;",
    ":woman_walking:": "&#x1F6B6;&#x200D;&#x2640;&#xFE0F;",
    ":person_running:": "&#x1F3C3;",
    ":man_running:": "&#x1F3C3;&#x200D;&#x2642;&#xFE0F;",
    ":woman_running:": "&#x1F3C3;&#x200D;&#x2640;&#xFE0F;",
    ":woman_dancing:": "&#x1F483;",
    ":man_dancing:": "&#x1F57A;",
    ":person_in_suit_levitating:": "&#x1F574;",
    ":people_with_bunny_ears_partying:": "&#x1F46F;",
    ":men_with_bunny_ears_partying:": "&#x1F46F;&#x200D;&#x2642;&#xFE0F;",
    ":women_with_bunny_ears_partying:": "&#x1F46F;&#x200D;&#x2640;&#xFE0F;",
    ":person_in_steamy_room:": "&#x1F9D6;",
    ":man_in_steamy_room:": "&#x1F9D6;&#x200D;&#x2642;&#xFE0F;",
    ":woman_in_steamy_room:": "&#x1F9D6;&#x200D;&#x2640;&#xFE0F;",
    ":person_climbing:": "&#x1F9D7;",
    ":man_climbing:": "&#x1F9D7;&#x200D;&#x2642;&#xFE0F;",
    ":woman_climbing:": "&#x1F9D7;&#x200D;&#x2640;&#xFE0F;",
    ":person_fencing:": "&#x1F93A;",
    ":horse_racing:": "&#x1F3C7;",
    ":skier:": "&#x26F7;",
    ":snowboarder:": "&#x1F3C2;",
    ":person_golfing:": "&#x1F3CC;",
    ":man_golfing:": "&#x1F3CC;&#xFE0F;&#x200D;&#x2642;&#xFE0F;",
    ":woman_golfing:": "&#x1F3CC;&#xFE0F;&#x200D;&#x2640;&#xFE0F;",
    ":person_surfing:": "&#x1F3C4;",
    ":man_surfing:": "&#x1F3C4;&#x200D;&#x2642;&#xFE0F;",
    ":woman_surfing:": "&#x1F3C4;&#x200D;&#x2640;&#xFE0F;",
    ":person_rowing_boat:": "&#x1F6A3;",
    ":man_rowing_boat:": "&#x1F6A3;&#x200D;&#x2642;&#xFE0F;",
    ":woman_rowing_boat:": "&#x1F6A3;&#x200D;&#x2640;&#xFE0F;",
    ":person_swimming:": "&#x1F3CA;",
    ":man_swimming:": "&#x1F3CA;&#x200D;&#x2642;&#xFE0F;",
    ":woman_swimming:": "&#x1F3CA;&#x200D;&#x2640;&#xFE0F;",
    ":person_bouncing_ball:": "&#x26F9;",
    ":man_bouncing_ball:": "&#x26F9;&#xFE0F;&#x200D;&#x2642;&#xFE0F;",
    ":woman_bouncing_ball:": "&#x26F9;&#xFE0F;&#x200D;&#x2640;&#xFE0F;",
    ":person_lifting_weights:": "&#x1F3CB;",
    ":man_lifting_weights:": "&#x1F3CB;&#xFE0F;&#x200D;&#x2642;&#xFE0F;",
    ":woman_lifting_weights:": "&#x1F3CB;&#xFE0F;&#x200D;&#x2640;&#xFE0F;",
    ":person_biking:": "&#x1F6B4;",
    ":man_biking:": "&#x1F6B4;&#x200D;&#x2642;&#xFE0F;",
    ":woman_biking:": "&#x1F6B4;&#x200D;&#x2640;&#xFE0F;",
    ":person_mountain_biking:": "&#x1F6B5;",
    ":man_mountain_biking:": "&#x1F6B5;&#x200D;&#x2642;&#xFE0F;",
    ":woman_mountain_biking:": "&#x1F6B5;&#x200D;&#x2640;&#xFE0F;",
    ":person_cartwheeling:": "&#x1F938;",
    ":man_cartwheeling:": "&#x1F938;&#x200D;&#x2642;&#xFE0F;",
    ":woman_cartwheeling:": "&#x1F938;&#x200D;&#x2640;&#xFE0F;",
    ":people_wrestling:": "&#x1F93C;",
    ":men_wrestling:": "&#x1F93C;&#x200D;&#x2642;&#xFE0F;",
    ":women_wrestling:": "&#x1F93C;&#x200D;&#x2640;&#xFE0F;",
    ":person_playing_water_polo:": "&#x1F93D;",
    ":man_playing_water_polo:": "&#x1F93D;&#x200D;&#x2642;&#xFE0F;",
    ":woman_playing_water_polo:": "&#x1F93D;&#x200D;&#x2640;&#xFE0F;",
    ":person_playing_handball:": "&#x1F93E;",
    ":man_playing_handball:": "&#x1F93E;&#x200D;&#x2642;&#xFE0F;",
    ":woman_playing_handball:": "&#x1F93E;&#x200D;&#x2640;&#xFE0F;",
    ":person_juggling:": "&#x1F939;",
    ":man_juggling:": "&#x1F939;&#x200D;&#x2642;&#xFE0F;",
    ":woman_juggling:": "&#x1F939;&#x200D;&#x2640;&#xFE0F;",
    ":lotus_position:": "&#x1F9D8;",
    ":man_in_lotus_position:": "&#x1F9D8;&#x200D;&#x2642;&#xFE0F;",
    ":woman_in_lotus_position:": "&#x1F9D8;&#x200D;&#x2640;&#xFE0F;",
    ":bath:": "&#x1F6C0;",
    ":sleeping_accommodation:": "&#x1F6CC;",
    ":baby_symbol:": "&#x1F6BC;",
    ":person_with_blond_hair:": "&#x1F471;",
    ":japanese_ogre:": "&#x1F479;",
    ":japanese_goblin:": "&#x1F47A;",
    ":imp:": "&#x1F47F;",
    ":dancer:": "&#x1F483;",
    ":lipstick:": "&#x1F484;",
    ":massage:": "&#x1F486;",
    ":haircut:": "&#x1F487;",
    ":barber:": "&#x1F488;",
    ":syringe:": "&#x1F489;",
    ":pill:": "&#x1F48A;",
    ":ring:": "&#x1F48D;",
    ":gem:": "&#x1F48E;",
    ":couplekiss:": "&#x1F48F;",
    ":bouquet:": "&#x1F490;",
    ":couple_with_heart:": "&#x1F491;",
    ":wedding:": "&#x1F492;",
    ":heart_decoration:": "&#x1F49F;",
    ":diamond_shape_with_a_dot_inside:": "&#x1F4A0;",
    ":bulb:": "&#x1F4A1;",
    ":droplet:": "&#x1F4A7;",
    ":hankey:": "&#x1F4A9;",
    ":white_flower:": "&#x1F4AE;",
    ":moneybag:": "&#x1F4B0;",
    ":currency_exchange:": "&#x1F4B1;",
    ":heavy_dollar_sign:": "&#x1F4B2;",
    ":credit_card:": "&#x1F4B3;",
    ":yen:": "&#x1F4B4;",
    ":dollar:": "&#x1F4B5;",
    ":euro:": "&#x1F4B6;",
    ":pound:": "&#x1F4B7;",
    ":money_with_wings:": "&#x1F4B8;",
    ":chart:": "&#x1F4B9;",
    ":seat:": "&#x1F4BA;",
    ":computer:": "&#x1F4BB;",
    ":briefcase:": "&#x1F4BC;",
    ":minidisc:": "&#x1F4BD;",
    ":floppy_disk:": "&#x1F4BE;",
    ":cd:": "&#x1F4BF;",
    ":dvd:": "&#x1F4C0;",
    ":file_folder:": "&#x1F4C1;",
    ":open_file_folder:": "&#x1F4C2;",
    ":page_with_curl:": "&#x1F4C3;",
    ":page_facing_up:": "&#x1F4C4;",
    ":date:": "&#x1F4C5;",
    ":calendar:": "&#x1F4C6;",
    ":card_index:": "&#x1F4C7;",
    ":chart_with_upwards_trend:": "&#x1F4C8;",
    ":chart_with_downwards_trend:": "&#x1F4C9;",
    ":bar_chart:": "&#x1F4CA;",
    ":clipboard:": "&#x1F4CB;",
    ":pushpin:": "&#x1F4CC;",
    ":round_pushpin:": "&#x1F4CD;",
    ":paperclip:": "&#x1F4CE;",
    ":straight_ruler:": "&#x1F4CF;",
    ":triangular_ruler:": "&#x1F4D0;",
    ":bookmark_tabs:": "&#x1F4D1;",
    ":ledger:": "&#x1F4D2;",
    ":notebook:": "&#x1F4D3;",
    ":notebook_with_decorative_cover:": "&#x1F4D4;",
    ":closed_book:": "&#x1F4D5;",
    ":book:": "&#x1F4D6;",
    ":open_book:": "&#x1F4D6;",
    ":green_book:": "&#x1F4D7;",
    ":blue_book:": "&#x1F4D8;",
    ":orange_book:": "&#x1F4D9;",
    ":books:": "&#x1F4DA;",
    ":name_badge:": "&#x1F4DB;",
    ":scroll:": "&#x1F4DC;",
    ":memo:": "&#x1F4DD;",
    ":telephone_receiver:": "&#x1F4DE;",
    ":pager:": "&#x1F4DF;",
    ":fax:": "&#x1F4E0;",
    ":satellite:": "&#x1F4E1;",
    ":loudspeaker:": "&#x1F4E2;",
    ":mega:": "&#x1F4E3;",
    ":outbox_tray:": "&#x1F4E4;",
    ":inbox_tray:": "&#x1F4E5;",
    ":package:": "&#x1F4E6;",
    ":e_mail:": "&#x1F4E7;",
    ":incoming_envelope:": "&#x1F4E8;",
    ":envelope_with_arrow:": "&#x1F4E9;",
    ":mailbox_closed:": "&#x1F4EA;",
    ":mailbox:": "&#x1F4EB;",
    ":mailbox_with_mail:": "&#x1F4EC;",
    ":mailbox_with_no_mail:": "&#x1F4ED;",
    ":postbox:": "&#x1F4EE;",
    ":postal_horn:": "&#x1F4EF;",
    ":newspaper:": "&#x1F4F0;",
    ":iphone:": "&#x1F4F1;",
    ":calling:": "&#x1F4F2;",
    ":vibration_mode:": "&#x1F4F3;",
    ":mobile_phone_off:": "&#x1F4F4;",
    ":no_mobile_phones:": "&#x1F4F5;",
    ":signal_strength:": "&#x1F4F6;",
    ":camera:": "&#x1F4F7;",
    ":camera_with_flash:": "&#x1F4F8;",
    ":video_camera:": "&#x1F4F9;",
    ":tv:": "&#x1F4FA;",
    ":radio:": "&#x1F4FB;",
    ":vhs:": "&#x1F4FC;",
    ":film_projector:": "&#x1F4FD;",
    ":prayer_beads:": "&#x1F4FF;",
    ":twisted_rightwards_arrows:": "&#x1F500;",
    ":repeat:": "&#x1F501;",
    ":repeat_one:": "&#x1F502;",
    ":arrows_clockwise:": "&#x1F503;",
    ":arrows_counterclockwise:": "&#x1F504;",
    ":low_brightness:": "&#x1F505;",
    ":high_brightness:": "&#x1F506;",
    ":mute:": "&#x1F507;",
    ":speaker:": "&#x1F508;",
    ":sound:": "&#x1F509;",
    ":loud_sound:": "&#x1F50A;",
    ":battery:": "&#x1F50B;",
    ":electric_plug:": "&#x1F50C;",
    ":mag:": "&#x1F50D;",
    ":mag_right:": "&#x1F50E;",
    ":lock_with_ink_pen:": "&#x1F50F;",
    ":closed_lock_with_key:": "&#x1F510;",
    ":key:": "&#x1F511;",
    ":lock:": "&#x1F512;",
    ":unlock:": "&#x1F513;",
    ":bell:": "&#x1F514;",
    ":no_bell:": "&#x1F515;",
    ":bookmark:": "&#x1F516;",
    ":link:": "&#x1F517;",
    ":radio_button:": "&#x1F518;",
    ":back:": "&#x1F519;",
    ":end:": "&#x1F51A;",
    ":on:": "&#x1F51B;",
    ":soon:": "&#x1F51C;",
    ":top:": "&#x1F51D;",
    ":underage:": "&#x1F51E;",
    ":keycap_ten:": "&#x1F51F;",
    ":capital_abcd:": "&#x1F520;",
    ":abcd:": "&#x1F521;",
    ":1234:": "&#x1F522;",
    ":symbols:": "&#x1F523;",
    ":abc:": "&#x1F524;",
    ":fire:": "&#x1F525;",
    ":flashlight:": "&#x1F526;",
    ":wrench:": "&#x1F527;",
    ":hammer:": "&#x1F528;",
    ":nut_and_bolt:": "&#x1F529;",
    ":hocho:": "&#x1F52A;",
    ":knife:": "&#x1F52A;",
    ":gun:": "&#x1F52B;",
    ":microscope:": "&#x1F52C;",
    ":telescope:": "&#x1F52D;",
    ":crystal_ball:": "&#x1F52E;",
    ":six_pointed_star:": "&#x1F52F;",
    ":beginner:": "&#x1F530;",
    ":trident:": "&#x1F531;",
    ":black_square_button:": "&#x1F532;",
    ":white_square_button:": "&#x1F533;",
    ":red_circle:": "&#x1F534;",
    ":large_blue_circle:": "&#x1F535;",
    ":large_orange_diamond:": "&#x1F536;",
    ":large_blue_diamond:": "&#x1F537;",
    ":small_orange_diamond:": "&#x1F538;",
    ":small_blue_diamond:": "&#x1F539;",
    ":small_red_triangle:": "&#x1F53A;",
    ":small_red_triangle_down:": "&#x1F53B;",
    ":arrow_up_small:": "&#x1F53C;",
    ":arrow_down_small:": "&#x1F53D;",
    ":om:": "&#x1F549;",
    ":dove_of_peace:": "&#x1F54A;",
    ":kaaba:": "&#x1F54B;",
    ":mosque:": "&#x1F54C;",
    ":synagogue:": "&#x1F54D;",
    ":menorah_with_nine_branches:": "&#x1F54E;",
    ":clock1:": "&#x1F550;",
    ":clock2:": "&#x1F551;",
    ":clock3:": "&#x1F552;",
    ":clock4:": "&#x1F553;",
    ":clock5:": "&#x1F554;",
    ":clock6:": "&#x1F555;",
    ":clock7:": "&#x1F556;",
    ":clock8:": "&#x1F557;",
    ":clock9:": "&#x1F558;",
    ":clock10:": "&#x1F559;",
    ":clock11:": "&#x1F55A;",
    ":clock12:": "&#x1F55B;",
    ":clock130:": "&#x1F55C;",
    ":clock230:": "&#x1F55D;",
    ":clock330:": "&#x1F55E;",
    ":clock430:": "&#x1F55F;",
    ":clock530:": "&#x1F560;",
    ":clock630:": "&#x1F561;",
    ":clock730:": "&#x1F562;",
    ":clock830:": "&#x1F563;",
    ":clock930:": "&#x1F564;",
    ":clock1030:": "&#x1F565;",
    ":clock1130:": "&#x1F566;",
    ":clock1230:": "&#x1F567;",
    ":candle:": "&#x1F56F;",
    ":menorah:": "&#x1F54E;",
    ":dove:": "&#x1F54A;",
    ":hindu_temple:": "&#x1F6D5;",
    ":place_of_worship:": "&#x1F6D0;",
    ":atom_symbol:": "&#x269B;",
    ":om_symbol:": "&#x1F549;",
    ":star_of_david:": "&#x2721;",
    ":wheel_of_dharma:": "&#x2638;",
    ":yin_yang:": "&#x262F;",
    ":latin_cross:": "&#x271D;",
    ":orthodox_cross:": "&#x2626;",
    ":star_and_crescent:": "&#x262A;",
    ":peace_symbol:": "&#x262E;",
    ":aries:": "&#x2648;",
    ":taurus:": "&#x2649;",
    ":gemini:": "&#x264A;",
    ":cancer:": "&#x264B;",
    ":leo:": "&#x264C;",
    ":virgo:": "&#x264D;",
    ":libra:": "&#x264E;",
    ":scorpius:": "&#x264F;",
    ":sagittarius:": "&#x2650;",
    ":capricorn:": "&#x2651;",
    ":aquarius:": "&#x2652;",
    ":pisces:": "&#x2653;",
    ":ophiuchus:": "&#x26CE;",
    ":arrow_forward:": "&#x25B6;",
    ":fast_forward:": "&#x23E9;",
    ":black_right_pointing_double_triangle_with_vertical_bar:": "&#x23ED;",
    ":black_right_pointing_triangle_with_double_vertical_bar:": "&#x23EF;",
    ":next_track_button:": "&#x23ED;",
    ":play_or_pause_button:": "&#x23EF;",
    ":arrow_backward:": "&#x25C0;",
    ":rewind:": "&#x23EA;",
    ":arrow_double_up:": "&#x23EB;",
    ":arrow_double_down:": "&#x23EC;",
    ":pause_button:": "&#x23F8;",
    ":stop_button:": "&#x23F9;",
    ":record_button:": "&#x23FA;",
    ":eject_button:": "&#x23CF;",
    ":cinema:": "&#x1F3A6;",
    ":female_sign:": "&#x2640;",
    ":male_sign:": "&#x2642;",
    ":medical_symbol:": "&#x2695;",
    ":infinity:": "&#x267E;",
    ":recycle:": "&#x267B;",
    ":fleur_de_lis:": "&#x269C;",
    ":o:": "&#x2B55;",
    ":white_check_mark:": "&#x2705;",
    ":ballot_box_with_check:": "&#x2611;",
    ":heavy_check_mark:": "&#x2714;",
    ":heavy_multiplication_x:": "&#x2716;",
    ":x:": "&#x274C;",
    ":negative_squared_cross_mark:": "&#x274E;",
    ":heavy_plus_sign:": "&#x2795;",
    ":heavy_minus_sign:": "&#x2796;",
    ":heavy_division_sign:": "&#x2797;",
    ":curly_loop:": "&#x27B0;",
    ":loop:": "&#x27BF;",
    ":part_alternation_mark:": "&#x303D;",
    ":eight_spoked_asterisk:": "&#x2733;",
    ":eight_pointed_black_star:": "&#x2734;",
    ":sparkle:": "&#x2747;",
    ":double_exclamation_mark:": "&#x203C;",
    ":exclamation:": "&#x2757;",
    ":question:": "&#x2753;",
    ":grey_exclamation:": "&#x2755;",
    ":grey_question:": "&#x2754;",
    ":bangbang:": "&#x203C;",
    ":interrobang:": "&#x2049;",
    ":tm:": "&#x2122;",
    ":information_source:": "&#x2139;",
    ":left_right_arrow:": "&#x2194;",
    ":arrow_up_down:": "&#x2195;",
    ":arrow_upper_left:": "&#x2196;",
    ":arrow_upper_right:": "&#x2197;",
    ":arrow_lower_right:": "&#x2198;",
    ":arrow_lower_left:": "&#x2199;",
    ":leftwards_arrow_with_hook:": "&#x21A9;",
    ":arrow_right_hook:": "&#x21AA;",
    ":watch:": "&#x231A;",
    ":hourglass:": "&#x231B;",
    ":keyboard:": "&#x2328;",
    ":eject:": "&#x23CF;",
    ":black_left_pointing_double_triangle_with_vertical_bar:": "&#x23EE;",
    ":arrow_left:": "&#x2B05;",
    ":arrow_up:": "&#x2B06;",
    ":arrow_down:": "&#x2B07;",
    ":black_large_square:": "&#x2B1B;",
    ":white_large_square:": "&#x2B1C;",
    ":star:": "&#x2B50;",
    ":o2:": "&#x1F17E;",
    ":wavy_dash:": "&#x3030;",
    ":congratulations:": "&#x3297;",
    ":secret:": "&#x3299;",
    ":mahjong:": "&#x1F004;",
    ":black_joker:": "&#x1F0CF;",
    ":a:": "&#x1F170;",
    ":b:": "&#x1F171;",
    ":parking:": "&#x1F17F;",
    ":ab:": "&#x1F18E;",
    ":cl:": "&#x1F191;",
    ":cool:": "&#x1F192;",
    ":free:": "&#x1F193;",
    ":id:": "&#x1F194;",
    ":new:": "&#x1F195;",
    ":ng:": "&#x1F196;",
    ":ok:": "&#x1F197;",
    ":sos:": "&#x1F198;",
    ":up:": "&#x1F199;",
    ":vs:": "&#x1F19A;",
    ":cn:": "&#x1F1E8;&#x1F1F3;",
    ":de:": "&#x1F1E9;&#x1F1EA;",
    ":es:": "&#x1F1EA;&#x1F1F8;",
    ":fr:": "&#x1F1EB;&#x1F1F7;",
    ":gb:": "&#x1F1EC;&#x1F1E7;",
    ":it:": "&#x1F1EE;&#x1F1F9;",
    ":jp:": "&#x1F1EF;&#x1F1F5;",
    ":kr:": "&#x1F1F0;&#x1F1F7;",
    ":ru:": "&#x1F1F7;&#x1F1FA;",
    ":us:": "&#x1F1FA;&#x1F1F8;",
    ":koko:": "&#x1F201;",
    ":sa:": "&#x1F202;",
    ":u7121:": "&#x1F21A;",
    ":u6307:": "&#x1F22F;",
    ":u7981:": "&#x1F232;",
    ":u7a7a:": "&#x1F233;",
    ":u5408:": "&#x1F234;",
    ":u6e80:": "&#x1F235;",
    ":u6709:": "&#x1F236;",
    ":u6708:": "&#x1F237;",
    ":u7533:": "&#x1F238;",
    ":u5272:": "&#x1F239;",
    ":u55b6:": "&#x1F23A;",
    ":ideograph_advantage:": "&#x1F250;",
    ":accept:": "&#x1F251;",
    ":cyclone:": "&#x1F300;",
    ":foggy:": "&#x1F301;",
    ":closed_umbrella:": "&#x1F302;",
    ":night_with_stars:": "&#x1F303;",
    ":sunrise_over_mountains:": "&#x1F304;",
    ":sunrise:": "&#x1F305;",
    ":city_sunset:": "&#x1F306;",
    ":city_sunrise:": "&#x1F307;",
    ":rainbow:": "&#x1F308;",
    ":bridge_at_night:": "&#x1F309;",
    ":ocean:": "&#x1F30A;",
    ":volcano:": "&#x1F30B;",
    ":milky_way:": "&#x1F30C;",
    ":earth_africa:": "&#x1F30D;",
    ":earth_americas:": "&#x1F30E;",
    ":earth_asia:": "&#x1F30F;",
    ":globe_with_meridians:": "&#x1F310;",
    ":new_moon:": "&#x1F311;",
    ":waxing_crescent_moon:": "&#x1F312;",
    ":first_quarter_moon:": "&#x1F313;",
    ":moon:": "&#x1F314;",
    ":waxing_gibbous_moon:": "&#x1F314;",
    ":full_moon:": "&#x1F315;",
    ":waning_gibbous_moon:": "&#x1F316;",
    ":last_quarter_moon:": "&#x1F317;",
    ":waning_crescent_moon:": "&#x1F318;",
    ":crescent_moon:": "&#x1F319;",
    ":new_moon_with_face:": "&#x1F31A;",
    ":first_quarter_moon_with_face:": "&#x1F31B;",
    ":last_quarter_moon_with_face:": "&#x1F31C;",
    ":full_moon_with_face:": "&#x1F31D;",
    ":sun_with_face:": "&#x1F31E;",
    ":star2:": "&#x1F31F;",
    ":stars:": "&#x1F320;",
    ":thermometer:": "&#x1F321;",
    ":mostly_sunny:": "&#x1F324;",
    ":barely_sunny:": "&#x1F325;",
    ":partly_sunny_rain:": "&#x1F326;",
    ":rain_cloud:": "&#x1F327;",
    ":snow_cloud:": "&#x1F328;",
    ":lightning:": "&#x1F329;",
    ":tornado:": "&#x1F32A;",
    ":fog:": "&#x1F32B;",
    ":wind_blowing_face:": "&#x1F32C;",
    ":hotdog:": "&#x1F32D;",
    ":taco:": "&#x1F32E;",
    ":burrito:": "&#x1F32F;",
    ":chestnut:": "&#x1F330;",
    ":seedling:": "&#x1F331;",
    ":evergreen_tree:": "&#x1F332;",
    ":deciduous_tree:": "&#x1F333;",
    ":palm_tree:": "&#x1F334;",
    ":cactus:": "&#x1F335;",
    ":hot_pepper:": "&#x1F336;",
    ":tulip:": "&#x1F337;",
    ":cherry_blossom:": "&#x1F338;",
    ":rose:": "&#x1F339;",
    ":hibiscus:": "&#x1F33A;",
    ":sunflower:": "&#x1F33B;",
    ":blossom:": "&#x1F33C;",
    ":corn:": "&#x1F33D;",
    ":ear_of_rice:": "&#x1F33E;",
    ":herb:": "&#x1F33F;",
    ":four_leaf_clover:": "&#x1F340;",
    ":maple_leaf:": "&#x1F341;",
    ":fallen_leaf:": "&#x1F342;",
    ":leaves:": "&#x1F343;",
    ":grapes:": "&#x1F347;",
    ":melon:": "&#x1F348;",
    ":watermelon:": "&#x1F349;",
    ":tangerine:": "&#x1F34A;",
    ":lemon:": "&#x1F34B;",
    ":banana:": "&#x1F34C;",
    ":pineapple:": "&#x1F34D;",
    ":apple:": "&#x1F34E;",
    ":green_apple:": "&#x1F34F;",
    ":pear:": "&#x1F350;",
    ":peach:": "&#x1F351;",
    ":cherries:": "&#x1F352;",
    ":strawberry:": "&#x1F353;",
    ":kiwi:": "&#x1F95D;",
    ":tomato:": "&#x1F345;",
    ":coconut:": "&#x1F965;",
    ":avocado:": "&#x1F951;",
    ":eggplant:": "&#x1F346;",
    ":potato:": "&#x1F954;",
    ":carrot:": "&#x1F955;",
    ":cucumber:": "&#x1F952;",
    ":leafy_green:": "&#x1F96C;",
    ":broccoli:": "&#x1F966;",
    ":garlic:": "&#x1F9C4;",
    ":onion:": "&#x1F9C5;",
    ":mushroom:": "&#x1F344;",
    ":peanuts:": "&#x1F95C;",
    ":bread:": "&#x1F35E;",
    ":croissant:": "&#x1F950;",
    ":baguette_bread:": "&#x1F956;",
    ":flatbread:": "&#x1FAD3;",
    ":pretzel:": "&#x1F968;",
    ":bagel:": "&#x1F96F;",
    ":pancakes:": "&#x1F95E;",
    ":waffle:": "&#x1F9C7;",
    ":cheese_wedge:": "&#x1F9C0;",
    ":meat_on_bone:": "&#x1F356;",
    ":poultry_leg:": "&#x1F357;",
    ":cut_of_meat:": "&#x1F969;",
    ":bacon:": "&#x1F953;",
    ":hamburger:": "&#x1F354;",
    ":fries:": "&#x1F35F;",
    ":pizza:": "&#x1F355;",
    ":sandwich:": "&#x1F96A;",
    ":tamale:": "&#x1FAD4;",
    ":stuffed_flatbread:": "&#x1F959;",
    ":falafel:": "&#x1F9C6;",
    ":egg:": "&#x1F95A;",
    ":cooking:": "&#x1F373;",
    ":shallow_pan_of_food:": "&#x1F958;",
    ":pot_of_food:": "&#x1F372;",
    ":fondue:": "&#x1FAD5;",
    ":bowl_with_spoon:": "&#x1F963;",
    ":green_salad:": "&#x1F957;",
    ":popcorn:": "&#x1F37F;",
    ":butter:": "&#x1F9C8;",
    ":salt:": "&#x1F9C2;",
    ":canned_food:": "&#x1F96B;",
    ":bento:": "&#x1F371;",
    ":rice_cracker:": "&#x1F358;",
    ":rice_ball:": "&#x1F359;",
    ":rice:": "&#x1F35A;",
    ":curry:": "&#x1F35B;",
    ":ramen:": "&#x1F35C;",
    ":spaghetti:": "&#x1F35D;",
    ":sweet_potato:": "&#x1F360;",
    ":oden:": "&#x1F362;",
    ":sushi:": "&#x1F363;",
    ":fried_shrimp:": "&#x1F364;",
    ":fish_cake:": "&#x1F365;",
    ":moon_cake:": "&#x1F96E;",
    ":dango:": "&#x1F361;",
    ":dumpling:": "&#x1F95F;",
    ":fortune_cookie:": "&#x1F960;",
    ":takeout_box:": "&#x1F961;",
    ":crab:": "&#x1F980;",
    ":lobster:": "&#x1F99E;",
    ":shrimp:": "&#x1F990;",
    ":squid:": "&#x1F991;",
    ":oyster:": "&#x1F9AA;",
    ":icecream:": "&#x1F366;",
    ":shaved_ice:": "&#x1F367;",
    ":ice_cream:": "&#x1F368;",
    ":doughnut:": "&#x1F369;",
    ":cookie:": "&#x1F36A;",
    ":birthday:": "&#x1F382;",
    ":cake:": "&#x1F370;",
    ":cupcake:": "&#x1F9C1;",
    ":pie:": "&#x1F967;",
    ":chocolate_bar:": "&#x1F36B;",
    ":candy:": "&#x1F36C;",
    ":lollipop:": "&#x1F36D;",
    ":custard:": "&#x1F36E;",
    ":honey_pot:": "&#x1F36F;",
    ":glass_of_milk:": "&#x1F95B;",
    ":hot_beverage:": "&#x2615;",
    ":teapot:": "&#x1FAD6;",
    ":teacup_without_handle:": "&#x1F375;",
    ":sake:": "&#x1F376;",
    ":bottle_with_popping_cork:": "&#x1F37E;",
    ":wine_glass:": "&#x1F377;",
    ":cocktail:": "&#x1F378;",
    ":tropical_drink:": "&#x1F379;",
    ":beer:": "&#x1F37A;",
    ":beers:": "&#x1F37B;",
    ":clinking_glasses:": "&#x1F942;",
    ":tumbler_glass:": "&#x1F943;",
    ":cup_with_straw:": "&#x1F964;",
    ":bubble_tea:": "&#x1F9CB;",
    ":beverage_box:": "&#x1F9C3;",
    ":mate:": "&#x1F9C9;",
    ":ice_cube:": "&#x1F9CA;",
    ":chopsticks:": "&#x1F962;",
    ":fork_and_knife_with_plate:": "&#x1F37D;",
    ":spoon:": "&#x1F944;",
    ":kitchen_knife:": "&#x1F52A;",
    ":amphora:": "&#x1F3FA;",
    ":world_map:": "&#x1F5FA;",
    ":japan:": "&#x1F5FE;",
    ":compass:": "&#x1F9ED;",
    ":mountain_snow:": "&#x1F3D4;",
    ":mountain:": "&#x26F0;",
    ":mount_fuji:": "&#x1F5FB;",
    ":camping:": "&#x1F3D5;",
    ":beach_umbrella:": "&#x1F3D6;",
    ":desert:": "&#x1F3DC;",
    ":desert_island:": "&#x1F3DD;",
    ":national_park:": "&#x1F3DE;",
    ":stadium:": "&#x1F3DF;",
    ":classical_building:": "&#x1F3DB;",
    ":building_construction:": "&#x1F3D7;",
    ":bricks:": "&#x1F9F1;",
    ":rock:": "&#x1FAA8;",
    ":wood:": "&#x1FAB5;",
    ":hut:": "&#x1F6D6;",
    ":houses:": "&#x1F3D8;",
    ":derelict_house:": "&#x1F3DA;",
    ":house:": "&#x1F3E0;",
    ":house_with_garden:": "&#x1F3E1;",
    ":office:": "&#x1F3E2;",
    ":post_office:": "&#x1F3E3;",
    ":european_post_office:": "&#x1F3E4;",
    ":hospital:": "&#x1F3E5;",
    ":bank:": "&#x1F3E6;",
    ":hotel:": "&#x1F3E8;",
    ":love_hotel:": "&#x1F3E9;",
    ":convenience_store:": "&#x1F3EA;",
    ":school:": "&#x1F3EB;",
    ":department_store:": "&#x1F3EC;",
    ":factory:": "&#x1F3ED;",
    ":japanese_castle:": "&#x1F3EF;",
    ":european_castle:": "&#x1F3F0;",
    ":tokyo_tower:": "&#x1F5FC;",
    ":statue_of_liberty:": "&#x1F5FD;",
    ":church:": "&#x26EA;",
    ":shinto_shrine:": "&#x26E9;",
    ":fountain:": "&#x26F2;",
    ":tent:": "&#x26FA;",
    ":cityscape:": "&#x1F3D9;",
    ":hotsprings:": "&#x2668;",
    ":carousel_horse:": "&#x1F3A0;",
    ":ferris_wheel:": "&#x1F3A1;",
    ":roller_coaster:": "&#x1F3A2;",
    ":circus_tent:": "&#x1F3AA;",
    ":steam_locomotive:": "&#x1F682;",
    ":railway_car:": "&#x1F683;",
    ":bullettrain_side:": "&#x1F684;",
    ":bullettrain_front:": "&#x1F685;",
    ":train2:": "&#x1F686;",
    ":metro:": "&#x1F687;",
    ":light_rail:": "&#x1F688;",
    ":station:": "&#x1F689;",
    ":tram:": "&#x1F68A;",
    ":monorail:": "&#x1F69D;",
    ":mountain_railway:": "&#x1F69E;",
    ":train:": "&#x1F68B;",
    ":bus:": "&#x1F68C;",
    ":oncoming_bus:": "&#x1F68D;",
    ":trolleybus:": "&#x1F68E;",
    ":minibus:": "&#x1F690;",
    ":ambulance:": "&#x1F691;",
    ":fire_engine:": "&#x1F692;",
    ":police_car:": "&#x1F693;",
    ":oncoming_police_car:": "&#x1F694;",
    ":taxi:": "&#x1F695;",
    ":oncoming_taxi:": "&#x1F696;",
    ":car:": "&#x1F697;",
    ":oncoming_automobile:": "&#x1F698;",
    ":blue_car:": "&#x1F699;",
    ":pickup_truck:": "&#x1F6FB;",
    ":truck:": "&#x1F69A;",
    ":articulated_lorry:": "&#x1F69B;",
    ":tractor:": "&#x1F69C;",
    ":racing_car:": "&#x1F3CE;",
    ":motorcycle:": "&#x1F3CD;",
    ":motor_scooter:": "&#x1F6F5;",
    ":manual_wheelchair:": "&#x1F9BD;",
    ":motorized_wheelchair:": "&#x1F9BC;",
    ":auto_rickshaw:": "&#x1F6FA;",
    ":bike:": "&#x1F6B2;",
    ":kick_scooter:": "&#x1F6F4;",
    ":skateboard:": "&#x1F6F9;",
    ":roller_skate:": "&#x1F6FC;",
    ":busstop:": "&#x1F68F;",
    ":motorway:": "&#x1F6E3;",
    ":railway_track:": "&#x1F6E4;",
    ":oil_drum:": "&#x1F6E2;",
    ":fuelpump:": "&#x26FD;",
    ":rotating_light:": "&#x1F6A8;",
    ":traffic_light:": "&#x1F6A5;",
    ":vertical_traffic_light:": "&#x1F6A6;",
    ":octagonal_sign:": "&#x1F6D1;",
    ":construction:": "&#x1F6A7;",
    ":anchor:": "&#x2693;",
    ":sailboat:": "&#x26F5;",
    ":canoe:": "&#x1F6F6;",
    ":speedboat:": "&#x1F6A4;",
    ":passenger_ship:": "&#x1F6F3;",
    ":ferry:": "&#x26F4;",
    ":motor_boat:": "&#x1F6E5;",
    ":ship:": "&#x1F6A2;",
    ":airplane:": "&#x2708;",
    ":small_airplane:": "&#x1F6E9;",
    ":flight_departure:": "&#x1F6EB;",
    ":flight_arrival:": "&#x1F6EC;",
    ":parachute:": "&#x1FA82;",
    ":helicopter:": "&#x1F681;",
    ":suspension_railway:": "&#x1F69F;",
    ":mountain_cableway:": "&#x1F6A0;",
    ":aerial_tramway:": "&#x1F6A1;",
    ":artificial_satellite:": "&#x1F6F0;",
    ":rocket:": "&#x1F680;",
    ":flying_saucer:": "&#x1F6F8;",
    ":bellhop_bell:": "&#x1F6CE;",
    ":luggage:": "&#x1F9F3;",
    ":hourglass_flowing_sand:": "&#x23F3;",
    ":alarm_clock:": "&#x23F0;",
    ":stopwatch:": "&#x23F1;",
    ":timer_clock:": "&#x23F2;",
    ":mantelpiece_clock:": "&#x1F570;",
    ":sunny:": "&#x2600;",
    ":cloud:": "&#x2601;",
    ":partly_sunny:": "&#x26C5;",
    ":cloud_with_lightning_and_rain:": "&#x26C8;",
    ":sun_behind_small_cloud:": "&#x1F324;",
    ":sun_behind_large_cloud:": "&#x1F325;",
    ":sun_behind_rain_cloud:": "&#x1F326;",
    ":cloud_with_rain:": "&#x1F327;",
    ":cloud_with_snow:": "&#x1F328;",
    ":cloud_with_lightning:": "&#x1F329;",
    ":wind_face:": "&#x1F32C;",
    ":umbrella2:": "&#x2602;",
    ":umbrella:": "&#x2602;",
    ":umbrella_on_ground:": "&#x26F1;",
    ":zap:": "&#x26A1;",
    ":snowflake:": "&#x2744;",
    ":snowman2:": "&#x2603;",
    ":snowman:": "&#x26C4;",
    ":comet:": "&#x2604;",
    ":christmas_tree:": "&#x1F384;",
    ":fireworks:": "&#x1F386;",
    ":sparkler:": "&#x1F387;",
    ":firecracker:": "&#x1F9E8;",
    ":sparkles:": "&#x2728;",
    ":balloon:": "&#x1F388;",
    ":tada:": "&#x1F389;",
    ":confetti_ball:": "&#x1F38A;",
    ":tanabata_tree:": "&#x1F38B;",
    ":bamboo:": "&#x1F38D;",
    ":dolls:": "&#x1F38E;",
    ":flags:": "&#x1F38F;",
    ":wind_chime:": "&#x1F390;",
    ":rice_scene:": "&#x1F391;",
    ":red_envelope:": "&#x1F9E7;",
    ":ribbon:": "&#x1F380;",
    ":gift:": "&#x1F381;",
    ":reminder_ribbon:": "&#x1F397;",
    ":tickets:": "&#x1F39F;",
    ":ticket:": "&#x1F3AB;",
    ":medal:": "&#x1F396;",
    ":trophy:": "&#x1F3C6;",
    ":medal_sports:": "&#x1F3C5;",
    ":first_place:": "&#x1F947;",
    ":second_place:": "&#x1F948;",
    ":third_place:": "&#x1F949;",
    ":soccer:": "&#x26BD;",
    ":baseball:": "&#x26BE;",
    ":softball:": "&#x1F94E;",
    ":basketball:": "&#x1F3C0;",
    ":volleyball:": "&#x1F3D0;",
    ":football:": "&#x1F3C8;",
    ":rugby_football:": "&#x1F3C9;",
    ":tennis:": "&#x1F3BE;",
    ":flying_disc:": "&#x1F94F;",
    ":bowling:": "&#x1F3B3;",
    ":cricket_game:": "&#x1F3CF;",
    ":field_hockey:": "&#x1F3D1;",
    ":ice_hockey:": "&#x1F3D2;",
    ":lacrosse:": "&#x1F94D;",
    ":ping_pong:": "&#x1F3D3;",
    ":badminton:": "&#x1F3F8;",
    ":boxing_glove:": "&#x1F94A;",
    ":martial_arts_uniform:": "&#x1F94B;",
    ":goal_net:": "&#x1F945;",
    ":dart:": "&#x1F3AF;",
    ":kite:": "&#x1FA81;",
    ":yo_yo:": "&#x1FA80;",
    ":pool_8_ball:": "&#x1F3B1;",
    ":nazar_amulet:": "&#x1F9FF;",
    ":video_game:": "&#x1F3AE;",
    ":joystick:": "&#x1F579;",
    ":slot_machine:": "&#x1F3B0;",
    ":game_die:": "&#x1F3B2;",
    ":puzzle_piece:": "&#x1F9E9;",
    ":teddy_bear:": "&#x1F9F8;",
    ":nesting_dolls:": "&#x1FA86;",
    ":spade_suit:": "&#x2660;",
    ":heart_suit:": "&#x2665;",
    ":diamond_suit:": "&#x2666;",
    ":club_suit:": "&#x2663;",
    ":joker:": "&#x1F0CF;",
    ":mahjong_red_dragon:": "&#x1F004;",
    ":flower_playing_cards:": "&#x1F3B4;",
    ":performing_arts:": "&#x1F3AD;",
    ":framed_picture:": "&#x1F5BC;",
    ":art:": "&#x1F3A8;",
    ":thread:": "&#x1F9F5;",
    ":yarn:": "&#x1F9F6;",
    ":carpentry_saw:": "&#x1FA9A;",
    ":axe:": "&#x1FA93;",
    ":screwdriver:": "&#x1FA9B;",
    ":gear:": "&#x2699;",
    ":chains:": "&#x26D3;",
    ":hook:": "&#x1FA9D;",
    ":toolbox:": "&#x1F9F0;",
    ":magnet:": "&#x1F9F2;",
    ":ladder:": "&#x1FA9C;",
    ":alembic:": "&#x2697;",
    ":test_tube:": "&#x1F9EA;",
    ":petri_dish:": "&#x1F9EB;",
    ":dna:": "&#x1F9EC;",
    ":satellite_antenna:": "&#x1F4E1;",
    ":drop_of_blood:": "&#x1FA78;",
    ":adhesive_bandage:": "&#x1FA79;",
    ":stethoscope:": "&#x1FA7A;",
    ":door:": "&#x1F6AA;",
    ":elevator:": "&#x1F6D7;",
    ":mirror:": "&#x1FA9E;",
    ":window:": "&#x1FA9F;",
    ":bed:": "&#x1F6CF;",
    ":couch_and_lamp:": "&#x1F6CB;",
    ":chair:": "&#x1FA91;",
    ":toilet:": "&#x1F6BD;",
    ":plunger:": "&#x1FAA0;",
    ":shower:": "&#x1F6BF;",
    ":bathtub:": "&#x1F6C1;",
    ":mouse_trap:": "&#x1FAA3;",
    ":razor:": "&#x1FA92;",
    ":lotion_bottle:": "&#x1F9F4;",
    ":safety_pin:": "&#x1F9F7;",
    ":broom:": "&#x1F9F9;",
    ":basket:": "&#x1F9FA;",
    ":roll_of_paper:": "&#x1F9FB;",
    ":bucket:": "&#x1FAA3;",
    ":soap:": "&#x1F9FC;",
    ":toothbrush:": "&#x1FAA5;",
    ":sponge:": "&#x1F9FD;",
    ":fire_extinguisher:": "&#x1F9EF;",
    ":shopping_cart:": "&#x1F6D2;",
    ":cigarette:": "&#x1F6AC;",
    ":coffin:": "&#x26B0;",
    ":headstone:": "&#x1FAA6;",
    ":funeral_urn:": "&#x26B1;",
    ":moai:": "&#x1F5FF;",
    ":placard:": "&#x1FAA7;",
    ":identification_card:": "&#x1FAAA;"
};

interface EmojiReplaceProps {
    stringText: string
}

export const EmojiReplace = (props: EmojiReplaceProps): React.ReactElement => {
    const parts = props.stringText.split(/(:[a-zA-Z_]+:)/g);
    return (
        <>
            {parts.map((part, index) => {
                    if (emojiMap[part]) {
                        return <span key={index} dangerouslySetInnerHTML={{ __html: emojiMap[part] }} />;
                    } else {
                        return <span key={index}>{part}</span>;
                    }
                })}
        </>
    );
}
