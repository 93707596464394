import {useLocation} from "react-router-dom";
import {useMemo} from "react";

export const useHash = () => {
    const { hash } = useLocation();

    console.log(hash)
    return useMemo(() => new URLSearchParams(hash.substring(1)), [hash]);
}

