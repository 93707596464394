import React from "react";
import {CircularProgress, Link} from "@mui/material";
import {HomeSection} from "../HomeSection";
import {luckyDraw} from "../../../../features/purchase/getting-started/GettingStartedStep";
import {MonthlyDrawContent} from "../MonthlyDrawContent";
import {coachFeedbackServiceApi} from "../../../../services/coach-feedback-service";
import {usePurchaseNavigate} from "../../../../hooks/usePurchaseNavigate";
import {selected} from "../../../../constants/urlParameters";
import {Analytics} from "../../../../lib/analytics";

export const MonthlyDrawSection = (): JSX.Element => {
    const [startPurchase] = usePurchaseNavigate();
    const {isLoading: submittedBallotLoading, data: monthlyDraw} = coachFeedbackServiceApi.useGetSubmittedBallotQuery("", {refetchOnMountOrArgChange: true});
    const {isLoading: ballotsLoading, data: ballots} = coachFeedbackServiceApi.useGetOpenBallotsQuery("");
    const loading = submittedBallotLoading || ballotsLoading;

    return (
        <>
            {loading && <CircularProgress sx={{m: "10px auto"}} />}
            {!loading && (
                <HomeSection
                    title="Monthly Draw entry"
                    emptyContentSubtitle={<>You haven’t entered the {ballots?.current?.name} Monthly Draw.&nbsp;
                        <Link sx={{cursor: "pointer"}} onClick={() => {Analytics.enterDrawButtonClick();startPurchase({[selected]: luckyDraw.sku})}}>
                            Enter {ballots?.current?.name} Draw
                        </Link>
                    </>}
                    content={monthlyDraw ? <MonthlyDrawContent/> : null}
                />
            )}
        </>
    );
}
