import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {AppConfig} from "../config";
import {getIdToken} from "./cognito";

export const consentServiceApi = createApi({
    reducerPath: "consentServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${AppConfig.API_HOST}/v1`,
        prepareHeaders: async (headers) => {
            const token = await getIdToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["ConsentStatus"],
    endpoints: (build) => ({
        fetchConsentStatus: build.query<string, string>({
            query: () => ({
                url: `/consent/status`
            }),
            providesTags: ["ConsentStatus"],
            transformResponse: (response: { parental_email: string }) => response.parental_email,
        })
    })
});
