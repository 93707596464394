import { useNavigate, useLocation } from 'react-router-dom';
import {useCallback} from "react";

export const useCustomNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const navigateWithQueryParam = useCallback((key: string, value: string | number) => {
        const params = new URLSearchParams(location.search);
        params.set(key, value.toString());

        // Construct the new search query by merging the existing parameters with the new one
        const newSearch = params.toString();

        // Navigate to the same route with the updated search query
        navigate(`${location.pathname}?${newSearch}`);
    }, [location.search, location.pathname, navigate]);

    return [navigateWithQueryParam];
};
