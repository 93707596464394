import {Box, Link, styled, Typography} from "@mui/material";
import React from "react";
import {Links} from "../../../../../constants/links";

interface IAcceptTermsModalContentProps {
    setContentRead: (read: boolean) => void;
}
const HeaderTypography = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "700",
}));
const TitleTypography = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    fontWeight: "600",
    color:  `${theme.palette.grey[700]} !important`,
    margin: `${theme.spacing(2.5)} 0 ${theme.spacing(1)}`
}));
const BodyTypography = styled(Box)(({theme}) => ({
    fontSize: "12px",
    fontWeight: "400",
    color: `${theme.palette.grey[700]} !important`,
    "ol": {
        paddingInlineStart: theme.spacing(3)
    }
}));
const StyledLink = styled(Link)(() => ({
    cursor: "pointer",
    color: "#A0720E"
}));
interface ITermsContent {
    title: string;
    body: JSX.Element;
}
const privacyPolicyLink = <StyledLink href={Links.privacy} target="_blank">Privacy Policy</StyledLink>;
const communityRulesLink = <StyledLink href={Links.rules} target="_blank">Community Rules</StyledLink>;
const contactEmailLink = <StyledLink href="mailto:hello@viaacademy.com">hello@viaacademy.com</StyledLink>;
const customerPortalGeneral = <StyledLink href={Links.customerPortalGeneral} target="_blank">Customer Portal</StyledLink>;
const thisBreachesForm = <StyledLink href={Links.breachesForm} target="_blank">this form</StyledLink>;
const content: ITermsContent[] = [
    {
        title: "Introduction",
        body: <>
            These Terms apply to the Via Academy mobile application (the “<b>App</b>“) and to the Coach Feedback service
            offered via the VIA Academy website (including <StyledLink href="www.viaacademy.com" target="_blank">www.viaacademy.com</StyledLink> and its subdomains) (together, the “<b>Services</b>“). The Services are made available by KaiZhan Limited, a company registered in England and Wales under company number 12610906 whose registered office is at 506 Kingsbury Road, London, NW9 9HE, United Kingdom (“KaiZhan” or “us” or “we”) and whose VAT number is 382742381 and whose email address is {contactEmailLink}.<br/><br/>These Terms describe how you may download, access and use the Services, including the Coach Feedback service
            and prize draw, as well as the terms on which we supply the Services to you. Please read these Terms together with our <StyledLink>Website Terms</StyledLink>, {communityRulesLink} and {privacyPolicyLink}. By using the App you are deemed to have agreed to these Terms. You must also read and agree to these Terms before you can register for the Services and before you can purchase Coach Feedback.
        </>
    },
    {
        title: "Notice to Parents of Minors",
        body: <>
            You must be at least 18 years old to agree to these Terms. If you are under 13 years of age, your parent or legal guardian of at least 18 years of age must agree to these Terms in your place. If your age is between 13 and 18 (or the age of majority where you live), you and your parent or guardian must review and agree to these Terms together. We strongly recommend that parents and guardians familiarise themselves with parental controls on devices they provide a child.<br/><br/>
            If you are a parent or legal guardian who is agreeing to these Terms on behalf of a child aged under 18, you agree that: (a) you and your child will comply with these Terms (and any breach by your child will be deemed your breach); and (b) you are liable for your child’s acts or omissions when they use the Services.<br/><br/>
            Please also note that you will be responsible for managing your child’s activities in relation to the Services and that your child may send/provide to us personal data. You as the parent of the child accept and agree that any use of the Services by the child (and any collection/use of the child’s data as part of his/her use of the Services) is on the terms of our {privacyPolicyLink}.<br/><br/>
            If your child is aged under 13 years old, you must also give your child permission to register for the Services, and to use certain interactive or paid features, using the KidsWebServices “parent portal”. You will need to create an account with KidsWebServices and verify your identity. You can manage your child’s permissions using the parent portal until your child turns 13 years old. After your child turns 13, we will continue to rely upon the consent and permissions you have provided unless you contact us, or your child creates a new account.<br/><br/>
            If your child is aged under 13 years old you will also receive relevant emails from us pertaining to your child’s activity within the app (for example regarding videos uploaded by your child, and changes to your child’s privacy settings when they turn 13). You will receive these emails until your child turns 13 years old.
        </>
    },
    {
        title: "Data & Privacy",
        body: <>
            When you register to use the Services and use the Services as a registered user, we may collect and use your personal data (and the personal data of any child who is registering for/using the Services with your permission). Please also refer to our {privacyPolicyLink} which explains how all information collected through your use of the Services may be used and shared. You must agree to the {privacyPolicyLink} in order to register for the Services. All data generated by your use of the Services (but not including content uploaded or contributed by you) belongs to us. We will not sell or share any of your data to third parties.
        </>
    },
    {
        title: "User Account and Password",
        body: <>
            When you register to use the Services you will be asked to create a User Account and to provide certain information to us, including your email address, password and date of birth, as part of our security procedures. You are responsible for the accuracy of all  User Account information you provide to us.<br/><br/>
            You must treat your User Account login details as confidential and you must not disclose them to any third party. You are responsible for any unauthorised use of your User Account login details. If you know or suspect that anyone other than you knows your User Account login details, you must immediately notify us at {contactEmailLink}<br/><br/>
            We have the right to disable any User Accounts and/or passwords, at any time, if in our reasonable opinion you have breached these Terms or other policies regarding quality standards or standards of conduct, including our {communityRulesLink} and {privacyPolicyLink}. You cannot use the Services if we have previously disabled your account for violation of law or any of our policies.
        </>
    },
    {
        title: "User Profile Information",
        body: <>
            When you create a User Profile, we will ask you to provide us with certain personal information including your first and last name, username, nationality, football player position, football team gender, and profile photo (“User Profile Information”). If you are a parent or guardian creating a User Profile on behalf of your child, you should enter your child’s information. You will also have the opportunity to edit your User Profile Information. It is your responsibility to ensure that your User Profile Information is up to date, accurate and complete.<br/><br/>
            Your User Profile Information must not contain any pictures, text or other content that contains profanities, nudity, suggestive content, violence, disturbing content, rude gestures, drugs, tobacco, alcohol, gambling or hate symbols, or is unkind, rude, offensive, racist, sexist or otherwise inappropriate, as deemed by us.<br/><br/>
            You must not impersonate any other person or organisation, or pretend to be someone or something that you are not, including any football club, Academy, team, player or organisation. You must not create an account for someone else unless you have their express permission.<br/><br/>
            Your User Profile Information must not contain any logos, trade marks, images or text belonging to someone else without their permission. You warrant that you own or have the rights to use any content contained in your User Profile Information and you will be responsible for any consequences if you don’t have the necessary permissions and consents.<br/><br/>
            If you upload a photo that shows another person, you must ask that person if they are happy for you to upload the photo, and get their permission before you do so. You hereby warrant to us that you have the necessary permission and consent from anyone included in your photo before you upload it. You should not upload any photo showing someone under 13 years old.
        </>
    },
    {
        title: "Followers and Following",
        body: <>
            Users aged over 13 years old can be followed by both Academies and other App users. If you are over 13, when you register for the App, you will automatically be “followed” by Academies. Users aged over 13 years old can choose who can see their Followers/Following lists by enabling or disabling the “Hide my Followers/Following lists” toggle in Profile Settings. For users aged between 13-17 years old, this setting is enabled by default.<br/><br/>
            For users aged 18+, this setting is disabled by default. Users aged over 13 can choose  to enable/disable “Follower requests”. For users aged between 13-17 years old, “Follower requests” is enabled by default. For users aged 18+, “Follower requests” is disabled by default.<br/><br/>
            Users aged under 13 years old can only be followed by Academies (and not by other App users). If you are aged under 13, “Following” by Academies will also require permission from your parent/guardian via the Parent Portal. For users aged under 13, the “Hide my Followers/Following” setting and the “Follower requests” setting are enabled by default and cannot be changed.
        </>
    },
    {
        title: "Videos and other User-Generated Content",
        body: <>
            As a registered user you can create, upload and/or post videos and video descriptions to and via the Services (“User Videos”). Your User Videos may also be combined with sound recordings from the VIA Academy music library (“VIA Music”) and visual elements such as the VIA Academy and “V” logos.<br/><br/>
            As a registered user in some circumstances you are also permitted to download your own User Videos via the Services, and (provided you are over 13) post your own User Videos to third party apps/platforms via the Services (e.g. TikTok, Instagram, Facebook, YouTube, Twitter).<br/><br/>
            Whenever you upload or post User Videos to or via the Services you must comply with the standards set out in these Terms, our {communityRulesLink}, our {privacyPolicyLink} and our other policies. If you choose to post User Videos via the Services to third party apps/platforms, you must also comply with the content guidelines of those apps/platforms.<br/><br/>
            You hereby warrant and confirm that any User Videos that you upload or post to or via the Services do comply with the standards set out in these Terms, our {communityRulesLink}, our {privacyPolicyLink} and our other policies, and the standards of any connected apps/platforms to which you upload or post, and you agree that you will be liable to us and indemnify us for any breach of this warranty. This means that you will be responsible for any loss or damage we suffer if you are in breach of this obligation.<br/><br/>
            We do not approve, endorse or verify the content in any User Videos uploaded or posted to or via the Services, or downloaded via the Services. The views expressed in any User Videos uploaded or posted to or via the Services, or downloaded via the Services, do not represent our views or values.
        </>
    },
    {
        title: "Content Standards",
        body: <>
            User Videos uploaded or posted to or via the Services must be in the nature of football videos that show you demonstrating a football skill, drill, exercise, trick or move or performing in a football match scenario. The Services are designed for you to improve your football, practise your football skills, and compete, connect and interact with professional football Academies and other footballers. You must not upload or post User Videos to or via the Services for any other purposes, including to do or encourage anything illegal, or to promote goods or services.<br/><br/>
            User Videos uploaded or posted to or via the Services must not contain any pictures, text, audio or other content that contains profanities, nudity, suggestive or sexual content, violence, threats, harassment or abuse, graphic or disturbing content, rude gestures, insults, drugs, tobacco, alcohol, gambling, hate speech or hate symbols, or that is toxic, unkind, rude, offensive, racist, sexist or otherwise inappropriate, as deemed by us.<br/><br/>
            User Videos uploaded or posted to or via the Services must not feature another person who is not yourself unless that person knows about the video and has given you permission to post it, even if they are in the background. User Videos must not contain another person’s private information, or contain footage, audio or images of another person in a private situation (e.g. a locker room). You must have, and you hereby warrant to us that you have, the necessary permission and consent from anyone included in your video before you upload or post it to or via the Services. You will be responsible for any consequences if you don’t have the necessary permissions and consents. User Videos should never contain footage of a person under 13 years old, unless that person is yourself and you have posted the User Video using your own registered account, with the requisite parental permission.<br/><br/>
            Your User Videos, and any audio, images, text, logos, names or trademarks contained in your User Videos, must be your own, or you must have permission from the person who owns that content before you upload or post the User Video to or via the Services. You warrant to us that any footage and audio in your User Videos belongs to you, or that you have received all of the necessary permissions to use, upload and/or post the User Videos to or via the Services. You will be responsible for any consequences if you do not have all of the necessary permissions and consents. This includes (but is not limited to) in particular, match footage and football exercises undertaken with others. If we suspect that you do not have permission to use the footage or audio track in any User Video that you upload or post to or via the App, we have the right to remove your video from our Services immediately and, in the case of any paid features, goods or services relating to that User Video, to refuse to provide the paid features/goods/services to you.<br/><br/>
            You must not impersonate other people or organisations, or mislead others into thinking you are someone or something you are not. This includes impersonating any football player, celebrity, club, Academy, team, brand or other organisation.<br/><br/>
            There are also certain technical and quality requirements that must be met in order for you to upload or post a video. These requirements will be communicated to you at the point of upload/posting. It is your responsibility to ensure that you have submitted the correct video and that the technical requirements and quality standards are met.
        </>
    },
    {
        title: "Content Moderation and Removal",
        body: <>
            If in our opinion your User Video does nor, or may not, comply with these Terms, our {communityRulesLink}, our {privacyPolicyLink} or our other policies, or if we are permitted or required to do so by law, we reserve the right, at our sole discretion and with or without any notice, and without any liability to you to:<br/>
            - refuse to post your User Videos,<br/>
            - temporarily or permanently hide or remove any User Videos,<br/>
            - temporarily or permanently suspend any User Account, and/or<br/>
            - ask you to re-submit any User Videos connected with paid features,<br/>
            - refuse to provide any features, products or services connected with your User Videos. Whether or not a refund is provided in such circumstances will be determined by us in our sole discretion,<br/>
            - disqualify any Monthly Draw submission.<br/><br/>
            We also have the right to, but are not required to, temporarily or permanently hide or remove any User Videos, and/or temporarily or permanently suspend any User Account, in response to complaints from other users or third parties.<br/><br/>
            As part of our content moderation processes, we use an automatic moderation service that automatically detects and removes unlawful or objectionable content including where that content is believed to contain profanities, explicit nudity, suggestive situations, sexual content, violence, threats, harassment or abuse, graphic or disturbing content, rude gestures, drugs, tobacco alcohol, gambling or hate speech or hate symbols. We do not guarantee the accuracy of the moderation service.<br/><br/>
            Although we have processes in place to remove unlawful and objectionable content, we do not control and are not responsible for what you or other people do on, or upload or post to or via, the Services, and we are not responsible for any content uploaded or posted by you or someone else to or via the Services. If you believe that content on the Services breaches these Terms, our {communityRulesLink}, our {privacyPolicyLink} or our other policies, or the law, then please file an in-app report, file a report in your browser using our {customerPortalGeneral}, or contact us at {contactEmailLink}.
        </>
    },
    {
        title: "Coach Feedback",
        body: <>
            You must be over 18 years of age to purchase Coach Feedback. If you are under 18 years of age, please ask your parent/guardian to purchase the Coach Feedback on your behalf.<br/><br/>
            When creating the Coach Feedback, the coach may elect to use and rely on your Video Upload, any past Video Uploads submitted by you, and any written information submitted by you with your Video Upload.<br/><br/>
            It is your responsibility to ensure that your information is complete, up to date and accurate.<br/><br/>
            The provision of Coach Feedback relies on the quality of your Video Uploads. You are responsible for ensuring the quality of your Video Uploads, including that you have submitted the correct video and that the technical requirements and quality standards are met.<br/><br/>
            You will be notified that your Coach Feedback is available via in-app notification, push notification (if push notifications are enabled on your device) and email (via the email address associated with your User Profile or, in the case of children aged under 13 years, the parental email address which was used when parental permission was provided). You are responsible for ensuring that emails sent in relation to your User Account are not blocked or filtered by your email provider. If you believe that you are not receiving email communications from us pertaining to your User Account, please send us a WhatsApp at <StyledLink href="tel:+447311345970">+44 7311345970.</StyledLink><br/><br/>
            We will reimburse you for any fees paid by you for Coach Feedback, with credits or a refund in our sole discretion (subject to any applicable law), if:<br/>
            - for any reason we cannot provide the Coach Feedback to you within the stated time frame; or<br/>
            - due to errors or malfunctions you cannot access the Coach Feedback, and those errors/malfunctions are not resolved in a reasonable timeframe, in our sole discretion.
        </>
    },
    {
        title: "Coach Feedback Monthly Draw",
        body: <>
            The Monthly Draw recurring prize draw (the “<b>Monthly Draw</b>”) will start as of October 2023 and will continue to run until further notice. Prize draws will occur monthly (each a “<b>Prize Draw</b>”).<br/><br/>
            The entry period for each Prize Draw (each, an “<b>Entry Period</b>”) will begin on the 1st day of each calendar month at 00:00 BST and will close on the last day of the calendar month at 23:59 BST.<br/><br/>
            Prize Draws are open to all registered users. No purchase of any goods or services is necessary to be eligible for entry. Registered users under the age of 13 will require parental permission to enter.<br/><br/>
            Each user may only submit one entry per Prize Draw. Entries cannot be changed or deleted after submission.<br/><br/>
            Each Prize Draw will take place on the 1st day of the month following the Entry Period for that Prize Draw, with the first Prize Draw occuring on 1 November 2023.<br/><br/>
            Winners will be selected at random from all valid entries received in the Entry Period immediately prior to the relevant Prize Draw. At least one (1) winner will be drawn in each Prize Draw. The number of winners drawn may vary between Prize Draws.<br/><br/>
            Each winner will receive Coach Feedback on the video that was submitted in their Prize Draw entry (the “<b>Prize</b>”). The Academy coach allocated to provide the Coach Feedback will be selected by us in our sole discretion. The Prize will be available within 3 weeks of the relevant Prize Draw. Prizes cannot be transferred to a third party.<br/><br/>
            Winners will be notified via in-app notification, push notification (if push notifications are enabled on your device) and email (via the email address associated with your User Profile. If the winner is aged under 13 years, the email notification will be sent to the winner’s parent’s email address.<br/><br/>
            Winners’ usernames will be published on VIA Academy social media and/or website following the relevant Prize Draw.<br/><br/>
            We are not responsible for technical malfunctions, lost or unavailable network connections, failed uploads or other technical problems related to entries. Any entry which in our determination does not comply with these Terms, our {communityRulesLink}, our {privacyPolicyLink} or any of our other policies may be deemed invalid at our sole discretion. If a winning entry is subsequently deemed to be invalid, we reserve the right to refuse to provide the Prize.<br/><br/>
            Any user who enters or attempts to enter a Prize Draw in a manner which in our determination does not comply with these Terms, our {communityRulesLink}, our {privacyPolicyLink} or any of our other policies, or by its nature is fraudulent or unjust to other entrants, including without limitation tampering with the operation of the Monthly Draw, cheating, hacking, deception or any other unfair practices such as creating multiple user accounts or duplicate entries, may be disqualified from a Prize Draw and/or the Monthly Draw at our sole discretion.<br/><br/>
            We reserve the right to modify the Prize Draw eligibility and rules, cancel or suspend a Prize Draw, or suspend or terminate the Monthly Draw in whole or in part at any time.<br/><br/>
            All decisions made by us on matters relating to the Monthly Draw will be final and binding.
        </>
    },
    {
        title: "Acceptable Use",
        body: <>
            In addition to the Terms regarding acceptable use of the Services outlined above, you agree that you shall not, directly or indirectly:
            <div>
                <ol type="a">
                    <li>use the Services in any way which might infringe any third party rights, including third party intellectual property rights;</li>
                    <li>reproduce, distribute, modify, create derivative works of, or otherwise use (except as explicitly authorised in these Terms) content and information including photos, videos, logos and images available on or through the Services;</li>
                    <li>upload or post any content containing someone else’s private or confidential information (including personal data), without their permission;</li>
                    <li>use the Services to develop or provide any product or service that competes with our business;</li>
                    <li>download, screenshot, scrape, extract or otherwise obtain any material from the Services for use within a third party website or application;</li>
                    <li>use the Services in any manner inconsistent with these Terms or our policies including the {communityRulesLink} and {privacyPolicyLink};</li>
                    <li>infringe our intellectual property rights in relation to your use of the Services (to the extent that such use is not licensed under these Terms);</li>
                    <li>use the Services to do anything unlawful, misleading or fraudulent, or for any illegal or unauthorised purpose;</li>
                    <li>misuse, interfere with or impair the intended operation of the Services or any of its features;</li>
                    <li>misuse the Services by knowingly introducing viruses or other material which is malicious or harmful;</li>
                    <li>attempt to gain unauthorised access to the Services or any technology related to it;</li>
                    <li>transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Services; or</li>
                    <li>act in a way that is demeaning or derogatory about any other user of the Services.</li>
                </ol>
            </div>
        </>
    },
    {
        title: "Suspension and Termination",
        body: <>
            You are free to delete your User Account, uninstall the App, and/or stop accessing the Services at any time.<br/><br/>
            If in our opinion we believe that you have breached these Terms, our {communityRulesLink}, or {privacyPolicyLink} or any of our policies, or are infringing (or likely to infringe) any third party rights, or where we are permitted or required to do so by law, we reserve the right to temporarily or permanently take the following actions with or without notice to you:
            <div>
                <ol type="a">
                    <li>suspend or terminate your access to the Services</li>
                    <li>stop providing all or part of the Services to you</li>
                    <li>disable or terminate your User Account with immediate effect;</li>
                    <li>temporarily or permanently hide or remove any content provided by you;</li>
                    <li>ask you to re-submit any content;</li>
                    <li>issue you with a warning;</li>
                    <li>take further legal action as we see fit; and/or</li>
                    <li>at our discretion notify relevant authorities.</li>
                </ol>
            </div>
            If your account is terminated for violating these Terms or any of our other policies, you are not allowed to register for the Services again. If we take any of the actions described above, we will not be obliged to provide you with any refund or compensation for any paid services.
        </>
    },
    {
        title: "Communications",
        body: <>
            If you register for the Services we may send email communications and/or push notifications to you, either directly or through one of our partners, for customer service and to provide you with important updates and other information relating to the Services. You can turn push notifications on and off in your App settings. Parents of children under 13 who grant their child permission to use the app via the parent portal will receive email updates from us relating to the child’s activity in the app, until the point at which the child turns 13.<br/><br/>
            If you opt-in to join our mailing list, we may send further email communications to you, either directly or through one of our partners, for marketing and promotional purposes. You can unsubscribe from these communications at any time.<br/><br/>
            Any communications or materials (including, without limitation, any Video Uploads) you upload, post or send to us through the Services or by electronic mail or by any other means will <b><u>NOT</u></b> be treated as confidential (other than communications in respect of purchases). Please ensure that you only upload, post or send content where you fully intend to do so.
        </>
    },
    {
        title: "Access to Services",
        body: <>
            General access to the Services is free of charge. For App access you will require a device running either the iOS or Android operating systems and an internet connection. Access to the Coach Feedback service will require a computer or mobile device and an internet connection. You will be responsible for arranging your own Internet access. You are given free access to certain features, including the Pitch zone and User Profile within the App.  Some limited features can be accessed without a registered account, however access to the full range of features requires registration. At this stage in our product development, all current services and communications will be provided in English language, regardless of the country where you are accessing the Services.<br/><br/>
            We will refresh, update and modify the Services from time to time. We cannot therefore guarantee that specific content will always be available.<br/><br/>
            We will use our reasonable endeavours to ensure that the App and the website where Coach Feedback services are offered are available 24/7 subject to scheduled and unscheduled downtime and interruptions beyond our control. Stock levels for the Coach Feedback service fluctuate and we do not guarantee that Coach Feedback from any particular Academy will be available for purchase at any particular time.<br/><br/>
            We may suspend, withdraw, discontinue or change the Services without notice and will not be liable to you if for any reason the Services are unavailable at any time or for any period. We may update and/or change the content, features and other goods or services offered in the Services at any time.
        </>
    },
    {
        title: "Viruses",
        body: <>The Services are made available on an “as is” basis. We do not guarantee that the Services will be secure or free from bugs or viruses, so you should use your own virus protection software.</>
    },
    {
        title: "Our Intellectual Property",
        body: <>
            As between you and us, we are the owners or lawful licensees of all intellectual property rights in and relating to the Services and any communications or materials provided as part of the Services.<br/><br/>
            We grant to you a non-transferable, non-exclusive, revocable licence to use the Services subject always to compliance with these Terms and the documents referred to in it. Your right to use the Services is limited to personal use only.
        </>
    },
    {
        title: "Third Party Links",
        body: <>The Services may from time-to-time contain links to third party apps, platforms or sites for which we are not liable. Please read the terms of use of such apps, platforms or sites for yourself. We assume no responsibility for the content of third party apps, platforms or sites linked to from the Services and will not be liable for any loss or damage that may arise from your use of them.</>
    },
    {
        title: "Your Intellectual Property",
        body: <>For rights in relation to Coach Feedback, please refer to that section above.  You will continue to own the rights in any Video Uploads and User Profile Information, but you hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, irrevocable worldwide licence to use, host, distribute, display, and share any content you upload, post or share with us (eg. photo and video content as well as any other data, ideas, suggestions or other information), (consistent with your privacy and application settings), for any purpose we deem fit relating to the business, including, without limitation, the development of the Services and for marketing and promotional purposes. You hereby confirm that you will have and/or give us any consents, permissions and authorities which we require under any applicable law, including those relating to privacy, to use any content or data provided by you in such a way. This licence ends when your content is deleted from our systems. You can delete content individually, or all at once by deleting your account. By agreeing to our {privacyPolicyLink}, you confirm that we have all such privacy consents, permissions and authorities which we require to use your data as set out in these Terms.<br/><br/>If you believe that something on the VIA Academy app breaches your copyright, please complete {thisBreachesForm}. </>
    },
    {
        title: "Our Liability",
        body: <>
            Nothing in these Terms excludes or limits our liability for:
            <div>
                <ol type="a">
                    <li>death or personal injury caused by our negligence;</li>
                    <li>fraud or fraudulent misrepresentation; and</li>
                    <li>any matter in respect of which it would be unlawful for us to exclude or restrict our liability.</li>
                </ol>
            </div>
            Under no circumstances are we liable for any consequential or indirect loss caused to you whether in contract, tort or statutory duty, and we have no liability to you for any direct or indirect loss of profit, loss of business, business interruption, or loss of business opportunity.<br/><br/>
            Nothing in these Terms affects your statutory rights. Advice about your statutory rights is available from your local Citizens' Advice Bureau or Trading Standards Office.<br/><br/>
            <b>PLEASE NOTE THAT YOU ARE RESPONSIBLE FOR YOUR PARTICIPATION IN ANY OF THE CHALLENGES, SKILLS OR OTHER ACTIVITIES REFERRED TO ON THE SERVICES. YOU UNDERTAKE THEM ENTIRELY AT YOUR OWN (AND IN RESPECT OF A MINOR, YOUR PARENT OR GUARDIAN'S) RISK.</b><br/><br/>
            <b>WE ARE NOT LIABLE FOR ANY LOSS OR DAMAGE SUFFERED BY YOU WHILST USING THE SERVICES INCLUDING AS A RESULT OF THE UPLOAD OF ANY VIDEO  EXCEPT AS A RESULT OF OUR NEGLIGENCE OR DELIBERATE DEFAULT.</b><br/><br/>
            <b>WE WILL NOT BE LIABLE IN ANY WAY FOR ANY USER VIDEOS IN ANY CIRCUMSTANCES.</b><br/><br/>
            In respect of any Coach Feedback for which you have paid an additional service fee, our aggregate liability to you in respect of any loss or damage suffered and arising out of or in connection with these Terms and/or your access and use of the Services, whether in contract, tort (including negligence) or otherwise shall not exceed the higher of: (a) £100; and (b) the amount paid by you for the Coach Feedback.
        </>
    },
    {
        title: "Changes to these Terms",
        body: <>We may make changes to these Terms by giving not less than five days' prior written notice. Any use of the Services after the expiry of such notice period will be deemed acceptance by you of the revised terms.</>
    },
    {
        title: "Other important information",
        body: <>
            If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.<br/><br/>
            Any provision of these Terms that expressly or by implication is intended to come into or continue in force on or after termination will remain in full force and effect.
        </>
    },
    {
        title: "Transfer",
        body: <>You may not transfer your rights under these Terms to a third party without our consent.</>
    },
    {
        title: "Complaints",
        body: <>Should you have any complaints or issues regarding the Services, we would obviously like to resolve such matters directly with you; contact us at {contactEmailLink} with details of any concerns.  We will investigate any and all issues thoroughly and  engage with you as constructively as we can.</>
    },
    {
        title: "Governing Law and Jurisdiction",
        body: <>
            These Terms are governed by English law and any dispute will be heard before the English courts.<br/><br/>
            Nothing in these Terms affects your rights as a consumer to rely on such mandatory provisions of local law.
        </>
    },
    {
        title: "Appstore Providers",
        body: <>
            For the purpose of this section, "Appstore Provider" means the provider of the app store through which you have downloaded the App (for example, Apple is the Appstore Provider if you have downloaded the App from the Apple App Store, Google is the Appstore Provider if you have downloaded the App from Google Play, etc).<br/><br/>
            You acknowledge and agree that these Terms have been concluded between you and us, and not with the Appstore Provider. You acknowledge and agree that the Appstore Provider is not responsible for the App and its content.<br/><br/>
            You acknowledge and agree that the Appstore Provider has no obligation to provide any maintenance or support in respect of the App. Should you have any problems in using the App, please contact us at {contactEmailLink}.<br/><br/>
            In the event that the App does not conform with any product warranty provided for by these Terms, the Appstore Provider may provide you with a refund of the price that you paid to purchase the App (if any). The Appstore Provider shall, to the maximum extent permitted by law, have no obligation to you whatsoever with respect to the App.<br/><br/>
            You acknowledge and agree that the Appstore Provider shall not be responsible for addressing any claims that you might have relating to the App, including (without limitation): product liability claims; any claim that the App fails to conform to any applicable legal or regulatory requirement; and claims arising under consumer protection or similar legislation.<br/><br/>
            In the event that a third party claims that the App infringes its intellectual property rights, we (and not the Appstore Provider) shall be solely responsible for the investigation, defence, settlement and discharge of such claim.<br/><br/>
            You warrant and represent that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.<br/><br/>
            If the Appstore Provider is Apple, you acknowledge and agree that Apple and its subsidiaries are third party beneficiaries to these Terms. Upon your acceptance of these Terms, Apple will have the right to enforce these Terms against you as a third party beneficiary.
        </>
    },
    {
        title: "Contact Us",
        body: <>Should you have any questions please contact us at {contactEmailLink}.</>
    },
];
export const AcceptTermsModalContent = ({setContentRead}: IAcceptTermsModalContentProps) => {
    const handleScroll = (e: any) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if ((scrollTop + clientHeight) >= 0.95 * scrollHeight) {
            setContentRead(true);
        }
    };

    return (
        <Box>
            <HeaderTypography color="text.primary">VIA Academy Terms</HeaderTypography>
            <Box sx={{mt: "10px", overflowY: "auto", maxHeight: "55vh"}} onScroll={handleScroll}>
                {content.map((item: ITermsContent, index: number) => (
                    <div key={index}>
                        <TitleTypography>{item.title}</TitleTypography>
                        <BodyTypography>{item.body}</BodyTypography>
                    </div>
                ))}
            </Box>
        </Box>
    );
}
