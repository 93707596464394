import { useState } from "react";
import {emailRegexp} from "../constants/regexp";

export const useValidateEmail = (emailArg: string) => {
  const [email, setEmail] = useState<string>(emailArg);

  const isEmailEmpty = email.length === 0;
  const isEmailValid = emailRegexp.test(String(email).toLowerCase());
  return [email, setEmail, isEmailEmpty, isEmailValid] as const;
}
