import React from "react";
import {Button, styled, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

interface IAddSomethingBoxProps {
    boxTitle: string;
    sx: Object;
    active: boolean;
    onAddNew: () => void;
}

const StyledButton = styled(Button)(({theme}) => ({
    display: "flex",
    justifyContent: "left",
    border: `2px dotted ${theme.palette.secondary.main}`,
    borderRadius: "8px",
    padding: "15px 20px",
    "&:disabled": {
        border: "2px dotted #414141",
        "p, svg": {
            color: "#414141"
        }
    },
    "&:hover": {
        border: "2px dotted #AAAAAA",
    },
}));
const StylesPlusIcon = styled(Add)(({theme}) => ({
    color: theme.palette.secondary.main,
}));
const StyledTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.secondary.main,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "500"
}));

export const AddSomethingBox = ({ boxTitle, sx, active = true, onAddNew }: IAddSomethingBoxProps): JSX.Element => {
    return (
        <StyledButton
            fullWidth
            sx={sx}
            variant="outlined"
            color="secondary"
            startIcon={<StylesPlusIcon/>}
            disabled={!active}
            onClick={onAddNew}
        >
            <StyledTypography>{boxTitle}</StyledTypography>
        </StyledButton>
    );
}
