import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Link,
    styled,
    TextField,
    Typography, useTheme
} from "@mui/material";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import {useValidatePassword} from "../../hooks/useValidatePassword";
import {useValidateEmail} from "../../hooks/useValidateEmail";
import {emailRules, passwordRules} from "../../constants/rules";
import {SocialLoginGroup} from "./components/SocialLoginGroup";
import {Links} from "../../constants/links";
import {accountErrors} from "../../constants/account";
import {DeviceType, getDeviceType} from "../../util/userAgent";
import {BasicModal, ButtonType} from "../../shared/Modal";
import {CenteredItemsBox, ColumnBox} from "../../shared/styles";
import {ResetModalContent} from "./components/modal/ResetModalContent";
import {
    getProvider,
    resetToken,
    roleHasAccess,
    setIdToken,
    signIn,
    signOut,
    socialSignIn
} from "../../services/cognito";
import {isInViewport} from "../../util/isInViewport";
import {getUserProfileThunk, setAllowUserToRedirect, ViaUserProfile} from "../../features/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useQuery} from "../../hooks/useQuery";
import {
    accountNotFound,
    appAccessErrorValue,
    appErrorKey,
    errorKey,
    providerNameKey
} from "../../constants/urlParameters";
import {Analytics, SignInMethod} from "../../lib/analytics";
import {routes} from "../../constants/routes";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RootState} from "../../redux/store";
import {IExtendedErrorData} from "../../types/state";
import {ErrorCode} from "../../constants/error";
import {SocialProvider} from "../../constants/social";
import {GuestGateway} from "../gateway/GuestGateway";
import {LoaderWithWatchdog} from "../../shared/LoaderWithWatchdog";

const StyledContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}));
const StyledHeadImg = styled("img")(() => ({
    width: "70px",
    padding: "20px"
}));
const StyledForm = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "5px",
}));
const StyledTitle = styled(Typography)(() => ({
    fontSize: "22px",
    fontWeight: "700",
}));
const StyledNoteText = styled(Box)(() => ({
    fontSize: "12px",
    fontWeight: "500",
}));
const StyledErrorText = styled(StyledNoteText)(({theme}) => ({
    color: theme.palette.warning.main,
    marginTop: "-5px",
}));
const StyledTextField = styled(TextField)(({theme}) => ({
    "& .MuiInputBase-input:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.default} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
    },
}));
const StyledButton = styled(Button)(() => ({
    margin: "10px 0",
    borderRadius: "24px",
    textTransform: "none"
}));
const SignUpLink = styled(Link)(() => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

const StyledInsideBoxHeader = styled(Typography)(()=>({
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "center",

}))

export function LoginForm() {
    const theme = useTheme()
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const queryAppErrorKey = useQuery().get(appErrorKey);
    const formRef = useRef<HTMLDivElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const socialButtonsRef = useRef<HTMLInputElement>(null);
    const [email, setEmail, isEmailEmpty, isEmailValid] = useValidateEmail("");
    const [submitEmailError, setSubmitEmailError] = useState<boolean>(false);
    const [password, setPassword, isPasswordEmpty, isPasswordValid] = useValidatePassword("");
    const [submitPasswordError, setSubmitPasswordError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [authenticating, setAuthenticating] = useState<boolean>(false);

    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const errorData = useAppSelector<IExtendedErrorData | null>((state: RootState) => state.user.error);
    const [searchParams, setSearchParams] = useSearchParams();
    const [providerName, setProviderName] = useState<string>("");
    const [submitCognitoError, setSubmitCognitoError] = useState<string | null>(null);
    const [showLoader, setShowLoader] = useState<boolean>(true);

    const setUpAuthenticatedData = useCallback(async () => {
        Analytics.signInSuccess();
        await setIdToken();
        dispatch(getUserProfileThunk());
        if(!theme.custom.isWhiteLabelled){
            dispatch(setAllowUserToRedirect(true));
        }
    }, [dispatch, theme])

    useEffect(() => {
        const setToken = async () => {
            const hasAccess = await roleHasAccess();
            if (hasAccess) {
                await setUpAuthenticatedData();
                setProviderName(await getProvider());
            } else {
                resetToken();
                setSubmitCognitoError(accountErrors.wrongRole);
            }
        };

        if (searchParams.has("code") && !sessionStorage.getItem("idToken")) {
            setShowLoader(true)
            setToken();
        } else{
            setShowLoader(false)
        }
    }, [dispatch, searchParams, setSearchParams, setUpAuthenticatedData]);

    useEffect(() => {
        if (errorData?.status === ErrorCode.notFound && providerName) {
            searchParams.delete("code");
            searchParams.delete("state");
            searchParams.set(errorKey, accountNotFound);
            searchParams.set(providerNameKey, providerName);
            setSearchParams(searchParams);
            navigate(`${routes.home}?${searchParams.toString()}`);
        }
    }, [profile, errorData, searchParams, navigate, providerName, setSearchParams]);


    /* Removing as causing double count in new white label experience
    useEffect(() => {
        Analytics.signInView();
    }, [])
    */

    useEffect(() => {
        // Automatically focus on the email input when the component mounts
        emailInputRef.current?.focus();

        const handleScrollForKeyboard = () => {
            const form = formRef.current;
            if (!form || !socialButtonsRef.current) return;

            const socialButtonsInViewport = isInViewport(socialButtonsRef.current)

            if (!socialButtonsInViewport) {
                form.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        };

        window.addEventListener('resize', handleScrollForKeyboard);

        return () => {
            window.removeEventListener('resize', handleScrollForKeyboard);
        };
    }, []);

    useEffect(() => {
        if (queryAppErrorKey) {
            setSubmitCognitoError(queryAppErrorKey === appAccessErrorValue ? accountErrors.wrongRole : accountErrors.accountError);
        }
    }, [queryAppErrorKey]);


    const authenticateUser = async () => {
        if (isEmailEmpty || !isEmailValid || isPasswordEmpty || !isPasswordValid) {
            setSubmitEmailError(isEmailEmpty || !isEmailValid);
            setSubmitPasswordError(isPasswordEmpty || !isPasswordValid);
            return;
        }
        try {
            Analytics.signInAttempt(SignInMethod.UsernamePassword);
            setAuthenticating(true);
            await signIn(email, password);
            const hasAccess = await roleHasAccess();
            if (!hasAccess) {
                setSubmitCognitoError(accountErrors.wrongRole);
                await signOut();
                return;
            }
            await setUpAuthenticatedData();
        } catch (e) {
            setSubmitCognitoError(accountErrors.wrongCredentials);
        } finally {
            setAuthenticating(false);
        }
    }
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const getEmailErrorMessage = () => {
        if (isEmailEmpty) {
            return emailRules.required.message;
        }

        return emailRules.notValid.message;
    };
    const getPasswordErrorMessage = () => {
        if (isPasswordEmpty) {
            return passwordRules.required.message;
        }

        return passwordRules.minLength.message;
    };
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubmitEmailError(false);
        setSubmitCognitoError(null);
        setEmail(event.currentTarget.value);
    };
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubmitPasswordError(false);
        setSubmitCognitoError(null);
        setPassword(event.currentTarget.value);
    };

    return (
        <>{theme.custom.isWhiteLabelled ?
            showLoader ? <LoaderWithWatchdog/> : <GuestGateway/>
            :
        <>
        {showLoader ? <LoaderWithWatchdog/> :
        <StyledContainer>
            { !theme.custom.isWhiteLabelled &&
                <StyledHeadImg src={"/via_logo_gold.svg"} alt="Via Academy Logo"/>
            }
            { theme.custom.name === 'qpr' &&
                <StyledHeadImg src={theme.custom.brandTitleLogo} alt="QPR Logo"/>
            }
            <StyledForm>
                { !theme.custom.isWhiteLabelled ?
                    <>
                        <StyledTitle>Sign In</StyledTitle>
                        <ColumnBox ref={formRef}>
                            <StyledTextField
                                color="secondary"
                                label="VIA Academy login email"
                                variant="outlined"
                                sx={{m: "8px 0"}}
                                InputLabelProps={{style: {fontSize: "14px"}}}
                                inputRef={emailInputRef}
                                error={submitEmailError}
                                helperText={submitEmailError && getEmailErrorMessage()}
                                onChange={handleEmailChange}
                            />
                            <StyledTextField
                                color="secondary"
                                label="VIA Academy password"
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                sx={{m: "8px 0"}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePassword} color="secondary" edge="end"
                                                        sx={{mr: "0"}}>
                                                {showPassword ? <VisibilityOffOutlined fontSize="small"/> :
                                                    <VisibilityOutlined fontSize="small"/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{style: {fontSize: "14px"}}}
                                error={submitPasswordError}
                                helperText={submitPasswordError && getPasswordErrorMessage()}
                                onChange={handlePasswordChange}
                            />
                        </ColumnBox>
                        <StyledNoteText sx={{mt: "10px"}}>Our&nbsp;
                            <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy}>
                                Privacy Policy
                            </Link>&nbsp;
                            applies to this feature
                        </StyledNoteText>
                        <StyledButton
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={submitEmailError || submitPasswordError || authenticating || !!submitCognitoError}
                            endIcon={authenticating && <CircularProgress size={12} color="secondary"/>}
                            onClick={authenticateUser}
                        >
                            Sign In
                        </StyledButton>
                        {submitCognitoError && <StyledErrorText>{submitCognitoError}</StyledErrorText>}
                        <StyledNoteText sx={{m: "24px 0 12px"}}>
                            <CenteredItemsBox sx={{gap: "5px"}}>
                                Forgot password?
                                <BasicModal
                                    buttonType={ButtonType.Link}
                                    darkModal={getDeviceType() !== DeviceType.Desktop}
                                    buttonContent={<>Reset</>}
                                    buttonProps={{
                                        underline: "always",
                                        color: "secondary",
                                        sx: {
                                            backgroundColor: "none",
                                            cursor: "pointer"
                                        },
                                    }}

                                >
                                    <ResetModalContent/>
                                </BasicModal>
                                on the app
                            </CenteredItemsBox>
                        </StyledNoteText>
                        <Box ref={socialButtonsRef}><SocialLoginGroup submitCognitoError={submitCognitoError}/></Box>
                        <StyledNoteText sx={{mt: "12px"}}>
                            <CenteredItemsBox sx={{gap: "5px"}}>
                                No account? <SignUpLink color="secondary" onClick={() => navigate(routes.signUp)}>Sign
                                Up</SignUpLink>
                            </CenteredItemsBox>
                        </StyledNoteText>
                    </>
                    :
                    <>
                        <StyledInsideBoxHeader>Sign in for free with your</StyledInsideBoxHeader>
                        <Button
                            disableElevation
                            onClick={() => {
                                // navigate(routes.signIn)
                                socialSignIn(SocialProvider.KOREQPR)
                            }}
                            {...{
                                fullWidth: true,
                                color: "primary",
                                variant: "contained",
                                sx: {
                                    borderRadius: "31px",
                                    minHeight: "60px",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    margin: "30px 0 0",
                                    textTransform: "uppercase",
                                    color: "#FFFFFF"
                                }
                            }}
                        >{theme.custom.name} CLUB LOGIN</Button>
                    </>
                    // <Box ref={socialButtonsRef}><SocialLoginGroup submitCognitoError={submitCognitoError}/></Box>
                }
            </StyledForm>
        </StyledContainer>
        }
        </>
        }</>
    )
}




