import React from 'react'
import {IAcademy} from "../../../types/categories";
import {Box, Button, styled} from "@mui/material";


const StyledTeam = styled(Button)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    grow: 1,
    border: `1px solid ${theme.palette.text.primary}`,
    padding: "10px",
    minHeight: "80px",
    marginBottom: "10px",
    borderRadius: "8px",
    "&:disabled": {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        backgroundColor: "#414141",
    },
    "&.selected-team": {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
    },
    "&.selected-team:hover": {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
    },
    "&.greyed-team:not(:disabled)": {
        borderColor: theme.palette.grey[700],
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            'p': {
                color: theme.palette.secondary.main,
            },
            'img': {
                opacity: 1
            },
        },
        'p': {
            color: theme.palette.grey[700],
        },
        'img': {
            opacity: 0.5
        },
    },
}));


interface LuckyDrawTeamSelectProps {
    teamSelected: string
    teams: IAcademy[]
    setLuckyDrawTeam: (team:string) => void
}

export const LuckyDrawTeamSelect = (props: LuckyDrawTeamSelectProps): React.ReactElement => {

    return (
        <Box sx={{mt: "10px", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: { xs: "space-evenly", sm: "space-evenly"}}}>
            { props.teams.map( (a: IAcademy) => {
                return (
                    <StyledTeam key={"ld-team-" + a.sku} className={props.teamSelected === a.name ? "selected-team" : ""} onClick={()=>{props.setLuckyDrawTeam(a.name)}} >
                        <img className="feedback-item-club-logo" src={a.avatar} alt="team logo"/>
                    </StyledTeam>
                )
            })}
        </Box>
    )
}