import type { Middleware } from '@reduxjs/toolkit'
import {ErrorCode} from "../constants/error";
import {getFragmentParams} from "../util/url";
import {forceLogoutKey} from "../constants/urlParameters";

export const rtkQueryErrorLogger: Middleware =
    () => (next) => (action) => {
        if (action.payload?.status === ErrorCode.unauthorized && !window.location.href.includes(forceLogoutKey)) {
            window.location.href = window.location.href + getFragmentParams(forceLogoutKey, true);

            return null;
        }

        return next(action)
    }
