import React, {useEffect, useRef, useState} from "react";
import {
    Box, Button, Checkbox, FormControlLabel, FormGroup,
    Link,
    styled,
    Typography
} from "@mui/material";
import {DeviceType, getDeviceType} from "../../util/userAgent";
import {BasicModal, ButtonType} from "../../shared/Modal";
import {isInViewport} from "../../util/isInViewport";
import {routes} from "../../constants/routes";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DownloadModalContent} from "../login/components/modal/DownloadModalContent";
import {Links} from "../../constants/links";
import {SocialSignUpGroup} from "./components/SocialSignUpGroup";
import {SocialSignUpAgreeButton} from "./components/SocialSignUpAgreeButton";
import {Analytics} from "../../lib/analytics";
import {calculateAge} from "../../lib/utils";
import {usePurchaseNavigate} from "../../hooks/usePurchaseNavigate";
import {useTheme} from "@mui/material";


const StyledContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
}));
const StyledHeadImg = styled("img")(() => ({
    width: "70px",
    padding: "20px"
}));
const StyledForm = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "5px",
}));
const StyledTitle = styled(Typography)(() => ({
    fontSize: "22px",
    fontWeight: "700",
}));
const StyledPickerLabel = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "400",
    marginTop: "2em",
    textAlign: "left",
}));
const StyledNoteText = styled(Box)(() => ({
    fontSize: "12px",
    fontWeight: "500",
}));
export function SignUp() {
    const theme = useTheme();
    const [, deletePurchaseFlowData ] = usePurchaseNavigate();
    const birthdayBtnRef = useRef<HTMLButtonElement>(null);
    const formRef = useRef<HTMLDivElement>(null);
    const socialButtonsRef = useRef<HTMLInputElement>(null);
    const [age, setAge] = useState<number|undefined>(undefined)
    const [dateReasonable, setDateReasonable] = useState<boolean>(false)
    const [dateAccepted, setDateAccepted] = useState<boolean>(false)
    const [socialChoice, setSocialChoice] = useState<string>("")
    const [dateOfBirth, setDateOfBirth] = useState<number>(0)
    const [marketingOptIn, setMarketingOptIn] = useState<boolean>(false)
    const [agreed, setAgreed] = useState<boolean>(false)

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const onToggleResetModal = () => {
        setModalOpen(!modalOpen);
    }

    useEffect(() => {
        if (dateReasonable && birthdayBtnRef.current) {
            birthdayBtnRef.current.focus();
        }
    }, [dateReasonable]);

    useEffect(() => {
        
        //Analytics.createAccountAgeView();

        const handleScrollForKeyboard = () => {
            const form = formRef.current;
            if (!form || !socialButtonsRef.current) return;

            const socialButtonsInViewport = isInViewport(socialButtonsRef.current)

            if (!socialButtonsInViewport) {
                form.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        };

        window.addEventListener('resize', handleScrollForKeyboard);

        return () => {
            window.removeEventListener('resize', handleScrollForKeyboard);
        };
    }, []);


    const dateUpdate = ( d: string|null) => {
        console.log(d)
        if(d){
            const age = calculateAge(d)
            setDateOfBirth(Math.round((new Date(d).getTime())/1000)) // divide by 1000 to get epoch time in seconds for python backend
            if(!isNaN(age) && age > 2 && age < 105){
                setAge(age)
                setDateReasonable(true)
            }
            else{
                setDateReasonable(false)
            }
        }
        else
            setAge(undefined)
    }

    const handleMarketingCheckBox = (e: React.FormEvent<HTMLInputElement>) => {
        setMarketingOptIn(e.currentTarget.checked)
    }
    const handleAgreeCheckBox = (e: React.FormEvent<HTMLInputElement>) => {
        setAgreed(e.currentTarget.checked)
    }

    return (
        <StyledContainer>
            <StyledHeadImg src={"/via_logo_gold.svg"} alt="Via Academy Logo"/>
            <StyledForm>
                <StyledTitle>Create an account</StyledTitle>
                <StyledNoteText>Already have an account?&nbsp;
                    <Link color="primary" underline="always" href={routes.signIn}>
                        Sign In
                    </Link>
                </StyledNoteText>
                {(age === undefined || !dateAccepted) &&
                    <>
                        <StyledPickerLabel>When were you born?</StyledPickerLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{mt: "10px", mb: "4px"}}
                                format="DD/MM/YYYY"
                                label="Enter your date of birth"
                                onChange={dateUpdate}
                                // onAccept={() => setDateAccepted(true) }
                                disableFuture
                            />
                        </LocalizationProvider>
                        <StyledNoteText style={{textAlign: "left", maxWidth: "320px"}}>We need your date of birth to confirm your age. Your date of birth won’t be shared with other users.</StyledNoteText>
                        { dateReasonable &&

                            <Button
                                ref={birthdayBtnRef}
                                onClick={() => {
                                    deletePurchaseFlowData();
                                    setDateAccepted(true);
                                    Analytics.createAccountAgeContinueButtonClick(age || 0);
                                }}
                                {...{
                                    fullWidth: true,
                                    color: "primary",
                                    variant: "contained",
                                    sx: {
                                        borderRadius: "31px",
                                        minHeight: "60px",
                                        textAlign: "center",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        margin: "30px 0"
                                    }
                                }}
                            >Continue</Button>
                        }
                    </>
                }
                {dateAccepted && age !== undefined && age < 13 &&
                    <>
                        <StyledPickerLabel style={{textAlign: "center"}}>Thank you!</StyledPickerLabel>
                        <StyledNoteText>Please download the VIA Academy app to continue creating your account</StyledNoteText>
                        <BasicModal
                            controlledOpen={modalOpen}
                            onControlledToggleModal={onToggleResetModal}
                            buttonType={ButtonType.Button}
                            darkModal={getDeviceType() !== DeviceType.Desktop}
                            buttonContent={<>Download VIA Academy</>}
                            buttonProps={{
                                fullWidth: true,
                                color: "primary",
                                variant: "contained",
                                sx: {
                                    marginTop: "2em",
                                    borderRadius: "31px",
                                    minHeight: "60px",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                }
                            }}
                        >
                            <DownloadModalContent cb={onToggleResetModal}/>
                        </BasicModal>
                    </>
                }
                { dateAccepted && age !== undefined && age > 12 &&
                    <>
                        {/*<Box ref={socialButtonsRef}><SocialLoginGroup setUpAuthenticatedData={setUpAuthenticatedData}/></Box>*/}
                        <SocialSignUpGroup setSelected={setSocialChoice} selected={socialChoice}/>
                        <FormGroup sx={{margin: "0 16px"}}>
                            <FormControlLabel style={{textAlign:"left", visibility: (socialChoice === "" ? "hidden" : "inherit")}} control={<Checkbox onChange={handleAgreeCheckBox} />} label={<StyledNoteText>I've read and I agree to VIA Academy's <Link color="primary" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy}>Privacy Policy</Link> and <Link color="primary" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms}>Terms & Conditions</Link></StyledNoteText>} />
                        </FormGroup><br/>

                        { socialChoice !== "" &&
                            <>
                                <FormGroup sx={{margin: "0 16px"}}>
                                    <FormControlLabel style={{textAlign:"left", visibility: (socialChoice === "" ? "hidden" : "inherit")}} control={<Checkbox onChange={handleMarketingCheckBox} />} label={<StyledNoteText>Please keep me informed with news, updates, offers and promotions from VIA Academy and its partners</StyledNoteText>} />
                                </FormGroup>
                                {/*<StyledNoteText sx={{mt: "12px"}}>By continuing, you confirm you have read and agree to VIA Academy's <Link color="primary" target="_blank" href={Links.terms}>Terms & Conditions</Link> and <Link color="primary" target="_blank" href={Links.privacy}>Privacy Policy</Link></StyledNoteText>*/}
                                <SocialSignUpAgreeButton disabled={!agreed} selectedSocialProvider={socialChoice} dateOfBirth={dateOfBirth} marketingOptIn={marketingOptIn} />
                            </>
                        }
                    </>
                }
            </StyledForm>
        </StyledContainer>
    )
}




