import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {DashedLighterDivider, ErrorTypography, RowBox} from "../../../../../shared/styles";
import {FeedbackItem, Status} from "./FeedbackItem";
import {routes} from "../../../../../constants/routes";
import {EditFeedbackReason, editReason, editResultId} from "../../../../../constants/urlParameters";
import {getNavigationPath} from "../../../../../util/url";
import {
    FailedCoachFeedbackStatus,
    FailedCoachFeedbackStatusType,
    ICoachFeedbackResult
} from "../../../../../types/coachFeedback";
import {useAppDispatch} from "../../../../../redux/hooks";
import {setOrderWithIssues} from "../../../../../features/order/orderSlice";
import {Links} from "../../../../../constants/links";
import {getEditReasons} from "../../../../../util/editFeedbackReason";

interface IFailedFeedbackItemProps {
    feedback: ICoachFeedbackResult;
}
interface IIssuesButton {
    comment: string;
    actionTitle: string;
    action: () => void;
}
type PossibleIssues = Record<FailedCoachFeedbackStatusType, IIssuesButton>;
export const FailedFeedbackItem = ({ feedback }: IFailedFeedbackItemProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [issues, setIssues] = useState<IIssuesButton[]>([]);
    const navigateToPurchase = useCallback((firstRoute: EditFeedbackReason) => {
        navigate(getNavigationPath(routes.purchase, {[editResultId]: feedback.id, [editReason]: getEditReasons(feedback.status, firstRoute).toString()}))
    }, [navigate, feedback.id, feedback.status]);
    const possibleIssues: PossibleIssues = useMemo(() => ({
            [FailedCoachFeedbackStatus.userIdentificationRejected]: {
                comment: "We’re unable to identify you in your video",
                actionTitle: "Tell us",
                action: () => {
                    dispatch(setOrderWithIssues(feedback));
                    navigateToPurchase(EditFeedbackReason.identifyingComment);
                }
            },
            [FailedCoachFeedbackStatus.videoQualityRejected]: {
                comment: "Your video is too low quality to review",
                actionTitle: "Re-Upload",
                action: () => {
                    dispatch(setOrderWithIssues({...feedback, video: null}));
                    navigateToPurchase(EditFeedbackReason.videoQuality);
                }
            },
            [FailedCoachFeedbackStatus.improvementAreasRejected]: {
                comment: "Please clarify your improvement areas",
                actionTitle: "Tell us",
                action: () => {
                    dispatch(setOrderWithIssues(feedback));
                    navigateToPurchase(EditFeedbackReason.improvementAreas);
                }
            },
            [FailedCoachFeedbackStatus.otherRejected]: {
                comment: "Please contact us to resolve a problem with your submission",
                actionTitle: "Contact us",
                action: () => window.open(Links.customerPortal, '_blank')
            }
    }), [dispatch, feedback, navigateToPurchase]);

    useEffect(() => {
        if (!feedback.status.length) {
            return;
        }

        const filteredIssues = Object.keys(possibleIssues)
            .filter(status => feedback.status.includes(status as FailedCoachFeedbackStatusType))
            .map(status => possibleIssues[status as FailedCoachFeedbackStatusType]);

        setIssues(filteredIssues);
    }, [feedback.status, possibleIssues]);


    return (
        <Box sx={{mb: "40px"}}>
            <FeedbackItem
                key={`resolve_feedback_${feedback.id}`}
                videoItem={{
                    src: feedback.video?.url ?? null,
                    title: feedback.displayName || "",
                    createdDate: feedback.feedback?.createdDate ?? null,
                    status: Status.Failure
                }}
            />
            {issues.map(({comment, actionTitle, action}, index) => (
                <Box key={index}>
                    {index !== 0 && index !== issues.length && <DashedLighterDivider sx={{m: "10px 0"}}/>}
                    <RowBox>
                        <ErrorTypography sx={{flex: 1}}>{comment}</ErrorTypography>
                        <Button variant="outlined" size="small" color="warning" sx={{flexBasis: "110px"}} onClick={action}>{actionTitle}</Button>
                    </RowBox>
                </Box>
            ))}
        </Box>
    );
};
