import React from "react";
import {Box, Button, Link, styled, Typography} from "@mui/material";
// import {DeviceType, getDeviceType} from "../../../util/userAgent";
import {routes} from "../../../constants/routes";
import {useNavigate} from "react-router-dom";
import {Analytics} from "../../../lib/analytics";

const CenteredBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: "1"
}));
// const LandingLogoGroup = styled(Box)(() => ({
//     "img": {
//         width: "40px",
//         margin: "10px 20px"
//     }
// }));
const LandingTitle = styled(Typography)(() => ({
    textAlign: "center",
    fontSize: "32px",
    fontWeight: "800",
    margin: "15px 0",
}));
const LandingText = styled(Typography)(() => ({
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "400",
    margin: "15px 0 25px",
}));
const LandingTextBold = styled(Typography)(() => ({
    fontWeight: "700",
    display: "inline"
}));
const StyledButtonGroup = styled(Box)(() => ({
    padding: "20px 0 10px"
}));
const StyledNoteText = styled(Typography)(() => ({
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    paddingTop: "50px"
}));
const SignInLink = styled(Link)(() => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

export const WhiteLabelledLandingContent = (): JSX.Element => {
    const navigate = useNavigate();
    return (
        <CenteredBox>
            <img src={"/via_logo_gold.svg"} alt="Via Academy Logo"/>
            <LandingTitle color="primary">COACH FEEDBACK</LandingTitle>
            {/*<LandingLogoGroup>*/}
            {/*    <img src={process.env.PUBLIC_URL + "/club_logos/fh_logo.png"} alt="Fulham" />*/}
            {/*    <img src={process.env.PUBLIC_URL + "/club_logos/pb_logo.png"} alt="PUFC"/>*/}
            {/*    <img src={process.env.PUBLIC_URL + "/club_logos/qpr_logo.png"} alt="QPR"/>*/}
            {/*</LandingLogoGroup>*/}
            <LandingText><LandingTextBold>Get one step closer to being a pro</LandingTextBold> with personalised analysis and feedback on your videos.</LandingText>
            <StyledButtonGroup>
                <Button
                    onClick={() => {
                        Analytics.getStartedButtonClick();
                        navigate(routes.signUp)
                    }}
                    {...{
                        fullWidth: true,
                        color: "primary",
                        variant: "contained",
                        sx: {
                            borderRadius: "31px",
                            minHeight: "60px",
                            textAlign: "center",
                            fontSize: "20px",
                            fontWeight: "600",
                        }
                    }}
                >Get Started</Button>
                <StyledNoteText>Already have an account? <SignInLink color="primary" onClick={() => navigate(routes.signIn)}>Sign In</SignInLink></StyledNoteText>
            </StyledButtonGroup>
        </CenteredBox>
    )
}
