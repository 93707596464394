import React from 'react';

type MainComponentProps = React.PropsWithChildren<{
  className?: string;
}>;

const MainComponent: React.FC<MainComponentProps> = ({ children, className }) => {
  return <main className={className}>{children}</main>;
};

export default MainComponent;

