import axios, {AxiosResponse} from "axios";
import {MediaUploadResponse,} from "../features/media/mediaSlice";

let controller: AbortController | null = null;

export const putVideo = async (
    uploadResponse: MediaUploadResponse,
    file: File,
    onUploadProgress: (value: number) => void,
    onSuccessCallback: () => void,
    onFailCallback: () => void
): Promise<void> => {
    controller = new AbortController();
    try {
        const config = {
            headers: {
                'Content-Type': file.type,
            },
            signal: controller.signal,
            onUploadProgress: (progressEvent: any) => onUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100)),
        };

        const response: AxiosResponse = await axios.put(uploadResponse.url, file, config);

        if (response.status === 200) {
            onSuccessCallback();
        } else {
            onFailCallback();
        }
    } catch (error) {
        onFailCallback();
    }
}

export const abortUploadVideo = () => {
    if (controller) {
        controller.abort();
    }
}
