import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    getAcademyContent,
    getAcademyItem,
    PutUserFeedbackResponse,
    putLikeItem,
    PutLikeResponse, putLikertResponse, putUserPollSubmission, putUserQuestionResponse, getTrainingContent, getTags
} from "../../services/content-api";
import {cmsPageType} from "../../constants/cms";
import {getProVideoSecureMedia} from "../../services/api";



export type FeedItem = FeedItemPreview | ImageCarouselFeedEntryPage | UserPollPage | UserQuestionPage | LikertScalePage | TrainingVideoPage

export interface FeedItemPreview {
    "isPreview": boolean
    "id": number
    "meta": {
        "detail_url": string
        "first_published_at": string
        "html_url": string
        "slug": string
        "type": string
    },
    "title": string
}

export interface TrainingVideoPage {
    "id": number
    "meta": {
    "type": string
        "detail_url": string
        "html_url": string
        "slug": string
        "show_in_menus": boolean
        "seo_title": string
        "search_description": string
        "first_published_at": string
        // "alias_of": null,
        "parent": {
        "id": number
            "meta": {
            "type": string
                "detail_url": string
                "html_url": string
        },
        "title": string
    }
},
    "title": string
    "body": string
    "media": {
        "video": {
            "frame_image": {
                "mime_type": string
                "url": string
                "key": string
                "orientation": string
            },
            "stream": {
                "mime_type": string
                "url": string
                "key": string
                "orientation": string
            },
            "duration": number
            "mime_type": string
            "url": string
            "key": string
            "orientation": string
        },
        "subscription_level": string
        "surl"?: string
    } | null,
    "tags": string[]
    "isPreview": boolean
}

export interface VideoFeedEntryPage{
    "id": number,
    "meta": {
        "type": string,
        "detail_url": string,
        "html_url": string,
        "slug": string,
        "show_in_menus": boolean,
        "seo_title": string,
        "search_description": string,
        "first_published_at": string,
        // "alias_of": null,
        "parent": {
            "id": number,
            "meta": {
                "type": string,
                "detail_url": string,
                "html_url": string,
            },
            "title": string,
        }
    },
    "title": string,
    "body": string,
    "mp4_url": string,
    "stream_url": string,
    "first_frame_url": string,
    "video_orientation": string,
    "reactions": {
        "count": number,
        "user_has_reacted": boolean
        "like_failures": number | undefined //to be incremented on 400 like requests
    },
    "tags": string[],
    "isPreview": boolean
}

export interface UserQuestionPage {
    "id": number,
    "meta": {
        "type": string,
        "detail_url": string,
        "html_url": string,
        "slug": string,
        "show_in_menus": boolean,
        "seo_title": string,
        "search_description": string,
        "first_published_at": string,
        // "alias_of": null,
        "parent": {
            "id": number,
            "meta": {
                "type": string,
                "detail_url": string,
                "html_url": string,
            },
            "title": string,
        }
    },
    "title": string,
    "headline_text": string,
    "post_submission_heading": string,
    "post_submission_text": string,
    "response_data": {
        "submission_url": string,
        "user_has_submitted": boolean
        "user_just_submitted"?: boolean
    },
    "isPreview": boolean
}

export interface PollPageOption {
    "id": number
    "sort_order": number
    "option": string
    "votes": number
    "percentage": number
    "selected": boolean
}

export interface UserPollPage {
    "id": number,
    "meta": {
        "type": string,
        "detail_url": string,
        "html_url": string,
        "slug": string
        "show_in_menus": boolean,
        "seo_title": string,
        "search_description": string,
        "first_published_at": string,
        // "alias_of": null,
        "parent": {
            "id": number,
            "meta": {
                "type": string,
                "detail_url": string,
                "html_url": string,
            },
            "title": string,
        }
    },
    "title": string,
    "options": PollPageOption[],
    "response_data": {
        "submission_url": string,
        "user_has_submitted": boolean,
        "time_remaining": {
            "days": number,
            "hours": number
        },
        "user_is_submitting"?: boolean
    },
    "isPreview": boolean
}

export interface LikertScalePage {
    "id": number,
    "meta": {
        "type": string
        "detail_url": string,
        "html_url": string,
        "slug": string,
        "show_in_menus": boolean,
        "seo_title": string,
        "search_description": string,
        "first_published_at": string,
        //"alias_of": null,
        "parent": {
            "id": number
            "meta": {
                "type": string,
                "detail_url": string,
                "html_url": string,
            },
            "title": string,
        }
    },
    "title": string
    "question": string,
    "response_data": {
        "submission_url": string,
        "user_has_submitted": boolean
    },
    "isPreview": boolean
}

interface CarousalItem {
    "id": number,
    "meta": {
        "type": string,
    },
    "image": {
        "id": number,
        "meta": {
            "type": string
            "detail_url": string,
            "download_url": string,
        },
        "title": string
    },
    "caption": string,
}

export interface ImageCarouselFeedEntryPage {
    "isPreview": boolean
    "id": number,
    "meta": {
        "type": string,
        "detail_url": string,
        "html_url": string,
        "slug": string,
        "show_in_menus": boolean,
        "seo_title": string,
        "search_description": string,
        "first_published_at": string,
        // "alias_of": null,
        "parent": {
            "id": number,
            "meta": {
                "type": string,
                "detail_url": string,
                "html_url": string,
            },
            "title": string,
        }
    },
    "title": string,
    "body": string,
    "carousel_items": CarousalItem[],
    "reactions": {
        "count": number,
        "user_has_reacted": boolean
        "like_failures": number | undefined //to be incremented on 400 like requests
    },
    // "tags": []
}

export interface ContentTags {
    "all": string[]
    "general": string[]
    "age_related": string[]
}

export const getTagsThunk = createAsyncThunk<ContentTags, void>(
    'content/getTags',
    async () => {
        const gotten = await getTags();
        return gotten as ContentTags;
    }
)

export interface AcademyContentResponse {
    "meta": {
        "total_count": string
    },
    "items": FeedItemPreview[],
    "resetItems"?: boolean
}

export interface AcademyItemResponse {
    "status": number,
    "data": FeedItem
}

export interface queryParams {
    "offset": number,
    "limit": number,
    "tags"?: string[],
    "resetItems"?: boolean,
}


export const getAcademyItemThunk = createAsyncThunk<AcademyItemResponse, string>(
    'content/getAcademyItem',
    async (args) => {
        console.log(args)
        const gotten = await getAcademyItem(args);
        return gotten as AcademyItemResponse;
    }
)

export const getTrainingItemThunk = createAsyncThunk<AcademyItemResponse, string>(
    'content/getTrainingItem',
    async (args) => {
        console.log(args)
        const gotten = await getAcademyItem(args);
        return gotten as AcademyItemResponse;
    }
)

export const upgradeItemPreviewThunk = createAsyncThunk<AcademyItemResponse, string>(
    'content/upgradeItemPreview',
    async (args) => {
        console.log(args)
        const gotten = await getAcademyItem(args);
        return gotten as AcademyItemResponse;
    }
)
export const getAcademyContentThunk = createAsyncThunk<AcademyContentResponse, queryParams|void>(
    'content/getAcademyUpdates',
    async (args) => {
        console.log(args)
        const params = args ? args : undefined
        const gotten = await getAcademyContent(params);
        return gotten as AcademyContentResponse;
    }
)

type TrainingContentThunkArgs = {
    params?: queryParams;
    hasActiveProSubscription: boolean;
};

export const getTrainingContentThunk = createAsyncThunk<AcademyContentResponse, TrainingContentThunkArgs>(
    'content/getTrainingContent',
    async ({ params, hasActiveProSubscription }) => {
        console.log(params);
        const gotten = await getTrainingContent(params, hasActiveProSubscription);
        return gotten as AcademyContentResponse;
    }
);

export interface ProVideoSUrlResponse{
    media?: ProVideoSecureMedia
    status: number
}
export interface ProVideoSecureMedia {
    videoPageId: number
    "media": {
        "video": {
            "frame_image": {
                "mime_type": string,
                "url": string,
                "key": string,
            },
            "stream": {
                "mime_type": string,
                "url": string
            },
            "duration": number,
            "orientation": string
        }
    }
}

export interface GetProVideoSecureUrlArgs{
    videoPageId: number
    surl: string
}

export const getProVideoSecureUrlThunk = createAsyncThunk<ProVideoSUrlResponse, GetProVideoSecureUrlArgs>(
    'training/getProVideoSecureUrl',
    async (args) => {
        const gotten = await getProVideoSecureMedia(args);
        return gotten as ProVideoSUrlResponse;
    }
)

export interface UserFeedbackOptionSubmissionArgs {
    id: number
    value: number
    comment? : string
    destination: string
}

export interface UserQuestionSubmissionArgs {
    id: number
    question: string
    destination: string
}

export const submitUserPollResponse = createAsyncThunk<PutUserFeedbackResponse, UserFeedbackOptionSubmissionArgs>(
    'content/putUserPollSubmission',
    async (args) => {
        console.log(args)
        const gotten = await putUserPollSubmission(args.id, args.value, args.destination)
        return gotten as PutUserFeedbackResponse;
    }
)

export const submitLikertResponse = createAsyncThunk<PutUserFeedbackResponse, UserFeedbackOptionSubmissionArgs>(
    'content/putLikertResponse',
    async (args) => {
        console.log(args)
        const gotten = await putLikertResponse(args.id, args.value, args.comment || "", args.destination)
        return gotten as PutUserFeedbackResponse;
    }
)

export const submitUserQuestionResponse = createAsyncThunk<PutUserFeedbackResponse, UserQuestionSubmissionArgs>(
    'content/putUserQuestionResponse',
    async (args) => {
        console.log(args)
        const gotten = await putUserQuestionResponse(args.id, args.question, args.destination)
        return gotten as PutUserFeedbackResponse;
    }
)


export const likeItemThunk = createAsyncThunk<PutLikeResponse, number>(
    'content/likeItem',
    async (args) => {
        console.log(args)
        const gotten = await putLikeItem(args);
        return gotten as PutLikeResponse;
    }
)

export const getInitialPageLimit = () => {
    return 6
}

export const getInitialTrainingPageLimit = () => {
    return 6
}

interface academyContentState {
    trainingVideoSecureMedia?: ProVideoSecureMedia
    trainingVideoFetchingSecureMedia: boolean
    trainingItem?: FeedItem
    fetchingTrainingItem: boolean
    trainingFeedItems: FeedItem[]
    fetchingTrainingItems: boolean
    fetchingTrainingPage: number
    trainingItemLimit: number
    totalTrainingFeedItems: number
    item?: FeedItem
    fetchingItem: boolean
    academyFeedItems: FeedItem[]
    fetchingAcademyItems: boolean
    fetchingAcademyPage: number
    academyItemLimit: number
    totalAcademyFeedItems: number
    sendingLikeTo: number
    // sendingLikertTo: number
    sendingUserQuestionTo: number
    sendingPollResponseTo: number
    tags: ContentTags
}

const initialState: academyContentState = {
    trainingVideoSecureMedia: undefined,
    trainingVideoFetchingSecureMedia: false,
    trainingItem: undefined,
    fetchingTrainingItem: false,
    trainingFeedItems: [],
    fetchingTrainingItems: false,
    fetchingTrainingPage: 0,
    trainingItemLimit: getInitialTrainingPageLimit(),
    totalTrainingFeedItems: 0,
    item: undefined,
    fetchingItem: false,
    academyFeedItems: [],
    fetchingAcademyItems: false,
    fetchingAcademyPage: 0,
    academyItemLimit: getInitialPageLimit(),
    totalAcademyFeedItems: 0,
    sendingLikeTo: 0,
    // sendingLikertTo: 0,
    sendingUserQuestionTo: 0,
    sendingPollResponseTo: 0,
    tags: {"all": [], "general": [], "age_related": [] }
}

export const academyContentSlice = createSlice({
    name: 'academyContent',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setUserQuestionDone: (state: academyContentState, action: PayloadAction<number>) => {
            const items = state.academyFeedItems
            state.academyFeedItems = items.map((i => {
                if(i.id === action.payload){
                    const item = i as UserQuestionPage
                    return {
                        ...item,
                        response_data: {
                            ...item.response_data,
                            user_just_submitted: false,
                        }
                    }
                }else{
                    return i
                }
            }))
            console.log(state.academyFeedItems)
        },
        setSendingRollResponseTo: (state: academyContentState, action: PayloadAction<number>) => {
            state.sendingPollResponseTo = action.payload
        },
        setSendingLikeTo: (state: academyContentState, action: PayloadAction<number>) => {
            state.sendingLikeTo = action.payload
        },
        // setSendingLikertTo: (state: academyContentState, action: PayloadAction<number>) => {
        //     state.sendingLikertTo = action.payload
        // },
        setSendingUserQuestionTo: (state: academyContentState, action: PayloadAction<number>) => {
            state.sendingUserQuestionTo = action.payload
        },
        resetTrainingPage: (state: academyContentState, _action: PayloadAction<void>) => {
            state.trainingFeedItems = []
            state.fetchingTrainingPage = 0
        },
        resetItems: (state: academyContentState) => {
            state.item = undefined
            state.academyFeedItems = []
            state.fetchingAcademyPage = 0
        },
        clearPosts: (state: academyContentState) => {
            state.item = undefined
            state.trainingItem = undefined
            state.trainingVideoSecureMedia = undefined
            state.fetchingAcademyPage = 0
            state.fetchingTrainingPage = 0
            state.fetchingItem = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(upgradeItemPreviewThunk.fulfilled, (state: academyContentState, action: PayloadAction<AcademyItemResponse>) => {
            if(action.payload.data.meta.type === cmsPageType.TrainingVideoPage){
                const trainingItems = state.trainingFeedItems
                state.trainingFeedItems = trainingItems.map(i =>  {
                        if(i.id === action.payload.data.id){
                            return {...action.payload.data, isPreview: false}
                        } else{
                            return i
                        }
                    }
                )
            }else{
                const items = state.academyFeedItems
                state.academyFeedItems = items.map(i =>  {
                        if(i.id === action.payload.data.id){
                            return {...action.payload.data, isPreview: false}
                        } else{
                            return i
                        }
                    }
                )
            }
        })
        builder.addCase(likeItemThunk.rejected, (state: academyContentState) => {
            console.log("rejected")
            const items = state.academyFeedItems
            const sendingLikeTo = state.sendingLikeTo
            state.academyFeedItems = items.map((i => {
                if(i.id === sendingLikeTo){
                    const item = i as ImageCarouselFeedEntryPage
                    return {
                        ...item,
                        reactions: {
                            ...item.reactions,
                            like_failures: (item.reactions.like_failures || 0) + 1
                        }
                    }
                }else{
                    return i
                }
            }))
            console.log(state.academyFeedItems)
            state.sendingLikeTo = 0
        })
        builder.addCase(submitUserPollResponse.fulfilled, (state: academyContentState, action: PayloadAction<PutUserFeedbackResponse>) => {
            if(action.payload.status === 200){
                state.sendingPollResponseTo = 0
                const items = state.academyFeedItems
                state.academyFeedItems = items.map((i => {
                    if(i.id === action.payload.id){
                        const item = i as UserPollPage
                        return {
                            ...item,
                            response_data: {
                                ...item.response_data,
                                user_has_submitted: true,
                                user_is_submitting: true
                            }
                        }
                    }else{
                        return i
                    }
                }))
                console.log(state.academyFeedItems)
            }
        })
        builder.addCase(submitLikertResponse.fulfilled, (state: academyContentState, action: PayloadAction<PutUserFeedbackResponse>) => {
            if(action.payload.status === 200){
                const items = state.academyFeedItems
                state.academyFeedItems = items.map((i => {
                    if(i.id === action.payload.id){
                        const item = i as LikertScalePage
                        return {
                            ...item,
                            response_data: {
                                ...item.response_data,
                                user_has_submitted: true,
                            }
                        }
                    }else{
                        return i
                    }
                }))
                console.log(state.academyFeedItems)
            }
        })
        builder.addCase(submitUserQuestionResponse.fulfilled, (state: academyContentState, action: PayloadAction<PutUserFeedbackResponse>) => {
            if(action.payload.status === 200){
                state.sendingUserQuestionTo = 0
                const items = state.academyFeedItems
                state.academyFeedItems = items.map((i => {
                    if(i.id === action.payload.id){
                        const item = i as UserQuestionPage
                        return {
                            ...item,
                            response_data: {
                                ...item.response_data,
                                // user_has_submitted: true,
                                user_just_submitted: true,
                            }
                        }
                    }else{
                        return i
                    }
                }))
                console.log(state.academyFeedItems)
            }
        })
        builder.addCase(getTagsThunk.fulfilled, (state: academyContentState, action: PayloadAction<ContentTags>) => {
            state.tags = action.payload
        })
        builder.addCase(likeItemThunk.fulfilled, (state: academyContentState, _action: PayloadAction<PutLikeResponse>) => {
            state.sendingLikeTo = 0
        })
        builder.addCase(getTrainingContentThunk.pending, (state: academyContentState, _action: PayloadAction<void>) => {
            state.fetchingTrainingItems = true
        })
        builder.addCase(getTrainingContentThunk.fulfilled, (state: academyContentState, action: PayloadAction<AcademyContentResponse>) => {
            const items = !!action.payload.resetItems ? [] : state.trainingFeedItems
            const ids = items.map(i=>i.id)
            const newItems = items.concat(action.payload.items.filter(i => !ids.includes(i.id)))
            const thisPage = !!action.payload.resetItems ? 0 : state.fetchingTrainingPage
            console.log("fetching page number", state.fetchingTrainingPage)
            state.trainingFeedItems = newItems
            state.fetchingTrainingItems = false
            state.fetchingTrainingPage = ids.length <= newItems.length ? thisPage + 1 : thisPage
            state.totalTrainingFeedItems = Number(action.payload.meta.total_count) || 0
        })
        builder.addCase(getAcademyContentThunk.pending, (state: academyContentState, _action: PayloadAction<void>) => {
            state.fetchingAcademyItems = true
        })
        builder.addCase(getAcademyContentThunk.fulfilled, (state: academyContentState, action: PayloadAction<AcademyContentResponse>) => {
            const items = state.academyFeedItems
            const ids = items.map(i=>i.id)
            const newItems = items.concat(action.payload.items.filter(i => !ids.includes(i.id)))
            state.academyFeedItems = newItems
            state.fetchingAcademyItems = false
            state.fetchingAcademyPage = Math.floor(newItems.length / getInitialPageLimit() )
            // state.fetchingAcademyPage = ids.length <= newItems.length ? state.fetchingAcademyPage + 1 : state.fetchingAcademyPage
            state.totalAcademyFeedItems = Number(action.payload.meta.total_count) || 0
        })
        builder.addCase(getProVideoSecureUrlThunk.pending, (state: academyContentState) => {
            state.trainingVideoFetchingSecureMedia = true
        })
        builder.addCase(getProVideoSecureUrlThunk.fulfilled, (state: academyContentState, action: PayloadAction<ProVideoSUrlResponse>) => {
            console.log("secureMediaResponse", action.payload)
            state.trainingVideoSecureMedia = action.payload.media
            state.trainingVideoFetchingSecureMedia = false
        })
        builder.addCase(getProVideoSecureUrlThunk.rejected, (state: academyContentState) => {
            state.trainingVideoSecureMedia = undefined
            state.trainingVideoFetchingSecureMedia = false
        })
        builder.addCase(getAcademyItemThunk.pending, (state: academyContentState) => {
            state.fetchingItem = true
            state.fetchingTrainingItem = true
        })
        builder.addCase(getAcademyItemThunk.fulfilled, (state: academyContentState, action: PayloadAction<AcademyItemResponse>) => {
            state.fetchingItem = false
            state.fetchingTrainingItem = false
            if(action.payload.data.meta.type === cmsPageType.TrainingVideoPage)
                state.trainingItem = action.payload.data
            else
                state.item = action.payload.data
        })
    },
});

export const {clearPosts, resetTrainingPage, setUserQuestionDone, setSendingUserQuestionTo, setSendingRollResponseTo, setSendingLikeTo, resetItems} = academyContentSlice.actions;
export default academyContentSlice.reducer
