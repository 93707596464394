import {SignInMethod} from "../lib/analytics";
import {SocialProvider} from "../constants/social";

export const getAnalyticsProvider = (provider: string): SignInMethod | null => {
    switch (provider) {
        case SocialProvider.Facebook:
            return SignInMethod.Facebook;
        case SocialProvider.Apple:
            return SignInMethod.Apple;
        case SocialProvider.Google:
            return SignInMethod.Google;
        default:
            return null;
    }
}
