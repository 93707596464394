import React from 'react';
import {Card, CardMedia, CardContent, Typography, Box, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../constants/routes";
import {
    TrainingVideoPage,
} from "../../../features/academyContent/academyContentSlice";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface PostProps {
    item: TrainingVideoPage
    activateSubWallFn: () => void
    hasProSubscription: boolean
}

export const TrainingVideo: React.FC<PostProps> = (props: PostProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const postPath = routes.trainingVideo.replace(":pageId", props.item.id + "")
    const firstPublishedAt = new Date(props.item.meta.first_published_at);
    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    const isNew = firstPublishedAt >= oneWeekAgo;


    return (
        <Card raised sx={{ mx: 'auto', mt: 2, '&:hover': {boxShadow: "0px 3px 8px 2px #AAAAAA !important", cursor: "pointer"}}}
              onClick={()=> {
                  if((props.item.media?.subscription_level === "pro" && !props.hasProSubscription) || !props.item.media) {
                      props.activateSubWallFn()
                  }else {
                      navigate(postPath)
                  }
              }}>
            <Box position="relative">
                <CardMedia
                    component="img"
                    height="250"
                    image={ props.item.media ? props.item.media.video.frame_image.url : ""}
                    alt="TrainingVideo image"
                    sx={{
                        position: "relative"
                    }}
                />
                 {(props.item.media?.subscription_level === "pro" && 
                    <Box 
                            position="absolute" 
                            top="-4px"
                            right="-4px"
                            color="white"
                            bgcolor={theme.custom.proVideoLabelColor}
                            mr={2}
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            fontWeight="bold"
                            sx={{ paddingTop: '4px', paddingRight: '2px', paddingBottom: '4px', paddingLeft: '4px' }}
                            fontSize="10px"
                        >
                            PRO
                            { props.hasProSubscription ?
                                <PlayArrowIcon sx={{ fontSize: '16px' }} />
                                :
                                <LockOutlinedIcon sx={{ marginLeft: "2px", fontSize: '16px' }} />
                            }
                        </Box>
                    )}
                    {(props.item.media?.subscription_level === "free" && 
                        <Box 
                            position="absolute" 
                            top="-4px" 
                            right="-4px"
                            color="white"
                            bgcolor={theme.custom.freeVideoLabelColor}
                            mr={2}
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            fontWeight="bold"
                            fontSize="10px"
                            sx={{ paddingTop: '4px', paddingRight: '0px', paddingBottom: '4px', paddingLeft: '4px' }}
                        >
                            FREE
                            <PlayArrowIcon sx={{ fontSize: '16px' }} />
                        </Box>
                    )}
                    {(isNew && <Box 
                        position="absolute" 
                        top="-4px"
                        left="-4px" 
                        color="#012448"
                        bgcolor="white"
                        padding="4px"
                        ml={2}
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontWeight="bold"
                        fontSize="10px"
                    >
                        NEW
                    </Box>
                    )}
                    {/* <Box 
                        position="absolute" 
                        bottom={0} 
                        right={0} 
                        bgcolor="rgba(0, 0, 0, 0.5)" 
                        color="white" 
                        p={1}
                        borderRadius="4px"
                        mr={2}
                        mb={2}
                        height="25px"
                        width="55px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        00:24
                    </Box> */}
            </Box>
            <CardContent>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="body1" sx={[{
                            color: theme.custom.communityPostTextColor,
                            fontWeight: 'bold',
                            flexGrow: 1,
                            mr: 1
                        },
                            // {
                            //     '&:hover': {
                            //         cursor: 'pointer',
                            //     },
                            // }
                        ]}>
                            {props.item.title}
                        </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            marginTop: "4px",
                            color: "#666",
                            fontWeight: 400,
                            fontSize: "12px"
                        }}
                        dangerouslySetInnerHTML={{ __html: props.item.tags.join('&nbsp;&nbsp;&#8226;&nbsp;&nbsp;') }}
                    />
                </Box>
                {/*<Typography variant="caption" color="secondary">*/}
                {/*    {timeSince(props.item.meta.first_published_at)}*/}
                {/*</Typography>*/}
            </CardContent>
        </Card>
    );
};
