import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {PaymentMethod} from "@stripe/stripe-js";
import {generalMessage} from "../constants/common";

interface IStripeAddress {
    name: string;
    address: {
        line1: string;
        line2: string;
        city: string;
        country: string;
        postal_code: string;
        state: string;
    },
}
export const useViaStripe = () => {
    const stripe = useStripe();
    const elements = useElements();

    const createStripeCard = async (): Promise<PaymentMethod> => {
        if (!elements || !stripe) {
            throw new Error(generalMessage);
        }

        const addressValue = await elements.getElement('address')?.getValue();

        if (!addressValue) {
            throw new Error(generalMessage);
        }

        const billingDetails: IStripeAddress = {
            name: addressValue.value.name,
            address: {
                line1: addressValue.value.address.line1,
                line2: addressValue.value.address.line2 || "",
                city: addressValue.value.address.city,
                state: addressValue.value.address.state,
                postal_code: addressValue.value.address.postal_code,
                country: addressValue.value.address.country,
            },
        };

        try {
            // @ts-ignore
            const {paymentMethod, error} = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: billingDetails,
            });

            if (error) {
                throw new Error(error.message);
            }

            return paymentMethod;
        } catch (e: any) {
            throw new Error(e.message?? generalMessage);
        }
    }

    const confirmStripePayment = async (clientSecretId: string, cardId: string) => {
        if (!stripe) {
            return;
        }

        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecretId, {
            payment_method: cardId,
        });

        if (error) {
            throw new Error(error.message);
        }

        if (paymentIntent?.status !== "succeeded") {
            throw new Error("Stripe confirmation failed");
        }
    }

    return [createStripeCard, confirmStripePayment];
};
