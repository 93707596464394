import React from "react"
import {Box, styled, Typography} from "@mui/material";
import {MobileDownloadButtons} from "../../../../shared/MobileDownloadButtons";
import {DeviceType, getDeviceType} from "../../../../util/userAgent";

const HeaderTypography = styled(Typography)(() => ({
    fontSize: "25px",
    fontWeight: "700",
    textAlign: "center",
}));
const ContentBox = styled(Box)(({theme}) => ({
    flexDirection: "column",
    color: theme.palette.primary.contrastText,
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "center",
    marginTop: "15px"
}));
const StyledImg = styled("img")(() => ({
    maxWidth: "120px",
    margin: "30px 0"
}));
const StyledTypography = styled(Typography)(() => ({
    fontSize: "14px",
    fontWeight: "500",
}));

export const ResetModalContent = () => {
    return (
        <Box>
            <HeaderTypography color="common.black">Reset Password</HeaderTypography>
            <ContentBox>
                <StyledTypography>
                    {getDeviceType() === DeviceType.Desktop ? (
                        "Please reset your password on the VIA Academy app by scanning the QR code below, or by manually opening the app on your phone."
                    ): (
                        "Please open the VIA Academy app on your phone and reset your password in your Profile Settings."
                    )}
                </StyledTypography>
                {getDeviceType() === DeviceType.Desktop ? (
                    <StyledImg src={process.env.PUBLIC_URL + "/download_qr.svg"} alt="Download app"/>
                ) : (
                    <MobileDownloadButtons/>
                )}
            </ContentBox>
        </Box>
    );
}
