import {useCallback} from "react";
import {Stage} from "../constants/purchase";
import {MediaUploadStatus, updateUploadStatus} from "../features/media/mediaSlice";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {ICoachFeedbackResult} from "../types/coachFeedback";
import {RootState} from "../redux/store";
import {ICompleteUploadStepArgs} from "../features/purchase/upload-video/UploadVideoStep";
import {useQuery} from "./useQuery";
import {editReason} from "../constants/urlParameters";
import useQueryEditReasons from "./useQueryEditReasons";

interface IMountEditReasonStageProps {
    setPurchaseStage: (purchaseStage: Stage) => void;
    setVideoPreviewUrl: (url: string) => void;
    setUploadVideoStepData: (uploadStepArgs: ICompleteUploadStepArgs) => void;
    setImprovementAreasStepData: (list: string[]) => void;
}
const useEditOrderMountStage = ({setPurchaseStage, setVideoPreviewUrl, setUploadVideoStepData, setImprovementAreasStepData}: IMountEditReasonStageProps) => {
    const dispatch = useAppDispatch();
    const editReasons = useQuery().get(editReason)?.split(",").join(", ");
    const modifyingOrder = useAppSelector<ICoachFeedbackResult | null>((state: RootState) => state.orderSlice.modifyingOrder);
    const [has, , first] = useQueryEditReasons();

    const setStagesData = useCallback(() => {
        if (!modifyingOrder) return;

        if (!has.videoReason && modifyingOrder.video) {
            setVideoPreviewUrl(modifyingOrder.video.url);
            dispatch(updateUploadStatus(MediaUploadStatus.Success));
        }

        if (has.identifyingCommentReason) {
            setUploadVideoStepData({
                otherPlayersExist: "Yes",
                otherPlayersComment: ""
            });
        } else {
            setUploadVideoStepData({
                otherPlayersExist: modifyingOrder.othersInVideo ? "Yes" : "No",
                otherPlayersComment: modifyingOrder.othersInVideo ? modifyingOrder.identificationNotes : ""
            });
        }

        setImprovementAreasStepData(modifyingOrder.improvementAreas);
    }, [has.videoReason, has.identifyingCommentReason, modifyingOrder, setVideoPreviewUrl, setUploadVideoStepData, setImprovementAreasStepData, dispatch]);

    const mountEditReasonStage = useCallback(() => {
        if (first.identifyingComment) {
            setPurchaseStage(Stage.uploadVideo);
            setStagesData();
            return;
        }

        if (first.videoQuality) {
            setPurchaseStage(Stage.uploadVideo);
            setStagesData();
            return;
        }

        if (first.improvementAreas) {
            setPurchaseStage(Stage.improvementAreas);
            setStagesData();
            return;
        }

        throw new Error(`Edit reason handler was not set, ${editReasons}`);
    }, [first.identifyingComment, first.videoQuality, first.improvementAreas, editReasons, setPurchaseStage, setStagesData]);

    return [mountEditReasonStage] as const;
};

export default useEditOrderMountStage;
