import React from 'react'
import {Container, styled} from "@mui/material";

interface ViaContainerProps {
    children: React.ReactNode
    className: string
}

const StyledContainer = styled(Container)(() => ({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    padding: 0
}));
export const ViaContainer = (props: ViaContainerProps): JSX.Element => {

    return (
        <StyledContainer maxWidth="sm" className={props.className}>
            {props.children}
        </StyledContainer>

    )

}
