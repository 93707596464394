import React, {useState} from "react";
import {Box, Button, styled, useTheme} from "@mui/material";
import {SocialProvider} from "../../../constants/social";
import {Analytics} from "../../../lib/analytics";
import {signOut, socialSignIn} from "../../../services/cognito";
import {setRedirectAfterLogin, setSignupMode} from "../../../util/storage";
import {usePurchaseNavigate} from "../../../hooks/usePurchaseNavigate";

const SocialChoiceGroup = styled(Box)(() => ({
    width: "100%",
    margin: "32px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    gap: "8px",
}));
const SocialChoiceButton = styled(Button)(({theme}) => ({
    [theme.breakpoints.up("xs")]: {
        margin: "0 7%",
        padding: "10px 7%",
    },
    [theme.breakpoints.up("sm")]: {
        margin: "0 14%",
        padding: "10px 12%",
    },
    borderRadius: "24px",
    textAlign: "center",
    justifyContent: "flex-start",
    borderColor: theme.custom.wl.signIn.buttonBorderColor, // Set the border color
    backgroundColor: "#FFFFFF", // Set the background color to match the border
    color: theme.custom.darkerPrimaryColor, // Set text color to contrast with the background
    '&:hover': {
        backgroundColor: (theme.custom.wl.signIn.buttonBackgroundColor || theme.custom.darkerPrimaryColor) + "!important", // Change background color on hover
        borderColor: theme.custom.wl.signIn.buttonBackgroundColor, // Set the border color
        color: "#FFFFFF",
    },
}));
const StyledImg = styled("img")(() => ({
    width: "24px",
    height: "24px",
    margin: "0 10px",
}));
const StyledButtonText = styled("div")(() => ({
    textAlign:'center',
    margin: '0 auto',
    fontWeight: 600,
}));

interface SocialSignInGroupProps {
    dateOfBirth: number;
}
export const SocialSignInGroup = (props: SocialSignInGroupProps): React.ReactElement => {

    const theme = useTheme();
    const [, deletePurchaseFlowData ] = usePurchaseNavigate();

    const signIn = async (provider: string, isSSO: boolean) => {
        if(isSSO){
            Analytics.signInOrSignUpClubLoginButtonClick()
        }else{
            Analytics.selectSocialLogin(provider)
        }
        deletePurchaseFlowData()
        await signOut()
        setRedirectAfterLogin()
        setSignupMode(props.dateOfBirth, false)
        socialSignIn(provider)
    }

    const [hoveringOver, setHoveringOver] = useState<string>("")

    interface SignInButtonValues {
        provider: string
        logoPath: string
        alt: string
        buttonText: string
        isSSO: boolean
    }

    let buttonValues: SignInButtonValues[] = [
        {
            provider: SocialProvider.Facebook,
            logoPath: process.env.PUBLIC_URL + "/social/fb.svg",
            alt: "Facebook",
            buttonText: "Continue with Facebook",
            isSSO: false
        },
        {
            provider: SocialProvider.Apple,
            logoPath: process.env.PUBLIC_URL + ( hoveringOver !== SocialProvider.Apple ? "/social/apple-dark.svg" : "/social/apple.svg"),
            alt: "Apple",
            buttonText: "Continue with Apple",
            isSSO: false,
        },
        {
            provider: SocialProvider.Google,
            logoPath: process.env.PUBLIC_URL + "/social/google.svg",
            alt: "Google",
            buttonText: "Continue with Google",
            isSSO: false,
        },
    ]

    if(theme.custom.name === "qpr"){
        buttonValues.push({
            provider: SocialProvider.KOREQPR,
            logoPath: process.env.PUBLIC_URL + "/club_logos/qpr_logo.png",
            alt: "QPR",
            buttonText: "Continue with QPR Login",
            isSSO: true,
        })
    }

    if(theme.custom.name === "pufc"){
        buttonValues.push({
            provider: SocialProvider.ClubcastPUFC,
            logoPath: process.env.PUBLIC_URL + "/club_logos/pb_logo.png",
            alt: "PUFC",
            buttonText: "Continue with PUFC Login",
            isSSO: true,
        })
    }


    return (
        <>
            <SocialChoiceGroup>
                <>
                    { buttonValues.map((b: SignInButtonValues)  => {
                        return (
                            <SocialChoiceButton
                                onMouseLeave={() => {
                                    setHoveringOver("")
                                }}
                                onMouseOver={() => {
                                    setHoveringOver(b.provider)
                                }}
                                variant="outlined"
                                color='inherit'
                                onClick={async () => {await signIn(b.provider, b.isSSO)}}
                            >
                                <StyledImg src={b.logoPath} alt={b.alt}/>
                                <StyledButtonText>{b.buttonText}</StyledButtonText>
                            </SocialChoiceButton>
                        )
                    })}
                </>
            </SocialChoiceGroup>
        </>
    );
}
