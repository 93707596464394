import React, {useEffect, useState} from "react"
import {Box, Button, ButtonProps, IconButton, Link, LinkProps, Modal, styled} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";

interface IModalProps {
    buttonType?: ButtonType;
    buttonContent?: JSX.Element;
    buttonProps?: ButtonProps | LinkProps;
    darkModal?: boolean;
    children: JSX.Element;
    actionContent?: JSX.Element;
    blockClickOutside?: boolean;
    controlledOpen?: boolean;
    onControlledToggleModal?: () => void;
    isInlineLink?: boolean;
    paddingOverride?: string;
}

type StyledBoxProps = {
    darkmodal?: string;
};
const StyledBox = styled(("div"))<StyledBoxProps>(({theme, darkmodal}) => ({
    overflow: "auto",
    maxHeight: "90vh",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "375px",
    backgroundColor: darkmodal ? theme.palette.common.black : theme.palette.background.paper,
    borderRadius: "16px",
    padding: "24px 16px",
    [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 50px)",
        maxWidth: "calc(100% - 50px)",
        maxHeight: "calc(100% - 50px)",
    },
    "p, div": {
        color: darkmodal ? theme.palette.text.primary : theme.palette.primary.contrastText,
    },
    border: darkmodal ? `3px solid ${theme.palette.divider}` : "",
}));
const IconBox = styled(Box)(() => ({
    textAlign: "center",
    padding: "10px 0",
}));
const StyledIconButton = styled(IconButton)<StyledBoxProps>(({theme, darkmodal}) => ({
    backgroundColor: darkmodal ? theme.palette.grey[700] : theme.palette.grey[200],
    color: theme.palette.common.black,
    "&:hover": {
        color: theme.palette.primary.contrastText,
    },
}));
export const enum ButtonType {
    Button,
    Link
}

export const BasicModal = ({buttonType = ButtonType.Button, buttonContent, buttonProps, darkModal = false, children, actionContent, blockClickOutside = false, controlledOpen, onControlledToggleModal, isInlineLink, paddingOverride}: IModalProps) => {
    const [open, setOpen] = useState(!buttonContent);
    const toggleModal = () => {
        if (blockClickOutside) {
            return;
        }
        if (onControlledToggleModal) {
            onControlledToggleModal();
            return;
        }
        setOpen(!open);
    }

    useEffect(() => {
        if (controlledOpen !== undefined) {
            setOpen(controlledOpen);
        }
    }, [controlledOpen])

    return (
        <div style={(isInlineLink ? {display: "inline-block"} : {}) }>
            {buttonType === ButtonType.Button && (
                <Button onClick={toggleModal} {...buttonProps as ButtonProps}>
                    {buttonContent}
                </Button>
            )}
            {buttonType === ButtonType.Link && (
                <Link onClick={() => toggleModal()} {...buttonProps as LinkProps}>
                    {buttonContent}
                </Link>
            )}
            <Modal disableAutoFocus disableEnforceFocus open={open} onClose={toggleModal}>
                <StyledBox
                    darkmodal={darkModal ? darkModal.toString() : undefined}
                    sx={
                        !!paddingOverride ? {padding: paddingOverride} : {}
                    }

                >
                    {children}
                    <IconBox component="div">
                        {
                            actionContent ?
                                <>{actionContent}</>
                                :
                                <StyledIconButton size="small" onClick={toggleModal} darkmodal={darkModal ? darkModal.toString() : undefined}>
                                    <CloseRounded/>
                                </StyledIconButton>
                        }
                    </IconBox>
                </StyledBox>
            </Modal>
        </div>
    );
}
