import React, {useEffect, useRef, useState} from 'react'
import {Checkbox, CircularProgress, FormGroup, styled, TextField, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {clearCoupon} from "../../../util/storage";

const StyledCouponCheckboxText = styled(Typography)(() => ({
    color: "#EEEEEE",
    fontSize: "14px",
    margin: "18px 0",
    "&:hover": {
        cursor: "pointer",
    }
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({theme}) => ({
    color: theme.palette.primary.main,
}));

interface CouponCodeTextBoxProps {
    couponsFetching: boolean
    couponsSuccess: boolean
    couponsFailure: boolean
    setCouponCode: (code: string) => void
    couponCode: string
    resetCoupon: () => void
}


export const CouponCodeTextBox = (props: CouponCodeTextBoxProps) => {

    const [showCouponInput, setShowCouponInput] = useState(!!props.couponCode.length)
    const textFieldRef = useRef<HTMLInputElement>(null)

    const updateCheckBox = (checked: boolean): void => {
        setShowCouponInput(checked)
        if(!checked){
            props.resetCoupon()
        }
    }

    const handleCheckBox = (e: React.FormEvent<HTMLInputElement>) => {
        const checked = e.currentTarget.checked
        updateCheckBox(checked)
    }

    useEffect(()=>{
        if(props.couponsSuccess){
            textFieldRef.current?.blur()
        }
    },[props.couponsSuccess])

    useEffect(()=> {if(showCouponInput){
        textFieldRef.current?.focus()
    }}, [textFieldRef, showCouponInput])

    return (
            <FormGroup sx={{mt: "10px", pr: 0}}>
                <div style={{display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "flex-start", margin: "4px 0 0 0", minHeight: "57px"}}>
                <Checkbox key={Math.random()} onChange={handleCheckBox} defaultChecked={showCouponInput} />
                    { !showCouponInput ?
                        <StyledCouponCheckboxText onClick={() => {
                            setShowCouponInput(true)
                        }}>I have a promo code</StyledCouponCheckboxText>
                        :
                        <div id="promo-code-input">
                            <TextField
                                inputRef={textFieldRef}
                                label="Enter promo code"
                                onChange={event => {
                                    const newCode = event.currentTarget.value
                                    props.setCouponCode(newCode)
                                    if(newCode.length < 6){
                                        clearCoupon()
                                    }
                                }}
                                defaultValue={props.couponCode || ""}
                                error={!props.couponsFetching && props.couponsFailure}
                                helperText={!props.couponsFetching && props.couponsFailure ? "Please enter a valid promo code": ""}
                                sx={{
                                    height: "50px",
                                    width: "100%",
                                    margin: 0,
                                    color: "#414141",
                                    '& label.Mui-focused': {
                                        color: !props.couponsFetching && props.couponsFailure ? "#FF6E6E" : '#AAAAAA',
                                    },
                                    '& label.Mui-root': {
                                        color: !props.couponsFetching && props.couponsFailure ? "#FF6E6E" : '#AAAAAA',
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: !props.couponsFetching && props.couponsFailure ? "#FF6E6E" : "#414141",
                                        },
                                    },
                                    // textTransform: "none",
                                    borderColor: "#FFFFFF"}}
                                inputProps={{style: {textTransform: "uppercase"}}}
                                fullWidth
                            ></TextField>

                            <span className="promo-code-status-icon" onClick={event =>{event.preventDefault()}}>
                                {props.couponsFetching ?
                                    <CircularProgress/>
                                    :
                                    props.couponsSuccess ?
                                        <StyledCheckCircleIcon/>
                                        : null
                                }

                            </span>
                        </div> }
                </div>
            </FormGroup>
    )

}