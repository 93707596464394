import React, {useState, useEffect, useRef} from 'react'
import {Box, styled, Typography} from "@mui/material";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import ScheduleIcon from '@mui/icons-material/Schedule';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Analytics} from "../../../lib/analytics";

import {useTheme} from "@mui/material";
import {prefaceWithArticle} from "../../../lib/utils";
import {maxFileSize, maxVideoLengthInMinutes} from "../../../features/media/FeedbackUploadZone";
import {getCFMaxFileSize, getCFMaxLength} from "../../../util/storage";
import {formatBytes} from "../../../util/file";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const StyledGuidelineText = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 0 16px 0", //bottom
}));

const StyledFAQItemBox = styled(Box)(() => ({
    marginTop: "16px",
    borderBottom: "0.5px solid #B3BDC8",
}));

const StyledFAQQuestionContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer',
    }

}))

const StyledFAQQuestion = styled(StyledGuidelineText)(() => ({
    flexGrow: 1,
    padding: "8px 0"
}));


const StyledExampleHeadline = styled(Typography)(() => ({
    fontSize: "14px",
    fontWeight: "500",
    padding: "0 0 8px 0",
}));

const WhiteBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.custom.isWhiteLabelled ? "#FFFFFF" : "#000000",
    fontSize: "16px",
    fontWeight: "400",
    color: theme.custom.darkerPrimaryColor,
    padding: "16px",
    '& p': {
        color: theme.custom.darkerPrimaryColor + " !important",
    },
    '& .MuiSvgIcon-root': {
        color: theme.custom.darkerPrimaryColor + " !important",
    }
}));

const LightGreyBox = styled(WhiteBox)(({theme}) => ({
    backgroundColor: theme.custom.isWhiteLabelled ? "#EFEFEF" : "#121212"
}))

const HeaderColorBox = styled(Box)(({theme}) => ({
    borderTopStyle: "solid",
    borderTopWidth: "1px",
    borderTopColor: "#AAAAAA",
    backgroundColor: theme.custom.headerBrandTitleBackgroundColor,
    fontSize: "16px",
    color: "#FFFFFF !important",
    fontWeight: "400",
    padding: "16px",
    '& p': {
        color: "#FFFFFF !important",
    },
    '& .MuiSvgIcon-root': {
        color: "#FFFFFF !important",
    }
}));

const DarkBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.custom.darkerPrimaryColor,
    fontSize: "16px",
    color: "#FFFFFF !important",
    fontWeight: "400",
    padding: "16px",
    '& p': {
        color: "#FFFFFF !important",
    },
    '& .MuiSvgIcon-root': {
        color: "#FFFFFF !important",
    }
}));

const StyledTitle = styled(Typography)(() => ({
    fontSize: "32px",
    fontWeight: "700",
    padding: "0 0 16px 0",
}));

const StyledHeadline = styled(Typography)(() => ({
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    marginTop: "8px",
    marginBottom: "16px",
}));

const StyledL3Headline = styled(Typography)(() => ({
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "8px",
    marginBottom: "16px",
}));

const StyledColBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: "0.5px",
    borderColor: "#B3BDC8",
    padding: "8px 16px",
    borderRadius: "4px",
    color: theme.custom.darkerPrimaryColor,
}))

const StyledRowBox = styled(StyledColBox)(() => ({
    flexDirection: "row",
    padding: "16px",
    gap: "8px",
}))

const StyledDarkModeRowBox = styled(StyledRowBox)(() => ({
    flexDirection: "row",
    padding: "16px",
    gap: "8px",
    borderColor: "#FFFFFF",
    color: "#FFFFFF"
}))

const StyledDarkModeRowBoxText = styled(Typography)(() => ({
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
}))

const StyledColBoxText = styled(Typography)(() => ({
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 400,
}))

const StyledRowBoxText = styled(Typography)(() => ({
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
}))

const HiddenFAQTypography = styled(Box)(() => ({
    paddingLeft: "16px",
    overflow: 'hidden',
    maxHeight: 0, // Start with 0 max-height
    // opacity: 0,
    transition: 'all 0.3s ease-out',
    '&.visible': {
        maxHeight: '800px', // Adjust based on your content needs
        // opacity: 1,
    }
}));

interface FeedbackGuidelinesProps {
    cfPrice?: string
}


export const FeedbackGuidelines = (props: FeedbackGuidelinesProps): React.ReactElement => {
    const [visibleFAQs, setVisibleFAQs] = useState<number[]>([])

    const handleFAQClick = (itemNumber: number): void => {
        if(visibleFAQs.includes(itemNumber)){
            setVisibleFAQs(visibleFAQs.filter((i:number) => i !== itemNumber ))
        }else
            setVisibleFAQs(visibleFAQs.concat([itemNumber]))
    }

    const faqIsVisible = (itemNumber: number): boolean => {
        return visibleFAQs.includes(itemNumber)
    }
    
    useEffect(() => {
        Analytics.videoGuidelinesView();
    },[])

    const theme = useTheme();
    const academyName = theme.custom.isWhiteLabelled ? theme.custom.academyCommonName : "Academy"
    const coach = theme.custom.isWhiteLabelled ? theme.custom.academyCommonShortName + " coach" : "coach"
    const supportEmail = theme.custom.isWhiteLabelled ? theme.custom.wl.supportEmail : "hello@viaacademy.com"
    const sectionRef = useRef<HTMLDivElement>(null)

    return(
        <>
            { !!theme.custom.coachFeedbackPromoPoster ?
                <HeaderColorBox>
                    <StyledTitle color={"white"} className="use-header-font">FEEDBACK</StyledTitle>
                    <StyledGuidelineText>Personalised feedback and analysis on your videos from {academyName} coaches!</StyledGuidelineText>
                    <Box
                        onClick={()=>{
                            sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
                        }}
                        sx={{
                            "&:hover":{
                                cursor: "pointer"
                            }
                        }}
                    >
                        <img
                            style={{
                                maxWidth: "100%"
                            }}
                            src={theme.custom.coachFeedbackPromoPoster}
                            alt={theme.custom.academyCommonName + " Feedback Poster"}
                        />
                        <Typography
                            sx={{
                                marginTop: "16px",
                                fontSize: "16px",
                                lineHeight: "19px",
                                fontWeight: 400,
                                textAlign: "center"
                            }}
                        >LEARN MORE<br/><ArrowDownwardIcon/></Typography>
                    </Box>
                </HeaderColorBox>
                :
                <WhiteBox>
                    <StyledTitle color={"secondary"} className="use-header-font">FEEDBACK</StyledTitle>
                    <StyledGuidelineText>Personalised feedback and analysis on your videos
                        from {academyName} coaches!</StyledGuidelineText>
                    <video
                        controls
                        playsInline
                        src={theme.custom.coachFeedbackVideoSampleLink}
                        poster={theme.custom.coachFeedbackVideoSampleThumbnailLink}
                        style={{
                            width: "100%",
                            borderRadius: "4px",
                            // margin: "3%",
                        }}
                    />
                </WhiteBox>
            }
            <DarkBox>
                {!!theme.custom.coachFeedbackPromoPoster ?
                    <>
                    <StyledTitle ref={sectionRef} color={"white"} className="use-header-font">WHAT'S ACADEMY FEEDBACK?</StyledTitle>
                    <video
                        controls
                        playsInline
                        src={theme.custom.coachFeedbackVideoSampleLink}
                        poster={theme.custom.coachFeedbackVideoSampleThumbnailLink}
                        style={{
                            width: "100%",
                            borderRadius: "4px",
                            // margin: "3%",
                        }}
                    />
                    </>
                    : <StyledHeadline>What’s Academy Feedback?</StyledHeadline>
                }
                <StyledGuidelineText>This is your chance to send your match or training videos directly
                    to {prefaceWithArticle(academyName)} coach. You’ll receive your own feedback video
                    from {prefaceWithArticle(coach)} with constructive commentary and tips to help improve your
                    game. </StyledGuidelineText>
                <StyledGuidelineText>It's simple:</StyledGuidelineText>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "8px",
                        mb: "16px"
                    }}
                >
                    <StyledDarkModeRowBox>
                        <CloudUploadOutlinedIcon/>
                        <StyledDarkModeRowBoxText>Upload Your Video</StyledDarkModeRowBoxText>
                    </StyledDarkModeRowBox>
                    <StyledDarkModeRowBox>
                        <EditNoteOutlinedIcon/>
                        <StyledDarkModeRowBoxText>Tell the {coach} what you want to improve
                            on</StyledDarkModeRowBoxText>
                    </StyledDarkModeRowBox>
                    <StyledDarkModeRowBox>
                        <CheckCircleOutlineOutlinedIcon/>
                        <StyledDarkModeRowBoxText>{academyName} coach analyses your video</StyledDarkModeRowBoxText>
                    </StyledDarkModeRowBox>
                    <StyledDarkModeRowBox>
                        <TvOutlinedIcon/>
                        <StyledDarkModeRowBoxText>Receive a feedback video from the coach</StyledDarkModeRowBoxText>
                    </StyledDarkModeRowBox>
                </Box>
            </DarkBox>

            <WhiteBox sx={{padding: "16px"}}>
                {!!theme.custom.coachFeedbackPromoPoster ?
                    <StyledTitle color={"secondary"} className="use-header-font">WHAT VIDEOS CAN I SUBMIT?</StyledTitle>
                    :
                    <StyledHeadline>What videos can I submit?</StyledHeadline>
                }
                <StyledGuidelineText>You can submit up to 3 mins of footage. It can be a compilation of videos from your
                    matches, training, casual games in the park, etc.</StyledGuidelineText>
                <StyledExampleHeadline>Example: types of videos</StyledExampleHeadline>
                <video
                    controls
                    playsInline
                    src={theme.custom.submissionVideoSampleLink}
                    poster={theme.custom.submissionVideoSampleThumbnailLink}
                    style={{
                        width: "100%",
                        borderRadius: "4px",
                        // margin: "3%",
                    }}
                />

                <StyledL3Headline>File Requirements</StyledL3Headline>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "8px",
                        mb: "16px",
                    }}
                >
                    <StyledColBox flexGrow={1}>
                        <ScheduleIcon/>
                        <StyledColBoxText>1 to {getCFMaxLength() || maxVideoLengthInMinutes} minutes</StyledColBoxText>
                    </StyledColBox>
                    <StyledColBox flexGrow={1}>
                        <VideocamOutlinedIcon/>
                        <StyledColBoxText>MP4, MOV, HEVC</StyledColBoxText>
                    </StyledColBox>
                    <StyledColBox flexGrow={1}>
                        <UploadFileIcon/>
                        <StyledColBoxText>Max {formatBytes(getCFMaxFileSize() || maxFileSize)}</StyledColBoxText>
                    </StyledColBox>

                </Box>

                <StyledL3Headline>Top tips to get the best feedback</StyledL3Headline>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "8px",
                        mb: "16px"
                    }}
                >
                    <StyledRowBox>
                        <CheckCircleOutlineOutlinedIcon/>
                        <StyledRowBoxText>Make sure you’re clearly visible in the video, and that the video focuses on
                            you.</StyledRowBoxText>
                    </StyledRowBox>
                    <StyledRowBox>
                        <CheckCircleOutlineOutlinedIcon/>
                        <StyledRowBoxText>Try to upload high quality videos. Avoid uploading a mix of portrait and
                            landscape videos as this can make some videos appear very small.</StyledRowBoxText>
                    </StyledRowBox>
                    <StyledRowBox>
                        <CheckCircleOutlineOutlinedIcon/>
                        <StyledRowBoxText>Include footage showing you doing the things you'd like to improve
                            on.</StyledRowBoxText>
                    </StyledRowBox>
                </Box>
            </WhiteBox>
            <LightGreyBox>
                <StyledHeadline>FAQs</StyledHeadline>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(1)}>
                        <StyledFAQQuestion>How do I get Academy Feedback?</StyledFAQQuestion>
                        { faqIsVisible(1) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={ faqIsVisible(1) ? "visible" : "hidden" }>
                        <p>Getting Academy Feedback is simple!</p>

                        <p>To start, choose a video to submit to the Academy coach (see our tips below on what videos you can submit and how to submit a good quality video).</p>

                        <p>Once you’re ready to submit, all you need to do is:</p>
                        <ul>
                            <li>Upload your video</li>
                            <li>Tell the {coach} which player you are in the video</li>
                            <li>Tell the {coach} your name, position and age</li>
                            <li>Tell the {coach} up to three things you want to improve on</li>
                        </ul>

                        <p>The {coach} will analyse your video, and you’ll receive your own personalised Feedback video from the coach within 10 days.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(2)}>
                        <StyledFAQQuestion>Who are the coaches that will analyse my video?</StyledFAQQuestion>
                        { faqIsVisible(2) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                        <HiddenFAQTypography className={ faqIsVisible(2) ? "visible" : "hidden" }>
                            <p>Your video will be reviewed by a qualified professional coach who is currently employed in an active coaching role at the {academyName}{theme.custom.isWhiteLabelled ? "" : " of your choice"}. All of our coaches are specialists in top-level youth football.</p>
                        </HiddenFAQTypography>
                </StyledFAQItemBox>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(3)}>
                        <StyledFAQQuestion>What kinds of videos can I submit?</StyledFAQQuestion>
                        { faqIsVisible(3) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={ faqIsVisible(3) ? "visible" : "hidden" }>
                        <p>You can submit one video between 1-3 minutes long.</p>
                        <p>It can include clips from your matches, training, casual games in the park, practising in your backyard etc. Although you can only upload one video, your video can be a compilation of shorter clips stitched together.</p>
                        <p>We support MP4, MOV and HEVC file types. Maximum file size is 2GB.</p>
                        <p>Please make sure you have permission to use any videos you send to us.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(4)}>
                        <StyledFAQQuestion>How can I make sure I submit a good quality video?</StyledFAQQuestion>
                        { faqIsVisible(4) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={ faqIsVisible(4) ? "visible" : "hidden" }>
                            <p>
                            VISIBILITY: Make sure the video focuses on you. Your whole body should be clearly visible in the video. If you are using any cones, markers, targets or other players, include those in the shot, too. Avoid submitting videos that are filmed from really far away. 
                            </p>
                            <p>
                            FILE QUALITY: Try to submit high quality, large video files. If you are stitching different clips together, don’t combine portrait and landscape clips as this can make some videos appear very small.
                            </p>
                            <p>
                            IMPROVEMENT POINTS: When you submit your video you’ll also tell the coach what you want to improve on. Try to include footage in your video clips that shows you doing the things you want to improve on.
                            </p>
                            <p>
                            PLAYER ID: If there are other players in the video you’ll be asked to tell the coach which player you are. Make sure you clearly describe which player you are in each different clip that you submit, especially if you submit match footage. 
                            </p>
                            <p>
                            PROBLEMS: If we discover problems with your video file we will contact you by email (using the purchaser’s email address) to resolve the issue. 
                            </p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(5)}>
                        <StyledFAQQuestion>How long will it take to get my feedback?</StyledFAQQuestion>
                        { faqIsVisible(5) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(5) ? "visible" : "hidden"}>
                        <p>Your feedback video will usually be ready within 10 days of purchase.</p>
                        <p>If there is an issue with the video that you’ve submitted, we may ask you to re-submit your video or submit a new one, and your feedback video will be ready within 10 days after resubmission.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
                { (props.cfPrice || !theme.custom.isWhiteLabelled) &&
                    <StyledFAQItemBox>
                        <StyledFAQQuestionContainer onClick={() => handleFAQClick(6)}>
                            <StyledFAQQuestion>How much does Academy Feedback cost? </StyledFAQQuestion>
                            { faqIsVisible(6) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                        </StyledFAQQuestionContainer>
                        <HiddenFAQTypography className={faqIsVisible(6) ? "visible" : "hidden"}>
                            <p>Academy Feedback is a pay-per-use feature and costs {theme.custom.isWhiteLabelled ? props.cfPrice : "between £25-£45"} for one round of video submission and feedback{theme.custom.isWhiteLabelled ? "" : ", depending on the Academy you choose"}. This one-off payment allows you to submit one video and you will receive one feedback video in return.</p>
                        </HiddenFAQTypography>
                    </StyledFAQItemBox>
                }
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(7)}>
                        <StyledFAQQuestion>What should I do if Academy Feedback is sold out?</StyledFAQQuestion>
                        { faqIsVisible(7) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(7) ? "visible" : "hidden"}>
                        <p>Analysing your videos and giving you personalised feedback takes time, and the {academyName} coaches only have a limited number of time slots to allocate.</p>
                        <p>If all of our coaches’ available slots are full, you will see a ‘sold out’ message and we will temporarily suspend the ability to purchase Academy Feedback until the coaches have availability again.</p>
                        <p>If Academy Feedback is sold out, please check back tomorrow as coach availability is refreshed daily.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(9)}>
                        <StyledFAQQuestion>I’m purchasing Academy Feedback for my child, what do I need to do?</StyledFAQQuestion>
                        { faqIsVisible(9) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(9) ? "visible" : "hidden"}>
                        <p>Players and parents complete the same steps when purchasing Academy Feedback.</p>
                        <p>If you are purchasing Academy Feedback on behalf of your child, then whenever you are asked to enter information about the player (e.g. player identification, player name, age and position, and player improvement points), you should enter your child’s information.</p>
                        {theme.custom.isWhiteLabelled && <p>When you are entering your payment details, please make sure you enter your own email address, not your child’s. All notifications about your Academy Feedback order will be sent to your account email address.</p>}
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
                <StyledFAQItemBox>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(10)}>
                        <StyledFAQQuestion>Where will I receive my Feedback video?</StyledFAQQuestion>
                        { faqIsVisible(10) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(10) ? "visible" : "hidden"}>
                        <p>We will send {theme.custom.isWhiteLabelled ? "an email to your account email address":"you an email"} when your Feedback video is ready. Your Feedback video will appear in the Feedback tab under the heading ‘Received’. You will be able to download your Feedback video for offline viewing.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>
                <StyledFAQItemBox sx={{borderWidth: 0}}>
                    <StyledFAQQuestionContainer onClick={() => handleFAQClick(11)}>
                        <StyledFAQQuestion>I have another question about, or suggestion for, Academy Feedback</StyledFAQQuestion>
                        { faqIsVisible(11) ? <KeyboardArrowUp/> : <KeyboardArrowDownIcon/> }
                    </StyledFAQQuestionContainer>
                    <HiddenFAQTypography className={faqIsVisible(11) ? "visible" : "hidden"}>
                        <p>We’d love to hear from you! Please email us at {supportEmail} with any questions, feedback or suggestions.</p>
                    </HiddenFAQTypography>
                </StyledFAQItemBox>


            </LightGreyBox>
        </>


    )
}