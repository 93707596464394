import {useCallback} from "react";
import {getItem, removeItem} from "../util/storage";
import {purchaseStepDataStorageKey} from "../constants/purchase";
import {useNavigate} from "react-router-dom";
import {routes} from "../constants/routes";
import {getNavigationPath} from "../util/url";
import {resetVideo} from "../features/media/mediaSlice";
import {resetPaymentIntent} from "../features/order/orderSlice";
import {useAppDispatch} from "../redux/hooks";

export const usePurchaseNavigate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const deletePurchaseFlowData = useCallback(() => {
        if (getItem(purchaseStepDataStorageKey)) {
            removeItem(purchaseStepDataStorageKey);
        }
        dispatch(resetVideo());
        dispatch(resetPaymentIntent());
    }, [dispatch]);

    const startPurchase = useCallback((params?: Record<string, string>) => {
        deletePurchaseFlowData();
        navigate(routes.purchase);

        if (params) {
            navigate(getNavigationPath(routes.purchase, params));
        } else {
            navigate(routes.purchase);
        }


    }, [navigate, deletePurchaseFlowData]);

    return [startPurchase, deletePurchaseFlowData];
}
