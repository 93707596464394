import React, {useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {getUserProfileThunk, ViaUserProfile} from "../../../../features/user/userSlice";
import {routes} from "../../../../constants/routes";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {RootState} from "../../../../redux/store";
import {IExtendedErrorData} from "../../../../types/state";
import {useHash} from "../../../../hooks/useHash";
import {idTokenKey} from "../../../../constants/urlParameters";

export const SwitchUser = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const hashIdToken = useHash().get(idTokenKey);
    const profileLoading = useAppSelector<boolean>((state: RootState) => state.user.loading);
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const errorData = useAppSelector<IExtendedErrorData | null>((state: RootState) => state.user.error);
    const idToken = sessionStorage.getItem("idToken");

    useEffect(() => {
        if (location.pathname === routes.signIn && !errorData && profile) {
            navigate(routes.home);
        }
    }, [location.pathname, errorData, profile, navigate]);

    useEffect(() => {
        const fetchProfileOnPageLoad = async () => {
            if (!profileLoading && !profile && !errorData && idToken && !hashIdToken) {
                dispatch(getUserProfileThunk());
            }
        }

        fetchProfileOnPageLoad();
    }, [dispatch, idToken, profileLoading, profile, errorData, navigate, hashIdToken]);

    return <Outlet/>;
};
