import {Box, styled, Typography} from "@mui/material";
import React from "react";

interface IHomeSectionProps {
    title: string;
    subtitle?: string;
    emptyContentSubtitle: string | JSX.Element;
    content: JSX.Element | null;
}
const Section = styled(Box)(({theme}) => ({
    backgroundColor: theme.custom.mainBackgroundColor,
    padding: '16px',
}));
const StyledTitle = styled(Typography)(({theme}) => ({
    fontSize: "20px",
    fontWeight: "450",
    color: theme.custom.darkerPrimaryColor,
}));
const StyledSubTitle = styled(Typography)(({theme}) => ({
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    color: theme.custom.darkerPrimaryColor,
    margin: "15px 0"
}));
const Content = styled(Box)(() => ({
    margin: "20px 0"
}));
export const HomeSection = ({title, subtitle, emptyContentSubtitle, content}: IHomeSectionProps) => {
    return (
        <Section>
            <StyledTitle>{title}</StyledTitle>
            {content && subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
            {!content && <StyledSubTitle>{emptyContentSubtitle}</StyledSubTitle>}
            {content && <Content>{content}</Content>}
        </Section>
    );
}
