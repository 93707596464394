import {useCallback, useEffect, useState} from "react";
import isEqual from "lodash/isEqual";
import {ICompleteUploadStepArgs} from "../features/purchase/upload-video/UploadVideoStep";
import {IPaymentCardsData} from "../features/purchase/payment-cards/PaymentCardsStep";
import {purchaseStepDataStorageKey, Stage} from "../constants/purchase";
import {getItem, removeItem, setItem} from "../util/storage";
import {luckyDraw} from "../features/purchase/getting-started/GettingStartedStep";
import {
    MediaUploadResponse,
    updateUploadResponse,
    resetVideo, UploadFilename, updateFilename,
} from "../features/media/mediaSlice";
import {resetPaymentIntent} from "../features/order/orderSlice";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {RootState} from "../redux/store";

export interface IPlayerInformationStepData {
   firstName: string
   age?: number
   position?: string
}

interface IPurchaseHookData {
    gettingStartedStepData: string;
    uploadVideoStepData: ICompleteUploadStepArgs | null;
    videoPreviewUrl: string;
    playerInformationStepData: IPlayerInformationStepData | null;
    improvementAreasStepData: string[];
    paymentCardsStepData: IPaymentCardsData | null;
    id?: string;
}
export interface IPurchaseHookState extends IPurchaseHookData {
    purchaseStage: number;
}
interface IStorageValues extends IPurchaseHookData {
    uploadFilename: UploadFilename | undefined;
    uploadResponse: MediaUploadResponse | undefined;
}
const defaultInitialState: IPurchaseHookState = {
    purchaseStage: Stage.selectAcademies,
    gettingStartedStepData: "",
    uploadVideoStepData: null,
    videoPreviewUrl: "",
    playerInformationStepData: {firstName: "", age: undefined, position: undefined },
    improvementAreasStepData: [],
    paymentCardsStepData: null,
    id: undefined
};
const usePurchaseStepDataStorage = (initialState: IPurchaseHookState = defaultInitialState) => {
    const dispatch = useAppDispatch();
    const storedValues = getItem<IStorageValues | null>(purchaseStepDataStorageKey);
    const uploadFilename = useAppSelector<UploadFilename|undefined>((state: RootState) => state.media.uploadFilename)
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse)
    const [id, setId] = useState<string | undefined>(storedValues?.id ?? initialState.id);
    const [gettingStartedStepData, setGettingStartedStepData] = useState<string>(storedValues?.gettingStartedStepData ?? initialState.gettingStartedStepData);
    const [uploadVideoStepData, setUploadVideoStepData] = useState<ICompleteUploadStepArgs | null>(storedValues?.uploadVideoStepData ?? initialState.uploadVideoStepData);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState<string>(storedValues?.videoPreviewUrl ?? initialState.videoPreviewUrl);
    const [playerInformationStepData, setPlayerInformationStepData] = useState<IPlayerInformationStepData|null>(storedValues?.playerInformationStepData ?? initialState.playerInformationStepData);
    const [improvementAreasStepData, setImprovementAreasStepData] = useState<string[]>(storedValues?.improvementAreasStepData ?? initialState.improvementAreasStepData);
    const [paymentCardsStepData, setPaymentCardsStepData] = useState<IPaymentCardsData | null>(storedValues?.paymentCardsStepData ?? initialState.paymentCardsStepData);
    const getPurchaseStage = (): number => {
        if (
            gettingStartedStepData &&
            videoPreviewUrl && uploadVideoStepData &&
            improvementAreasStepData.length &&
            playerInformationStepData?.firstName !== "" &&
            (paymentCardsStepData || gettingStartedStepData === luckyDraw.sku)
        ) {
            return Stage.sendOrder;
        }
        if (!gettingStartedStepData) {
            return Stage.selectAcademies;
        }
        if (!uploadVideoStepData || !videoPreviewUrl) {
            return Stage.uploadVideo;
        }
        if (playerInformationStepData?.firstName === "") {
            return Stage.playerInformation;
        }
        if (!improvementAreasStepData.length) {
            return Stage.improvementAreas;
        }
        if (!paymentCardsStepData) {
            return Stage.paymentCard;
        }

        return initialState.purchaseStage;
    }
    const [purchaseStage, setPurchaseStage] = useState<number>(getPurchaseStage());

    const resetValues = useCallback(() => {
        removeItem(purchaseStepDataStorageKey);
        dispatch(resetVideo());
        dispatch(resetPaymentIntent());
        setGettingStartedStepData(initialState.gettingStartedStepData);
        setUploadVideoStepData(initialState.uploadVideoStepData);
        setPlayerInformationStepData(initialState.playerInformationStepData);
        setImprovementAreasStepData(initialState.improvementAreasStepData);
        setPaymentCardsStepData(initialState.paymentCardsStepData);
        setVideoPreviewUrl(initialState.videoPreviewUrl);
        setId(undefined);
    }, [dispatch, initialState]);

    useEffect(() => {
        if (
            !storedValues?.uploadFilename ||
            !storedValues?.uploadResponse ||
            !storedValues?.videoPreviewUrl
        ) {
            return;
        }

        if (
            (uploadFilename && !isEqual(storedValues.uploadFilename, uploadFilename)) ||
            (uploadResponse && !isEqual(storedValues.uploadResponse, uploadResponse))
        ) {
            setVideoPreviewUrl("");
            return;
        }

        if (!isEqual(storedValues.uploadFilename, uploadFilename)) {
            dispatch(updateFilename(storedValues?.uploadFilename));
        }
        if (!isEqual(storedValues.uploadResponse, uploadResponse)) {
            dispatch(updateUploadResponse(storedValues.uploadResponse));
        }
    }, [dispatch, uploadResponse, storedValues?.uploadResponse, uploadFilename, storedValues?.uploadFilename, storedValues?.videoPreviewUrl]);
    useEffect(() => {
        const setValuesToStorage = () => {
            const storedValues = getItem<IStorageValues | null>(purchaseStepDataStorageKey);
            if (!id && id !== storedValues?.id) {
                resetValues();
                return;
            }

            if (!isEqual(storedValues, {
                gettingStartedStepData,
                uploadVideoStepData,
                videoPreviewUrl,
                uploadFilename,
                uploadResponse,
                improvementAreasStepData,
                paymentCardsStepData,
                id
            })) {
                setItem(purchaseStepDataStorageKey, {
                    gettingStartedStepData,
                    uploadVideoStepData,
                    videoPreviewUrl,
                    uploadFilename,
                    uploadResponse,
                    improvementAreasStepData,
                    paymentCardsStepData,
                    id
                });
            }
        }

        setValuesToStorage();
    }, [gettingStartedStepData, uploadVideoStepData, videoPreviewUrl, uploadFilename, uploadResponse, improvementAreasStepData, paymentCardsStepData, id, resetValues]);

    return [
        resetValues,
        purchaseStage,
        setPurchaseStage,
        gettingStartedStepData,
        setGettingStartedStepData,
        uploadVideoStepData,
        setUploadVideoStepData,
        videoPreviewUrl,
        setVideoPreviewUrl,
        playerInformationStepData,
        setPlayerInformationStepData,
        improvementAreasStepData,
        setImprovementAreasStepData,
        paymentCardsStepData,
        setPaymentCardsStepData,
        id,
        setId
    ] as const;
};

export default usePurchaseStepDataStorage;
