import React, {useCallback, useEffect} from "react"
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {CheckCircleOutline} from "@mui/icons-material";
import {Button, CircularProgress, styled, Typography} from "@mui/material";
import {ViaUserProfile} from "../../user/userSlice";
import {useNavigate} from "react-router-dom";
import {consentServiceApi} from "../../../services/consent-service";
import {routes} from "../../../constants/routes";
import {Analytics} from "../../../lib/analytics";

const TitleTypography = styled(Typography)(() => ({
    fontSize: "22px",
    fontWeight: "700"
}));
const StyledButton = styled(Button)(() => ({
    marginBottom: "10px",
    width: "200px",
    borderRadius: "24px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "600"
}));
export const MonthlyDrawOrderCreated = (): JSX.Element => {
    const navigate = useNavigate();
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const {isLoading, data: parentalEmail} = consentServiceApi.useFetchConsentStatusQuery("", { skip: !profile || !profile.user.is_minor });
    const goToVideos = useCallback(() => {
        Analytics.competitionConfirmationMyVideosButtonClick();
        navigate(routes.home);
    }, [navigate]);
    
    useEffect(() => {
        Analytics.competitionConfirmationView(); 
    }, []);

    return (
        <>
            {isLoading && <CircularProgress sx={{m: "10px auto"}} />}
            {!isLoading && (
              <>
                  <CheckCircleOutline sx={{width: "54px", height: "54px"}} />
                  <TitleTypography>Monthly Draw entered!</TitleTypography>
                  <Typography variant="body2" align="center">
                      {profile?.user.is_minor ?
                          `If you win the draw, we will let your parent/guardian know at ${parentalEmail}` :
                          `If you win the draw, we will let you know at ${profile?.user.email}`
                      }
                  </Typography>
                  <StyledButton variant="outlined" color="secondary" sx={{mt: "50px"}} onClick={goToVideos}>Go to My Videos</StyledButton>
              </>
            )}
        </>
    );
}
