import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {AppConfig} from "../config";
import {IProfileDataRequestBody} from "../types/profile";
import {getIdToken} from "./cognito";

export const profileServiceApi = createApi({
    reducerPath: "profileServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${AppConfig.API_HOST}/v1`,
        prepareHeaders: async (headers) => {
            const token = await getIdToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Profile"],
    endpoints: (build) => ({
        updateProfile: build.query<void, IProfileDataRequestBody>({
            query: (profileData) => ({
                method: "PATCH",
                url: `/profile`,
                body: profileData,
            }),
        }),
    })
});
