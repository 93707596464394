import {configureStore, combineReducers, AnyAction} from '@reduxjs/toolkit'
import userReducer from '../features/user/userSlice'
import mediaReducer from '../features/media/mediaSlice'
import academyContentReducer from '../features/academyContent/academyContentSlice'
import consentReducer from '../features/consent/consentSlice'
import orderReducer from '../features/order/orderSlice'
import subscriptionsReducer from '../features/subscriptions/subscriptionsSlice'
import {consentServiceApi} from "../services/consent-service";
import {coachFeedbackServiceApi} from "../services/coach-feedback-service";
import {categoriesServiceApi, couponCategoriesServiceApi} from "../services/categories-service";
import {profileServiceApi} from "../services/profile-service";
import {orderServiceApi} from "../services/order-service";
import {rtkQueryErrorLogger} from "../services/middleware";

const combinedReducer = combineReducers({
    user: userReducer,
    media: mediaReducer,
    academyContent: academyContentReducer,
    subscriptions: subscriptionsReducer,
    orderSlice: orderReducer,
    consentSlice: consentReducer,
    [consentServiceApi.reducerPath]: consentServiceApi.reducer,
    [coachFeedbackServiceApi.reducerPath]: coachFeedbackServiceApi.reducer,
    [categoriesServiceApi.reducerPath]: categoriesServiceApi.reducer,
    [couponCategoriesServiceApi.reducerPath]: couponCategoriesServiceApi.reducer,
    [profileServiceApi.reducerPath]: profileServiceApi.reducer,
    [orderServiceApi.reducerPath]: orderServiceApi.reducer
});
const rootReducer = (state: any, action: AnyAction) => {
  // reset all states when log out
  if (action.type === 'user/userLogout') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck: false})
          .concat([consentServiceApi.middleware, coachFeedbackServiceApi.middleware, categoriesServiceApi.middleware, couponCategoriesServiceApi.middleware, profileServiceApi.middleware, orderServiceApi.middleware, rtkQueryErrorLogger])
});
