import {getClubContentApiHost,getClubContentAnalyticsId} from "./lib/skinningUtils";

const AppConfig = {
    ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ?? "",
    API_HOST: process.env.REACT_APP_API_HOST,
    CONTENT_API_HOST: getClubContentApiHost(),
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "",
    COGNITO: {
        REGION: process.env.REACT_APP_COGNITO_REGION ?? "",
        USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? "",
        WEB_CLIENT_ID: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID ?? "",
        OAUTH_CUSTOM_DOMAIN: process.env.REACT_APP_COGNITO_CUSTOM_HOSTNAME ?? "",
        OAUTH_LOGIN_REDIRECT: window.location.origin + "/sign-in",
    },
    ANALYTICS: {
      GA_MEASUREMENT_ID: getClubContentAnalyticsId() ?? "",
      TEST_MODE: ['true', 'yes', '1'].includes(process.env.REACT_APP_GA_TEST_MODE ?? "")
    }
}

export {
    AppConfig
}
