import {IViaSignUpRequestData} from "../features/user/userSlice";
import {routes} from "../constants/routes";
import {bytesIn1GB} from "./file";

export const getItem = <TValue>(key: string): TValue | null => {
    try {
        const serializedValue = localStorage.getItem(key);
        return serializedValue ? JSON.parse(serializedValue) : null;
    } catch (error) {
        return null;
    }
};

export const setItem = <TValue>(key: string, value: TValue): void => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        return;
    }
};

export const removeItem = (key: string): void => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        return;
    }
};

export const isSignupMode = ():boolean => {
    return localStorage.getItem('signupMode') === "active"
}


export const setSignupMode = (birthday: number, newsletter: boolean, isAnonymous: boolean = false):void => {
    localStorage.setItem('signupMode', "active")
    localStorage.setItem('signupBirthday', birthday.toString())
    localStorage.setItem('signupNewsletter', newsletter.toString())
    localStorage.setItem('signupAsAnon', isAnonymous ? "yes" : "no" )
}
export const getSignupValues = (): IViaSignUpRequestData => {
    if(localStorage.getItem('signupAsAnon') === "yes"){
        const values: IViaSignUpRequestData = {
            anonymous_user: true,
            organisation_id: getItem("organisation_id") || ""
        }
        console.log(values)
        return values
    }else{
        const values: IViaSignUpRequestData = {
            date_of_birth: Number(localStorage.getItem('signupBirthday')),
            marketing_opt_in: Boolean(localStorage.getItem('signupNewsletter')),
            organisation_id: getItem("organisation_id") || undefined
        }
        console.log(values)
        return values
    }
}

export const clearSignupMode = ():void => {
    localStorage.removeItem('signupMode')
    localStorage.removeItem('signupBirthday')
    localStorage.removeItem('signupNewsletter')
    localStorage.removeItem('signupAsAnon')
}

export const clearSignInRetryFlags = () => {
    removeItem('retryKoreQPR')
}

export const setCoupon = (code: string, price:number): void => {
    setItem('couponCode', code)
    setItem('couponPrice', price)
}

export const getCouponPrice = (): Number => {
    return Number(getItem('couponPrice'))
}

export const getCouponCode = (): string => {
    return getItem('couponCode') || ""
}

export const setDefaultClubName = (name: string): void => {
    setItem('defaultClubName', name)
}

export const getDefaultClubName = (): string => {
    return getItem('defaultClubName') || ""
}

export const clearDefaultClubName = (): void => {
    removeItem('defaultClubName')
}

export const setWhiteLabelClubName = (name: string): void => {
    setItem('whiteLabelClubName', name)
}

export const checkIsWhiteLabelled = (): boolean => {
    return !!getItem('whiteLabelClubName')
}

export const checkIsWhiteLabelledByQPR = (): boolean => {
    return getItem('whiteLabelClubName') === "QPR Academy"
}

export const checkIsWhiteLabelledByPUFC = (): boolean => {
    return getItem('whiteLabelClubName') === "PUFC Academy"
}

export const getWhiteLabelClubName = (): string => {
    return getItem('whiteLabelClubName') || ""
}

export const clearWhiteLabelClubName = (): void => {
    removeItem('whiteLabelClubName')
}

export const setLuckyDrawClubName = (name: string): void => {
    setItem('luckyDrawClubName', name)
}

export const getLuckyDrawClubName = (): string => {
    return getItem('luckyDrawClubName') || ""
}

export const clearLuckyDrawClubName = (): void => {
    removeItem('luckyDrawClubName')
}

export const clearCoupon = (): void => {
    removeItem('couponPrice')
    removeItem('couponCode')
}

export const setIsAnonymousUser = (): void => {
    sessionStorage.setItem('isAnonymousUser', 'yes')
}

export const clearIsAnonymousUser = (): void => {
    sessionStorage.removeItem('isAnonymousUser')
}

export const checkIsAnonymousUser = (): boolean => {
    return sessionStorage.getItem('isAnonymousUser') === 'yes'
}

export type SiteSections = "Insights" | "Feedback" | "Training" | "PostItem"

export const setGotoSiteSection = (section: SiteSections) => setCurrentSiteSection(section)
export const setCurrentSiteSection = (section: SiteSections) => {
    setItem("currentSiteSection", section)
    removeItem("currentPostPath")
}

export const setCurrentPostPath = (postPath: string): void => {
    setItem("currentSiteSection", "PostItem")
    setItem("currentPostPath", postPath)
}

export const setAttemptedPage = (path: string): void => {
    setItem("attemptedPagePath", path)
}

export const getAttemptedPage = (): string => {
    return getItem("attemptedPagePath") as string
}

export const setRedirectAfterLogin = (): void => { setItem("redirectAfterLogin", "true")}
export const isRedirectAfterLogin = (): boolean => { return !!getItem("redirectAfterLogin") }
export const clearRedirectAfterLogin =():void => { removeItem("redirectAfterLogin")}

export const getGotoUrl = (skipAttempted?: boolean ): string => {
    console.log("attempted", getAttemptedPage())
    if(!skipAttempted && !(isRedirectAfterLogin() && getAttemptedPage() === "/") && getAttemptedPage() !== "/sign-in")
        return (getAttemptedPage())

    clearRedirectAfterLogin()
    switch (getItem("currentSiteSection")) {
        case "Insights":
            return routes.insights
        case "Feedback":
            return routes.coachFeedback
        case "Training":
            return routes.train
        case "PostItem":
            return getItem("currentPostPath") || routes.insights
        default:
            return routes.insights
    }
}

export const setCFLengthAndGb = (lengthInMinutes: string, Gb: string ): void => {
    sessionStorage.setItem('maxLength', lengthInMinutes)
    sessionStorage.setItem('maxGb', Gb)
}

export const getCFMaxLength = (): number|undefined => {
    return !!sessionStorage.getItem('maxLength') === null ? undefined : Number(sessionStorage.getItem('maxLength'))
}

export const getCFMaxFileSize = (inGb: boolean = false): number|undefined => {
    const gain = inGb ? 1 : bytesIn1GB
    return !!sessionStorage.getItem('maxGb') === null ? undefined : Number(sessionStorage.getItem('maxGb')) * gain
}

