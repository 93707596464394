import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getS3urls, S3urlsRequest} from "../../services/api";

export interface UploadFilename {
    original: string
    cdnFilename: string
    localFilePath: string
    fileType: string
}

export interface MediaUploadResponse {
    url: string
    key: string
    cdn_base_url: string
}

export enum MediaUploadStatus {
    None,
    CreatingUrl,
    CreatedUrl,
    Posting,
    Success,
    Aborted,
    Fail
}

interface mediaState {
    uploadFilename?: UploadFilename
    uploadResponse?: MediaUploadResponse
    uploadStatus: MediaUploadStatus
}



// Define the initial state using that type
const initialState: mediaState = {
    uploadFilename: undefined,
    uploadResponse: undefined,
    uploadStatus: MediaUploadStatus.None
}


export const getS3urlsThunk = createAsyncThunk<MediaUploadResponse, S3urlsRequest>(
    'media/getS3urls',
    async (args) => {
        const gotten = await getS3urls(args);
        return gotten as MediaUploadResponse;
    }
)


export const mediaSlice = createSlice({
    name: 'media',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetVideo: (state: mediaState, action: PayloadAction<void>) => {
            state.uploadResponse = undefined
            state.uploadFilename = undefined
            state.uploadStatus = MediaUploadStatus.None;
        },
        updateFilename: (state: mediaState, action: PayloadAction<UploadFilename>) => {
            state.uploadResponse = undefined
            state.uploadFilename = action.payload
        },
        updateUploadResponse: (state: mediaState, action: PayloadAction<MediaUploadResponse>) => {
            state.uploadResponse = action.payload
        },
        updateUploadStatus: (state: mediaState, action: PayloadAction<MediaUploadStatus>) => {
            state.uploadStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getS3urlsThunk.pending, (state: mediaState) => {
            state.uploadStatus = MediaUploadStatus.CreatingUrl;
        })
        builder.addCase(getS3urlsThunk.fulfilled, (state: mediaState, action: PayloadAction<MediaUploadResponse>) => {
            state.uploadResponse = {
                url: action.payload.url,
                key: action.payload.key,
                cdn_base_url: action.payload.cdn_base_url
            } as MediaUploadResponse;
            state.uploadStatus = MediaUploadStatus.CreatedUrl;
        })
        builder.addCase(getS3urlsThunk.rejected, (state: mediaState) => {
            state.uploadResponse = undefined;
            state.uploadStatus = MediaUploadStatus.Fail;
        })
    },
});

export const {resetVideo, updateUploadResponse, updateUploadStatus, updateFilename} = mediaSlice.actions;
export default mediaSlice.reducer
