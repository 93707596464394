import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createOrder, createPaymentIntent, updatePaymentIntent} from "../../services/api";
import {generalMessage} from "../../constants/common";
import {RootState} from "../../redux/store";
import {ICoachFeedbackResult} from "../../types/coachFeedback";
import {clearCoupon} from "../../util/storage";

interface IPaymentState {
    loading: boolean;
    error: string;
    paymentIntentId: string;
    paymentIntentAmount: number | null;
    clientSecretId: string | null;
    orderDetails: ICreateOrderDetails | null;
    modifyingOrder: ICoachFeedbackResult | null;
}
const initialState: IPaymentState = {
    loading: false,
    error: '',
    paymentIntentId: '',
    paymentIntentAmount: null,
    clientSecretId: null,
    orderDetails: null,
    modifyingOrder: null,
}

export interface IOrderMetaData {
    others_in_video: boolean;
    order_name: string;
    order_age?: number
    order_position?: string
    identification_notes: string;
    improvement_areas: {detail: string}[],
    media: {
        video: {
            mime_type: string;
            key: string;
        }
    }
    organisation?: string
}

export interface ICreateOrderRequestData {
    coupon_code?: string
    source: string
    items: [{
        sku: string;
        quantity: number
        metadata: IOrderMetaData;
    }]
}

export interface ICreateOrderDetails {
    id: string;
}

export interface ICreatePaymentIntentArguments {
    amount: number;
    currency: string;
    organisation_id?: string;
    source: string;
}

interface ICreateOrderError {
    message: string;
    full_message: string;
    status: number;
    errors?: [{ description: string }];
}

export const createPaymentIntentThunk = createAsyncThunk(
    'order/createPaymentIntentThunk',
    async (args: ICreatePaymentIntentArguments, { rejectWithValue }) => {
        try {
            return await createPaymentIntent(args);
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
)

export interface UpdatePaymentIntentThunkArgs {
    source: string
    organisation_id: string
    orderId: string
    receiptRecipient: string
}

export const updatePaymentIntentThunk = createAsyncThunk(
    'order/updatePaymentIntentThunk',
    async (args: UpdatePaymentIntentThunkArgs, { rejectWithValue, getState }) => {
        const paymentIntentId: string = (getState() as RootState).orderSlice.paymentIntentId;
        if (!paymentIntentId || !args.orderId || !args.receiptRecipient) {
            return rejectWithValue(generalMessage);
        }

        try {
            return await updatePaymentIntent({
                id: paymentIntentId,
                source: args.source,
                organisation_id: !!args.organisation_id ? args.organisation_id : undefined,
                metadata: {order_id: args.orderId, receipt_recipient: args.receiptRecipient}
            });
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const purchaseOrderThunk = createAsyncThunk(
    'order/purchaseOrderThunk',
    async (args: ICreateOrderRequestData, { rejectWithValue }) => {
        try {
            return await createOrder(args);
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const orderSlice = createSlice({
    name: 'order',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetPaymentIntent: (state: IPaymentState) => {
            state.paymentIntentAmount = initialState.paymentIntentAmount;
            state.paymentIntentId = initialState.paymentIntentId;
            state.clientSecretId = initialState.clientSecretId;
        },
        setOrderWithIssues: (state: IPaymentState, action: PayloadAction<ICoachFeedbackResult | null>) => {
            state.modifyingOrder = action.payload;
        },
        resetError: (state: IPaymentState) => {
            state.error = "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createPaymentIntentThunk.pending, (state: IPaymentState, action) => {
            state.loading = true;
            state.paymentIntentAmount = action.meta.arg.amount;
            state.error = "";
        })
        builder.addCase(createPaymentIntentThunk.fulfilled, (state: IPaymentState, action: PayloadAction<{id: string, client_secret: string}>) => {
            state.loading = false;
            state.paymentIntentId = action.payload.id;
            state.clientSecretId = action.payload.client_secret;
        })
        builder.addCase(createPaymentIntentThunk.rejected, (state: IPaymentState, error: any) => {
            state.loading = false;
            state.error = error.message ?? generalMessage;
        })
        builder.addCase(updatePaymentIntentThunk.pending, (state: IPaymentState, _error: any) => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(updatePaymentIntentThunk.fulfilled, (state: IPaymentState, _error: any) => {
            state.loading = false;
        })
        builder.addCase(updatePaymentIntentThunk.rejected, (state: IPaymentState, error: any) => {
            state.loading = false;
            state.error = error.message ?? generalMessage;
        })
        builder.addCase(purchaseOrderThunk.pending, (state: IPaymentState) => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(purchaseOrderThunk.fulfilled, (state: IPaymentState, action: PayloadAction<{id: string}>) => {
            clearCoupon();
            state.loading = false;
            state.orderDetails = {
                id: action.payload.id,
            };
        })
        builder.addCase(purchaseOrderThunk.rejected, (state: IPaymentState, action: any) => {
            state.loading = false;
            const data = action.payload as ICreateOrderError;
            if (data) {
                const errors = data.errors ?? [];
                state.error = data.full_message ?? (errors.length && errors[0].description) ?? data.message
            } else {
                state.error = action.error.message ?? generalMessage
            }
        })
    },
});
export const {resetPaymentIntent, setOrderWithIssues, resetError} = orderSlice.actions;
export default orderSlice.reducer
