import React, {useEffect} from 'react'
import {Loader} from "../../shared/Loader";
import {useNavigate, useParams} from "react-router-dom";
import {routes} from "../../constants/routes";
import {setDefaultClubName} from "../../util/storage";

const processClubCode = (code: string): void => {
    if(code.trim().toUpperCase() === "QPR"){
        setDefaultClubName("QPR Academy")
    }else
    if(code.trim().toUpperCase() === "FFC"){
        setDefaultClubName("FFC Academy")
    }
    if(code.trim().toUpperCase() === "PUFC"){
        setDefaultClubName("PUFC Academy")
    }
}

export const PartnerReferred = (): React.ReactElement => {
    const { orgName } = useParams<string>();
    const navigate = useNavigate()

    useEffect(()=>{
        console.log(orgName)
        console.log(orgName?.trim().toUpperCase())
        if(!!orgName){
            processClubCode(orgName)
            navigate(routes.home)
        }
    },[orgName, navigate])

    return <Loader/>
}