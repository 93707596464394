import React, {useEffect} from 'react'
import {ScreenLayout} from "../../shared/ScreenLayout";
import {Box, Button, Link, styled, Typography} from "@mui/material";
import {useQuery} from "../../hooks/useQuery";
import CloseIcon from '@mui/icons-material/Close';
import {routes} from "../../constants/routes";
import {useNavigate} from "react-router-dom";
import {signOut} from "../../services/cognito";
import {userLogout} from "../../features/user/userSlice";
import {useAppDispatch} from "../../redux/hooks";
import {Analytics} from "../../lib/analytics";

const StyledTitleItem = styled(Typography)(({theme}) => ({
    fontSize: "22px",
    fontWeight: "500",
    color: theme.custom.darkerPrimaryColor,
}));

const StyledHr = styled('hr')(()=>({
    borderTop: "0.5px solid #B3BDC8",
    width: "100%",
    margin: "0",
}))

const StyledPageBox = styled(Box)(({theme})=>({
    color: theme.custom.darkerPrimaryColor
}))

export interface U13PageContent {
    title: string
    backToRoute: string
    body: React.ReactElement
}

export type U13MessageTopic = "feedback" | "training"

export const U13Message = ():React.ReactElement => {
    const query = useQuery()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const topic: U13MessageTopic = (query.get('topic') || 'training') as U13MessageTopic

    const loading = false

    const pageContent: { [key in U13MessageTopic]: U13PageContent } = {
        feedback: {
            title: "Feedback for Under 13s",
            backToRoute: routes.coachFeedback,
            body:
                <>
                    <Typography sx={{marginTop: "24px", fontSize: "16px", fontWeight: 400}}>Academy Feedback is for players of all ages.</Typography>
                    <Typography sx={{marginTop: "24px", fontSize: "16px", fontWeight: 400}}>However, for now, Academy Feedback for players aged under 13 must be purchased from a parent/guardian account.</Typography>
                    <Typography sx={{margin: "24px 0", fontSize: "16px", fontWeight: 700}}>To get Academy Feedback, please ask your parent/guardian to take these steps: </Typography>
                    <ol>
                        <li>Tap the <Link sx={{cursor: "pointer"}} onClick={async () => {
                            await signOut();
                            dispatch(userLogout());
                            Analytics.feedbackUnderAgeParentButtonClick();
                            navigate(routes.home);
                        }}>Parent/Guardian sign-in</Link> button below
                        </li>
                        <li>Sign in with their own (parent/guardian) QPR Club log-in, or create an account for free</li>
                        <li>Tap on the Feedback tab to purchase Academy Feedback for you</li>
                    </ol>
                    <Typography sx={{margin: "24px 0", fontSize: "16px", fontWeight: 400}}>When purchasing Academy Feedback, your parent/guardian will be asked to upload your video and tell the coach your player details. Your Academy Feedback will be sent to your parent/guardian’s account.</Typography>
                    <Box sx={{textAlign: "center"}}>
                        <Button variant="contained" disableElevation={true}
                                sx={{color: "#FFF", margin: "auto", textTransform: "uppercase"}} onClick={async () => {
                            await signOut();
                            dispatch(userLogout());
                            Analytics.feedbackUnderAgeParentButtonClick();
                            navigate(routes.home);
                        }}>parent/guardian sign-in</Button>
                    </Box>
                </>
        },
        training: {
            title: "Pro Membership: Under 13s",
            backToRoute: routes.train,
            body:
                <>
                    <Typography sx={{marginTop: "24px", fontSize: "16px", fontWeight: 400}}>The Pro Membership is for
                        players of all ages. However, for now, Pro Memberships for players aged under 13 must be
                        purchased
                        from a parent/guardian account.</Typography>
                    <Typography sx={{margin: "24px 0", fontSize: "16px", fontWeight: 700}}>To get a Pro Membership,
                        please
                        ask your parent/guardian to take these steps: </Typography>
                    <ol>
                        <li>Tap the <Link sx={{cursor: "pointer"}} onClick={async () => {
                            await signOut();
                            dispatch(userLogout());
                            navigate(routes.home);
                        }}>Parent/Guardian sign-in</Link> button below
                        </li>
                        <li>Sign in with their own (parent/guardian) QPR Club log-in, or create an account for free</li>
                        <li>Tap on the Training tab, then tap on any ‘Pro’ video to purchase a Pro Membership for you
                        </li>
                    </ol>
                    <Typography sx={{margin: "24px 0", fontSize: "16px", fontWeight: 400}}>To view the premium training
                        videos offered under the Pro Membership, you will need to be signed-in to your parent/guardian’s
                        account.</Typography>
                    <Box sx={{textAlign: "center"}}>
                        <Button variant="contained" disableElevation={true}
                                sx={{color: "#FFF", margin: "auto", textTransform: "uppercase"}} onClick={async () => {
                            await signOut();
                            dispatch(userLogout());
                            navigate(routes.home);
                        }}>parent/guardian sign-in</Button>
                    </Box>
                </>
        },
    };

    useEffect(() => {
        Analytics.feedbackUnderAgeView();
    },[])

    return (
    <Box sx={{display: loading ? "none" : "flex", flex: 1}}>
        <ScreenLayout
            title={
                <>
                    <StyledTitleItem><CloseIcon sx={{cursor: "pointer", verticalAlign: "middle" }} onClick={() => navigate(pageContent[topic].backToRoute)}/></StyledTitleItem>
                    <StyledTitleItem>{pageContent[topic].title}</StyledTitleItem>
                    <StyledTitleItem>&nbsp;</StyledTitleItem>
                </>
            }
            contentFeedBackgroundColor={"#FFFFFF"}
            ctaTitle={"n/a"}
            displayCta={false}
            onAction={()=>{}}
        >
            <StyledPageBox>
                <StyledHr/>
                {pageContent[topic].body}
            </StyledPageBox>
        </ScreenLayout>
    </Box>)

}