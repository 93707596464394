import React, {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import {CenteredItemsBox} from "./styles";
import {userInitiatedSignOut} from "../services/cognito";

const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
};

export const LoaderWithWatchdog = (props?: {size?: number|string}) => {

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // Clear local storage
            localStorage.removeItem("anon_pw")
            localStorage.removeItem("anon_email")
            userInitiatedSignOut().then(()=>{
                localStorage.clear()
                // Clear session storage
                sessionStorage.clear()
                // Clear cookies
                clearCookies()
                console.log('Timeout triggered, storage and cookies cleared')
                window.location.reload()
            })
        }, 9000) // Adjust the delay as needed
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    return (
        <CenteredItemsBox sx={{flex: 1}}>
            {props ?
                <CircularProgress size={props.size}/>
                :
                <CircularProgress/>
            }
        </CenteredItemsBox>
    )
}