import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {AppConfig} from "../config";
import {
    ICoachFeedbackData,
    ICoachFeedbackItemResponse,
    ICoachFeedbackRequestData,
    IMonthlyDraw,
    IMonthlyDrawResponse
} from "../types/coachFeedback";
import {transformCoachFeedbackResponse, transformMonthlyDrawResponse} from "../util/transformers/transformCoachFeedbackResponse";
import {IOrderMetaData} from "../features/order/orderSlice";
import {getIdToken} from "./cognito";
import {IOpenBallots, IOpenBallotsResponse} from "../types/ballots";
import {checkIsWhiteLabelled, getItem} from "../util/storage";

export const coachFeedbackServiceApi = createApi({
    reducerPath: "coachFeedbackServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${AppConfig.API_HOST}/v1/coach-feedback-requests`,
        prepareHeaders: async (headers) => {
            const token = await getIdToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["CoachFeedback", "SubmittedBallot", "SubmitBallot", "OpenBallots"],
    endpoints: (build) => ({
        getOpenBallots: build.query<IOpenBallots, string>({
            query: () => ({
                url: `/ballots`
            }),
            providesTags: ["OpenBallots"],
            transformResponse: ({ current, previous }: IOpenBallotsResponse) => {
                const openBallotsData: IOpenBallots = {
                    current: null,
                    previous: null,
                };

                if (current) {
                    openBallotsData.current = {
                        entered: current.entered,
                        name: current.name,
                        open: !!current.number,
                    }
                }

                if (previous) {
                    openBallotsData.previous = {
                        entered: previous.entered,
                        name: previous.name,
                        open: !!previous.number,
                    }
                }

                return openBallotsData;
            },
        }),
        getSubmittedBallot: build.query<{current?: IMonthlyDraw, last?: IMonthlyDraw}, string>({
            query: () => ({
                url: `/ballots/entries`,
            }),
            providesTags: ["SubmittedBallot"],
            transformResponse: (response: {current?: IMonthlyDrawResponse, last?: IMonthlyDrawResponse}) => {
                return {
                    current: response.current ? transformMonthlyDrawResponse(response.current) : undefined,
                    last: response.last ? transformMonthlyDrawResponse(response.last) : undefined,
                };
            },
        }),
        getCoachFeedback: build.query<ICoachFeedbackData, ICoachFeedbackRequestData>({
            query: ({page, pageSize, status}: ICoachFeedbackRequestData) => ({
                url: "",
                params: {
                    page,
                    pageSize,
                    status: status.join(","),
                    organisation_id: !!getItem("organisation_id") && checkIsWhiteLabelled() ? getItem("organisation_id") : undefined},
                }
            ),
            providesTags: ["CoachFeedback"],
            transformResponse: ({results, count, next, previous}: { results: ICoachFeedbackItemResponse[], count: number, next: number, previous: number }) => {
                return {
                    results: results.map(item => transformCoachFeedbackResponse(item)),
                    previousPageCount: previous,
                    nextPageCount: next,
                    totalCount: count,
                };
            }
        }),
        downloadVideo: build.query<Blob, string>({
            query: (feedbackUUID: string) => ({
                url: `/${feedbackUUID}/feedback/video`,
                responseHandler: ((response) => response.blob()),
                cache: "no-cache",
            }),
            providesTags: ["CoachFeedback"],
        }),
        submitMonthlyDraw: build.query<unknown, IOrderMetaData>({
            query: (requestBody) => ({
                method: "POST",
                url: "/ballots/entries",
                body: requestBody,
            }),
            providesTags: ["SubmitBallot"],
        }),
    })
});
