import React from "react"
import {Box, styled, Typography} from "@mui/material";
import {DeviceType, getDeviceType} from "../../../util/userAgent";
import {MobileDownloadButtons} from "../../../shared/MobileDownloadButtons";

interface INotRegisteredInstructionsState {
    providerName: string | null;
}
const HeaderTypography = styled(Typography)(() => ({
    fontSize: "25px",
    fontWeight: "700",
    textAlign: "center",
}));
const ContentBox = styled(Box)(() => ({
    flexDirection: "column",
    textAlign: "center",
    marginTop: "15px"
}));
const StyledImg = styled("img")(() => ({
    maxWidth: "120px",
    margin: "30px 0"
}));
const StyledTypography = styled(Typography)(() => ({
    fontSize: "14px",
    fontWeight: "500",
}));

export const NotRegisteredInstructions = ({providerName}: INotRegisteredInstructionsState) => {
    return (
        <Box>
            <HeaderTypography color="common.black">VIA Academy Profile Required</HeaderTypography>
            <ContentBox>
                <StyledTypography>You don’t have a VIA Academy profile {providerName && <>associated with your {providerName} account.</>}</StyledTypography>
                <StyledTypography sx={{mt: "20px"}}>Download the app and create an profile for free, then come back here to sign in.</StyledTypography>
                {getDeviceType() === DeviceType.Desktop ? (
                    <StyledImg src={process.env.PUBLIC_URL + "/download_qr.svg"} alt="Download app"/>
                ) : (
                    <MobileDownloadButtons/>
                )}
            </ContentBox>
        </Box>
    );
}
