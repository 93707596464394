import {domains} from "../constants/domains";
import {qprtheme} from "../skins/qpr/qprMuiTheme";
import {ffctheme} from "../skins/ffc/ffcMuiTheme";
import {pufctheme} from "../skins/pufc/pufcMuiTheme";
import {clearWhiteLabelClubName, getWhiteLabelClubName, setWhiteLabelClubName} from "../util/storage";
import {viatheme} from "../viaMuiTheme";
import {Theme} from "@mui/material";

export const processClubCode = (code: string): void => {
    if(code.trim().toUpperCase() === "QPR"){
        setWhiteLabelClubName("QPR Academy")
    }else
    if(code.trim().toUpperCase() === "FFC"){
        setWhiteLabelClubName("FFC Academy")
    }else
    if(code.trim().toUpperCase() === "PUFC"){
        setWhiteLabelClubName("PUFC Academy")
    }else
        clearWhiteLabelClubName()
}

export const getClubTheme = (): Theme => {
    const theme  = window.location.hostname === domains.qpr ? qprtheme :
        window.location.hostname === domains.ffc ? ffctheme :
            window.location.hostname === domains.pufc ? pufctheme :
                getWhiteLabelClubName() === "FFC Academy" ? ffctheme :
                    getWhiteLabelClubName() === "QPR Academy" ? qprtheme :
                        getWhiteLabelClubName() === "PUFC Academy" ? pufctheme :
                        viatheme

    processClubCode(theme.custom.name)
    return theme
}

export const getClubContentApiHost = (): string => {
    const apiHost =
        window.location.hostname === domains.qpr ? process.env.REACT_APP_QPR_CONTENT_API_HOST :
            window.location.hostname === domains.ffc ? process.env.REACT_APP_FFC_CONTENT_API_HOST :
                window.location.hostname === domains.pufc ? process.env.REACT_APP_PUFC_CONTENT_API_HOST :
                    getWhiteLabelClubName() === "FFC Academy" ? process.env.REACT_APP_FFC_CONTENT_API_HOST :
                        getWhiteLabelClubName() === "QPR Academy" ? process.env.REACT_APP_QPR_CONTENT_API_HOST :
                            getWhiteLabelClubName() === "PUFC Academy" ? process.env.REACT_APP_PUFC_CONTENT_API_HOST :
                            ""
    console.log("content_api_host:", apiHost)
    return apiHost ? apiHost : ""
}

export const getClubContentAnalyticsId = (): string => {
    const analyticsId =
        window.location.hostname === domains.qpr ? process.env.REACT_APP_QPR_GA_MEASUREMENT_ID:
            window.location.hostname === domains.ffc ? process.env.REACT_APP_FFC_GA_MEASUREMENT_ID :
                window.location.hostname === domains.pufc ? process.env.REACT_APP_PUFC_GA_MEASUREMENT_ID :
                    getWhiteLabelClubName() === "FFC Academy" ? process.env.REACT_APP_FFC_GA_MEASUREMENT_ID :
                        getWhiteLabelClubName() === "QPR Academy" ? process.env.REACT_APP_QPR_GA_MEASUREMENT_ID :
                            getWhiteLabelClubName() === "QPR Academy" ? process.env.REACT_APP_PUFC_GA_MEASUREMENT_ID :
                        process.env.REACT_APP_GA_MEASUREMENT_ID
    console.log("content_analytics_id:", analyticsId)
    return analyticsId ? analyticsId : ""
}