import React from "react";
import {Box, Button, styled} from "@mui/material";
import {SocialProvider} from "../../../constants/social";
import {Analytics} from "../../../lib/analytics";

interface SocialSignUpGroupProps {
    setSelected: (selection: string) => void
    selected: string
}
const SocialChoiceGroup = styled(Box)(() => ({
    width: "100%",
    margin: "32px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    gap: "8px",
}));
const SocialChoiceButton = styled(Button)(({theme}) => ({
    [theme.breakpoints.up("xs")]: {
        margin: "0 7%",
        padding: "10px 7%",
    },
    [theme.breakpoints.up("sm")]: {
        margin: "0 14%",
        padding: "10px 12%",
    },
    borderRadius: "24px",
    textAlign: "center",
    justifyContent: "flex-start",
}));
const StyledImg = styled("img")(() => ({
    width: "24px",
    height: "24px",
    margin: "0 10px",
}));
const StyledButtonText = styled("div")(() => ({
    textAlign:'center',
    margin: '0 auto',
    fontWeight: 600,
}));

type selectedChoice = "contained" | "outlined"
const checkHighlighted = (selected: string, button: string): selectedChoice => {
    return (selected === button ? "contained" : "outlined")
}

export const SocialSignUpGroup = (props: SocialSignUpGroupProps): React.ReactElement => {

    return (
        <>
            <SocialChoiceGroup>
                <SocialChoiceButton className={props.selected === SocialProvider.Facebook ? "social-button-selected": ""} variant={checkHighlighted(props.selected, SocialProvider.Facebook)} color='secondary' onClick={() => {props.setSelected(SocialProvider.Facebook); Analytics.selectSocialLogin(SocialProvider.Facebook)}} >
                    <StyledImg src={process.env.PUBLIC_URL + "/social/fb.svg"} alt="Facebook"/><StyledButtonText>Continue with Facebook</StyledButtonText>
                </SocialChoiceButton>
                <SocialChoiceButton className={props.selected === SocialProvider.Apple ? "social-button-selected": ""} variant={checkHighlighted(props.selected, SocialProvider.Apple)} color='secondary' onClick={() => {props.setSelected(SocialProvider.Apple); Analytics.selectSocialLogin(SocialProvider.Apple)}} >
                    <StyledImg src={process.env.PUBLIC_URL + ( props.selected === SocialProvider.Apple ? "/social/apple-dark.svg" : "/social/apple.svg")} alt="Apple" /><StyledButtonText>Continue with Apple</StyledButtonText>
                </SocialChoiceButton>
                <SocialChoiceButton className={props.selected === SocialProvider.Google ? "social-button-selected": ""} variant={checkHighlighted(props.selected, SocialProvider.Google)} color='secondary' onClick={() => {props.setSelected(SocialProvider.Google); Analytics.selectSocialLogin(SocialProvider.Google)}} >
                    <StyledImg src={process.env.PUBLIC_URL + "/social/google.svg"} alt="Google" /><StyledButtonText>Continue with Google</StyledButtonText>
                </SocialChoiceButton>
            </SocialChoiceGroup>
        </>
    );
}
