import React, {useEffect, useState} from 'react'
import {CircularProgress, useTheme} from "@mui/material";
import {useAppSelector} from "../../redux/hooks";
import {LandingDownloadContent} from "./components/LandingDownloadContent";
import {RootState} from "../../redux/store";
import {BasicModal} from "../../shared/Modal";
import {NotRegisteredInstructions} from "./components/NotRegisteredInstructions";
import {DeviceType, getDeviceType} from "../../util/userAgent";
import { SpacedMain } from '../../shared/styles';
import {useQuery} from "../../hooks/useQuery";
import {accountNotFound, errorKey, providerNameKey} from "../../constants/urlParameters";
import {useSearchParams} from "react-router-dom";
import {Analytics} from "../../lib/analytics";
import {WhiteLabelledLandingContent} from "./components/WhiteLabelledLandingContent";


export const LandingDownload = (): JSX.Element => {
    const theme = useTheme()
    const queryErrorKey = useQuery().get(errorKey);
    const queryProviderName = useQuery().get(providerNameKey);
    const [searchParams, setSearchParams] = useSearchParams();
    const loading = useAppSelector<boolean>((state: RootState) => state.user.loading);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const onToggleResetModal = () => {
        searchParams.delete(errorKey);
        searchParams.delete(providerNameKey);
        setSearchParams(searchParams);
        setModalOpen(!modalOpen);
    }

    useEffect(() => {
        Analytics.landingView();
    }, []);

    return (
        <SpacedMain>
        {
            loading && sessionStorage.getItem("idToken") ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                        <CircularProgress/>
                </div>
                :
                <>
                    {theme.custom.isWhiteLabelled ? <WhiteLabelledLandingContent/> : <LandingDownloadContent/>}
                    {queryErrorKey === accountNotFound && <BasicModal darkModal={getDeviceType() !== DeviceType.Desktop} onControlledToggleModal={onToggleResetModal}>
                      <NotRegisteredInstructions providerName={queryProviderName}></NotRegisteredInstructions>
                    </BasicModal>}
                </>
        }
        </SpacedMain>
    )
}
