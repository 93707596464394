import React, {useCallback} from "react";
import {Box, CircularProgress, styled, Typography} from "@mui/material";
import {formatDate} from "../../../../../util/date";

export enum Status {
    Reviewing,
    Reviewed,
    Entered,
    Failure,
}
interface IFeedbackItemProps {
    videoItem: {
        src: string | null;
        title: string;
        status: Status;
        createdDate: number | null;
    },
    children?: JSX.Element;
}
const RowBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    marginBottom: "20px"
}));
const VideoTitle = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    fontWeight: "500",
    color: theme.custom.darkerPrimaryColor,
}));
const SubtitleBox = styled(Box)(() => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "5px",
    margin: "10px 0",
}));
const BoldSubtitle = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    color: theme.custom.darkerPrimaryColor
}));
const Subtitle = styled(BoldSubtitle)(() => ({
    fontWeight: "500",
}));
const ProcessingBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.uploadBoxColor : theme.palette.olive.main,
    height: "150px",
    display: "flex",
    maxWidth: "50%",
    width: "50%",
    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
}));
const ProcessingTypography = styled(Typography)(({theme}) => ({
    color: theme.custom.darkerPrimaryColor,
    fontSize: "12px",
    fontWeight: "500"
}));
const VideoStatus = styled(Typography)(({theme}) => ({
    color: theme.custom.darkerPrimaryColor,
    fontSize: "14px",
    fontWeight: "500",
    padding: "5px 8px",
    border: `1px solid ${theme.custom.darkerPrimaryColor}`,
    borderRadius: "4px",
    width: "fit-content"
}));

export const FeedbackItem = ({ videoItem, children }: IFeedbackItemProps) => {
    const getDateTitle = useCallback(() => {
        if (videoItem.status === Status.Reviewed) {
            return "Created";
        }

        if (videoItem.status === Status.Entered) {
            return "Entered";
        }

        return "Submitted";
    }, [videoItem.status]);

    const getVideoPreview = useCallback(() => {
        if (videoItem.src) {
            return (
                <video src={`${videoItem.src}#t=0.001`} className="preview-video" controls playsInline style={{ width: '100%', height: '100%' }}/>
            )
        }

        return (
            <ProcessingBox>
                <CircularProgress size={16}/>
                <ProcessingTypography>Processing...</ProcessingTypography>
            </ProcessingBox>
        );
    }, [videoItem.src]);

    return (
        <RowBox>
            {getVideoPreview()}
            <Box sx={{flex: 1, wordBreak: "break-word", display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <VideoTitle>{videoItem.title}</VideoTitle>
                {videoItem.createdDate && <SubtitleBox>
                    <BoldSubtitle>{getDateTitle()}:</BoldSubtitle> <Subtitle>{formatDate(videoItem.createdDate)}</Subtitle>
                </SubtitleBox>}
                {videoItem.status === Status.Reviewing && <VideoStatus className="video-status">In progress</VideoStatus>}
                {children}
            </Box>
        </RowBox>
    );
};
