import {Button, CircularProgress} from "@mui/material";
import {FileDownloadOutlined} from "@mui/icons-material";
import {ErrorTypography} from "../../../shared/styles";
import {generalMessage} from "../../../constants/common";
import React from "react";
import {coachFeedbackServiceApi} from "../../../services/coach-feedback-service";

interface IDownloadFeedbackProps {
    id: string;
    fileName: string;
}
export const DownloadFeedback = ({id, fileName}: IDownloadFeedbackProps) => {
    const [ trigger, {isFetching, error} ] = coachFeedbackServiceApi.endpoints.downloadVideo.useLazyQuery();

    const handleDownload = async () => {
        try {
            const response = await trigger(id);

            if (!response.data) {
                return;
            }

            const link = document.createElement('a');
            const url = window.URL || window.webkitURL;
            const blobPDF = url.createObjectURL(response.data);
            link.href = blobPDF;
            link.setAttribute('download', `${fileName}.mp4`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading video:", error);
        }
    };

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                sx={{textTransform: "uppercase", fontWeight: "600", maxWidth: '150px'}}
                endIcon={isFetching ? <CircularProgress size={16} color="secondary" />: <FileDownloadOutlined />}
                disabled={isFetching}
                onClick={() => handleDownload()}
            >
                Download
            </Button>
            {error && !isFetching && <ErrorTypography sx={{mt: "10px"}}>{generalMessage}</ErrorTypography>}
        </>
    );
}
