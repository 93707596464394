export const bytesInMb = 1024;
export const bytesIn1GB = bytesInMb * bytesInMb * bytesInMb;

export const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(bytesInMb))

    return `${parseFloat((bytes / Math.pow(bytesInMb, i)).toFixed(dm))}${sizes[i]}`
}
