import React, { memo } from "react";
import {Box, Button, styled, Typography} from "@mui/material";
import {EditTextArea} from "./EditTextArea";

export interface ITextBoxItemItem {
    id: number;
    value: string;
    isEditing: boolean;
}

interface ITextBoxItemProps {
    item: ITextBoxItemItem;
    errorText?: string;
    onEdit: (id: number, value?: string) => void;
    onChange: () => void;
}

const StyledBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    border: `1px solid ${theme.custom.isWhiteLabelled ? theme.palette.grey[500] : "#414141"}`,
    padding: "10px",
    margin: "20px 0",
}));
const StyledTypography = styled(Typography)(({theme}) => ({
    flexGrow: "1",
    color: theme.custom.isWhiteLabelled ? theme.custom.wl.improvementStep.color : "#414141"
}));
const StyledButton = styled(Button)(({theme}) => ({
    flexBasis: "30px",
    color: theme.palette.grey[500],
    textTransform: "none"
}));
export const TextBoxItem = memo(({item, errorText, onEdit, onChange}: ITextBoxItemProps): JSX.Element => {
    return (
        <>
            {
                item.isEditing ? (
                    <EditTextArea
                        defaultValue={item.value}
                        errorText={errorText}
                        onChange={onChange}
                        onSave={(value: string) => onEdit(item.id, value)}
                        onDiscard={() => onEdit(item.id)}
                    />
                ) : (
                    <StyledBox color="secondary">
                        <StyledTypography color="secondary">{item.value}</StyledTypography>
                        <StyledButton color="secondary" variant="text" size="small"
                                      onClick={() => onEdit(item.id)}>Edit</StyledButton>
                    </StyledBox>
                )
            }
        </>
    );
})
