export interface IMonthlyDraw {
    displayName: string;
    createdAt: number;
    othersInVideo: boolean;
    identificationNotes: string;
    orderName: string;
    orderAge?: number
    orderPosition?: string;
    improvementAreas: string[];
    isWinner: boolean;
    feedbackRequest: { organisationName: string } | null;
    media: {
        video: {
            mimeType: string;
            key: string;
            url: string;
        }
    }
    organisation?: string
}

export interface IMonthlyDrawResponse {
    display_name: string;
    created_at: number;
    others_in_video: boolean;
    identification_notes: string;
    order_name: string|null;
    order_age: number|null;
    order_position: string|null;
    improvement_areas: {detail: string}[];
    is_winner: boolean;
    feedback_request: {
        uuid: string;
        organisation: {
            uuid: string;
            name: string;
        }
    },
    media: {
        video: {
            mime_type: string;
            key: string;
            url: string;
            orientation: null;
            frame_image: {
                mime_type: string;
                key: string;
                orientation: null;
            }
        }
    },
    organisation?: string
}

export enum CoachFeedbackStatus {
    inReview = "IN_REVIEW",
    reviewed = "REVIEWED",
    userIdentificationRejected = "REJECT_USER_UNIDENTIFIABLE",
    videoQualityRejected = "REJECT_USER_VIDEO_QUALITY_ISSUE",
    improvementAreasRejected = "REJECT_AMBIGUOUS_IMPROVEMENT_AREAS",
    otherRejected = "REJECT_OTHER_ISSUE"
}
export enum FetchCoachFeedbackStatus {
    inReview = "IN_REVIEW",
    reviewComplete = "REVIEW_COMPLETE",
    userIdentificationRejected = "REJECT_USER_UNIDENTIFIABLE",
    videoQualityRejected = "REJECT_USER_VIDEO_QUALITY_ISSUE",
    improvementAreasRejected = "REJECT_AMBIGUOUS_IMPROVEMENT_AREAS",
    otherRejected = "REJECT_OTHER_ISSUE"
}
export const FailedCoachFeedbackStatus = {
    userIdentificationRejected: CoachFeedbackStatus.userIdentificationRejected,
    videoQualityRejected: CoachFeedbackStatus.videoQualityRejected,
    improvementAreasRejected: CoachFeedbackStatus.improvementAreasRejected,
    otherRejected: CoachFeedbackStatus.otherRejected,
} as const;

export type FailedCoachFeedbackStatusType = typeof FailedCoachFeedbackStatus[keyof typeof FailedCoachFeedbackStatus];

export interface ICoachFeedbackRequestData {
    page: number;
    pageSize: number;
    status: FetchCoachFeedbackStatus[];
}
export interface ICoachFeedbackData {
    totalCount: number;
    previousPageCount: number;
    nextPageCount: number;
    results: ICoachFeedbackResult[];
}
interface IVideoData {
    key: string;
    url: string;
}
interface IFeedbackData extends IVideoData {
    createdDate: number
}

export interface ICoachFeedbackResult {
    id: string;
    createdAt: number;
    displayName: string;
    order: {
        id: string;
        createdAt: number;
    } | null;
    organisationId: string;
    identificationNotes: string;
    othersInVideo: boolean;
    orderName: string;
    orderAge: number|null;
    orderPosition: string;
    improvementAreas: string[];
    status: CoachFeedbackStatus[];
    video: IVideoData | null;
    feedback: IFeedbackData | null;
}

export interface ICoachFeedbackItemResponse {
    uuid: string;
    created_at: number;
    display_name: string;
    due_days_remaining: number;
    order: {
        id: string;
        created_at: number;
        item: {
            id: string;
            display_name: string;
        }
    };
    identification_notes: string;
    others_in_video: boolean;
    order_name: string|null;
    order_age: number|null;
    order_position: string|null;
    improvement_areas: {detail: string}[];
    user: {
        uuid: string;
        handle: string;
        avatar: string;
    };
    organisation: {
        uuid: string;
        name: string;
        category: number;
        abbreviation: string;
    };
    status: CoachFeedbackStatus[];
    feedback: {
        sign_off_date: number;
        media: [
            {
                video: {
                    mime_type: string;
                    url: string;
                    key: string;
                    orientation: string;
                    label: string;
                    last_updated: number;
                    frame_image: null
                }
            }
        ],
        reviewer: {
            uuid: string;
            created_at: number;
            first_name: string | null;
            last_name: string | null;
            organisation_id: string;
        },
        video: {
            mime_type: string;
            url: string;
            key: string;
            orientation: string;
            frame_image: {
                mime_type: string;
                url: string;
                key: string;
                orientation: string;
            },
            stream: {
                mime_type: string;
                url: string;
                key: string;
                orientation: string;
            }
        } | null;
    } | null;
    video: {
        video: {
            mime_type: string;
            url: string;
            key: string;
            orientation: string;
        };
    } | null;
}
