import React, {useCallback, useEffect} from "react"
import {Box, Link, styled, Typography} from "@mui/material";
import {MobileDownloadButtons} from "../../../../shared/MobileDownloadButtons";
import {DeviceType, getDeviceType} from "../../../../util/userAgent";
import {routes} from "../../../../constants/routes";
import {useLocation, useNavigate} from "react-router-dom";
import {Analytics} from "../../../../lib/analytics";

interface IDownloadModalContentProps {
    cb?: () => void;
}
const HeaderTypography = styled(Typography)(() => ({
    fontSize: "25px",
    fontWeight: "700",
    textAlign: "center",
}));
const ContentBox = styled(Box)(({theme}) => ({
    flexDirection: "column",
    color: theme.palette.primary.contrastText,
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "center",
    marginTop: "15px"
}));
const StyledImg = styled("img")(() => ({
    maxWidth: "120px",
    margin: "30px 0"
}));
const StyledTypography = styled(Typography)(() => ({
    fontSize: "14px",
    fontWeight: "500",
}));
const StyledNoteText = styled(Typography)(() => ({
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "400",
    paddingBottom: "20px"
}));
const StyledLink = styled(Link)(() => ({
    cursor: "pointer",
    color: "#A0720E"
}));

export const DownloadModalContent = ({cb}: IDownloadModalContentProps) => {
    useEffect(() => {
        Analytics.createAccountUnderAgeDownloadButtonClick();
    }, []);
    const navigate = useNavigate();
    const location = useLocation();
    const onSignIn = useCallback(() => {
        if (cb) {
            cb();
        }
        if (location.pathname !== routes.signIn) {
            navigate(routes.signIn);
        }
    }, [cb, location.pathname, navigate]);
    return (
        <Box>
            <HeaderTypography color="common.black">Download VIA Academy</HeaderTypography>
            <ContentBox>
                <StyledTypography>A VIA Academy account is required to use Coach Feedback. Download the app and create an account for free.</StyledTypography>
                {getDeviceType() === DeviceType.Desktop ? (
                    <StyledImg src={process.env.PUBLIC_URL + "/download_qr.svg"} alt="Download app"/>
                ) : (
                    <MobileDownloadButtons/>
                )}
                <StyledNoteText>Already have an account? <StyledLink onClick={onSignIn}>Sign In</StyledLink></StyledNoteText>
            </ContentBox>
        </Box>
    );
}
