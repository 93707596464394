import {format} from 'date-fns';

enum DateFormat {
    doMMMMyyyy = "do MMMM yyyy",
}

export const formatDate = (timestamp: number, dateFormat = DateFormat.doMMMMyyyy) => {
    const date = new Date(timestamp * 1000);
    return format(date, dateFormat);
};
