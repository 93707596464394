export enum ErrorCode {
    unauthorized = 401,
    forbidden = 403,
    notFound = 404
}

export interface IErrorResponse {
    full_message: string;
    message: string;
    status: ErrorCode;
}
