import {Box, Button, Divider, styled, TextField, Typography} from "@mui/material";

export const RowBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    gap: "10px",
}));
export const ColumnBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
}));
export const ErrorTypography = styled(Typography)(({theme}) => ({
    color: `${theme.palette.warning.main} !important`,
    fontSize: "12px",
    fontWeight: "500",
    marginTop: "5px"
}));
export const SubtitleErrorTypography = styled(ErrorTypography)(() => ({
    fontSize: "14px",
}));
export const TitleErrorTypography = styled(ErrorTypography)(() => ({
    fontSize: "16px",
}));

export const VerticallyCenteredItemsBox = styled(RowBox)(() => ({
    alignItems: "center",
}));
export const CenteredItemsBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
}));

export const CircledButton = styled(Button)(() => ({
    borderRadius: "20px"
}));
export const PurchaseCtaBox = styled(Box)(() => ({
    display: "flex",
    flexGrow: "1",
    justifyContent: "space-around",
}));
export const PurchaseStyledCTA = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "600",
    textTransform: "none",
}));
export const PurchaseListTypography = styled(Typography)(() => ({
    fontSize: "14px",
    lineHeight: "22px",
    "ol": {
        paddingLeft: "16px",
        "li": {
            marginBottom: "5px",
        },
    },
}));
export const SpacedMain = styled(ColumnBox)(() => ({
    flex: 1,
    marginTop: "20px",
}));
export const LightDivider = styled(Divider)(({theme}) => ({
    borderColor: `${theme.palette.grey[700]} !important`
}));
export const DashedLighterDivider = styled(Divider)(({theme}) => ({
    borderStyle:'dashed',
    borderColor: theme.palette.grey[800]
}));

export const StyledUserFeedbackTextField = styled(TextField)(() => ({
    "& label.Mui-focused": {

        color: "#AAAAAA" // Change label color when input is focused
    },
    "&:hover label": {
        color: "#AAAAAA", // Change label color when input is hovered
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#AAAAAA',
            // borderStyle: 'dashed'
        },
        '&:hover fieldset': {
            borderColor: '#AAAAAA',
            // borderStyle: 'dashed'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#AAAAAA',
            borderStyle: 'solid',
            borderWidth: "1px",
        },
    },
}))
