import {useQuery} from "./useQuery";
import {EditFeedbackReason, editReason} from "../constants/urlParameters";

const useQueryEditReasons = () => {
    const queryEditReasons = useQuery().get(editReason)?.split(",") ?? [];

    const has = {
        noIssues: !queryEditReasons.length,
        identifyingCommentReason: queryEditReasons.includes(EditFeedbackReason.identifyingComment),
        videoQualityReason: queryEditReasons.includes(EditFeedbackReason.videoQuality),
        improvementAreasReason: queryEditReasons.includes(EditFeedbackReason.improvementAreas),
        playerInformationReason: queryEditReasons.includes(EditFeedbackReason.playerInformation),
        videoReason: queryEditReasons.includes(EditFeedbackReason.videoQuality),
        failedUploadVideoStepReason: queryEditReasons.includes(EditFeedbackReason.videoQuality) || queryEditReasons.includes(EditFeedbackReason.identifyingComment),

    };
    const only = {
        identifyingComment: queryEditReasons.length === 1 && queryEditReasons[0] === EditFeedbackReason.identifyingComment,
        videoQuality: queryEditReasons.length === 1 && queryEditReasons[0] === EditFeedbackReason.videoQuality,
        playerInformation: queryEditReasons.length === 1 && queryEditReasons[0] === EditFeedbackReason.playerInformation,
        improvementAreas: queryEditReasons.length === 1 && queryEditReasons[0] === EditFeedbackReason.improvementAreas,
    };
    const first = {
        identifyingComment: queryEditReasons[0] === EditFeedbackReason.identifyingComment,
        videoQuality: queryEditReasons[0] === EditFeedbackReason.videoQuality,
        playerInformation: queryEditReasons[0] === EditFeedbackReason.playerInformation,
        improvementAreas: queryEditReasons[0] === EditFeedbackReason.improvementAreas,
    };
    const allow = {
        modifyImprovementAreas: has.videoReason || has.improvementAreasReason,
    };

    return [has, only, first, allow] as const;
};

export default useQueryEditReasons;
