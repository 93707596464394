import React, {useEffect, useState} from "react";
import {Box, Divider, FormControlLabel, Link, Radio, styled, Typography} from "@mui/material";
import {CheckBoxOutlined, CheckBoxOutlineBlank} from "@mui/icons-material";
import {ScreenLayout} from "../../../shared/ScreenLayout";
import {AddDebitCard} from "./AddDebitCard";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {Links} from "../../../constants/links";
import {ICommonStepProps} from "../../../types/stepProps";
import {useQuery} from "../../../hooks/useQuery";
import {update} from "../../../constants/urlParameters";
import {Analytics} from "../../../lib/analytics";
import {useTheme} from "@mui/material";

interface IPaymentCardsStepProps extends ICommonStepProps {
    paymentCardsStepData: IPaymentCardsData | null;
    onBack: () => void;
    onCompletePaymentCardsStep: (data: IPaymentCardsData) => void;
    submitErrorCopy: string | null;
    handleSetSubmitErrorCopy: (submitErrorMsg:string) => void;
}

export interface IPaymentCardsData {
    paymentMethodId: string;
    name: string;
    cardDetails: {
        last4: string;
        expiration: string;
    }
    billingAddress: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postalCode: string;
        state: string;
    };
    email: string;
}

export enum StripeElementsConfirmation {
    None,
    ShouldConfirm,
    Confirming,
    Confirmed,
    NotConfirmed,
}

const StickyFormControlLabel = styled(FormControlLabel)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "16px",
    margin: 0
}));
export const PaymentCardsStep = ({paymentCardsStepData, onBack, onCompletePaymentCardsStep, onCancelUpdateStep, submitErrorCopy, handleSetSubmitErrorCopy}: IPaymentCardsStepProps): JSX.Element => {
    const theme = useTheme();
    const queryUpdate = useQuery().get(update);
    const paymentError = useAppSelector<string>((state: RootState) => state.orderSlice.error);
    const [over18Years, setOver18Years] = useState<boolean>(false);
    const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
    const [confirmStripeElements, setConfirmStripeElements] = useState<StripeElementsConfirmation>(StripeElementsConfirmation.None);
    const [submitError, setSubmitError] = useState("");

    useEffect(() => {
        Analytics.paymentView();
    }, []);

    useEffect(() => {
        setSubmitError(paymentError);
    }, [paymentError]);

    useEffect(() => {
        if (submitErrorCopy) {
          setSubmitError(submitErrorCopy);
        }
    }, [submitErrorCopy])

    const onComplete = () => {
        if (confirmStripeElements !== StripeElementsConfirmation.Confirmed) {
            setConfirmStripeElements(StripeElementsConfirmation.ShouldConfirm);
        }
        Analytics.reviewButtonClick();
        handleSetSubmitErrorCopy("");
    }
    return (
        <ScreenLayout
            title="Payment Details"
            ctaTitle={queryUpdate ? "Save" : "Review"}
            ctaDisabled={!over18Years || showFormErrors}
            bottomSection={(
                <Box>
                    <Divider/>
                    <StickyFormControlLabel
                        control={
                            <Radio
                                checked={over18Years}
                                sx={{pl: 0}}
                                icon={<CheckBoxOutlineBlank color="secondary"/>}
                                checkedIcon={<CheckBoxOutlined color="secondary"/>}
                                onClick={() => setOver18Years(!over18Years)}
                            />
                        }
                        label={
                            <Typography>
                                I am over 18 years old and I agree to the <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms}>Terms and Conditions</Link>. Our&nbsp; <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy}>Privacy Policy</Link>&nbsp;applies.
                            </Typography>
                        }
                    />
                </Box>
            )}
            errorMessage={submitError}
            onBack={onBack}
            onCancel={queryUpdate ? onCancelUpdateStep : undefined}
            onAction={onComplete}
            contentFeedBackgroundColor={theme.custom.isWhiteLabelled ? theme.custom.mainBackgroundColor : undefined}
        >
            <AddDebitCard
                paymentCardsStepData={paymentCardsStepData}
                showFormErrors={showFormErrors}
                setShowFormErrors={setShowFormErrors}
                confirmStripeElementsStatus={confirmStripeElements}
                setConfirmStripeElementsStatus={setConfirmStripeElements}
                submitError={submitError}
                setSubmitError={setSubmitError}
                onSubmit={onCompletePaymentCardsStep}
            />
        </ScreenLayout>
    );
}
