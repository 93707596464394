import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {AppConfig} from "../config";
import {IResubmitOrderRequestData} from "../features/order/requestData";
import {ICoachFeedbackItemResponse} from "../types/coachFeedback";
import {getIdToken} from "./cognito";

export const orderServiceApi = createApi({
    reducerPath: "orderServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${AppConfig.API_HOST}/v1/coach-feedback-requests`,
        prepareHeaders: async (headers) => {
            const token = await getIdToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Order"],
    endpoints: (build) => ({
        resubmitOrder: build.query<ICoachFeedbackItemResponse, {id: string, requestBody: IResubmitOrderRequestData}>({
            query: ({ id, requestBody }) => ({
                url: `/${id}`,
                method: "PATCH",
                body: requestBody,
            }),
        }),
    }),
})
