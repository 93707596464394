import * as Sentry from "@sentry/react";


class Logger {

    initialised: boolean = false;

    init(environment: string, allowedOrigin: string) {
        if (!this.initialised) {
            Sentry.init({
                environment: environment,
                dsn: "https://63f117c04b73b752ee630f6e20ec9288@o4506299205156864.ingest.sentry.io/4506299214135296",
                integrations: [
                new Sentry.BrowserTracing({
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: [(new URL(allowedOrigin)).origin],
                }),
                new Sentry.Replay(),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, // Capture 100% of the transactions
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
            this.initialised = true;
        }
    }

    log(message: string) {
        if (this.initialised) {
          Sentry.captureMessage(message);
        } else {
            console.warn("Unable to log message - Logger not initialised.");
        }
    }
}

const instance = new Logger();

export default instance;
