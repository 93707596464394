import {ICreateOrderRequestData, IOrderMetaData} from "./orderSlice";
import {getCouponCode, getLuckyDrawClubName} from "../../util/storage";

interface IOrderData {
    othersInVideo: string;
    othersInVideoComment: string;
    orderName: string;
    orderAge?: number
    orderPosition?: string
    improvementAreas: string[];
    videoUrlKey: string;
    videoMimeType: string;
    paymentIntentId?: string
    payment_intent_id?: string
    id?: string | undefined;
    organisation?: string
}

interface IResubmitOrderData {
    othersInVideo?: string;
    othersInVideoComment?: string;
    improvementAreas?: string[];
    videoUrlKey?: string;
    videoMimeType?: string;
}

export interface IResubmitOrderRequestData {
    others_in_video?: boolean;
    identification_notes?: string;
    improvement_areas?: {detail: string}[],
    preview_url?: string,
    media?: {
        video: {
            mime_type: string;
            key: string;
        }
    }
}

export const createOrderRequestData = (
  academySku: string,
  source: string,
  {
    othersInVideo,
    othersInVideoComment,
      orderName,
      orderAge,
      orderPosition,
    improvementAreas,
    videoUrlKey,
    videoMimeType
  }: IOrderData
): ICreateOrderRequestData => {
    return {
        coupon_code: !!getCouponCode() ? getCouponCode() : undefined,
        source: source,
        items: [{
            sku: academySku,
            quantity: 1,
            metadata: {
                others_in_video: othersInVideo.includes("Yes"),
                order_name: orderName,
                order_age: orderAge ? orderAge : undefined,
                order_position: !!orderPosition ? orderPosition : undefined,
                identification_notes: othersInVideoComment,
                improvement_areas: improvementAreas.map(value => ({detail: value})),
                media: {
                    video: {
                        mime_type: videoMimeType,
                        key: videoUrlKey,
                    }
                }
            }
        }]
    };
}

export const createMonthlyDrawRequestData = ({
   videoUrlKey,
   videoMimeType,
   othersInVideo,
   orderName,
   orderAge,
   orderPosition,
   othersInVideoComment,
   improvementAreas,
   organisation
}: IOrderData): IOrderMetaData => {
    return {
        others_in_video: othersInVideo.includes("Yes"),
        identification_notes: othersInVideoComment,
        order_name: orderName,
        order_age: orderAge,
        order_position: orderPosition,
        improvement_areas: improvementAreas.map(value => ({detail: value})),
        media: {
            video: {
                mime_type: videoMimeType,
                key: videoUrlKey,
            }
        },
        organisation: organisation || (!!getLuckyDrawClubName() ? getLuckyDrawClubName() : undefined)
    };
}

export const resubmitOrderRequestData = ({
    othersInVideo,
    othersInVideoComment,
    improvementAreas,
    videoUrlKey,
    videoMimeType,
}: IResubmitOrderData): IResubmitOrderRequestData => {
    const data: IResubmitOrderRequestData = {};

    if (othersInVideo) {
        data.others_in_video = othersInVideo.includes("Yes");
        data.identification_notes = othersInVideoComment;
    }
    if (improvementAreas) {
        data.improvement_areas = improvementAreas.map(value => ({detail: value}));
    }
    if (videoUrlKey && videoMimeType) {
        data.media = {
            video: {
                mime_type: videoMimeType,
                key: videoUrlKey,
            }
        };
    }

    return data;
}
