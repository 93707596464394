import React, {useCallback, useEffect, useState} from "react";
import {ImprovementAreaList} from "./components/ImprovementAreaList";
import {AddSomethingBox} from "./components/AddSomethingBox";
import {ITextBoxItemItem, EditTextArea} from "../../../shared/text-field";
import {ScreenLayout} from "../../../shared/ScreenLayout";
import { ErrorTypography } from "../../../shared/styles";
import useQueryEditReasons from "../../../hooks/useQueryEditReasons";
import {Box, useTheme} from "@mui/material";
import {useQuery} from "../../../hooks/useQuery";
import {update} from "../../../constants/urlParameters";
import {ICommonStepProps} from "../../../types/stepProps";
import {Analytics} from "../../../lib/analytics";
import {useAppSelector} from "../../../redux/hooks";
import {ICoachFeedbackResult} from "../../../types/coachFeedback";
import {RootState} from "../../../redux/store";

interface IImprovementAreaStepProps extends ICommonStepProps {
    improvementAreasStepData: string[];
    monthlyDrawFlow: boolean;
    onBack: () => void;
    onCompleteImprovementAreaStep: (data: string[]) => void;
}
interface IImprovementAreasList extends ITextBoxItemItem {
    id: number;
    value: string;
    isEditing: boolean;
}

const maxImprovementAreasLength = 3;
const paymentCtaTitle = "Payment";
const reviewCtaTitle = "Review";
export const ImprovementAreaStep = ({improvementAreasStepData, monthlyDrawFlow, onBack, onCompleteImprovementAreaStep, onCancelUpdateStep}: IImprovementAreaStepProps) => {
    const theme = useTheme();
    const queryUpdate = useQuery().get(update);
    const [has, , , allow] = useQueryEditReasons();
    const modifyingOrder = useAppSelector<ICoachFeedbackResult | null>((state: RootState) => state.orderSlice.modifyingOrder);
    const [list, setList] = useState<IImprovementAreasList[]>([]);
    const [addingNew, setAddingNew] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const modifyingOrderWasUpdated = useCallback(() => {
        if (!list.length || list.some(listItem => listItem.isEditing) || !modifyingOrder?.improvementAreas.length) {
            return false;
        }

        return list.map(listItem => listItem.value).sort().join() === [...modifyingOrder.improvementAreas].sort().join();
    }, [list, modifyingOrder?.improvementAreas]);
    const getCTATitle = useCallback(() => {
        if (allow.modifyImprovementAreas || queryUpdate) {
            return "Save";
        }
        return monthlyDrawFlow ? reviewCtaTitle : paymentCtaTitle;
    }, [allow.modifyImprovementAreas, queryUpdate, monthlyDrawFlow]);

    useEffect(() => {
        Analytics.addImprovementAreasView();
        if (improvementAreasStepData.length) {
            setList(improvementAreasStepData.map((value, id) => ({id, value, isEditing: false})));
            setAddingNew(false);
        }
    }, [improvementAreasStepData]);

    const onAddNew = () => {
        Analytics.addImprovementAreasButtonClick();
        setAddingNew(true);
    };
    const onEdit = useCallback((id: number, value?: string) => {
        if (value?.length && !value.trim().length) {
            setError("This field cannot be blank");
            return;
        }
        setList((prevList) =>
            prevList.map((item) => item.id === id ? { ...item, value: value?.trim() ?? item.value, isEditing: !item.isEditing } : item)
        );
        setAddingNew(false);
        setError("");
    }, []);
    const onChange = useCallback(() => setError(""), [setError])
    const onSave = (area: string) => {
        if (!area.trim().length) {
            setError("This field cannot be blank");
            return;
        }
        Analytics.addImprovementAreaDone();
        setAddingNew(false);
        setList([...list, { id: list.length, value: area.trim(), isEditing: false } ]);
        setError("");
    };
    const validateList = (): boolean => {
        if (!list.length && addingNew) {
            setError("You need to add at least one improvement area");
            return false;
        }
        if ((list.length > 0 && addingNew) || list.some(item => item.isEditing)) {
            setError("Please save your changes or discard them to proceed");
            return false;
        }

        return true;
    }


    const onComplete = () => {
        if (getCTATitle() === paymentCtaTitle) {
            Analytics.paymentButtonClick();
        }
        if (getCTATitle() === reviewCtaTitle) {
            //Analytics.reviewButtonClick();
        }
        if (validateList()) {
            onCompleteImprovementAreaStep(list.map(item => item.value));
        }
    }

    return (
        <ScreenLayout
            title="Add improvement areas"
            subtitle="Tell the coach up to 3 things the player wants to improve on."
            ctaTitle={getCTATitle()}
            ctaDisabled={!!error}
            onBack={has.noIssues ? onBack : undefined}
            onCancel={queryUpdate ? onCancelUpdateStep : undefined}
            onAction={onComplete}
            contentFeedBackgroundColor={theme.custom.isWhiteLabelled ? theme.custom.mainBackgroundColor : undefined}
        >
            <Box>
                {has.improvementAreasReason && modifyingOrderWasUpdated() && (
                    <ErrorTypography sx={{m: "10px 0 20px 10px"}}>Please provide more detailed information about the areas where you want to improve.</ErrorTypography>
                )}
                {!!list.length && <ImprovementAreaList list={list} errorText={error} onEdit={onEdit} onChange={onChange}/>}
                {addingNew && (
                    <EditTextArea
                        errorText={error}
                        placeholderValue="E.g. I want to improve my passing"
                        onChange={onChange}
                        onSave={onSave}
                    />
                )}
                <AddSomethingBox
                    sx={{mt: "20px"}}
                    boxTitle="Add something else"
                    active={!addingNew && !list.some(item => item.isEditing) && list.length < maxImprovementAreasLength}
                    onAddNew={onAddNew}
                />
            </Box>
        </ScreenLayout>
    );
}
