import React, {useState} from "react";
import {Box, IconButton, styled, useTheme} from "@mui/material";
import {socialSignIn} from "../../../services/cognito";
import {SocialProvider} from "../../../constants/social";
import {ErrorTypography} from "../../../shared/styles";
import {Analytics} from "../../../lib/analytics";
import {getAnalyticsProvider} from "../../../util/analytics";

interface ISocialLoginGroupProps {
    submitCognitoError: string | null
}
const SocialGroup = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "40px",
}));
const SocialButton = styled(IconButton)(() => ({
    margin: "10px 0",
    borderRadius: "24px",
}));
const StyledImg = styled("img")(() => ({
    width: "24px",
    height: "24px",
}));
export const SocialLoginGroup = (props: ISocialLoginGroupProps): JSX.Element => {
    const theme = useTheme()

    const [authenticating, setAuthenticating] = useState<boolean>(false);

    const signIn = async (provider: string) => {
        const analyticsProvider = getAnalyticsProvider(provider);
        if (analyticsProvider) {
            Analytics.signInAttempt(analyticsProvider);
        }
        setAuthenticating(true);
        try {
            await socialSignIn(provider);
        } finally {
            setAuthenticating(false);
        }
    }
    return (
        <>
            <SocialGroup>
                {theme.custom.name !== 'qpr' ?
                    <>
                        <SocialButton onClick={() => signIn(SocialProvider.Facebook)} disabled={authenticating}>
                            <StyledImg src={process.env.PUBLIC_URL + "/social/fb.svg"} alt="Facebook"/>
                        </SocialButton>
                        <SocialButton onClick={() => socialSignIn(SocialProvider.Apple)} disabled={authenticating}>
                            <StyledImg src={process.env.PUBLIC_URL + "/social/apple.svg"} alt="Apple" />
                        </SocialButton>
                        <SocialButton onClick={() => socialSignIn(SocialProvider.Google)} disabled={authenticating}>
                            <StyledImg src={process.env.PUBLIC_URL + "/social/google.svg"} alt="Google" />
                        </SocialButton>
                    </>
                    :
                    <SocialButton onClick={() => socialSignIn(SocialProvider.KOREQPR)} disabled={authenticating}>
                        <StyledImg src={theme.custom.brandTitleLogo} alt="QPR" />
                    </SocialButton>
                }
            </SocialGroup>
            {props.submitCognitoError && <ErrorTypography align={"center"}>{props.submitCognitoError}</ErrorTypography>}
        </>
    );
}
