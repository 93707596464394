export const rulesText = {
    required: (field: string) => `Please enter your ${field}`,
    validity: (field: string) => `Please enter a valid ${field}`,
};

export const emailRules = {
    required: { value: true, message: rulesText.required("email") },
    notValid: { value: true, message: rulesText.validity("email") },
};
export const passwordRules = {
    required: { value: true, message: rulesText.required("password") },
    minLength: { value: 8, message: "Your password must be at least 8 characters" },
};
