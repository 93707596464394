import React, {useEffect, useState} from 'react'
import {Analytics} from "../../lib/analytics";
import {usePurchaseNavigate} from "../../hooks/usePurchaseNavigate";
import {useNavigate} from "react-router-dom";
import {
    checkIsAnonymousUser, getAttemptedPage, getGotoUrl,
    isSignupMode, setCurrentSiteSection, setRedirectAfterLogin,
    setSignupMode,
    SiteSections
} from "../../util/storage";
import {useAppDispatch} from "../../redux/hooks";
import {createAnonAccountThunk, loginAnon} from "../../features/user/userSlice";
import {GuestGatewayComponent} from "./GuestGatewayComponent";
import {routes} from "../../constants/routes";
import {clearPreventEmailSignInTimeOut, preventEmailSignInTimeOut} from "../../services/cognito";
import {LoaderWithWatchdog} from "../../shared/LoaderWithWatchdog";



export const GuestGateway = (): React.ReactElement => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [chosen, setChosen] = useState<SiteSections|null>(null)
    const [, deletePurchaseFlowData ] = usePurchaseNavigate();

    const AnonLoginClickHandler = (goto: SiteSections) => {
        setChosen(goto)
        setCurrentSiteSection(goto)
        // Analytics.createAccountAgeContinueButtonClick(1)
        deletePurchaseFlowData()
        setSignupMode(0, false, true)
        clearPreventEmailSignInTimeOut()
        dispatch(createAnonAccountThunk())
    }

    useEffect(()=> {

        const loginIfAnon = async (email: string, pw: string) => {
                await loginAnon(email, pw)
        }
        const email = localStorage.getItem('anon_email')
        const pw = localStorage.getItem('anon_pw')
        console.log("anon_email", email)
        if(email && pw && !checkIsAnonymousUser() && !preventEmailSignInTimeOut() ) {
            loginIfAnon(email,pw).then(() => {
                navigate(
                    getAttemptedPage() === "/" ? routes.home
                        : getGotoUrl()
                )
            })
        }else{
            // rehydrateSession()
        }

    },[navigate])

    if(chosen === null && (isSignupMode() || !!localStorage.getItem("anon_email"))){
        return( <LoaderWithWatchdog/>)
    }

    return(
        <GuestGatewayComponent
            onInsightClick={()=>{
                Analytics.homeInsightsButtonClick();
                setRedirectAfterLogin()
                if(!chosen)
                    AnonLoginClickHandler("Insights")
            }}
            onFeedbackClick={()=>{
                Analytics.homeFeedbackButtonClick();
                setRedirectAfterLogin()
                if(!chosen)
                    AnonLoginClickHandler("Feedback")
            }}
            onTrainingClick={()=>{
                Analytics.homeTrainingButtonClick();
                setRedirectAfterLogin()
                if(!chosen)
                    AnonLoginClickHandler("Training")
            }}
            chosen={chosen}
        />
    )
}


