import {IErrorResponse} from "../constants/error";
import {generalMessage} from "../constants/common";

export const getErrorMessage = (errorData: any) => {
    if ('data' in errorData) {
        return (errorData.data as IErrorResponse)?.message ?? generalMessage;
    }

    return generalMessage;
}
