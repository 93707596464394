import React, {useEffect, useState} from 'react'
import {Box, styled, Typography} from "@mui/material";
import {Loader} from "../../shared/Loader";
import {ScreenLayout} from "../../shared/ScreenLayout";
import {
    clearPosts,
    FeedItem,
    FeedItemPreview,
    getAcademyContentThunk
} from "../../features/academyContent/academyContentSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {CenteredItemsBox} from "../../shared/styles";
import {FeedItemSifter} from "./FeedItemSifter";
import {useLocation} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {Analytics} from "../../lib/analytics";
import {setFeedParentPages} from "../../lib/asyncUtils";
import {setCurrentSiteSection} from "../../util/storage";

export const StyledCardClickOverlay = styled(Typography)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    // width: "100%",
    // height: "100%",
    "&:hover": {
        cursor: "pointer",

    },
    background: "rgba(0, 0, 0, 0)", /* optional for visual indication */
    zIndex: 21, /* ensure it's on top of the buttons */
}));

export const AcademyUpdates = (): React.ReactElement => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Extract parameters
    const cancelPurchase = queryParams.get('cancelPurchase') === "true";

    const dispatch = useAppDispatch()
    const loading = false

    const theme = useTheme()

    const contentItems = useAppSelector<FeedItem[]>((state: RootState) => state.academyContent.academyFeedItems);
    const fetchingAcademyItems = useAppSelector<boolean>((state: RootState) => state.academyContent.fetchingAcademyItems);
    const fetchingAcademyPage = useAppSelector<number>((state: RootState) => state.academyContent.fetchingAcademyPage);
    const totalAcademyFeedItems = useAppSelector<number>((state: RootState) => state.academyContent.totalAcademyFeedItems);
    const academyItemLimit = useAppSelector<number>((state: RootState) => state.academyContent.academyItemLimit);
    const [likertItemsSent, setLikertItemsSent] = useState<number[]>([])
    const [likertItemsDone, setLikertItemsDone] = useState<number[]>([])
    const [nextGetMoreTime, setNextGetMoreTime] = useState<number>(1000)
    const [showSubWall, setShowSubWall] = useState<boolean>(cancelPurchase)

    const addLikertResponseSending = (newVal: number): void => {
        setLikertItemsSent(likertItemsSent.concat([newVal]))
    }
    const addLikertItemDone = (newVal: number): void => {
        setLikertItemsDone(likertItemsSent.concat([newVal]))
    }

    useEffect(() => {
        dispatch(clearPosts())
    }, [dispatch]);

    useEffect(() => {

        const setParentPages = async () => {await setFeedParentPages(theme)}

        setParentPages().then()
        dispatch(getAcademyContentThunk({offset: 0, limit: academyItemLimit}))
    },[dispatch, academyItemLimit, theme])


    useEffect(() => {
        const handleScroll  = () => {
            const now = new Date().getTime();
            if( ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 120)  && now > nextGetMoreTime && !fetchingAcademyItems && contentItems.length < totalAcademyFeedItems ){
                console.log(contentItems)
                setNextGetMoreTime(now + 2000)
                dispatch(getAcademyContentThunk({offset: contentItems.length, limit: academyItemLimit}))
            }
        };

        const intervalId = setInterval(() => {
            handleScroll()
        }, 250);

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(intervalId);
        };
    }, [nextGetMoreTime, dispatch, fetchingAcademyItems, fetchingAcademyPage, academyItemLimit, totalAcademyFeedItems, contentItems]);

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        Analytics.insightsView();
        setCurrentSiteSection("Insights")
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change

    return (
        <>
            <Box sx={{display: loading ? "none" : "flex", flex: 1 }}>
                <ScreenLayout
                    title={"Insights"}
                    ctaTitle={""}
                    displayCta={false}
                    onAction={()=>{}}
                    hideSubWall={()=>setShowSubWall(false)}
                    reloadSignIn={ async ()=> {
                        setShowSubWall(false)
                        setTimeout(()=> setShowSubWall(true), 0.001 )
                    }}
                    displaySubGateway={showSubWall}
                >
                        <>
                        <Typography style={{
                            fontWeight: 400, 
                            backgroundColor: "#FFFFFF", 
                            fontSize: "16px", 
                            color: "#666666", 
                            paddingLeft: "16px", 
                            paddingBottom: "8px", 
                            paddingRight: "18px", 
                            marginLeft: "-8px", 
                            marginRight: "-8px", 
                            marginBottom: "8px", 
                            width: "auto", 
                            flexGrow: 1,}}>
                        Go behind-the-scenes in the {theme.custom.academyCommonName} with weekly updates from our coaches
                        </Typography>
                            {contentItems.map(((item: FeedItemPreview) =>
                                    <section key={item.id} id={"item-" + item.id}>
                                        <FeedItemSifter
                                            item={item}
                                            likertResponseSending={likertItemsSent.includes(item.id)}
                                            addLikertResponseSending={addLikertResponseSending}
                                            likertDone={likertItemsDone.includes(item.id)}
                                            addLikertItemDone={addLikertItemDone}
                                            activateSubWallFn={()=>setShowSubWall(true)}
                                            showingSubWall={showSubWall}
                                        />
                                    </section>
                            ))}

                            <div style={{paddingTop: "8px"}}>
                                { !fetchingAcademyItems && contentItems.length < totalAcademyFeedItems
                                    ?
                                    <CenteredItemsBox>
                                        <MoreVertIcon
                                            sx={{
                                                "&:hover": { cursor: "pointer"}
                                            }}
                                            onClick={() => {
                                                console.log("fetchingAcademyPage: ", fetchingAcademyPage)
                                                dispatch(getAcademyContentThunk({
                                                    offset: contentItems.length,
                                                    limit: academyItemLimit
                                                }))
                                            }}
                                            fontSize="large"/>
                                    </CenteredItemsBox>
                                    : null
                                }
                                { fetchingAcademyItems ? <Loader/> : null }
                            </div>

                            {/*<LikertScale />*/}
                            {/*<QnA />*/}
                        </>
                </ScreenLayout>
            </Box>
        </>
    )

}