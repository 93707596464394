import {CoachFeedbackStatus} from "../types/coachFeedback";
import {EditFeedbackReason} from "../constants/urlParameters";

export const getEditReasons = (statuses: CoachFeedbackStatus[], firstRoute: EditFeedbackReason): EditFeedbackReason[] => {
    const editReasons: EditFeedbackReason[] = [];

    statuses.forEach((status) => {
        switch (status) {
            case CoachFeedbackStatus.videoQualityRejected:
                editReasons.push(EditFeedbackReason.videoQuality);
                break;
            case CoachFeedbackStatus.userIdentificationRejected:
                editReasons.push(EditFeedbackReason.identifyingComment);
                break;
            case CoachFeedbackStatus.improvementAreasRejected:
                editReasons.push(EditFeedbackReason.improvementAreas);
                break;
            default:
                break;
        }
    });

    const firstRouteIndex = editReasons.indexOf(firstRoute);
    if (firstRouteIndex === -1) {
        console.error(`${firstRoute} was not found after collecting statuses from ${editReasons.toString()}`);
        return [];
    }

    editReasons.splice(firstRouteIndex, 1);
    editReasons.unshift(firstRoute);
    return editReasons;
}
