import React from 'react'
import {Box, styled, Typography} from "@mui/material";

const StyledBox = styled(Box)(({theme})=> ({
        color: theme.custom.darkerPrimaryColor
}))

export const SoldOut = (): React.ReactElement => {

    return (<StyledBox>
        <Typography sx={{
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            lineHeight: "20px",
            padding: "8px 8px 0",
        }}>Sorry, Academy Feedback is sold out!</Typography>
        
        <Typography sx={{
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: "24px",
            padding: "8px",
        }}>Availability refreshed daily, check back tomorrow</Typography>

    </StyledBox>)
}