import React, {useCallback, useEffect, useState} from "react";
import {Box, CircularProgress, Link} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {FeedbackItem, Status} from "./item/FeedbackItem";
import {HomeSection} from "../HomeSection";
import {CoachFeedbackStatus, FetchCoachFeedbackStatus, ICoachFeedbackResult} from "../../../../types/coachFeedback";
import {routes} from "../../../../constants/routes";
import {coachFeedbackServiceApi} from "../../../../services/coach-feedback-service";
import {CenteredItemsBox, CircledButton, LightDivider, TitleErrorTypography} from "../../../../shared/styles";
import {FailedFeedbackItem} from "./item/FailedFeedbackItem";

export const pageSize = 10;
export const initialPage = 1;
export const fetchProcessingFeedbackStatuses: FetchCoachFeedbackStatus[] = [FetchCoachFeedbackStatus.inReview, FetchCoachFeedbackStatus.userIdentificationRejected, FetchCoachFeedbackStatus.improvementAreasRejected, FetchCoachFeedbackStatus.videoQualityRejected, FetchCoachFeedbackStatus.otherRejected];
export const ProcessingFeedbackSection = () => {
    const navigate = useNavigate();
    const [localResults, setLocalResults] = useState<ICoachFeedbackResult[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(initialPage);
    const {isLoading, data: coachFeedbackData, isFetching: fetchingMore} = coachFeedbackServiceApi.useGetCoachFeedbackQuery({page: currentPage, pageSize, status: fetchProcessingFeedbackStatuses}, {refetchOnMountOrArgChange: true});
    const issuesExist = localResults.some(result => !result.status.includes(CoachFeedbackStatus.inReview));

    const updateLocalResults = useCallback(() => {
        if (!coachFeedbackData) {
            return;
        }
        setLocalResults(coachFeedbackData?.previousPageCount ? localResults.concat(coachFeedbackData.results) : coachFeedbackData.results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coachFeedbackData]);

    useEffect(() => {
        updateLocalResults();
    }, [updateLocalResults]);

    const handleLoadMore = useCallback(() => {
        if (coachFeedbackData?.nextPageCount) {
            setCurrentPage((prevPage: number) => prevPage + 1);
        }
    }, [coachFeedbackData, setCurrentPage]);

    return (
        <>
            {isLoading && <CircularProgress sx={{m: "10px auto"}} />}
            {!isLoading && (
                <HomeSection
                    title="Submitted"
                    emptyContentSubtitle={<>You have no videos waiting for feedback.&nbsp;
                        <Link color="secondary" sx={{cursor: "pointer"}} onClick={() => navigate(routes.purchase)}>Submit a video for feedback.</Link>
                    </>}
                    content={(
                        localResults.length ?
                            <>
                                {issuesExist && <TitleErrorTypography sx={{mb: "16px"}}>Please help us resolve some issues with your video</TitleErrorTypography>}
                                {localResults.map((result, index) => (
                                    <Box key={result.id}>
                                        {result.status.includes(CoachFeedbackStatus.inReview) ? <FeedbackItem
                                            videoItem={{
                                                src: result.video?.url ?? null,
                                                title: result.displayName || "",
                                                createdDate: result.createdAt,
                                                status: Status.Reviewing
                                            }}
                                        /> : <FailedFeedbackItem feedback={result}/>}
                                        {!result.status.includes(CoachFeedbackStatus.inReview) && (index !== localResults.length - 1) && <LightDivider sx={{m: "-20px 0 20px"}} />}
                                    </Box>
                                ))}
                                {coachFeedbackData?.nextPageCount && (
                                    <CenteredItemsBox sx={{mt: "10px"}}>
                                        <CircledButton variant="outlined" size="large" color="secondary" endIcon={fetchingMore && <CircularProgress size={12} color="secondary"/>} onClick={handleLoadMore}>
                                            Load more...
                                        </CircledButton>
                                    </CenteredItemsBox>
                                )}
                            </>
                        : null
                    )}
                />
            )}
        </>
    );
}
