import {createTheme} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        olive: Palette['primary'];
    }

    interface PaletteOptions {
        olive?: PaletteOptions['primary'];
    }
}

export const viatheme = createTheme({
    custom: {
        name: 'via',
        academyName: 'Via Academy',
        isWhiteLabelled: false,
        stripe: {
            colorPrimary: "#EEEEEE", //this.palette.secondary.main,
            colorBackground: "#121212", //this.custom.isWhiteLabelled ? this.custom.mainBackgroundColor : this.custom.mainBackgroundColor,
            colorText: "#EEEEEE", //this.custom.isWhiteLabelled ? this.custom.stripePaymentText : this.palette.text.primary,
            colorDanger: "#FF6E6E", //this.palette.warning.main,
            colorWarning: "#FF6E6E", //this.palette.warning.main,
            fontFamily:  [
                'Poppins',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','), //this.typography.fontFamily,
            fontSizeBase: "16px",
            fontWeightNormal: "400",
            spacingUnit: "5px",
            borderRadius: "16px",
        },
        wl: {
            bottomCTA: {
                buttonBackgroundColor: "#0054A4",
                buttonColor: "#FFFFFF"
            },
            purchaseStepHeader: {
                backgroundColor: '#121212',
                color: "#0054A4",
                liveProgressTextColor: "#000000",
                progressBackgroundColor: "#999999"
            },
            improvementStep: {
                backgroundColor: '#012448',
                color: '#FFFFFF'
            },
            bottomAppTabs: {
                backgroundColor: "#FFFFFF",
                color: "#012448",
                borderTop: "#B3BDC8"
            }
        },
        headerBrandTitleColor: 'primary',
        headerBrandTitleBackgroundColor: "#121212",
        headerBrandTitleText: 'COACH FEEDBACK',
        brandTitleLogo: "/via_logo_gold.svg",
        contentFeedBackgroundColor: "#121212",
        mainBackgroundColor: "#121212",
        coachFeedbackVideoSampleLink: "https://via.kz-cdn.net/static/samples/qpr_sample.mp4",
        coachFeedbackVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/qpr_sample_thumbnail.jpg",
        submissionVideoSampleLink: "https://via.kz-cdn.net/static/samples/video_guidelines.mp4",
        submissionVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/video_guidelines_thumbnail.jpg",
    },
    typography: {
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            textTransform: "none"
        }
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#DAB870',
            contrastText: '#081111',
        },
        secondary: {
            main: '#EEEEEE',
            contrastText: '#081111',
        },
        warning: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        error: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        info: {
            main: '#2196f3',
            contrastText: '#DAB870',
        },
        text: {
            primary: '#D2D2D2',
            secondary: '#999999',
        },
        grey: {
            100: '#F1F1F1',
            200: '#E8E8E8',
            300: '#D2D2D2',
            500: '#AAAAAA',
            600: '#A9A9A9',
            700: '#616161',
            800: '#414141',
            900: '#121212'
        },
        common: {
            black: '#081111'
        },
        background: {
            paper: '#F1F1F1'
        },
        divider: "#222222",
        olive: {
            main: '#162121',
        }
    },
    // components: {
    //     MuiTypography: {
    //         defaultProps: {
    //             variantMapping: {
    //                 h1: 'h2',
    //                 h2: 'h2',
    //                 h3: 'h2',
    //                 h4: 'h2',
    //                 h5: 'h2',
    //                 h6: 'h2',
    //                 subtitle1: 'h2',
    //                 subtitle2: 'h2',
    //                 body1: 'span',
    //                 body2: 'span',
    //             },
    //         },
    //     },
    // },
})
