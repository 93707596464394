import React from "react";
import {Box, Button, styled, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";
import {RowBox} from "../../shared/styles";

interface IAddVideoBoxProps {
    boxTitle: string;
    boxSubtitle: string;
    sx: Object;
    onAddNew: () => void;
}

const StyledButton = styled(Button)(({theme}) => ({
    display: "flex",
    justifyContent: "left",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23414141' stroke-width='6' stroke-dasharray='10' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e")`,
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.uploadBoxColor : theme.palette.olive.main,
    borderRadius: "8px",
    padding: "15px 20px",
    "&:hover": {
        border: "none",
        backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.uploadBoxColor : theme.palette.olive.main,
    },
}));
const StylesPlusIcon = styled(Add)(({theme}) => ({
    color: theme.palette.secondary.main,
}));
const StyledBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
}));
const StyledTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.secondary.main,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "500"
}));
const StyledNote = styled(Typography)(({theme}) => ({
    color: theme.palette.grey[500],
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "400"
}));

export const AddVideoBox = ({ boxTitle, boxSubtitle = "", sx, onAddNew }: IAddVideoBoxProps): JSX.Element => {
    return (
        <StyledButton
            fullWidth
            sx={sx}
            variant="outlined"
            color="secondary"
            onClick={onAddNew}
        >
            <StyledBox>
                <RowBox sx={{mb: "8px"}}><StylesPlusIcon /><StyledTypography>{boxTitle}</StyledTypography></RowBox>
                {boxSubtitle && <StyledNote>{boxSubtitle}</StyledNote>}
            </StyledBox>
        </StyledButton>
    );
}
