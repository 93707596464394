import {createSetupIntent, createUserSubscription} from "../services/api";
import {CreateSetupIntentArgs, CreateUserSubscriptionResponse} from "../features/subscriptions/subscriptionsSlice";
import {PaymentMethodCreateParams, SetupIntent, Stripe, StripeCardElement, StripeError} from "@stripe/stripe-js";
import {viaDelay} from "./utils";


export interface TrialSubscriptionPurchaseResponse {
    error?: StripeError|string
    setupIntent?:SetupIntent
    subscriptionResponse?: CreateUserSubscriptionResponse
}


export const performTrialSubscriptionPurchase = async (
                            stripe: Stripe,
                            cardElement: StripeCardElement,
                            address: PaymentMethodCreateParams.BillingDetails.Address,
                            organisationId: string,
                            proSubSKU: string,
                            email: string
                        ): Promise<TrialSubscriptionPurchaseResponse> => {

    const createSetupIntentArgs: CreateSetupIntentArgs = {
        organisation_id: organisationId,
        source: "CLUB_VFA",
        customer_email: email,
    }
    const setupIntentVals = await createSetupIntent(createSetupIntentArgs)

    console.log("setupIntentVals", setupIntentVals)

    console.log("delay...")
    await viaDelay(1000)
    console.log("delay...")
    await viaDelay(1000)
    console.log("delay...")
    await viaDelay(1000)

    const { error, setupIntent } = await stripe.confirmCardSetup(setupIntentVals.client_secret, {
        payment_method: {
            card: cardElement,
            billing_details: {
                address,
                email,
            },
        },
    });

    if (error) {
        // setErrorMessage(error.message || 'Payment failed');
        // setTimeout(async ()=> makeTrialPurchase().then(), 2000)
        console.log("confirmCardSetup error: ", error)
        return {error: error}
    } else if (setupIntent) {

        console.log("delay...")
        await viaDelay(1000)
        console.log("delay...")
        await viaDelay(1000)
        console.log("delay...")
        await viaDelay(1000)


        const paymentMethodId: string|undefined = typeof setupIntent.payment_method === "string" ? setupIntent.payment_method : undefined
        const createSubArgs =  {
            sku: proSubSKU,
            receipt_recipient: email,
            organisation_id: organisationId,
            source: "CLUB_VFA",
            activate_trial: true,
            payment_method_id: paymentMethodId,
        }
        const createSubResponse = await createUserSubscription(createSubArgs)

        return {setupIntent: setupIntent, subscriptionResponse: createSubResponse}
    }
    else
        return {error: "invalid response from confirmCardSetup"}
}

export interface SubscriptionPurchaseResult {
    error?: StripeError|string
}


export const performSubscriptionPurchase = async (): Promise<SubscriptionPurchaseResult> => {

    return {error: "invalid response from confirmCardPayment"}
}