import React from "react"
import {Divider, Box, Typography, Link} from "@mui/material";
import {Links} from "../constants/links";
import {useTheme} from "@mui/material";

export const Footer = (): JSX.Element => {
    const theme = useTheme(); 
    return (
        <footer style={{minHeight: "70px", zIndex: 2}}>
            <Divider/>
            <Box style={{margin: "16px"}}>
                <Box display="flex" justifyContent="center">
                    <Link href={theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy} target="_blank" underline="hover" sx={{mr: "12px"}} color="text.primary" variant="caption">
                        Privacy policy
                    </Link>
                    <Link href={theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms} target="_blank" underline="hover" color="text.primary" variant="caption">
                        Terms & Conditions
                    </Link>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="caption" color="text.secondary">
                        &copy; {new Date().getFullYear()} Kaizhan Ltd trading as VIA Academy.
                    </Typography>
                </Box>
            </Box>
        </footer>
    );
}
