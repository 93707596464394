import React, {useCallback, useState} from 'react'
import {Button} from "@mui/material";
import {resetToken, roleHasAccess, setIdToken, socialSignIn} from "../../../services/cognito";
import {accountErrors} from "../../../constants/account";
import {Analytics} from "../../../lib/analytics";
import {ErrorTypography} from "../../../shared/styles";
import {setSignupMode} from "../../../util/storage";

interface SocialSignUpAgreeButtonProps {
    selectedSocialProvider: string
    dateOfBirth: number
    marketingOptIn: boolean
    disabled: boolean
}

export const SocialSignUpAgreeButton = (props: SocialSignUpAgreeButtonProps): React.ReactElement => {
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [submitCognitoError, setSubmitCognitoError] = useState<string | null>(null);

    const setUpAuthenticatedData = useCallback(async () => {
        Analytics.createAccountSocialSignUpSuccess(props.selectedSocialProvider);
        await setIdToken();
    }, [props.selectedSocialProvider])

    const signIn = async (provider: string) => {
        Analytics.createAccountSocialSignUpButtonClick(provider);
        setAuthenticating(true);
        try {
            await socialSignIn(provider);
            const hasAccess = await roleHasAccess();
            if (hasAccess) {
                await setUpAuthenticatedData();
            } else {
                resetToken();
                setSubmitCognitoError(accountErrors.wrongRole);
            }
        } finally {
            setAuthenticating(false);
        }
    }


    return (
        <>
            <Button
                onClick={() => {
                    setSignupMode(props.dateOfBirth, props.marketingOptIn)
                    signIn(props.selectedSocialProvider)
                }}
                disabled={authenticating || props.disabled}
                {...{
                    fullWidth: true,
                    color: "primary",
                    variant: "contained",
                    sx: {
                        borderRadius: "31px",
                        minHeight: "60px",
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "600",
                        margin: "30px 0"
                    }
                }}
            >Agree and Continue</Button>
            {submitCognitoError && <ErrorTypography align={"center"}>{submitCognitoError}</ErrorTypography>}
        </>

)


}