import React, {useEffect, useState} from 'react'
import {Box, Button, IconButton, styled, Typography} from "@mui/material";
import {Loader} from "../../shared/Loader";
import {ScreenLayout} from "../../shared/ScreenLayout";

import {
    ImageCarouselFeedEntryPage,
    getAcademyItemThunk, FeedItem, setSendingLikeTo, likeItemThunk, VideoFeedEntryPage
} from "../../features/academyContent/academyContentSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {useNavigate, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {cmsPageType} from "../../constants/cms";
import {routes} from "../../constants/routes";
import {timeSince} from "../../lib/utils";
import {useVideoDimensions} from "../../hooks/useVideoDimensions";
import {Analytics} from "../../lib/analytics";
import {HLSVideoPlayer} from "../../shared/HLSVideoPlayer";
import {EmojiReplace} from "../../lib/EmojiReplace";
import {useTheme} from "@mui/material/styles";
import DynamicTopBarColor from '../../DynamicTopBarColor';
import Divider from '@mui/material/Divider';
import {setCurrentPostPath} from "../../util/storage";

const StyledBackButton = styled(Button)(() => ({
    fontSize: "32px",
    fontWeight: "700",
    margin: "0",
    padding: "10px",
    minWidth: "5px"
}));


export const AcademyPost = (): React.ReactElement => {
    const navigate = useNavigate()
    const params = useParams();
    const postId = params.postId ? params.postId : ""
    const theme = useTheme();

    const dispatch = useAppDispatch()

    const postContent = useAppSelector<FeedItem|undefined>((state: RootState) => state.academyContent.item);
    const fetchingItem = useAppSelector<boolean>((state: RootState) => state.academyContent.fetchingItem);

    // const post = postContent as ImageCarouselFeedEntryPage
    const video_post = (postContent && postContent.meta.type === cmsPageType.VideoFeedEntryPage) ? postContent as VideoFeedEntryPage : undefined
    const image_post = (postContent && postContent.meta.type === cmsPageType.ImageCarouselFeedEntryPage) ? postContent as ImageCarouselFeedEntryPage : undefined


    const post = postContent as ImageCarouselFeedEntryPage
    const {dimensions, error } = useVideoDimensions(video_post?.mp4_url || "")
    console.log(error)

    const postReactions =
        video_post ? video_post.reactions :
            image_post ? image_post.reactions:
                {
                    like_failures: 0,
                    user_has_reacted: 0,
                    count: 0,
                }

    useEffect(() => {
        if(postId)
            dispatch(getAcademyItemThunk(postId))
        if(postId && postContent?.meta.type) {
            Analytics.insightsPostView(postId, postContent?.meta.type);
            setCurrentPostPath(routes.academyPost.replace(":postId", postId))
        }
    },[postId, postContent?.meta.type, dispatch])

    const [likeSent, setLikeSent] = useState(false)
    const likeFailureFlip =
        (( (postReactions.user_has_reacted ? 1 : 0) + (postReactions.like_failures || 0)) % 2)
    const liked = !!likeFailureFlip ? !likeSent : likeSent
    const likeFailureReverseCount = (postReactions.like_failures || 0)%2
    const likeCounted: number = postReactions.user_has_reacted
        ? (likeSent ? -1 + likeFailureReverseCount : 0 - likeFailureReverseCount)
        : (likeSent ? 1 - likeFailureReverseCount : likeFailureReverseCount)



    return (
        <>
            <DynamicTopBarColor color={theme.custom.mainBackgroundColor}/>
            <Box sx={{display: "flex", flex: 1}}>
                <ScreenLayout
                    title={<StyledBackButton onClick={()=> navigate(postContent ? routes.insights + "#item-" + postContent.id : routes.insights)}><KeyboardBackspaceIcon/></StyledBackButton>}
                    ctaTitle={""}
                    onAction={()=>{}}
                    displayCta={false}
                    contentMarginTop={"0"}
                    contentPadding={"0"}
                    headerMarginTop={"0"}
                    headerPadding={"0"}
                    contentFeedBackgroundColor="#FFFFFF"
                ><>
                    { ((image_post === undefined && video_post === undefined) || fetchingItem) && !dimensions ?
                        <Loader/> :
                        <>
                            {image_post && image_post.carousel_items.map((ci, i) =>
                                <img key={i} alt={ci.caption} width="100%" src={ci.image.meta.download_url}/>
                            )}
                            {video_post &&
                                <HLSVideoPlayer
                                    style={{backgroundColor: "#000000"}}
                                    controls
                                    width="100%"
                                    height={dimensions && dimensions.height >= dimensions.width ? "250px" : undefined}
                                    hlsSrc={video_post.stream_url} // URL of the HLS stream
                                    mp4Src={video_post.mp4_url} // Fallback URL of the MP4 video
                                    videoPageId={video_post.id}

                                    autoPlay
                                    // muted={true}
                                    playsInline
                                />
                            }
                            <Box sx={{padding: "16px 16px 0 16px"}}>
                                <Typography sx={{fontSize: "18px", fontWeight: "600"}}><EmojiReplace stringText={post.title}/></Typography>
                                <Typography sx={{fontSize: "12px"}} variant="caption" color="#666666">
                                    {timeSince(post.meta.first_published_at)}
                                </Typography>
                            </Box>
                            <Box sx={{padding: "16px 12px"}}>
                                <IconButton 
                                    aria-label="like" 
                                    sx={{
                                        color:theme.custom.communityLikertTextColor,
                                        fontSize: '16px',
                                        flexShrink: 0,
                                        backgroundColor: "#E4E8F2",
                                        '&:hover': {
                                        backgroundColor: "#B3BDC8",
                                        },
                                        width: "auto",
                                        height: "32px", 
                                        borderRadius: "16px", 
                                        padding: "0 8px", 
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }} 
                                    onClick={() => {
                                        dispatch(setSendingLikeTo(post.id))
                                        dispatch(likeItemThunk(post.id))
                                        setLikeSent(!likeSent)
                                    }}  >
                                    { liked ?
                                        <FavoriteIcon sx={{ fontSize: '16px' }}/>
                                        :
                                        <FavoriteBorderIcon sx={{ fontSize: '16px' }}/>
                                    }
                                    {(post.reactions.count + likeCounted) > 0 && (post.reactions.count + likeCounted) }
                                </IconButton>
                            </Box>
                            <Box sx={{ paddingTop: '8px', paddingBottom: '0px', paddingLeft: '12px', paddingRight: '12px' }}>
                                <Divider sx={{ borderBottomWidth: '0.1px', borderColor: theme.custom.wl.bottomAppTabs.borderTop }}/>
                            </Box>
                            <Box
                                sx={{
                                    fontSize: '16px',
                                    color: theme.custom.darkerPrimaryColor,
                                    padding: "0 16px",
                                    '& a': {
                                        color: theme.custom.darkerPrimaryColor,
                                    },
                                    '& *': {
                                        color: theme.custom.darkerPrimaryColor,
                                    }
                                }}
                                dangerouslySetInnerHTML={{__html: (video_post !== undefined ? video_post.body : (image_post !== undefined ? image_post.body : "") ) } }

                            />

                        </>
                    }
                </>
                </ScreenLayout>
            </Box>
        </>
    )

}