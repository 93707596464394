import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserProfileOrganisation} from "../user/userSlice";
import {
    createUserSubscription, deleteSubscription,
    getIntendedSubscription,
    getSubscriptionProducts,
    getUserSubscriptions
} from "../../services/api";

export const getSubscriptionProductCategorySlug = (): string => {
    return "pro-membership"
}

export interface PaymentIntent {
        "id": string
        "client_secret": string
}

export interface SubscriptionsResponse{
    "next"?: number
    "previous"?: number,
    "count": number,
    "results":  SubscriptionProduct[]
}

export interface UserSubscriptionsResponse{
    "next"?: number
    "previous"?: number,
    "count": number,
    "results":  UserSubscription[]
}

export const getSubscriptionProductsThunk = createAsyncThunk<SubscriptionsResponse, void>(
    'subscriptions/getSubscriptionProducts',
    async (args) => {
        console.log(args)
        const categorySlug = getSubscriptionProductCategorySlug()
        const gotten = await getSubscriptionProducts(categorySlug)
        return gotten as SubscriptionsResponse;
    }
)

export const getUserSubscriptionsThunk = createAsyncThunk<UserSubscriptionsResponse, void>(
    'subscriptions/getUserSubscriptions',
    async (args, {dispatch}) => {
        console.log(args)
        const gotten = await getUserSubscriptions()
        const hasActiveSubscription = gotten.results.some(sub => sub.status === "ACTIVE")
        if(hasActiveSubscription){
            const incompleteSubs = gotten.results.filter(sub => sub.status === "INCOMPLETE")
            for(let i = 0; i < incompleteSubs.length; i++) {
                dispatch(deleteSubscriptionThunk(incompleteSubs[i].uuid))
            }
            const nonIncomplete = gotten.results.filter(sub => sub.status !== "INCOMPLETE")
            return {...gotten, results: nonIncomplete} as UserSubscriptionsResponse
        }
        return gotten as UserSubscriptionsResponse;
    }
)


export const getSubscriptionPurchaseRecordThunk = createAsyncThunk<UserSubscription, string>(
    'subscriptions/getRecord',
    async (args) => {
        return await getIntendedSubscription(args)
    }
)

export const getIntendedSubscriptionThunk = createAsyncThunk<UserSubscription, string>(
    'subscriptions/getIntended',
    async (args) => {
        return await getIntendedSubscription(args)
    }
)

export const deleteSubscriptionThunk = createAsyncThunk<UserSubscription, string>(
    'subscriptions/delete',
    async (args) => {
        const gotten = await deleteSubscription(args)
        return gotten as UserSubscription;
    }
)

export interface CreateSubscriptionArgs {
    sku: string
    receipt_recipient: string
    source: string
    organisation_id: string
    activate_trial: boolean
    payment_method_id?: string
}

export interface CreateUserSubscriptionResponse {
    uuid: string
    status: string
    product: string
}

export const createSubscriptionThunk = createAsyncThunk<CreateUserSubscriptionResponse, CreateSubscriptionArgs>(
    'subscriptions/create',
    async (args, { dispatch } ) => {
        console.log(args)
        const gotten = await createUserSubscription(args)
        dispatch(getIntendedSubscriptionThunk(gotten.uuid))
        return gotten as CreateUserSubscriptionResponse;
    }
)

export interface CreateSetupIntentArgs {
    organisation_id: string
    source: string
    customer_email: string
}

// export interface SubscriptionOrderResponse {
//     "uuid": string
//     "status": string
//     "product": string
// }

export interface SubscriptionProduct {
    "name": string
    "category": {
            "name": string
            "slug": string
    }
    "organisation": UserProfileOrganisation
    "sku": string
    "price": string
    "stock_units": number
}

export interface UserSubscription {
    "uuid": string
    "cancelled": boolean
    "status": string
    "subscription_id": string
    "product":  SubscriptionProduct
    "period_start":  null | any
    "period_end": null | any
    "trial_activated": boolean
    "payment_intent"?: PaymentIntent
}

export interface TrialSetup{
    id: string
    client_secret: string
}

interface subscriptionsState {
    subscriptionProducts: SubscriptionProduct[]
    mySubscriptions: UserSubscription[]
    currentOrder?: UserSubscription
    currentTrialSetup?: TrialSetup
    trialUsed: boolean
    activatedSubscription: UserSubscription | undefined
    subReceipt?: UserSubscription
}

const initialState: subscriptionsState = {
    subscriptionProducts: [],
    mySubscriptions: [],
    currentOrder: undefined,
    currentTrialSetup: undefined,
    trialUsed: true,
    activatedSubscription: undefined,
    subReceipt: undefined
}

const findValidSub = (subs: UserSubscription[]): UserSubscription | undefined => {
    for(let i=0;i<subs.length;i++){
        if(subs[i].status === "ACTIVE" || subs[i].status === "TRIALING" || subs[i].status === "PAST_DUE"){
            return subs[i]
        }
    }
    return undefined
}

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetSubscriptionProducts: (state: subscriptionsState) => {
            state.subscriptionProducts = []
        },
        clearCurrentOrder: (state: subscriptionsState) => {
            state.currentOrder = undefined
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteSubscriptionThunk.fulfilled, (_state: subscriptionsState, action: PayloadAction<UserSubscription>) => {
            console.log("deleted subscription", action)
        })
        builder.addCase(getIntendedSubscriptionThunk.fulfilled, (state: subscriptionsState, action: PayloadAction<UserSubscription>) => {
            state.subReceipt = action.payload
            state.currentOrder = action.payload
        })
        builder.addCase(getSubscriptionProductsThunk.fulfilled, (state: subscriptionsState, action: PayloadAction<SubscriptionsResponse>) => {
            state.subscriptionProducts = action.payload.results
        })
        builder.addCase(getSubscriptionPurchaseRecordThunk.fulfilled, (state: subscriptionsState, action: PayloadAction<UserSubscription>) => {
            state.subReceipt = action.payload
        })
        builder.addCase(getUserSubscriptionsThunk.fulfilled, (state: subscriptionsState, action: PayloadAction<UserSubscriptionsResponse>) => {
            state.mySubscriptions = action.payload.results
            state.trialUsed = action.payload.results.some(sub => sub.trial_activated)
            // state.trialUsed = action.payload.results.some(sub => sub.status === "PAST_DUE")
            // state.trialUsed = true

            state.activatedSubscription = findValidSub(action.payload.results)
        })
    },
});

export const {   clearCurrentOrder, resetSubscriptionProducts } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer